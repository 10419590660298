import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import { WhiteLayoutContent } from "../../assets/styles/AppContainers";
import Grid from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ImportExport, North, South } from "@mui/icons-material";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const QuestionTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="top" arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0,0,0,0.9)",
    color: "#ffffff",
    maxWidth: 320,
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(0,0,0,0.9)",
  },
}));

const PulseBarCharts = ({
  data,
  chartType,
  chartTitle,
  selectedQuestions,
  surveyCount,
}) => {
  const [sortingOrder, setsortingOrder] = useState(0);
  const [sortQ, setSortQ] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const sortOrder = {
    none: 0,
    accesnding: 1,
    decesnding: 2,
  };
  data.sort((a, b) => {
    return a.surveyId - b.surveyId;
  });

  function sortByQuestionKey(questionKey, direction) {
    setsortingOrder(direction);
    setSortQ(questionKey);
    data.sort((a, b) => {
      if (direction === sortOrder.accesnding) {
        return a[questionKey] - b[questionKey];
      } else if (direction === sortOrder.decesnding) {
        return b[questionKey] - a[questionKey];
      } else {
        throw new Error(
          "Invalid sort direction. It should be either 'ascending' or 'descending'."
        );
      }
    });
  }

  var baseColor;
  switch (chartType) {
    case 1:
      baseColor = [
        "#45338C",
        "#5B4BA3",
        "#7264BB",
        "#887CD2",
        "#978DE2",
        "#ADA5F9",
        // "#7264BB",
        // "#796CC3",
        // "#8074CA",
        // "#887CD2",
        // "#8F84DA",
        // "#978DE2",
        // "#9E95E9",
        // "#A69DF1",
        // "#ADA5F9",
      ];
      break;
    case 2:
      baseColor = [
        "#E84F6B",
        "#ED6780",
        "#F27F95",
        "#F798AB",
        "#FCB0C0",
        "#FFC0CE",
        // "#F27F95",
        // "#F3889D",
        // "#F590A4",
        // "#F798AB",
        // "#F8A0B2",
        // "#FAA8B9",
        // "#FCB0C0",
        // "#FDB8C7",
        // "#FFC0CE",
      ];
      break;
    case 3:
      baseColor = [
        "#007A7A",
        "#0A9392",
        "#15ACAA",
        "#1FC5C1",
        "#29DED9",
        "#30EFE9",
        // "#15ACAA",
        // "#18B5B2",
        // "#1BBDB9",
        // "#1FC5C1",
        // "#22CEC9",
        // "#26D6D1",
        // "#29DED9",
        // "#2DE7E1",
        // "#30EFE9",
      ];
      break;
    case 4:
      baseColor = [
        "#53B5E0",
        "#5DBCE4",
        "#6DC6EB",
        "#7DD0F2",
        "#8CDBF8",
        "#92DEFB",
        // "#72CAED",
        // "#78CDF0",
        // "#7DD0F2",
        // "#82D4F4",
        // "#87D7F6",
        // "#8CDBF8",
        // "#92DEFB",
        // "#97E1FD",
        // "#9CE4FF",
      ];
      break;
    case 5:
      baseColor = [
        "#F980BC",
        "#FA8BC2",
        "#FB9ACB",
        "#FCAAD3",
        "#FEBADC",
        "#FFCAE5",
        // "#FCA0CE",
        // "#FCA5D1",
        // "#FCAAD3",
        // "#FDB0D6",
        // "#FDB5D9",
        // "#FEBADC",
        // "#FEBFDF",
        // "#FFC5E2",
        // "#FFCAE5",
      ];
      break;
    case 6:
      baseColor = [
        "#FFB205",
        "#FFB91C",
        "#FFC33E",
        "#FFCD60",
        "#FFD782",
        "#FFE1A4",
        // "#FFC649",
        // "#FFCA55",
        // "#FFCD60",
        // "#FFD06B",
        // "#FFD477",
        // "#FFD782",
        // "#FFDA8D",
        // "#FFDE99",
        // "#FFE1A4",
      ];
      break;
    case 7:
      baseColor = [
        "#FF5939",
        "#FF684B",
        "#FF7E66",
        "#FF9482",
        "#FFAA9D",
        "#FFC0B8",
        // "#FF856F",
        // "#FF8D79",
        // "#FF9482",
        // "#FF9B8B",
        // "#FFA394",
        // "#FFAA9D",
        // "#FFB1A6",
        // "#FFB9AF",
        // "#FFC0B8",
      ];
      break;
    case 8:
      baseColor = [
        "#53B5E0",
        "#5DBCE4",
        "#6DC6EB",
        "#7DD0F2",
        "#8CDBF8",
        "#92DEFB",
        // "#72CAED",
        // "#78CDF0",
        // "#7DD0F2",
        // "#82D4F4",
        // "#87D7F6",
        // "#8CDBF8",
        // "#92DEFB",
        // "#97E1FD",
        // "#9CE4FF",
      ];
      break;
    case 9:
      baseColor = [
        "#F980BC",
        "#FA8BC2",
        "#FB9ACB",
        "#FCAAD3",
        "#FEBADC",
        "#FFCAE5",
        // "#FCA0CE",
        // "#FCA5D1",
        // "#FCAAD3",
        // "#FDB0D6",
        // "#FDB5D9",
        // "#FEBADC",
        // "#FEBFDF",
        // "#FFC5E2",
        // "#FFCAE5",
      ];
      break;
    default:
      baseColor = [
        "#45338C",
        "#5B4BA3",
        "#7264BB",
        "#887CD2",
        "#978DE2",
        "#ADA5F9",
        // "#7264BB",
        // "#796CC3",
        // "#8074CA",
        // "#887CD2",
        // "#8F84DA",
        // "#978DE2",
        // "#9E95E9",
        // "#A69DF1",
        // "#ADA5F9",
      ];
  }
  const questionKeys = Object.keys(data[0]).filter(
    (key) => !["name", "Decile", "QuestionText", "surveyId"].includes(key)
  );
  const groupedData = data.reduce((acc, item) => {
    (acc[item.name] = acc[item.name] || []).push(item);
    return acc;
  }, {});

  const countXaxisBarTicks = {};
  data.forEach((item) => {
    countXaxisBarTicks[item.name] = (countXaxisBarTicks[item.name] || 0) + 1;
  });

  const downloadChart = () => {
    setIsLoader(true);
    return new Promise((resolve, reject) => {
      try {
        const mgtChart = "chart-download";
        const chartContainer = document.getElementById(mgtChart);
        if (!chartContainer) {
          console.error(`Element with id '${mgtChart}' not found in the DOM.`);
          reject(
            new Error(`Element with id '${mgtChart}' not found in the DOM.`)
          );
          return;
        }

        const originalWidth = chartContainer.style.width;
        const originalPadding = chartContainer.style.padding;
        
        // Set styles for image download
       // chartContainer.style.width = '2200px';
        chartContainer.style.padding = '20px';
        chartContainer.style.backgroundColor = 'white';
    
        domtoimage
          .toBlob(chartContainer)
          .then((blob) => {
            saveAs(blob, `${chartTitle}Graph.png`);
          })
          .catch((error) => {
            console.error("Error generating screenshot:", error);
          })
          .finally(() => {
            chartContainer.style.width = originalWidth;
            chartContainer.style.padding = originalPadding;
            setIsLoader(false);
            resolve();
          });
      } catch (error) {
        setIsLoader(false);
        console.log("chart-download", error);
        reject(error);
      }
    });
  };

  return (
    <>
    
      {Object.keys(groupedData).map((name) => {
        const group = groupedData[name];
        const questionTexts = group[0].QuestionText;
        const transformedData = questionKeys.map((q, qIndex) => {
          let obj = { name: q };
          group.forEach((item, dataIndex) => {
            obj[item.name + "_" + dataIndex] = item[q];
            obj["decile_" + dataIndex] = item.Decile[qIndex];
          });

          return obj;
        });

        return selectedQuestions?.length > 0 ? (
          selectedQuestions.map((selectedQuestion) => {
            const filterData = transformedData.filter(
              (q) => q.name === selectedQuestion
            );
            const columnWidth =
              selectedQuestions?.length === 1 || surveyCount > 5 ? 12 : 6;
            return (
              <Grid item lg={columnWidth} md={columnWidth} sm={12} xs={12}>
                <WhiteLayoutContent
                  className="analytics-graph"
                  id="chart-download"
                >
                  <div className="large-download-btn" style={{ zIndex: 10 }}>
                    <QuestionTooltip
                      title="Download Chart"
                      placement="top"
                      arrow
                    >
                      {isLoader ? (
                        <CircularProgress />
                      ) : (
                        <FileDownloadOutlinedIcon
                          className="large-graph-btn"                          
                          onClick={(e) => {e.preventDefault(); downloadChart();}}
                          sx={{fontSize: '22px'}}
                        />
                      )}
                    </QuestionTooltip>
                  </div>

                  <p style={{ textAlign: "center", paddingTop: "20px" }}>
                    {chartTitle}
                  </p>
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart
                      data={filterData}
                      barSize={40}
                      align="left"
                      margin={{ top: 20 }}
                      left={true}
                      offSet={20}
                    >
                      <CartesianGrid
                        strokeDasharray="1 5"
                        horizontal={false}
                        position="left"
                      />
                      <XAxis
                        dataKey="name"
                        position="left"
                        interval="preserveStart"
                      />
                      <YAxis domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />

                      <Legend
                        position={"top"}
                        payload={[
                          {
                            value: name,
                            type: "circle",
                            color: baseColor[0],
                          },
                        ]}
                      />
                      {group.map((item, dataIndex) => (
                        <Bar
                          key={item.name + "_" + dataIndex}
                          dataKey={item.name + "_" + dataIndex}
                          align={"left"}
                          ticks={{ position: "left" }}
                        >
                          {filterData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={baseColor[dataIndex % baseColor.length]}
                            />
                          ))}
                          <LabelList
                            dataKey={item.name + "_" + dataIndex}
                            position="top"
                            fill="#000"
                            formatter={(value) => value?.toFixed(2)}
                          />
                          <LabelList
                            dataKey={"decile_" + dataIndex}
                            position="insideMiddle"
                            fill="#fff"
                          />
                        </Bar>
                      ))}
                    </BarChart>
                  </ResponsiveContainer>
                </WhiteLayoutContent>
              </Grid>
            );
          })
        ) : (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <WhiteLayoutContent className="analytics-graph" id="chart-download">
              <div className="large-download-btn" style={{ zIndex: 10 }}>
                <QuestionTooltip title="Download Chart" placement="top" arrow>
                  {isLoader ? (
                    <CircularProgress />
                  ) : (
                    <FileDownloadOutlinedIcon
                      className="large-graph-btn"
                      sx={{fontSize: '22px'}}
                      onClick={() => downloadChart()}
                    />
                  )}
                </QuestionTooltip>
              </div>

              <p style={{ textAlign: "center", paddingTop: "20px" }}>
                {chartTitle}
              </p>
              <ResponsiveContainer width="100%" height={350}>
                <BarChart
                  data={transformedData}
                  barSize={40}
                  align="left"
                  verticalAlign="start"
                  margin={{ top: 20 }}
                >
                  <CartesianGrid strokeDasharray="1 5" horizontal={false} />
                  <XAxis dataKey="name" interval="preserveStart" />
                  <YAxis domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />

                  <Legend
                    payload={[
                      {
                        value: name,
                        type: "circle",
                        color: baseColor[0],
                      },
                    ]}
                  />
                  {group.map((item, dataIndex) => (
                    <Bar
                      key={item.name + "_" + dataIndex}
                      dataKey={item.name + "_" + dataIndex}
                      align={"left"}
                    >
                      {transformedData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={baseColor[dataIndex % baseColor.length]}
                        />
                      ))}
                      <LabelList
                        dataKey={item.name + "_" + dataIndex}
                        position="top"
                        fill="#000"
                        formatter={(value) => value?.toFixed(2)}
                      />
                      <LabelList
                        dataKey={"decile_" + dataIndex}
                        position="insideMiddle"
                        fill="#fff"
                      />
                    </Bar>
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </WhiteLayoutContent>
          </Grid>
        );
      })}
    </>
  );
};

const PulseBarChart = ({
  data,
  chartType,
  chartTitle,
  selectedQuestions,
  surveyCount,
}) => {
  return (
    <Grid container spacing={2}>
      <PulseBarCharts
        data={data}
        chartType={chartType}
        chartTitle={chartTitle}
        selectedQuestions={selectedQuestions}
        surveyCount={surveyCount}
      />
    </Grid>
  );
};

export default PulseBarChart;
