import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, InputLabel, Switch, Accordion, AccordionSummary, AccordionDetails, IconButton, Typography, Grid, Button, FormControlLabel, RadioGroup, Radio, FormLabel, Modal, Box, Alert } from '@mui/material';
import { AppContainer, QuestionsTextField, TableStyle, WhiteLayoutContent, QuestionsSelect, PulseTextField } from '../../../assets/styles/AppContainers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { QuestionsBankCategory } from '../../../utils/Constants';
import { questionSetService } from '../../../services/api/questionSetService'
import { Edit, OpenWith } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import PulseTooltip from '../../../components/PulseTooltip';

function QuestionPool({ Survey, onSurveyChange, OnHasUpdate }) {
    const { organization } = useSelector(store => store)
    const QCount = 12;
    const { questionBank } = useSelector(store => store.questionBank)

    const [questionSets, setQuestionSets] = useState([])
    const [isQSet, setIsQSet] = useState(false);
    const [addedQuestions, setAddedQuestions] = useState([]);
    const [expanded, setExpanded] = useState('');
    const [selectedText, sselectedText] = useState('');
    const managementSt = [
        { id: 1, text: 'Demands' },
        { id: 2, text: 'Control' },
        { id: 3, text: 'Manager Support' },
        { id: 4, text: 'Relationships' },
        { id: 5, text: 'Role' },
        { id: 6, text: 'Change' },
        { id: 7, text: 'Peer Support' }];
    const [selectedQuestionSignType, setSelectedQuestionSignType] = useState(null);
    const [selectedQuestionIdx, setSelectedQuestionIdx] = useState(null);

    const [selectedOpenQuestionIdx, setSelectedOpenQuestionIdx] = useState(null);
    const [selectedOpenText, setSelectedOpenText] = useState('');

    //list of questions selected from questions set
    const [addedQuestionSet, setAddedQuestionSet] = useState([]);

    //checked or unchecked state for radio buttons in question set
    const [selectedQuestionSet, setSelectedQuestionSet] = useState(-1);

    const [qOption, setQOption] = useState(0);
    const [openAddQuestion, setOpenAddQuestion] = useState(false);
    const [openAddOpenQuestion, setOpenAddOpenQuestion] = useState(false);

    // question Type
    const [selectedQuestionType, setSelectedQuestionType] = useState(null);
    const [selectedQuestionFormat, setSelectedQuestionFormat] = useState(0);

    // custom quesation's custom options
    const [customOpt, setCustomOption] = useState([]);
    const [showCustomOption, setShoWCustomOption] = useState(false);
    const [dragEnterItem, setDragEnterItem] = useState(null)
    const [dragItem, setDragItem] = useState(null)
    const [draggedObj, setDraggedObj] = useState(null)
    //useEffect(() => { }, [draggedObj])

    useEffect(() => {
        questionSetService.Get().then(data => {
            setQuestionSets(data)
        })
        if (Survey?.surveyQuestions !== null && Survey?.surveyQuestions?.length > 0) {
            let questionBankCount = Survey.surveyQuestions.filter(x => x.questionPoolType === 1).length;
            let customeQuesttionCount = Survey.surveyQuestions.filter(x => x.questionPoolType === 2).length;
            let QuestionSetCount = Survey.surveyQuestions.filter(x => x.questionPoolType === 3).length;
            if (questionBankCount > 0)//question bank
            {
                setQOption("2");
                setIsQSet(false)
                setAddedQuestions(Survey.surveyQuestions)
            }
            if (questionBankCount === 0 && customeQuesttionCount > 0 && QuestionSetCount === 0)//custome questions only
            {
                setQOption("3");
                setIsQSet(false)
                setAddedQuestions(Survey.surveyQuestions)
            }
            if (QuestionSetCount > 0) {
                setSelectedQuestionSet(Survey.surveyQuestions[0].managementStandard)
                setExpanded(Survey.surveyQuestions[0].managementStandard)
                setQOption("1");
                setIsQSet(true)
                setAddedQuestionSet(Survey.surveyQuestions)
            }
        }
    }, [])

    const AddQuestionSet = (id) => {
        ;
        setSelectedQuestionSet(id);
        if (id) {
            let fNewList = questionSets.filter(x => x.managementStandard === id);
            let newQSet = [];
            fNewList.map(element => {
                newQSet.push(
                    {
                        id: 0,
                        text: element.questionText.replace('[District/School/Setting]', organization.name),
                        questionPoolTypeId: element.id,
                        questionPoolType: 3,
                        questionLikertFormat: 2,
                        questionReponse: element.questionReponse,
                        order: element.order,
                        managementStandard: element.managementStandard,
                        surveyQuestionOptions: GuestionDefaultOptions(element.questionReponse + ""),
                        surveyId: Survey.id,
                        questionSignType: element.signType,
                    })
            });

            setAddedQuestionSet(newQSet);
            updateSurveyQuestions(newQSet);
        }
        else {
            setAddedQuestionSet([]);
            updateSurveyQuestions([]);
        }
    }
    const updateSurveyQuestions = (questions) => {
        ;
        onSurveyChange([...questions]);
        OnHasUpdate(true);
    }
    //screen1
    const RenderAddedQuestions = () => {
        let questionsToRender = [];

        if (qOption === '1')
            questionsToRender = addedQuestionSet;
        else
            questionsToRender = addedQuestions;

        if (questionsToRender?.length === 0)
            return <div className="no-block" style={{ marginTop: '10px' }}>No questions added.</div>
        if (questionsToRender.length > 0)
            return (
                <>
                    {qOption !== '1' ? <Alert severity="error" style={{ marginTop: '10px' }}>You can add up to 12 questions.</Alert > : null}
                    < TableStyle >
                        <table>
                            <thead>
                                <tr>
                                    <th>Question Text</th>
                                    <th>Question Type</th>
                                    <th>Choose from</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {questionsToRender.sort((x, y) => x.order - y.order).map((row, idx) => {
                                    return (
                                        <tr style={{ cursor: draggedObj === null ? 'grab' : 'grabbing' }}
                                            onDragStart={e => {
                                                if (row.questionPoolType !== 3) {
                                                    setDragEnterItem(idx)
                                                    setDraggedObj(questionsToRender[idx])
                                                    setDragItem(idx)
                                                    //   e.target.style = 'border-style: dotted ;';
                                                }
                                            }}

                                            onDragEnter={(e) => {
                                                if (row.questionPoolType !== 3) {
                                                    setDragEnterItem(idx)
                                                    //e.target.parentElement.style = 'border-style: dotted ;cursor :grabbing';
                                                    ////e.target.parentNode.style = 'background-color: lightgre ;';
                                                    //  e.target.parentElement.style.cursor = 'grab';
                                                    e.target.parentNode.style = 'border-style: dotted; border-color:darkcyan ;';
                                                }
                                            }}
                                            onDragLeave={(e) => {
                                                if (row.questionPoolType !== 3) {
                                                    e.target.parentNode.style = 'border-style: none ;';
                                                    //   e.target.style = 'cursor :grab';
                                                }
                                            }}
                                            onDragEnd={e => {
                                                if (row.questionPoolType !== 3) {
                                                    let questionsSort = questionsToRender;
                                                    let removeItem = questionsSort.findIndex(x => x.text === draggedObj.text)
                                                    questionsSort.splice(removeItem, 1)
                                                    questionsSort.splice(dragEnterItem, 0, draggedObj)
                                                    for (var i = 0; i < questionsSort.length; i++) {
                                                        questionsSort[i].order = i + 1;
                                                    }
                                                    if (qOption === '1') {
                                                        setAddedQuestionSet(questionsSort)
                                                    }
                                                    else {
                                                        setAddedQuestions(questionsSort)
                                                    }

                                                    updateSurveyQuestions(questionsSort);
                                                    e.target.style = 'border-style: none';
                                                    // e.target.style = 'cursor :grab';
                                                    setDragEnterItem(null)
                                                    setDraggedObj(null)
                                                    // e.target.parentElement.cursor = 'grabbing';
                                                    // e.target.parentElement.parentElement.cursor = 'grabbing';
                                                }
                                            }
                                            }
                                            key={row.text}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            draggable
                                        >
                                            <td>
                                                {idx + 1}{'. '}
                                                {row.text}
                                            </td>
                                            <td>
                                                {row.questionLikertFormat === 1 ? 'Open' : 'Likert'}
                                            </td>
                                            <td>
                                                {
                                                    row.questionPoolType === 3 ? managementSt[row.managementStandard - 1]?.text : row.questionPoolType === 2 ? 'Custom Question' : 'Question Bank'

                                                }
                                            </td>
                                            <td>
                                                {((qOption === '3' || qOption === '2')|| (qOption === '1' &&  row.questionPoolType === 2 )) && <DeleteIcon style={{ cursor: 'pointer', fontSize: '18px', color: '#e84f6b' }} onClick={() => {
                                                    let removeQ = questionsToRender;
                                                    removeQ.splice(idx, 1)
                                                    // if (qOption === '1') {
                                                    //     setAddedQuestionSet(removeQ)
                                                    // }
                                                    // else {
                                                    //     setAddedQuestions(removeQ);
                                                    // }
                                                    setAddedQuestions(removeQ);

                                                    updateSurveyQuestions(removeQ);
                                                    sselectedText(null);
                                                }} />}
                                            </td>
                                            <td>
                                                {row.questionPoolType === 2 && qOption !== '1' ?
                                                    <Edit style={{ cursor: 'pointer', fontSize: '18px', color: '#1976d2' }} onClick={() => {
                                                        let selectedQ = addedQuestions[idx]
                                                        setSelectedQuestionIdx(idx)
                                                        //  console.loq(selectedQ)
                                                        setSelectedQuestionType(selectedQ.questionLikertFormat)
                                                        sselectedText(selectedQ.text)
                                                        setSelectedQuestionSignType(selectedQ.questionSignType)
                                                        setShoWCustomOption(selectedQ.questionReponse === 5)
                                                        setCustomOption(selectedQ.surveyQuestionOptions)
                                                        //SetCustomQuestionOptions(selectedQ.questionReponse)
                                                        setSelectedQuestionFormat(selectedQ.questionReponse)
                                                        setOpenAddQuestion(true)//close popup
                                                    }}></Edit> : ''}
                                            </td>
                                            <td>
                                                {row.questionPoolType !== 3 ?
                                                    <PulseTooltip style={{ fontSize: '16px', color: '#45338c' }} title="Drag & Drop" placement='left'><OpenWith></OpenWith></PulseTooltip> : null}
                                            </td>
                                        </tr>)
                                })}
                            </tbody>
                        </table>
                    </TableStyle >
                </>
            )
    }
    const GuestionDefaultOptions = (val) => {
        ;
        let tempOpt = [];
        if (val === "1") {
            tempOpt = [{ text: "Never", value: 1 }, { text: "Seldom", value: 2 }, { text: "Sometimes", value: 3 }, { text: "Often", value: 4 }, { text: "Always", value: 5 }];
        }
        if (val === "2") {
            tempOpt = [{ text: "Strongly disagree", value: 1 }, { text: "Disagree", value: 2 }, { text: "Neutral", value: 3 }, { text: "Agree", value: 4 }, { text: "Strongly agree", value: 5 }];
        }
        if (val === "3") {
            tempOpt = [{ text: "Very dissatisfied", value: 1 }, { text: "Dissatisfied", value: 2 }, { text: "Neutral", value: 3 }, { text: "Satisfied", value: 4 }, { text: "Very satisfied", value: 5 }];
        }
        if (val === "4") {
            tempOpt = [{ text: "Not important", value: 1 }, { text: "Slightly Important", value: 2 }, { text: "Fairly Important", value: 3 }, { text: "Important", value: 4 }, { text: "Very important", value: 5 }];
        }
        return tempOpt
    }
    const SetCustomQuestionOptions = (val) => {
        setSelectedQuestionFormat(val);
        // custom options
        if (val === "5") {
            setShoWCustomOption(true)
        }
        else {
            setShoWCustomOption(false);
            setCustomOption(GuestionDefaultOptions(val));
        }
    }
    const CutomOptionSettings = (val, idx) => {
        let tempOpt = customOpt;
        if (tempOpt[idx]) {
            tempOpt[idx].text = val;
            tempOpt[idx].value = idx + 1;
        }
        else {
            tempOpt.push({ text: val, value: idx + 1 });
        }
        // if (idx >= tempOpt.length)
        // {
        //     tempOpt.push({ text: val });
        // }
        // else
        // {
        //     tempOpt[idx].text = val;
        // }
        setCustomOption(tempOpt);
    }
    const RenderLabel = (label) => {
        var labelSplit = label.split(', ');
        return <Stack direction="row">
            <Typography style={{ color: GetLabelStyle(1) }}>{labelSplit[0]}</Typography>
            {', '}{labelSplit[1]}
            {', '}{labelSplit[2]}
            {', '}{labelSplit[3]}
            {', '} <Typography style={{ color: GetLabelStyle(5), paddingLeft: '0.3em' }}>{labelSplit[4]}</Typography>
        </Stack>

    }
    const GetLabelStyle = (level) => {
        if (selectedQuestionSignType === 1 && level === 5) {
            return 'red'
        }
        else if (selectedQuestionSignType === 1 && level === 1) {
            return 'green'
        }
        else if (selectedQuestionSignType === 2 && level === 1) {
            return 'red'
        }
        else if (selectedQuestionSignType === 2 && level === 5) {
            return 'green'
        }
    }
    const QuestionForm = () => {
        let form = (
            <Box style={{
                backgroundColor: '#F8FDFF',
                border: 'rgba(83, 181, 224, 0.5) 1px solid',
                marginTop: '12px',
                padding: 20,
                borderRadius: 4,
            }}>
                <IconButton
                    aria-label="close"
                    onClick={e => setOpenAddQuestion(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        fontSize: '22px'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {selectedQuestionIdx === null ? <h2>Add New Question</h2> : <h2>Edit Question</h2>}
                        {selectedQuestionIdx === null ? <p style={{ marginTop: '0', marginBottom: '25px' }}>Type your question  in the box below and  choose the question type.</p> : ''}
                    </Grid>
                </Grid>

                <Grid container rowGap={1} spacing={2}>


                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="question-type-select-label">Question Type</InputLabel>
                            <QuestionsSelect
                                labelId="question-type-select-label"
                                id="question-type-select"
                                label="Question Type"
                                defaultValue={null}
                                value={selectedQuestionType}
                                onChange={(e) => setSelectedQuestionType(e.target.value)}
                            >
                                <MenuItem value={1}>Open</MenuItem>
                                <MenuItem value={2}>Likert</MenuItem>
                            </QuestionsSelect>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        {selectedQuestionType === 2 ?
                            <FormControl fullWidth>
                                <InputLabel id="nature-simple-select-label">Question Nature</InputLabel>
                                <QuestionsSelect
                                    labelId="nature-simple-select-label"
                                    id="nature-simple-select"
                                    label="Question Nature"
                                    defaultValue={null}
                                    value={selectedQuestionSignType}
                                    onChange={(e) => setSelectedQuestionSignType(e.target.value)}
                                >
                                    <MenuItem value={2} sx={{ justifyContent: 'space-between' }}>
                                        Positive (1-5)
                                        <PulseTooltip title="Positive scale is 1 to 5 with 5 being the highest score.">
                                            <ErrorOutlineIcon sx={{ fontSize: 'large', float: 'right' }}></ErrorOutlineIcon>
                                        </PulseTooltip>
                                    </MenuItem>
                                    <MenuItem value={1} sx={{ justifyContent: 'space-between' }}>
                                        Negative (5-1)
                                        <PulseTooltip title="Negative scale is 5 to 1 with 5 being the lowest score.">
                                            <ErrorOutlineIcon sx={{ fontSize: 'large', float: 'right' }}></ErrorOutlineIcon>
                                        </PulseTooltip>
                                    </MenuItem>
                                </QuestionsSelect>
                            </FormControl>
                            : ''}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <QuestionsTextField label="Question" variant="outlined" fullWidth value={selectedText} onChange={(e) => sselectedText(e.target.value)} />
                    </Grid>
                    {
                        selectedQuestionType === 2 ?
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <FormControl>
                                    <FormLabel id="format-radio-label">Answer Format</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="format-radio-label"
                                        name="format-buttons-group"
                                        value={selectedQuestionFormat}
                                        onChange={(e) => {
                                            if (selectedQuestionSignType === null) {
                                                alert('Please select question nature before choosing the answer format. Thank you.')
                                                return
                                            }
                                            SetCustomQuestionOptions(e.target.value)
                                        }}
                                        style={{ marginTop: '10px' }}
                                    >
                                        <FormControlLabel value={1} control={<Radio />} label={RenderLabel("Never, Seldom, Sometimes, Often, Always")} />
                                        <FormControlLabel value={2} control={<Radio />} label={RenderLabel("Strongly disagree, Disagree, Neutral, Agree, Strongly agree")} />
                                        <FormControlLabel value={3} control={<Radio />} label={RenderLabel("Very dissatisfied, Dissatisfied, Neutral, Satisfied, Very satisfied")} />
                                        <FormControlLabel value={4} control={<Radio />} label={RenderLabel("Not important, Slightly Important, Fairly Important, Important, Very important")} />
                                        <FormControlLabel value={5} control={<Radio />} label="Custom" />
                                        {showCustomOption ?
                                            <Grid container spacing={1} mt={2}>
                                                <Grid item lg={2} md={2} sm={6} xs={12}>
                                                    <PulseTextField label={'Option 1'} value={customOpt[0]?.text} onChange={e => CutomOptionSettings(e.target.value, 0)} sx={{ input: { color: GetLabelStyle(1) } }}></PulseTextField>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={6} xs={12}>
                                                    <PulseTextField label={'Option 2'} value={customOpt[1]?.text} onChange={e => CutomOptionSettings(e.target.value, 1)}></PulseTextField>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={6} xs={12}>
                                                    <PulseTextField label={'Option 3'} value={customOpt[2]?.text} onChange={e => CutomOptionSettings(e.target.value, 2)}></PulseTextField>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={6} xs={12}>
                                                    <PulseTextField label={'Option 4'} value={customOpt[3]?.text} onChange={e => CutomOptionSettings(e.target.value, 3)}></PulseTextField>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={6} xs={12}>
                                                    <PulseTextField label={'Option 5'} value={customOpt[4]?.text} onChange={e => CutomOptionSettings(e.target.value, 4)} sx={{ input: { color: GetLabelStyle(5) } }}></PulseTextField>
                                                </Grid></Grid> : ''}
                                    </RadioGroup>
                                </FormControl>
                            </Grid> : ''
                    }
                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign='right'>
                        <Button variant="contained" className={addedQuestions.length < QCount ? 'add-question-btn' : 'grey-button'} onClick={() => {
                            if (addedQuestions.length < QCount) {
                                if (selectedQuestionType === 0 || selectedQuestionType === null) {
                                    alert('Please select question type.')
                                    return
                                }
                                if (selectedQuestionType === 2) {
                                    if (selectedQuestionSignType === 0 || selectedQuestionSignType === '0' || selectedQuestionSignType === null) {
                                        alert('Please select question nature.')
                                        return
                                    }
                                }
                                if (selectedText === '' || selectedText === null) {
                                    alert('Please enter question text.')
                                    return
                                }
                                if (selectedQuestionType === 2 && (selectedQuestionFormat === 0 || selectedQuestionFormat === null)) {
                                    alert('Please select answer format.')
                                    return
                                }
                                let newAddedQuestions = [];
                                if (selectedQuestionIdx === null) {
                                    newAddedQuestions = [...addedQuestions,
                                    {
                                        id: 0,
                                        text: selectedText.replace('[District/School/Setting]', organization.name),
                                        questionPoolType: 2,
                                        questionLikertFormat: selectedQuestionType,
                                        questionReponse: parseInt(selectedQuestionFormat),
                                        order: addedQuestions.length,
                                        managementStandard: 0,
                                        surveyQuestionOptions: selectedQuestionType === 1 ? null : customOpt,
                                        surveyId: Survey.id,
                                        questionSignType: selectedQuestionSignType,
                                    }]//todo : const for questionPoolType

                                }
                                else {
                                    newAddedQuestions = addedQuestions;
                                    let delted = newAddedQuestions.splice(selectedQuestionIdx, 1)
                                    newAddedQuestions.splice(selectedQuestionIdx, 0,
                                        {
                                            id: delted.id,
                                            text: selectedText.replace('[District/School/Setting]', organization.name),
                                            questionPoolType: 2,
                                            questionLikertFormat: selectedQuestionType,
                                            questionReponse: parseInt(selectedQuestionFormat),
                                            order: delted.order,
                                            managementStandard: 0,
                                            surveyQuestionOptions: selectedQuestionType === 1 ? null : customOpt,
                                            surveyId: Survey.id,
                                            questionSignType: selectedQuestionSignType,
                                        })
                                }
                                setSelectedQuestionIdx(null)
                                setAddedQuestions(newAddedQuestions);
                                sselectedText('')
                                updateSurveyQuestions(newAddedQuestions);
                                setSelectedQuestionType(null)
                                setSelectedQuestionFormat(0)
                                setSelectedQuestionSignType(null)
                                setShoWCustomOption(false);
                                setCustomOption([]);
                                setOpenAddQuestion(false)//close popup
                                setSelectedQuestionIdx(null)
                            }
                        }}>
                            {selectedText ? 'Save Question' : 'Add Question'}
                        </Button>
                    </Grid>

                </Grid >
            </Box >
        );
        return form
    }
    const OpenQuestionForm = () => {
        let form = (
            <Box style={{
                backgroundColor: '#F8FDFF',
                border: 'rgba(83, 181, 224, 0.5) 1px solid',
                marginTop: '12px',
                padding: 20,
                borderRadius: 4,
            }}>
                <IconButton
                    aria-label="close"
                    onClick={e => setOpenAddOpenQuestion(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        fontSize: '22px'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {selectedOpenQuestionIdx === null ? <h2>Add Open Question</h2> : <h2>Edit Question</h2>}
                        {selectedOpenQuestionIdx === null ? <p style={{ marginTop: '0', marginBottom: '25px' }}>Type open question  in the box below.</p> : ''}
                    </Grid>
                </Grid>

                <Grid container rowGap={1} spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <QuestionsTextField label="Question" variant="outlined" fullWidth value={selectedOpenText} onChange={(e) => setSelectedOpenText(e.target.value)} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign='right'>
                        <Button variant="contained" className={addedQuestionSet.length < QCount ? 'add-question-btn' : 'grey-button'} onClick={() => {
                            if (addedQuestionSet.length < QCount) {
                                let newAddedQuestions = null;
                                let newOpenQuestion = {
                                    id: 0,
                                    text: selectedOpenText,
                                    questionPoolType: 2,
                                    questionLikertFormat: 1,
                                    questionReponse: parseInt(selectedQuestionFormat),
                                    order: addedQuestionSet.length,
                                    managementStandard: 0,
                                    surveyQuestionOptions: null,
                                    surveyId: Survey.id,
                                    questionSignType: 0,
                                };

                                newAddedQuestions = addedQuestionSet;

                                if (selectedOpenQuestionIdx === null) {
                                    newAddedQuestions.push(newOpenQuestion);
                                }
                                else {
                                    let delted = newAddedQuestions.splice(selectedOpenQuestionIdx, 1);
                                    newOpenQuestion.id = delted.id;
                                    newAddedQuestions.splice(selectedOpenQuestionIdx, 0, newOpenQuestion)
                                }
                                // order the questions
                                for (var i = 0; i < newAddedQuestions.length; i++) {
                                    newAddedQuestions[i].order = i + 1;
                                }

                                setSelectedOpenQuestionIdx(null)
                                setAddedQuestionSet(newAddedQuestions);
                                setSelectedOpenText('')
                                setOpenAddOpenQuestion(false)
                                updateSurveyQuestions(newAddedQuestions);
                            }
                        }}>
                            {selectedText ? 'Save Question' : 'Add Question'}
                        </Button>
                    </Grid>

                </Grid >
            </Box >
        );
        return form
    }
    //screen1
    const GetAddQuestionPopup = () => {
        let popup =
            (<Modal
                open={openAddQuestion}
                onClose={() => setOpenAddQuestion(false)}
            >
                <div className='question-popup'>
                    {QuestionForm()}

                </div>
            </Modal>)
        return popup;
    }

    const GetAddOpenQuestionPopup = () => {
        let popup =
            (<Modal
                open={openAddOpenQuestion}
                onClose={() => setOpenAddOpenQuestion(false)}
            >
                <div className='question-popup'>
                    {OpenQuestionForm()}

                </div>
            </Modal>)
        return popup;
    }
    //screen1
    const GetQuestionBankPanel = () => {
        return (qOption > 1 ?


            QuestionsBankCategory.map(category => {
                return (
                    <Accordion className="accordion" disableGutters elevation={0}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel1a-content-${category.id}`}
                            id={`panel1a-header-${category.id}`}
                            className="accordion-header"
                        >
                            {category.name}
                        </AccordionSummary>
                        <AccordionDetails className='accordion-detail'>
                            {questionBank.filter(x => x.category === category.id).map((qbank, idx) => {
                                return (
                                    <Grid container lg={12} flexDirection='row'>
                                        <Grid item lg={10} md={10} sm={10} xs={10}>
                                            {qbank.questionText.replace('[District/School/Setting]', organization.name)}
                                        </Grid>
                                        <Grid lg={2} md={2} sm={2} xs={2} textAlign='end'>
                                            <Switch id={'switch-' + idx} disabled={addedQuestions.length >= QCount}
                                                value={addedQuestions.findIndex(x => (x.questionPoolTypeId === questionBank.filter(x => x.id === qbank.id)[0].id && x.questionPoolType === 1)) > -1}
                                                checked={addedQuestions.findIndex(x => (x.questionPoolTypeId === qbank.id && x.questionPoolType === 1)) > -1}
                                                onChange={(e) => {
                                                    let sQuestion = questionBank.filter(x => x.id === qbank.id);
                                                    let newAddedq = [];
                                                    let exists = addedQuestions.findIndex(x => (x.questionPoolTypeId === sQuestion[0].id && x.questionPoolType === 1));
                                                    if (exists === -1) {
                                                        if (addedQuestions.length < QCount) {
                                                            newAddedq = [...addedQuestions, {
                                                                surveyId: Survey.id,
                                                                id: 0,
                                                                questionPoolTypeId: sQuestion[0].id,
                                                                managementStandard: sQuestion[0].managementStandard,
                                                                questionPoolType: 1,
                                                                questionLikertFormat: sQuestion[0].type,
                                                                questionReponse: sQuestion[0].questionReponse,
                                                                text: sQuestion[0].questionText.replace('[District/School/Setting]', organization.name),
                                                                order: addedQuestions.length + 1,
                                                                questionSignType: sQuestion[0].signType,
                                                                surveyQuestionOptions: GuestionDefaultOptions(sQuestion[0].questionReponse + '')
                                                            }];
                                                            setAddedQuestions([...newAddedq]);
                                                            updateSurveyQuestions([...newAddedq])
                                                        }
                                                        else {
                                                            e.preventDefault();
                                                        }
                                                    }
                                                    else {
                                                        newAddedq = addedQuestions
                                                        newAddedq.splice(exists, 1);
                                                        setAddedQuestions([...newAddedq]);
                                                        updateSurveyQuestions([...newAddedq])
                                                    }
                                                }} ></Switch></Grid>
                                    </Grid>
                                )
                            })}
                        </AccordionDetails>
                    </Accordion>)
            })

            : '')
    }
    const GetQuestionSetPanel = () => {
        let qsetdiv =
            (<>
                {
                    managementSt.map((set, idx) => {

                        return (
                            <Accordion key={idx} className="accordion" disableGutters elevation={0}
                                aria-controls={`panelsq-content-${idx}`}
                                onChange={e => setExpanded(set.id)}
                                defaultExpanded={expanded === set.id}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id={`panelsq-summery-${idx}`}
                                    className="accordion-header"
                                >
                                    {set.text}
                                </AccordionSummary>
                                <AccordionDetails className='accordion-detail'>
                                    <div className='questionset-quiestions'>
                                        <span>
                                            {questionSets.filter(x => x.managementStandard === set.id).map(qq => {
                                                return <Typography className='questions'>{qq.questionText.replace('[District/School/Setting]', organization.name)}</Typography>
                                            })}
                                        </span>
                                        <span>
                                            <FormControlLabel control={
                                                <Switch
                                                    checked={selectedQuestionSet === set.id}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            AddQuestionSet(set.id)
                                                        }
                                                        else {
                                                            setSelectedQuestionSet(-1)
                                                            setAddedQuestionSet([]);
                                                            updateSurveyQuestions([]);
                                                        }
                                                    }} />}
                                            />
                                        </span>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }

            </>)
        return qsetdiv;
    }


    return (
        <AppContainer>
            <Grid container padding={3}>
                <Grid item lg={12} md={12} xs={12}>
                    <h2>Add Questions</h2>
                </Grid >
                <Grid item lg={12} md={12} xs={12}>
                    <div className='question-selection'>
                        <h3>Choose Questions</h3>
                        <RadioGroup
                            name="qOptions"
                            value={qOption}
                            onChange={(e) => setQOption(e.target.value)}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Choose from question set" />
                            <FormControlLabel value="2" control={<Radio />} label="Choose from question bank" />
                            <FormControlLabel value="3" control={<Radio />} label="Choose your own questions" />
                        </RadioGroup>
                    </div>
                </Grid>
                {qOption === '1' ? <>
                    <Grid item lg={12} md={12} xs={12}>
                        {RenderAddedQuestions()}

                        {addedQuestionSet.length > 0
                            && <div className='question-set' style={{ marginTop: '12px', padding: '10px' }}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={{ sm: 'space-between' }} alignItems="center">
                                    <h2 style={{ padding: '0' }}>Add Open Questions</h2>
                                    <Button variant='contained' className={addedQuestionSet.length < QCount ? 'next-button' : 'grey-button'} onClick={() => {
                                        if (addedQuestionSet.length >= QCount)
                                            return
                                        setOpenAddOpenQuestion(!openAddOpenQuestion)
                                        setSelectedOpenText(null)
                                    }}>Add Open Questions</Button></Stack></div>}
                        <div className='question-set' style={{ marginTop: '12px' }}>
                            <h2>Question Sets</h2>
                            {GetAddOpenQuestionPopup()}
                            {GetQuestionSetPanel()}
                        </div>
                    </Grid></> : ''}
                {qOption === '2' ? <>
                    <Grid item lg={12} md={12} xs={12}>
                        {RenderAddedQuestions()}
                        <div className='question-set' style={{ marginTop: '12px' }}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={{ sm: 'space-between' }} style={{ marginBottom: '20px' }}>
                                <h2>Choose Questions</h2>
                                <Button variant='contained' className={addedQuestions.length < QCount ? 'next-button' : 'grey-button'} onClick={() => {
                                    if (addedQuestions.length >= QCount)
                                        return
                                    setOpenAddQuestion(!openAddQuestion)
                                    sselectedText(null)
                                }}>Add New Questions</Button>
                            </Stack>
                            {GetAddQuestionPopup()}
                            {GetQuestionBankPanel()}
                        </div>
                    </Grid></> : ''}
                {qOption === '3' ? <>
                    <Grid item lg={12} md={12} xs={12}>
                        {RenderAddedQuestions()}
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        {QuestionForm()}
                    </Grid></> : ''}
            </Grid >
        </AppContainer >

    );
}

export default QuestionPool;