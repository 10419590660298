import React from 'react';
import { Grid } from '@mui/material';
import { AppContainer } from '../../assets/styles/AppContainers';
import { thankyou } from '../../assets/images/index';

function SurveySubmitted(props)
{
    return (
        <AppContainer>
            <Grid container justifyContent="center">
                <Grid item lg={8} md={8} sm={8} xs={12}>
                    <div className='thankyou'>
                        <img src={thankyou} alt='' />
                        <h1>Your responses have been saved.</h1>
                    </div>
                </Grid>
            </Grid>
        </AppContainer>
    );
}

export default SurveySubmitted;