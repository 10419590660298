import React from 'react';
import { WhiteLayoutContent } from '../assets/styles/AppContainers';
import { Grid, Modal, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


function ModalPopup({ onCancel, title, render, onAction }) {



    return (

        <Modal open={true} className='warning-modal' style={{ width: '50%'}}>
            <WhiteLayoutContent sx={{ padding: 3 }}>
                <IconButton
                    aria-label="close"
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        fontSize: '22px'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Grid container>
                    <Grid item lg={12} md={12} xs={12}>
                        <h1>{title} </h1>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12} mt={1}>
                        {render}
                    </Grid>
                </Grid>
                <Grid container mt={4}>
                    <Grid item lg={6} md={6} xs={6}
                        display="flex"
                        justifyContent="flex-start">
                        <Button variant="contained" className='prev-button' onClick={onCancel} >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item lg={6} md={6} xs={6}
                        display="flex"
                        justifyContent="flex-end">
                        <Button variant="contained" className='next-button' onClick={onAction} >
                            {title}
                        </Button>                        
                    </Grid>                    
                </Grid>
            </WhiteLayoutContent >
        </Modal >
    );
}

export default ModalPopup;