import { createSlice } from '@reduxjs/toolkit';

const initialState = { schoolTimezone: { id: 4, name: 'UK, Ireland, Lisbon Time' } };


export const schoolTimezoneSlice = createSlice({
    name: 'schoolTimezone',
    initialState,
    reducers: {
        updateSchoolTimezone: (state, action) =>
        {
            state.schoolTimezone.id = action.payload.id;
            state.schoolTimezone.name = action.payload.name;
        },
    },
})

export const { updateSchoolTimezone } = schoolTimezoneSlice.actions;
export default schoolTimezoneSlice.reducer 