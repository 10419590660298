import request from '../request';
import { surveyResultsApi } from '../Urls';

export class surveyResultsService {
    static async GetQuestionAverages(token) {
        return request({
            url: surveyResultsApi.GetQuestionAverages(),
            method: 'POST',
            data: token
        });
    }

    static async GetAnalyticsResults(analyticsRequest) {
        return request({
            url: surveyResultsApi.GetAnalyticsResults(),
            method: 'POST',
            data: analyticsRequest
        });
    }
    static async DownloadHeatmap(data) {
        return request({
            url: surveyResultsApi.DownloadHeatmap(),
            method: 'POST',
            data: data,
            responseType: 'blob',
        });
    }
}