import React, { useState } from 'react';
import { Grid, Popover, Snackbar, MenuItem, Modal, Collapse, IconButton, Box, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Popup from './Popup';
import MuiAlert from '@mui/material/Alert';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Surveys } from '../assets/styles/AppContainers';
import { SurveyStatus, PulseStatus, RepeatDuration } from '../utils/Constants'
import { Colors } from '../assets/styles/AppContainers';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { iconedit, icondelete, iconrerun, iconclose, iconcopy } from '../assets/images/index';
import { surveyService } from '../services';
import { useDispatch } from 'react-redux';
import { GetDateFromString, LoadSurveyDashboard } from '../utils/Utils'
import { ParticipantsList } from '../assets/styles/AppContainers';
import { ScrollContainer } from 'react-nice-scroll';
import { useNavigate } from "react-router-dom";
import { getCurrentSurveyForDashboard } from '../redux/features/surveySlice';
import SendInvitePopup from './SendInvitePopup';
import { Config } from '../utils/Config';
import { Pause, Done, HourglassEmpty, Bolt, Stop, History, SkipNext } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PauseButton from './PauseButton';
import PlayButton from './PlayButton';
import ScheduleForm from '../views/Surveys/SurveyForms/ScheduleForm';
import { ReRunSurveyButton } from './ReRunSurveyButton';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const OptType = {
    None: 0,
    Reopen: 1,
    Close: 2,
    Delete: 3,
}
function SurveyBlock({ survey, key, showReruns, reloadList, resetStatus }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSnak, setOpen] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [opt, setOpt] = React.useState(OptType.None);
    const [sendInvitesPopup, setInvitePopup] = React.useState(false);
    const [allRerun, showAllRerun] = useState(false);
    const [allRerunList, setAllRerunList] = useState([]);
    const [laodingReruns, setLoadingReruns] = useState(true);
    const [isCloning, setIsCloning] = useState(false);
    const [showRerunPanel, setShowrerunPanel] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const divRef = React.useRef();


    function handleClick() {
        setAnchorEl(divRef.current);
    }
    function handleClose() {
        setAnchorEl(null);
    }

    const GetstatusName = (id) => {
        if (id === SurveyStatus.Draft)
            return 'Draft'
        if (id === SurveyStatus.Open)
            return 'Scheduled'
        if (id === SurveyStatus.Live)
            return 'Live'
        if (id === SurveyStatus.Paused)
            return 'Paused'
        if (id === SurveyStatus.Stopped)
            return 'Stopped'
        return 'Completed'
    }
    const GetPulseStatusName = (id) => {
        if (id === PulseStatus.Published)
            return 'Published'
        if (id === PulseStatus.Live)
            return 'Live'
        if (id === PulseStatus.Close)
            return 'Close'
        if (id === PulseStatus.Paused)
            return 'Paused'
    }
    const GetRepeatDurationName = (id) => {
        // if (id === RepeatDuration.OneOff)
        //     return 'OneOff'
        if (id === RepeatDuration.Weekly)
            return 'Weekly'
        if (id === RepeatDuration.Monthly)
            return 'Monthly'
        if (id === RepeatDuration.Fortnightly)
            return 'Fortnightly'
        if (id === RepeatDuration.Custom)
            return 'Custom'
        return 'Repeating';
    }
    const GetstatusColor = (id) => {
        if (id === SurveyStatus.Draft)
            return Colors.yellow
        if (id === SurveyStatus.Open)
            return Colors.orange
        if (id === SurveyStatus.Live)
            return Colors.green

        return Colors.purple;
    }
    const GetPulseStatusColor = (id) => {
        if (id === PulseStatus.Published)
            return Colors.pink
        if (id === PulseStatus.Close)
            return Colors.lightgrey
        if (id === PulseStatus.Paused)
            return Colors.lightgrey
        if (id === PulseStatus.Live)
            return Colors.skyblue

        return Colors.purple
    }
    const EditSurvey = () => {
        surveyService.GetEditToken(survey.token)
            .then(token => {
                if (token) {
                    navigate(`/survey-panel/${token}`)
                }
            })
    }
    const DeleteSurvey = () => {
        setOpt(OptType.Delete)
        setMsg('This will delete the survey. Are you sure to delete the survey?')
        setOpenModal(true);
    }
    const CloseSurvey = () => {
        setOpt(OptType.Close)
        setMsg('This will close the survey. Are you sure to close the survey?')

        setOpenModal(true);
    }
    const ReopenSurvey = () => {
        setOpt(OptType.Reopen)
        setMsg('This will reopen the survey. Are you sure to reopen the survey?')
        setOpenModal(true);
    }
    const onConfirm = () => {
        setOpenModal(false);
        if (opt === OptType.Close) {
            survey.surveyStatus = SurveyStatus.Close;
            surveyService.CloseSurvey(survey.token)
        }
        if (opt === OptType.Reopen) {
            survey.surveyStatus = SurveyStatus.Live;
            surveyService.ReopenSurvey(survey.token)
        }
        if (opt === OptType.Delete) {
            survey.surveyStatus = SurveyStatus.Delete
            surveyService
                .DeleteSurvey(survey.token)
                .then(e => {
                    reloadList(survey.id)
                }
                ).catch(e => { })
        }
        setOpt(OptType.None)
        handleClose();
    }
    const onCancel = () => {
        setOpenModal(false);
        setOpt(OptType.None)
    }
    const SendInvites = () => {
        setInvitePopup(true);
        // surveyService.SendInvites(survey.token)
        // survey.surveyStatus = SurveyStatus.Live;
        // setMsg('Invitations sent');
        // setOpen(true)
        // handleClose();
    }
    const GetMenuItems = () => {
        let div = '';
        if (survey.surveyStatus === SurveyStatus.Draft)
            div =
                <div className='dropdown-items'>
                    <MenuItem onClick={onClone}><img src={iconcopy}></img><span>Clone</span></MenuItem>
                    <MenuItem onClick={EditSurvey}><img src={iconedit} alt='' /><span>Edit</span></MenuItem>
                    <MenuItem onClick={DeleteSurvey}><img src={icondelete} alt='' /><span>Delete</span></MenuItem>
                </div>
        if (survey.surveyStatus === SurveyStatus.Open)
            div =
                <div className='dropdown-items'>
                    <MenuItem onClick={onClone}><img src={iconcopy}></img><span>Clone</span></MenuItem>
                    <MenuItem onClick={EditSurvey}><img src={iconedit} alt='' /><span>Edit</span></MenuItem>
                    <MenuItem onClick={DeleteSurvey}><img src={icondelete} alt='' /><span>Delete</span></MenuItem>
                </div>

        if (survey.surveyStatus === SurveyStatus.Live)
            div =
                <div className='dropdown-items'>
                    <MenuItem onClick={onClone}><img src={iconcopy}></img><span>Clone</span></MenuItem>
                    <MenuItem onClick={CloseSurvey}><img src={iconclose} alt='' /><span>Close</span></MenuItem>
                    <MenuItem onClick={SendInvites}><img src={iconrerun} alt='' />
                        <Tooltip title='Click to re-send the survey invitation to individuals or choose All to send to everyone who has not taken the survey.' placement='bottom'>
                            <span>Resend Invitations</span>
                        </Tooltip>
                    </MenuItem>
                </div>

        if (survey.surveyStatus === SurveyStatus.Close)
            div = <div className='dropdown-items'>
                <MenuItem onClick={ReopenSurvey}><img src={iconrerun} alt='' /><span>Reopen</span></MenuItem>
                <MenuItem onClick={onClone}><img src={iconcopy}></img><span>Clone</span></MenuItem>
                <MenuItem onClick={e => setShowrerunPanel(true)}><img src={iconrerun} alt='' /><span>Run Again</span></MenuItem>
                <MenuItem onClick={DeleteSurvey}><img src={icondelete} alt='' /><span>Delete</span></MenuItem>
            </div>
        return div
    }
    const GetLinkCopy = () => {
        let div = '';
        if (survey.RepeatDuration === RepeatDuration.OneOff)
            div =
                <div className='survey-link' style={{ cursor: 'pointer' }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                        var textField = document.createElement('textarea')
                        textField.innerText = `${Config.FRONTEND_URL}/#/survey/token/` + survey.token;
                        document.body.appendChild(textField)
                        textField.select()
                        document.execCommand('copy')
                        textField.remove();
                        setMsg('Link is copied');
                        setOpen(true)

                    }}><InsertLinkIcon sx={{ fontSize: 20 }} />
                    </div>
                    <div>Survey<br />Link</div>
                </div>

        return div;
    }
    const GetCss = (id) => {
        if (id === PulseStatus.Live)
            return { backgroundColor: '#8CC3C3', fontSize: '13px', fontWeight: '600' }
        if (id === PulseStatus.Published)
            return { backgroundColor: '#FFD7B3', fontSize: '13px', fontWeight: '600' }
        if (id === PulseStatus.Skipped)
            return { backgroundColor: 'lightgrey', fontSize: '13px', fontWeight: '600' }

        return { color: '#000000', fontWeight: '600', fontSize: '13px' }
    }
    const GetDashboardLink = () => {
        let div = '';
        if (survey.surveyStatus !== SurveyStatus.Draft && survey.surveyStatus !== SurveyStatus.Open)
            div =
                <div className='survey-link' style={{ cursor: 'pointer' }} onClick={async () => {
                    // await dispatch(getCurrentSurveyForDashboard(survey.token))
                    // navigate(`/surveys/dashboard/token/${survey.token}`)
                    LoadSurveyDashboard(survey.token)
                }}
                >
                    <div><DashboardOutlinedIcon sx={{ fontSize: 20 }} /></div>
                    <div>Survey<br />Overview</div>
                </div>

        return div;
    }
    const GetParticipantCount = () => {
        let div = '';
        div = survey.surveyStatus === SurveyStatus.Live ? <Grid container className='participants'>
            {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                {survey.surveySubmissions?.length}{survey.surveyParticipants?.length > 0 ? ' out of ' : ''} {survey.surveyParticipants?.length > 0 ? survey.surveyParticipants?.length : ''} participants have completed the the survey
            </Grid> */}
        </Grid > : ''
        return div;
    }
    const GetAllReruns = () => {
        setLoadingReruns(true)
        // showAllRerun(true)
        setTimeout(() => {
            surveyService.GetRerunSurveys(survey.token)
                .then((data) => {
                    let reruns = data//.filter(x => survey.surveyStatus === SurveyStatus.Close ? x.pulseStatus === PulseStatus.Close
                    //:(x.pulseStatus !== PulseStatus.Skipped && x.pulseStatus !== PulseStatus.Close) )
                    setAllRerunList(reruns);
                    showAllRerun(true)
                    setLoadingReruns(false)
                }).catch((err) => {
                    showAllRerun(false)
                    setLoadingReruns(false)
                });
        }, 1000);
    }
    const GetDatePanel = () => {
        let div = '';
        if (survey.surveyStatus === SurveyStatus.Draft)
            div = <p>
                Created on: {GetDateFromString(survey.createdDate)}
            </p>
        if (survey.surveyStatus === SurveyStatus.Open)
            div = <p>
                Live from: {GetDateFromString(survey.startDate)}
            </p>
        if (survey.surveyStatus === SurveyStatus.Live)
            div = <p>
                Survey went live on: {GetDateFromString(survey.startDate)}
            </p>

        if (survey.surveyStatus === SurveyStatus.Close)
            div = <p>
                Closed on: {GetDateFromString(survey.endDate)}
            </p>
        return div
    }

    const onClone = () => {
        if (!isCloning) {
            surveyService.CloneSurvey(survey.token)
                .then(token => {
                    if (token) {
                        setMsg('Survey is cloned');
                        setOpen(true)
                        setTimeout(() => {
                            navigate(`/survey-panel/${token}`)
                        }, 1000);
                    }
                    else {
                        setMsg('Survey is not cloned');
                        setOpen(true)
                    }
                })
                .finally(() => { setIsCloning(false) })
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" + survey.id : undefined;


    const GetPulseRow = (row, idx) => {
        let rowCss = GetCss(row.pulseStatus)
        return <TableRow
            key={row.name + idx}
        //sx={{ '&:last-child td, &:last-child th': { border: 0 } }} height={row.pulseStatus === PulseStatus.Live ? 105 : 'auto'}            
        >
            <TableCell style={rowCss} >
                {row.pulseStatus === PulseStatus.Close ?
                    <Done className='schedule-icons'></Done> :
                    row.pulseStatus === PulseStatus.Published ?
                        <HourglassEmpty className='schedule-icons'></HourglassEmpty> :
                        row.pulseStatus === PulseStatus.Live ? <Bolt className='schedule-icons' /> : row.pulseStatus === PulseStatus.Paused ? <Pause className='schedule-icons' /> :
                            row.pulseStatus === PulseStatus.Skipped ?
                                <SkipNext className='schedule-icons'></SkipNext>
                                : ''}</TableCell>
            <TableCell style={rowCss}>
                {row.label === null ? 'Pulse ' + (idx + 1) : row.label}
            </TableCell>
            <TableCell style={rowCss}>{
                (row.pulseStatus === PulseStatus.Live ||
                    row.pulseStatus === PulseStatus.Close) ? 'Live' :
                    row.pulseStatus === PulseStatus.Skipped ? 'Skipped' : 'Scheduled'}: {GetDateFromString(row.startDate)} </TableCell>

            <TableCell style={rowCss}>{
                (row.pulseStatus === PulseStatus.Close) ? 'Closed On: ' + GetDateFromString(row.endDate) : ''}

                {row.pulseStatus === PulseStatus.Live ?

                    <div className='survey-link' style={{ cursor: 'pointer' }} onClick={e => {
                        surveyService.CloseSurvey(row.token).then(e => {
                            setLoadingReruns(false)
                            GetAllReruns()
                        })
                        setLoadingReruns(true)
                    }}>
                        <div className='weekly-icons'><Stop className='schedule-icons'></Stop>Close</div>
                    </div> : ''
                }
                {row.pulseStatus === PulseStatus.Published ?
                    <PauseButton token={row.token} onPause={GetAllReruns} /> :
                    row.pulseStatus === PulseStatus.Paused ?
                        <PlayButton token={row.token} OnResume={GetAllReruns} /> :
                        row.pulseStatus === PulseStatus.Skipped ? <>
                            <div className='weekly-icons'><SkipNext className='schedule-icons'></SkipNext>Skipped</div></>
                            : ''
                }
            </TableCell>

            {/* <TableCell style={GetCss(row.pulseStatus)}>

                {row.pulseStatus === PulseStatus.Published ?
                    <div className='survey-link' style={{ cursor: 'pointer' }}>
                        <div><Edit className='schedule-icons'></Edit>Edit</div>
                    </div> : ''
                }
            </TableCell> */}
            {/* <TableCell style={GetCss(row.pulseStatus)}>
                {row.pulseStatus === PulseStatus.Live ?
                    <div className='survey-link' style={{ cursor: 'pointer' }} onClick={e => {
                        var textField = document.createElement('textarea')
                        textField.innerText = `${Config.FRONTEND_URL}/#/survey/token/` + row.token;
                        textField.select();
                        // document.execCommand('copy')
                        // textField.remove();
                        // setMsg('Link is copied');
                        // setOpen(true)


                        navigator.clipboard.writeText(`${Config.FRONTEND_URL}/#/survey/token/` + row.token).then(
                            () => {
                                setMsg('Link is copied');
                                setOpen(true)
                            })
                            .catch(
                                e => {
                                    alert(e); // error
                                });
                    }}>
                        <div className='weekly-icons'><InsertLinkIcon className='schedule-icons'></InsertLinkIcon>Copy Link</div>
                    </div> : ''}
            </TableCell> */}
        </TableRow>

    }
    return (
        <Surveys key={'survey-block' + key}>

            {showRerunPanel && <ReRunSurveyButton token={survey.token} showButton={false} onClose={() => setShowrerunPanel(false)} />}
            {sendInvitesPopup ?
                <SendInvitePopup token={survey.token} onClose={e => {
                    setInvitePopup(false);
                    handleClose();
                }} onAction={e => {
                    setInvitePopup(true);
                    // surveyService.SendInvites(survey.token)
                    setInvitePopup(false);
                    setMsg('Invitations sent');
                    setOpen(true)
                    handleClose();
                }} /> : ''}
            <Snackbar
                key={'snack-' + key}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openSnak}
                onClose={() => { setOpen(false) }}
                autoHideDuration={2000}
            >
                <Alert onClose={() => { setOpen(false) }} severity="success" sx={{ width: '100%' }}>
                    {msg}
                </Alert>
            </Snackbar>

            {openModal ? <Popup Title={opt === OptType.Close ? "Close the Survey" : opt === OptType.Delete ? "Delete the Survey" : "Reopen the Survey"} Msg={msg} onCancel={onCancel} onAction={onConfirm}></Popup> : ''}
            <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                    <div className='survey-status' style={{ backgroundColor: GetstatusColor(survey.surveyStatus) }}>
                        {GetstatusName(survey.surveyStatus)}
                    </div>
                </Grid>
                <Grid item lg={12} md={12} xs={12} paddingBottom={2}>
                    <div className='survey'>
                        <div className='survey-name'>
                            <h3>{survey.name} </h3>
                            {GetDatePanel()}
                        </div>

                        <div className='survey-extra'>


                            {survey.repeatDuration === 1 && survey.pulseStatus === PulseStatus.Published ?
                                <PauseButton isMainButton={true} token={survey.token} onPause={() => resetStatus(survey.token, PulseStatus.Paused)} /> : ''}
                            {survey.repeatDuration === 1 && survey.pulseStatus === PulseStatus.Paused ?
                                <PlayButton isMainButton={true} token={survey.token} OnResume={() => resetStatus(survey.token, PulseStatus.Paused)} /> : ''
                            }

                            {survey.isRepeating == true && showReruns && survey.surveyStatus != SurveyStatus.Draft && survey.surveyStatus != SurveyStatus.Close ?
                                <div className='survey-options' onClick={GetAllReruns}>
                                    <div>
                                        <History sx={{ fontSize: 20 }}></History>
                                    </div>
                                    <div>Survey<br />Schedule</div>
                                </div>
                                : <></>}
                            {/* {GetLinkCopy()} */}
                            {GetDashboardLink()}
                            <div className='survey-options' ref={divRef}
                                aria-describedby={id}
                                variant="contained"
                                onClick={handleClick} >
                                <div><MoreHorizIcon sx={{ fontSize: 20 }}
                                /></div><div>Options</div>
                            </div>

                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}
                                transformOrigin={{
                                    vertical: " top",
                                    horizontal: "center"
                                }}
                                key={'menu-' + key}
                            >
                                {GetMenuItems()}
                            </Popover>
                        </div>
                    </div>
                </Grid>
                {GetParticipantCount()}
            </Grid>
            <Modal open={allRerun} onClose={e => showAllRerun(false)}>
                <div className='question-popup'>
                    <Box style={{
                        padding: 20,
                        borderRadius: 4,
                    }}>
                        <IconButton
                            aria-label="close"
                            onClick={e => showAllRerun(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                fontSize: '22px'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Grid container>
                            <Grid item lg={12} md={12} xs={12}>
                                <h1>{survey.name}</h1>
                            </Grid>
                            <Grid item lg={12} md={12} xs={12}>
                                {/* <p>This pulse series is scheduled <strong style={{ color: '#FF5939' }}>{GetRepeatDurationName(survey.repeatDuration)}</strong> and {(survey.pulseStatus === PulseStatus.Published) ? 'commenced' : 'commences'} on <strong style={{ color: '#FF5939' }}>{GetDateFromString(survey.surveyStartDate)}</strong> and finishes on <strong style={{ color: '#FF5939' }}>{laodingReruns ? '' : GetDateFromString(allRerunList[allRerunList.length - 1].endDate)}</strong>.</p> */}
                                <p>This pulse series is <strong style={{ color: '#FF5939' }}>{GetRepeatDurationName(survey.repeatDuration)?.toLocaleLowerCase()}</strong> and the schedule is shown below.</p>
                                {laodingReruns ? '' : (allRerunList.length > 0 ? null :
                                    <p>There is no live or upcoming surveys. Please add schedule. Edit schedule.</p>)}
                            </Grid>
                            <Grid item lg={12} md={12} xs={12} >
                                {
                                    laodingReruns ?
                                        <div>Loading surveys</div> :
                                        (allRerunList.length > 0 ?
                                            <ScrollContainer alwaysShowTracks={true}>
                                                <ParticipantsList>
                                                    <TableContainer component={Paper}>
                                                        <Table size="small">
                                                            <TableBody>
                                                                {allRerunList.map((row, idx) => (
                                                                    GetPulseRow(row, idx)
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </ParticipantsList>
                                            </ScrollContainer>
                                            :
                                            null
                                        )
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Modal>

        </Surveys >
    );
}

export default SurveyBlock;