import React from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { Grid } from "@mui/material";


const PulseChartTicksWarpper = ({
  data,
  dataKey,
  children,
  chartTitle,
  chartCategory,
  questionNumber,
  totalHeight,
}) => {
  return (
    <Grid container id="chart-download">
      <Grid item lg={12} md={12} xs={12}>
        <p style={{ textAlign: "center", marginTop: "30px" }}>{chartTitle}</p>

        <div className="stackbarchart" style={{ paddingLeft: "10px" }}>
          {questionNumber.map((question, index) => {
            const dataset =
              chartCategory === "All Staff"
                ? data
                : data?.filter((q) => q.category === question);

            return (
              <div key={question}>
                {index === 0 && (
                  <>
                    <div style={{ height: "63px" }}></div>
                    <BarChart
                      width={158.5}
                      height={totalHeight}
                      layout="vertical"
                      data={dataset}
                      barSize={30}
                      fontWeight="bold"
                      fontFamily="Avenir LT Pro"
                      tick={{ fill: "#000", fontSize: 12, textWrap: "wrap" }}
                    >
                      <CartesianGrid horizontal={false} vertical={false} />
                      <XAxis type="number" hide={true} domain={[0, 5]} />
                      <YAxis
                        width={153}
                        dataKey={dataKey}
                        type="category"
                        hide={index !== 0}
                        interval="preserveEnd"
                        fontWeight="600"
                        fontFamily="Avenir LT Pro"
                        tick={{ fill: "#000", fontSize: 12 }}
                        tickLine={{ stroke: "none", textWrap: "auto" }}
                      />
                    </BarChart>
                  </>
                )}
              </div>
            );
          })}
          {children}
        </div>
      </Grid>
    </Grid>
  );
};

PulseChartTicksWarpper.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  children: PropTypes.node,
  chartType: PropTypes.number,
  chartTitle: PropTypes.string,
  chartCategory: PropTypes.string,
  questionNumber: PropTypes.array,
  sortByQuestionKey: PropTypes.func,
  headerWidth: PropTypes.number,
  headerRef: PropTypes.object,
  sortQ: PropTypes.string,
  sortingOrder: PropTypes.number,
  totalHeight: PropTypes.number,
};

export default PulseChartTicksWarpper;
