import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createtFolder, getFolders } from '../redux/features/folderSlice'
import { WhiteLayoutContent, PulseTextField } from '../assets/styles/AppContainers';
import { object, string } from 'yup';
import SuccessAlert from './SuccessAlert';

const schema = object().shape({
    pulseInputField: string().matches(/^[^,]*$/, 'Folder name cannot contain commas')
        .min(2, 'Folder name must be at least 2 characters'),
});

function FolderForm() {
    const { organization } = useSelector(store => store)
    const [name, setName] = useState(null);
    const [validationError, setValidationError] = useState('');
    const dispatch = useDispatch();
    const createFolder = () => {
        if (name !== '' && name !== null) {
            dispatch(createtFolder({ orgId: organization.orgId, isMat: organization.isMat, name: name }));
            return <SuccessAlert Message='Folder is created' />;
        }
    }

    const validateInput = async () => {
        try {
            await schema.validate({ pulseInputField: name }, { abortEarly: false });
            setValidationError('');
        } catch (errors) {
            setValidationError(errors.errors[0]);
        }
    };

    return (
        <WhiteLayoutContent>
            <Grid container padding={3}>
                <Grid item lg={12} md={12} xs={12}>
                    <h2>Create New Folder</h2>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    <PulseTextField label="Folder Name" fullWidth value={name} onBlur={validateInput} onChange={(e) => setName(e.target.value)} />
                    {validationError && <div style={{ color: 'red' }}>{validationError}</div>}
                </Grid>
                <Grid item lg={12} md={12} xs={12} paddingTop={2} textAlign='right'>
                    <Button variant="contained" className='next-button' onClick={createFolder}>Create Folder</Button>
                </Grid>
            </Grid>
        </WhiteLayoutContent>
    );
}

export default FolderForm;