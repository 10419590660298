import React from 'react';
import { SurveyLayoutContent, TableStyle } from '../../assets/styles/AppContainers';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';

const ParticipationCount = ({ teachingStaffCount, supportStaffCount, leadStaffCount }) => {
    const message = <strong style={{color: 'red'}}>No data available.</strong>;
    return (
        <>
            <h2>Participation</h2>
            <TableStyle>
                <Table sx={{marginTop: '0 !important'}}>
                    <TableBody>
                        <TableRow>
                            <TableCell><strong>Teaching Staff</strong>: {teachingStaffCount > 5 ? teachingStaffCount : message}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><strong>Support Staff</strong>: {supportStaffCount > 5 ? supportStaffCount : message}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><strong>Lead Staff</strong>: {leadStaffCount > 5 ? leadStaffCount : message}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableStyle>
        </>
    );
};

export default ParticipationCount;