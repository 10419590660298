import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { surveyService } from '../../services'


const initialState = {
    currSurvey: {},
    loading: false
}


// export const getCurrentSurveyForDashboard = createAsyncThunk('surveyService.GetSurveyByTokenForDashboard', async (token) => {
//     return surveyService.GetSurveyByTokenForDashboard(token);
// });

export const schoolSlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        // increment: (state) => {
        //     state.value += 1;
        // },
        // decrement: (state) => {
        //     state.value -= 1;
        // },
        updateCurrentSurvey: (state, action) => {
            state.currSurvey = action.payload
            state.loading = false
        },

    },
    // extraReducers: {
    //     [getCurrentSurveyForDashboard.pending]: (state) => {
    //         state.loading = true
    //     },
    //     [getCurrentSurveyForDashboard.fulfilled]: (state, action) => {

    //         state.currSurvey = action.payload
    //         state.loading = false
    //     },
    //     [getCurrentSurveyForDashboard.rejected]: (state) => {
    //         state.loading = false
    //     },
    // }
})

export const { updateCurrentSurvey } = schoolSlice.actions;
export default schoolSlice.reducer 