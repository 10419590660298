import React from 'react';

const LinkButton = ({ onClick, children }) => {
    return (
        <p onClick={onClick} style={{ color: '#4880E8', cursor: 'pointer' }}>
            {children}
        </p >
    );
};

export default LinkButton;