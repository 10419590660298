import React, { useEffect, useState } from 'react';
import { Grid, Box, Button, Tabs, Snackbar, Alert, Stack } from '@mui/material';
import SurveyForm from './SurveyForms/SurveyForm';
import ScheduleForm from './SurveyForms/ScheduleForm';
import { surveyService } from '../../services';
import QuestionPool from './SurveyForms/QuestionPool';
import QuestionsForm from './SurveyForms/QuestionsForm';
import ParticipantsForm from './SurveyForms/ParticipantsForm';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFolders } from '../../redux/features/folderSlice'
import { StyledTab, WhiteLayoutContent } from '../../assets/styles/AppContainers';
import { PulseStatus, SurveyStatus, minParticipantsRequired } from '../../utils/Constants';
import SurveyDetailForm from './SurveyForms/SurveyDetailForm';
import EmailInvite from './SurveyForms/EmailInvite';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}

function CreateSurveyScreen(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { organization } = useSelector(store => store)
    const { schoolTimezone } = useSelector(store => store.schoolTimezone)
    const [showReviewScreen, setShowReviewScreen] = useState(false);
    const [isDraft, setIsDraft] = useState(true);
    const [value, setValue] = React.useState(0);
    const [warning, setwarning] = useState(false);
    const [success, setSuccess] = useState(false);
    const [msg, setmsg] = useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [hasUpdate, setHasUpdate] = React.useState(false);
    const [disableBtn, setDisableBtn] = React.useState(false);
    const [nextBtnTitle, setNextBtnTitle] = React.useState('Save and Next');
    //const [editMode, setEditMode] = React.useState(false);
    const [pFilters, setPFilters] = useState(null)

    const [survey, setSurvey] = React.useState({
        orgId: organization.orgId,
        isMatSurvey: organization.isMat,
        token: null,
        name: '',
        folderId: null,
        folder: null,
        schoolId: 0,
        startHour: 0,
        startMin: 0,
        endHour: 0,
        endMin: 0,
        startDate: null,
        endDate: null,
        surveyParticipants: [],
        surveyQuestions: [],
        repeatDuration: '1',
        participantFilters: null,
        timeZoneIdx: schoolTimezone,
        timeZoneOffset: (new Date()).getTimezoneOffset(),
        schoolName: organization.name,
        frequency: 2,
        noOfWorkingDays: 5,
        surveyStatus: SurveyStatus.Draft,
        inviteEmailSubject: null,
        inviteEmailBody: null
    });
    const [participants, setParticipants] = useState([]);
    const [participantFitlers, setParticipantFilters] = useState([]);
    const [questions, setQuestions] = useState([]);

    // const setParticipants = (list) =>
    // {
    //     
    //     let newSurvey = survey;
    //     newSurvey.surveyParticipants = list;
    //     setSurvey(newSurvey);
    // }

    // const setQuestions = (list) =>
    // {
    //     let newSurvey = survey;
    //     newSurvey.surveyQuestions = list;
    //     setSurvey({ ...newSurvey });
    // }
    const handleChange = (e, newValue) => {
        if (!disableBtn) {
            setDisableBtn(true);
            if (survey.name === '') {
                setValue(0);
                setwarning(true)
                setDisableBtn(false);
                setmsg('Survey name needed')
                return;
            }
            GetNextButtonTitle(survey.surveyParticipants?.length, newValue)
            let needStoreUpdated = false
            if (survey.folderId === null && survey.folder?.name !== '')
                needStoreUpdated = true;

            if (newValue === 5) {
                if (survey.repeatDuration == 1) {
                    if ((survey.endDate < survey.startDate) || (survey.endDate === survey.startDate && survey.startHour > survey.endHour)) {
                        setValue(4);
                        setwarning(true)
                        setmsg('Survey end Date and time is behind the survey start date and time   ')
                        setDisableBtn(false)
                        return;
                    }
                }

                if (survey.repeatDuration > 1 && survey.repeatDuration < 5) {
                    if ((survey.startDate == null)) {
                        setValue(4);
                        setwarning(true)
                        setmsg('Please provide survey start date and time.')
                        setDisableBtn(false)
                        return;
                    }
                    if ((survey.frequency === 0) || (survey.noOfWorkingDays === 0)) {
                        setValue(4);
                        setwarning(true)
                        setmsg('Please provide no of working days and frequency')
                        setDisableBtn(false)
                        return;
                    }
                }
            }
            if (hasUpdate) {
                let updatedSurvey = survey;
                ///// updatedSurvey.surveyStatus = SurveyStatus.Draft;
                updatedSurvey.surveyParticipants = [...participants];
                updatedSurvey.surveyQuestions = [...questions];
                updatedSurvey.timeZoneIdx = schoolTimezone.id
                updatedSurvey.timeZoneOffset = (new Date()).getTimezoneOffset()
                updatedSurvey.participantFilters = participantFitlers?.length > 0 ? participantFitlers : survey.participantFilters;
                updatedSurvey.schoolName = organization.name
                // if (isSurveyValid()) {
                //     updatedSurvey.surveyStatus = SurveyStatus.Open;
                // }
                // else {
                updatedSurvey.surveyStatus = SurveyStatus.Draft;
                //}
                surveyService.CreateSurvey(updatedSurvey)
                    .then((data) => {
                        setDisableBtn(false)
                        setSurvey({ ...data });
                        if (needStoreUpdated) {
                            dispatch(getFolders({ orgId: organization.orgId, isMat: organization.isMat }));
                        }
                    }).catch(e => setDisableBtn(false));;
                setHasUpdate(false);
            }

            // if (newValue === 3) {
            //     if (survey.surveyParticipants === null || survey.surveyParticipants?.length < minParticipantsRequired || survey.surveyQuestions === null || survey.surveyQuestions?.length === 0
            //         || survey.endDate === null || survey.startDate === null || survey.startHour === null || survey.startMin === null
            //         || survey.endMin === null || survey.endHour === null) {
            //         setIsDraft(true)
            //         setShowReviewScreen(false)
            //     }
            //     else {
            //         setIsDraft(false)
            //     }
            // }
        }
        if (!hasUpdate) {
            setDisableBtn(false)
        }
        if (newValue === 5 && !isDraft) {
            setShowReviewScreen(true)
        }
        if (newValue === 5 && isDraft) {
            setmsg('Survey saved as draft')
            setSuccess(true)

        }
        if (newValue < 5) {
            setValue(newValue);
        }
        else {
            setValue(4);
        }
    }
    const onCancel = () => {
        setShowReviewScreen(false)
    }

    const onPublish = () => {
        ///// let publishSurvey = survey;
        ///// publishSurvey.surveyStatus = SurveyStatus.Open;
        ///// publishSurvey.pulseStatus = PulseStatus.Published;
        surveyService.publishSurvey(survey.token)
            .then((data) => {
                if (data) {
                    navigate('/survey-created')
                }
                else {
                    setwarning(true)
                    setmsg('Error in saving survey')
                }
            }).catch(e => {
                setwarning(true)
                setmsg('Error in saving survey')
            });
    }
    // useEffect(() => { }, [nextBtnTitle]);
    useEffect(() => {
        let t = window.location.href.split('survey-panel/')[1]

        if (t) {
            //setEditMode(true)
            surveyService.GetSurveyByToken(t)
                .then((data) => {
                    setSurvey({ ...data });
                    setQuestions([...data.surveyQuestions]);
                    setParticipants([...data.surveyParticipants]);
                    setLoaded(true)
                });;
        }
        else {
            // setEditMode(false)
            setLoaded(true)
        }
    }, []);
    const autoSave = (e) => {
        handleChange(e, value)
    }
    const GetNextButtonTitle = (staffCount, newValue) => {
        if (newValue === 1 && staffCount < minParticipantsRequired) {
            setIsDraft(true)
            setNextBtnTitle('Skip');
        }
        else if (newValue >= 4) {
            if (!isSurveyValid()) {
                setIsDraft(true)
                setNextBtnTitle('Save as Draft')
                return
            }
            if (staffCount >= minParticipantsRequired) {
                setIsDraft(false)
                setNextBtnTitle('Review Survey');
            }
        }
        else {
            setIsDraft(true)
            setNextBtnTitle('Save and Next');
        }
    }
    const handleParticipantFiltersChange = (fil) => {
        // let filterSurvey = survey;
        // filterSurvey.participantFilters = fil;
        // setSurvey({ ...filterSurvey });
        setParticipantFilters(fil)
    }

    const isSurveyValid = () => {
        if (survey.surveyParticipants?.length < minParticipantsRequired || survey.surveyQuestions === null || survey.surveyQuestions?.length === 0
            || survey.startDate === null || survey.startHour === null || survey.startMin === null
            || (survey.repeatDuration === 1 || survey.repeatDuration === 5) && (survey.endDate === null || survey.endMin === null || survey.endHour === null)
            || survey.repeatDuration > 1 && survey.repeatDuration < 5 && (survey.frequency <= 0 || survey.noOfWorkingDays <= 0)) {
            return false;
        }
        return true;
    }
    return (
        loaded ?
            <WhiteLayoutContent>
                <Snackbar
                    key={'snack-waring'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={warning}
                    autoHideDuration={6000}
                    onClose={() => setwarning(false)}
                >
                    <Alert severity="error" variant='filled'>{msg}</Alert></Snackbar>
                <Snackbar
                    key={'snack-success'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={() => setSuccess(false)}
                >
                    <Alert severity="success" variant='filled'>
                        Survey saved as draft
                    </Alert></Snackbar>
                <Grid container >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="">
                                    <StyledTab label="Create Survey" padding={0} />
                                    <StyledTab label="Choose Participants" padding={0} />
                                    <StyledTab label="Add Questions" padding={0} />
                                    <StyledTab label="Customise Invitation" padding={0} />
                                    <StyledTab label="Schedule" padding={0} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0} classname="tabpanel" >
                                <SurveyForm Survey={survey} onSurveyChange={setSurvey} OnHasUpdate={setHasUpdate}></SurveyForm>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ParticipantsForm nextOperation={GetNextButtonTitle} onSurveyChange={setParticipants} Survey={survey} OnHasUpdate={setHasUpdate} onParticipantFilterChange={setParticipantFilters} ></ParticipantsForm>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <QuestionPool Survey={survey} onSurveyChange={setQuestions} OnHasUpdate={setHasUpdate}></QuestionPool>
                                {/*<QuestionsForm Survey={survey} onSurveyChange={setQuestions} OnHasUpdate={setHasUpdate}></QuestionsForm>>*/}
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <EmailInvite Survey={survey} onSurveyChange={setSurvey} OnHasUpdate={setHasUpdate}></EmailInvite>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <ScheduleForm Survey={survey} onSurveyChange={setSurvey} OnHasUpdate={setHasUpdate} onAutoSave={autoSave}></ScheduleForm>
                            </TabPanel>
                            {showReviewScreen ? <SurveyDetailForm Survey={survey} onCancel={onCancel} onPublish={onPublish}></SurveyDetailForm> : ''}
                        </Box >
                    </Grid>
                </Grid >
                <Grid container padding={3} paddingTop={0}>
                    <Grid item lg={12} md={12} xs={12}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} justifyContent="space-between">
                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end">
                                {value > 0 ? <Button variant="outlined" className='prev-button' onClick={(e) => handleChange(e, value - 1)} >
                                    Previous
                                </Button> : ''}
                            </Stack>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                                <Button variant="outlined" className='prev-button' onClick={(e) => navigate(-1)} >
                                    Cancel
                                </Button>
                                {nextBtnTitle === 'Review Survey' ?
                                    <Button variant="outlined" className='prev-button' onClick={(e) => {
                                        autoSave(e)
                                        setSuccess(true)
                                    }}>
                                        Save as Draft
                                    </Button> : ''
                                }
                                <Button variant="contained" className='next-button' onClick={(e) => handleChange(e, value + 1)}>
                                    {nextBtnTitle}
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </WhiteLayoutContent > : ''
    );
}

export default CreateSurveyScreen;