import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { surveyService } from '../services';
import { updateCurrentSurvey } from '../redux/features/surveySlice';
import { useSelector, useDispatch } from 'react-redux';

const useSelectedSurvey = () => {

    const location = useLocation();
    const [loading, setLoading] = useState(true)
    const currSurvey = useSelector(state => state.currSurvey.currSurvey)
    const [selectedSurvey, setSelectedSurvey] = useState(null)
    //const [token, setToken] = useState('');
    const dispatch = useDispatch()

    useEffect(() => {
        // if (currSurvey === null || currSurvey?.token !== location.pathname.split('token/')[1]) {
        surveyService.GetSurveyDashboardByToken(location.pathname.split('token/')[1])
            .then(data => {
                setSelectedSurvey(data)
                dispatch(updateCurrentSurvey(data))
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
        // }
        // else {
        //     setSelectedSurvey(currSurvey)
        //     setLoading(false)
        // }
    }, [])

    return { selectedSurvey, loading }
}

export default useSelectedSurvey;

