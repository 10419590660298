import React, { useEffect, useState } from 'react';
import { AppContainer } from '../../../assets/styles/AppContainers';
import { FormControlLabel, Grid, RadioGroup, Radio } from '@mui/material';
import { questionService } from '../../../services';

function QuestionsForm({ Survey, onSurveyChange, OnHasUpdate })
{
    const [eystnQuestions, setEystnQuestions] = useState([]);
    const [eystnLoaded, setEystnLoaded] = useState(false);
    const [ofstedQuestions, setOfstedQuestions] = useState([]);
    const [ofstedLoaded, setOfstedLoaded] = useState(false);
    const [qOption, setQOption] = useState(null);

    useEffect(() =>
    {
        if (!eystnLoaded)
        {
            questionService
                .GetEystnQuestions()
                .then(data =>
                {
                    setEystnQuestions(data);
                    setEystnLoaded(true);
                })
                .catch(e =>
                {
                    setEystnQuestions([]);
                    setEystnLoaded(false);
                });
        }
        if (!ofstedLoaded)
        {
            questionService
                .GetOfstedQuestions()
                .then(data =>
                {
                    setOfstedQuestions(data);
                    setOfstedLoaded(true);
                })
                .catch(e =>
                {
                    setOfstedQuestions([]);
                    setOfstedLoaded(false);
                });
        }

        if (Survey.surveyQuestions && Survey.surveyQuestions.length > 0)
        {
            if (Survey.surveyQuestions[0].questionPoolType === 4)
            {
                setQOption('1');
            }
            if (Survey.surveyQuestions[0].questionPoolType === 5)
            {
                setQOption('2');
            }
        }
    }, []);

    const onChangeQuestion = (e) =>
    {
        setQOption(e.target.value);
        ;
        let choosedQuestions = [];
        let ChoosedQuestionPoolType = 4;
        let questions = [];

        if (e.target.value === '1')
        {
            choosedQuestions = eystnQuestions;
            ChoosedQuestionPoolType = 4;
        }

        if (e.target.value === '2')
        {
            choosedQuestions = ofstedQuestions;
            ChoosedQuestionPoolType = 5;
        }
        choosedQuestions.forEach((element, idx) =>
        {
            questions.push({
                id: 0,
                text: element.questionText,
                questionPoolTypeId: 0,
                questionPoolType: ChoosedQuestionPoolType,
                questionLikertFormat: element.type,
                questionReponse: element.questionReponse,
                order: idx,
                managementStandard: null,
                surveyQuestionOptions: GuestionDefaultOptions(element.questionReponse + ""),
                surveyId: Survey.id,
                questionSignType: element.signType,
                additionalInfoRequired: element.additionalInfoRequired,
                additionalInfoText: element.additionalInfoText,
                explanation: element.explanation,
                skipNextOnValue: element.skipNextOnValue
            })
        });
        onSurveyChange([...questions]);
        OnHasUpdate(true);
    }
    const GuestionDefaultOptions = (val) =>
    {
        let tempOpt = [];
        if (val === "6")
        {
            tempOpt = [{ text: "Agree", value: 5 }, { text: "Disagree", value: 1 }];
        }
        if (val === "7")
        {
            tempOpt = [{ text: "Agree", value: 5 }, { text: "Disagree", value: 1 }, { text: "Not Applicable", value: 3 }];
        }
        if (val === "8")
        {
            tempOpt = [{ text: "Yes", value: 5 }, { text: "No", value: 1 }];
        }
        if (val === "9")
        {
            tempOpt = [{ text: "Yes – a lot better", value: 5 }, { text: "Yes – a little better", value: 4 }, { text: "Neither better nor worse", value: 3 }, { text: "No – a little worse", value: 2 }, { text: "No – a lot worse", value: 1 }];
        }
        if (val === "2")
        {
            tempOpt = [{ text: "Strongly agree", value: 5 }, { text: "Agree", value: 4 }, { text: "Neither agree nor disagree", value: 3 }, { text: "Disagree", value: 2 }, { text: "Strongly disagree", value: 1 }];
        }
        return tempOpt
    }
    return (
        <AppContainer>
            <Grid container padding={3} spacing={2}>
                <Grid item lg={12} md={12} xs={12} marginBottom={2}>
                    <h1>Survey Questions</h1>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    <RadioGroup
                        name="qOptions"
                        value={qOption}
                        onChange={onChangeQuestion}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Choose Estyn Questions" />
                        <FormControlLabel value="2" control={<Radio />} label="Choose Ofsted Questions" />
                    </RadioGroup>
                </Grid>
            </Grid>
        </AppContainer >
    );
}

export default QuestionsForm;