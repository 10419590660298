import React, { useEffect, useState } from 'react';
import { AppContainer, ParticipantsList, PulseTextField, TableStyle } from '../../../assets/styles/AppContainers';
import Grid from '@mui/material/Grid';
import { Alert, Badge, Button, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Snackbar, Slide, Skeleton, Stack } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Select, { components } from 'react-select';
import participantsService from '../../../services/api/participantsService';
import { useSelector } from 'react-redux';
import { JobRole, minParticipantsRequired } from '../../../utils/Constants';
import { ScrollContainer } from 'react-nice-scroll';
import Group from '@mui/icons-material/Group';
import { filter, get } from 'lodash';
import { ArrowDownward, ArrowUpward, ImportExport } from '@mui/icons-material';
//import Stack from '@mui/material';
//import Skeleton from '@mui/material';

const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex ",
        width: "48%",
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };
    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input
                type="checkbox"
                checked={isSelected}
            />
            <div style={{ fontWeight: children === 'Select All' ? "bold" : '' }}>{children}</div>
        </components.Option >
    );
};




function ParticipantsForm({ nextOperation, Survey, onSurveyChange, OnHasUpdate, onParticipantFilterChange }) {
    const [allStaff, setAllStaff] = useState(false); // Checkbox
    const [allSchoolsSelected, setAllSchoolsSelected] = useState(false); // dropdown
    const [schools, setSchools] = useState([]); // commplete list of schools
    const [staff, setStaff] = useState([]); //complete list of staff
    const [loading, setLoading] = useState(true); //used will api call
    const [filterNames, setFilterNames] = useState([]); // commplete list of filtersNames
    const [availableFilterCount, setAvailableFilterCount] = useState(0);// total no of rows that can be added
    const [availableFilters, setAvailableFilters] = useState([]);// total no of rows that can be added


    // selected values
    const [selectedSchools, setSelectedSchools] = useState([]); //selected list of schools
    const [selectedSchoolsOptions, setSelectedSchoolsOptions] = useState([]); //selected list of schools
    const [filteredStaff, setFilteredStaff] = useState([]); //list of participants based upon selected filters
    const [selectedFilters, setSelectedFilters] = useState([]);// selected main filters 
    const [selectedChildFilter, setSelectedChildFilters] = useState([]);// available filters based on selected main filters 
    const [availableChildFilters, setAvailableChildFilters] = useState([]);// available filters based on selected main filters
    // others
    const [open, setOpen] = useState(false);

    const [hasChanged, setHasChanged] = useState(false); // used to check if any change has been made

    const [inputValue, setInputValue] = useState("");

    const { organization } = useSelector(store => store)
    const allSite = { value: -1, label: 'All Sites' };

    const [sortFName, setSortFName] = useState(true);
    const [sortLName, setSortLName] = useState(true);
    const [searchText, setSearchText] = useState('')


    useEffect(() => {
        if (!hasChanged) {
            predictFiltersWithMoreStaff(staff)
        }
    }, [filterNames])

    useEffect(() => {
        setLoading(true)
        participantsService.GetStaff(organization.orgId, organization.isMat).then(resp => {
            if (resp.success) {
                setSchools(resp.data.schools)
                setStaff(resp.data.staffs.sort((x, y) => (x.firstName + x.lastName).localeCompare(y.firstName + y.lastName)))//.sort((x, y) => x.firstName.localeCompare(y.firstName))
                setFilterNames(resp.data.filters)
                setAvailableFilters(resp.data.filters)
                setAvailableFilterCount(resp.data.filters.filter(x => x.filterGroupId === null).length)

                if (Survey.surveyParticipants && Survey.surveyParticipants.length > 0) {
                    if (Survey.participantFilters) {
                        let selectedFiltersEdit = JSON.parse(Survey.participantFilters)
                        if (selectedFiltersEdit) {
                            if (selectedFiltersEdit.schools) {
                                setSelectedSchools(selectedFiltersEdit.schools)
                                if (resp.data.schools.length == selectedFiltersEdit.schools.length) {
                                    setSelectedSchoolsOptions([allSite])
                                }
                                else {
                                    setSelectedSchoolsOptions(selectedFiltersEdit.schools)
                                }
                            }
                            if (selectedFiltersEdit.selectedFilters) {
                                setSelectedFilters(selectedFiltersEdit.selectedFilters)
                            }
                            if (selectedFiltersEdit.selectedChildFilter) {
                                setSelectedChildFilters(selectedFiltersEdit.selectedChildFilter)
                            }
                            if (selectedFiltersEdit.id === -1) {
                                setAllStaff(true)
                            }
                        }
                    }
                    setFilteredStaff(Survey.surveyParticipants)
                }
            }
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (hasChanged) {
            updateSelectedStaffListUponFilterChange()
            setHasChanged(false)
        }
    }, [allStaff, selectedSchools, selectedFilters, availableFilterCount, selectedChildFilter, filteredStaff, selectedSchoolsOptions, availableFilters, hasChanged, availableChildFilters])


    const onInputChange = (inputValue, { action }) => {
        if (action === "input-blur") {
            setInputValue("");
        }
        else if (action === "input-change") {
            setInputValue(inputValue);
        }
    };


    const handleAddRow = e => {
        if (
            allStaff
            || selectedFilters.length >= availableFilterCount
            || (selectedSchools.length === 0 && organization.isMat)
            || (filteredStaff?.length <= minParticipantsRequired && (selectedFilters.length > 0 || (selectedSchools.length > 0 && organization.isMat)))
        ) {
            //setOpen(true)
            return
        }
        if (selectedFilters.length === availableFilterCount)
            return;
        if (selectedSchools.length === 0 & organization.isMat)
            return;
        setSelectedFilters([...selectedFilters, { value: -1, label: '' }])
    }
    const handleSchoolSelect = (selectValue, { action }) => {
        if (action === 'select-option') {
            if (selectValue[selectValue.length - 1].value === -1) {
                setAllSchoolsSelected(true)
                setSelectedSchools(schools)
                setSelectedSchoolsOptions(selectValue.filter(x => x.value === -1))
            }
            else {
                setAllSchoolsSelected(false)
                setSelectedSchools(selectValue.filter(x => x.value !== -1))
                setSelectedSchoolsOptions(selectValue.filter(x => x.value !== -1))
            }
        }
        else {
            setAllSchoolsSelected(false)
            setSelectedSchools(selectValue)
            setSelectedSchoolsOptions(selectValue)
        }
        setSelectedFilters([])
        setSelectedChildFilters([])
        setHasChanged(true)
    }

    const handleSelectedMainFilter = (selectValue, idx) => {
        let newfilter = [...selectedFilters];
        newfilter[idx] = selectValue
        if (idx < selectedFilters.length - 1) {
            newfilter.splice(idx + 1, selectedFilters.length - idx)
        }
        setSelectedFilters([...newfilter])
        handleSelectedChildFilters([null], idx)
        setHasChanged(true)
        let newAvialbleChildFilters = availableChildFilters
        newAvialbleChildFilters[idx] = availableFilters.filter(x => x.filterGroupId === selectValue.value)
        setAvailableChildFilters([...newAvialbleChildFilters])
    }

    const predictFiltersWithMoreStaff = (newStaffList) => {

        let choosed = []

        selectedFilters.map((filter, index) => {
            if (filter.value !== -1) {
                choosed.push(filter)
                filterNames.map(x => {
                    if (x.filterGroupId === filter.value) {
                        choosed.push(x)
                    }
                })
            }
        })

        const remainingFilters = []
        filterNames.filter(dd => dd.filterGroupId === null && !selectedFilters.some(ee => ee.value === dd.value)).map(f => {
            remainingFilters.push(f)
        });
        let newFilters = [];
        let pFilters = []
        remainingFilters.forEach(filter => {
            let count = 0;

            let childFilter = filterNames.filter(f => f.filterGroupLabel === filter.label && f.filterGroupId !== null);
            if (filter.filterGroupLabel === "Job Role") { // filter for job role

                count = newStaffList.filter(x => x.coreFilter?.some(ss => filterNames.filter(y => y.filterGroupLabel === filter.filterGroupLabel && y.filterGroupId !== null && ss.value))).length;

                childFilter.map(x => {
                    let c = newStaffList.filter(y => y.coreFilter.some(zz => zz === x.value)).length
                    if (c > 0 && c < newStaffList.length) {
                        pFilters.push(x)
                    }
                })

            } else if (filter.filterGroupLabel === "Job Type") { // full time or part time
                count = newStaffList.filter(x => childFilter.some(y => y.employmentType === x.value)).length;
                childFilter.map(x => {
                    let c = newStaffList.filter(y => y.employmentType === x.value).length
                    if (c > 0 && c < newStaffList.length) {
                        pFilters.push(x)
                    }
                })

            } else if (filter.filterGroupLabel === "Time At School") { // Time at school
                count = newStaffList.filter(x => childFilter.some(y => y.timeWorking === x.label)).length;
                childFilter.map(x => {
                    let c = newStaffList.filter(y => y.timeWorking === x.value).length
                    if (c > 0 && c < newStaffList.length) {
                        pFilters.push(x)
                    }
                })

            } else if (filter.filterGroupLabel === 'Department') { // departmentger
                count = newStaffList.filter(x => childFilter.some(y => x.department.includes(y.label))).length;
                childFilter.map(x => {
                    let c = newStaffList.filter(y => y.department.includes(x.label)).length
                    if (c > 0 && c < newStaffList.length) {
                        pFilters.push(x)
                    }
                })

            } else if (filter.filterGroupLabel === 'Year or Key Stage') { // phase
                count = newStaffList.filter(x => childFilter.some(y => x.phaseYear.includes(y.label))).length;
                childFilter.map(x => {
                    let c = newStaffList.filter(y => y.phaseYear.includes(x.label)).length
                    if (c > 0 && c < newStaffList.length) {
                        pFilters.push(x)
                    }
                })

            } else if (filter.filterGroupLabel === 'Job Title') { // job Title
                count = newStaffList.filter(x => childFilter.some(y => x.jobTitle.includes(y.label))).length;
                childFilter.map(x => {
                    let c = newStaffList.filter(y => y.jobTitle.includes(x.label)).length
                    if (c > 0 && c < newStaffList.length) {
                        pFilters.push(x)
                    }
                })
            }
            if (count >= minParticipantsRequired && pFilters.filter(x => x.filterGroupLabel === filter.label).length > 0) {
                newFilters.push(filter);
            }
        });
        let newAvailFilter = [...pFilters, ...choosed, ...newFilters]
        setAvailableFilterCount(newAvailFilter.filter(x => x.filterGroupId === null).length)
        setAvailableFilters(newAvailFilter)
        let availableChildFiltersNew = [...availableChildFilters];
        choosed.filter(x => x.filterGroupId === null).map((filter, index) => {
            availableChildFiltersNew[index] = filterNames.filter(x => x.filterGroupId === filter.value && x.filterGroupId !== null)
        })
        setAvailableChildFilters([...availableChildFiltersNew])
    }


    const handleSelectedChildFilters = (val, idx) => {
        let newfilter = [...selectedChildFilter]
        newfilter[idx] = val;
        if (idx < selectedChildFilter.length - 1) {
            newfilter.splice(idx + 1, selectedChildFilter.length - idx)
            let sf = selectedFilters
            if (idx < sf.length - 1) {
                sf.splice(idx + 1, selectedFilters.length - idx)
                setSelectedFilters([...sf])
            }
        }
        setSelectedChildFilters([...newfilter])
        setHasChanged(true)
    }

    const handleDelete = (idx) => {
        let parent = selectedFilters
        parent.splice(idx, 1)
        setSelectedFilters([...parent])

        let children = selectedChildFilter
        children.splice(idx, 1)
        setSelectedChildFilters([...children])

        let newAvialbleChildFilters = availableChildFilters
        newAvialbleChildFilters.splice(idx, 1)
        setAvailableChildFilters([...newAvialbleChildFilters])

        setHasChanged(true)
    }

    const updateSelectedStaffListUponFilterChange = () => {
        if (allStaff) {
            setFilteredStaff(staff)
            onSurveyChange(staff)
            OnHasUpdate(true)
            nextOperation(staff.length, 1)
            onParticipantFilterChange(JSON.stringify({ id: -1, value: 'All Staff', schools: schools }))//all
        }
        else if (selectedSchools.length > 0 || selectedChildFilter.length > 0) {
            var newList = [];
            if (organization.isMat) {
                if (selectedSchools.length > 0) {
                    selectedSchools.forEach(school => {
                        newList = [...newList, ...staff.filter(x => x.schoolId === school.value)]
                        if (newList.length < minParticipantsRequired) {
                            setSelectedFilters([])
                            setSelectedChildFilters([])
                        }
                    })
                }
            }
            else {
                newList = staff;
            }
            if (selectedFilters.length > 0) {
                if (selectedChildFilter.length > 0) {
                    selectedFilters.map((filter, index) => {
                        let seg = selectedChildFilter[index]
                        if (seg !== undefined && seg[0]?.value > -1) {
                            if (filter.filterGroupLabel === 'Job Role')// filter for job role
                            {
                                newList = newList.filter(x => seg?.some(y => x.coreFilter?.indexOf(y.value) > -1))
                            }
                            else if (filter.filterGroupLabel === "Job Type")// full time or part time
                            {
                                newList = newList.filter(x => seg?.some(y => x.employmentType === y.value))
                            }
                            else if (filter.filterGroupLabel === "Time At School")// Time at school
                            {
                                newList = newList.filter(x => seg?.some(y => x.timeWorking === y.value))
                            }
                            else if (filter.label === 'Department')// department
                            {
                                newList = newList.filter(x => seg?.some(y => x.department.split(',').indexOf(y.label)>-1))
                            }
                            else if (filter.label === 'Year or Key Stage')// phase
                            {
                                newList = newList.filter(x => seg?.some(y => x.phaseYear.includes(y.label)))
                            }
                            else if (filter.label === 'Job Title')// job Title
                            {
                                newList = newList.filter(x => seg?.some(y => x.jobTitle.split(',').indexOf(y.label)>-1))
                            }
                        }
                    })
                }
            }
            predictFiltersWithMoreStaff([...newList])
            setFilteredStaff(newList)
            onSurveyChange(newList)
            OnHasUpdate(true)
            nextOperation(newList.length, 1)
            onParticipantFilterChange(JSON.stringify({ schools: selectedSchools, selectedFilters: selectedFilters, selectedChildFilter: selectedChildFilter }))//all
            //  setAvailableFilters(filterNames)
        }
        else {
            setFilteredStaff([])
            onSurveyChange([])
            OnHasUpdate(true)
            nextOperation(0, 1)
            onParticipantFilterChange(null)
            predictFiltersWithMoreStaff(staff)
        }
    }

    const sortList = (column) => {

        let list = [...staff];
        if (column === 'f') {
            list.sort((x, y) => (sortFName ? x.firstName.localeCompare(y.firstName) : y.firstName.localeCompare(x.firstName)))
            setSortFName(!sortFName)
            setSortLName(null)
        }
        else {
            list.sort((x, y) => (sortLName ? x.lastName.localeCompare(y.lastName) : y.lastName.localeCompare(x.lastName)))
            setSortLName(!sortLName)
            setSortFName(null)
        }
        setStaff([...list])
    }

    const putSelectedStaffFirst = (list) => {
        let selectedStaff = filteredStaff.map(x => x.schoolStaffId)
        let newList = list.filter(x => selectedStaff.includes(x.schoolStaffId))
        let restList = list.filter(x => !selectedStaff.includes(x.schoolStaffId))
        return [...newList, ...restList]
    }

    return (
        <AppContainer>
            <Grid container padding={3}>
                <Grid item lg={12} md={12} xs={12}>
                    <Stack direction="row" spacing={1}>
                        <h2>Choose Participants</h2>
                        <Badge color="warning" badgeContent={filteredStaff.length + ''}>
                            <Group sx={{ fontSize: 22 }} color='grey'></Group>
                        </Badge>
                    </Stack>
                </Grid>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={<Slide direction="left" />} autoHideDuration={6000} open={open} onClose={() => setOpen(false)} >
                    <Alert severity='warning'>
                        Please select sub filters before adding new fitlers
                    </Alert>
                </Snackbar>
                {!loading ?
                    <>
                        <div className='question-selection' style={{ width: '100%' }}>

                            <Grid item lg={12} md={12} xs={12} mb={1}>
                                <FormControlLabel label='All Staff' control={
                                    <Checkbox checked={allStaff}
                                        onChange={e => {
                                            setAllStaff(!allStaff)
                                            setHasChanged(true)
                                        }}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}>
                                    </Checkbox>}>
                                </FormControlLabel>
                            </Grid>
                            <Grid item lg={12} md={12} xs={12}>
                                <h3>Choose from Filters</h3>
                            </Grid>
                            {organization.isMat ?
                                <Grid item lg={12} md={12} xs={12} mb={1}>
                                    <Select
                                        isDisabled={allStaff}
                                        isMulti
                                        className="custom-filter-participants-dropdown school-dd"
                                        classNamePrefix="Select Schools"
                                        name="Schools"
                                        placeholder="Select Sites"
                                        hideSelectedOptions={false}
                                        closeMenuOnSelect={false}
                                        onChange={handleSchoolSelect}
                                        value={selectedSchoolsOptions?.map(selected => selected ? (
                                            {
                                                value: selected.value,
                                                label: selected.label,
                                            }) : null)}
                                        components={{
                                            Option: InputOption
                                        }}
                                        options={[allSite, ...schools]}
                                    />

                                </Grid> :
                                null}
                            {selectedFilters.map((item, idx) =>
                                <Grid container spacing={1} mb={1}>
                                    <Grid item lg={3} md={3} xs={12} >
                                        <Select
                                            isDisabled={allStaff}
                                            className="basic-single"
                                            placeholder="Select Filter"
                                            name="filters"
                                            value={selectedFilters[idx].value === -1 ? null :
                                                {
                                                    value: selectedFilters[idx].value,
                                                    label: selectedFilters[idx].label,
                                                }}
                                            onChange={(selectValue) => handleSelectedMainFilter(selectValue, idx)}
                                            options={availableFilters.filter(x => x.filterGroupId === null && selectedFilters.findIndex(y => y.value === x.value) === -1)}
                                        />
                                    </Grid>
                                    <Grid item lg={8} md={8} xs={11}>
                                        <Select
                                            className='custom-filter-participants-dropdown'
                                            isDisabled={allStaff}
                                            isMulti
                                            inputValue={inputValue}
                                            onInputChange={onInputChange}
                                            id={'child-filter-' + idx}
                                            hideSelectedOptions={true}
                                            closeMenuOnSelect={false}
                                            onChange={(selectValue) => handleSelectedChildFilters(selectValue, idx)}
                                            components={{
                                                Option: InputOption
                                            }}
                                            value={selectedChildFilter[idx]?.map(selected => {
                                                return selected ? (
                                                    {
                                                        value: selected.value,
                                                        label: selected.label,
                                                    }) : null
                                            })}
                                            placeholder="Select Sub Filters"
                                            isClearable={true}
                                            isSearchable={true}
                                            options={availableChildFilters[idx]}
                                        />
                                    </Grid>
                                    <Grid item lg={1} md={1} xs={1} style={{ cursor: 'pointer' }} >
                                        <DeleteOutlinedIcon color="action" sx={{ fontSize: 24, paddingTop: '6px' }} onClick={e => handleDelete(idx)} />
                                    </Grid>
                                </Grid>
                            )}

                            <Grid item lg={2} md={2} xs={12}>
                                <Button variant="contained" className={allStaff
                                    || availableFilterCount === 0
                                    || selectedFilters.length >= availableFilterCount
                                    || (selectedSchools.length === 0 && organization.isMat)
                                    || (filteredStaff?.length <= minParticipantsRequired && (selectedFilters.length > 0 || (selectedSchools.length > 0 && organization.isMat)))
                                    ? 'grey-button' : 'next-button'} onClick={handleAddRow}>Add Filters</Button>
                            </Grid>

                            {/* {!organization.isMat ? <Grid item lg={12} md={12} xs={12} mt={2}>
                                <Button variant="contained" className={allStaff
                                    || selectedFilters.length >= availableFilterCount
                                    || (selectedSchools.length === 0 && organization.isMat)
                                    || (filteredStaff?.length <= minParticipantsRequired && (selectedFilters.length > 0 || (selectedSchools.length > 0 && organization.isMat)))
                                    ? 'grey-button' : 'next-button'} onClick={handleAddRow}>Add Filters</Button>
                            </Grid> : null} */}
                        </div>
                        <Grid item lg={12} md={12} xs={12} my={1}>
                            {filteredStaff | filteredStaff?.length < minParticipantsRequired ?
                                <Alert severity="error">Please select a minimum of {minParticipantsRequired} participants. You can also skip, save the survey, and add participants later.</Alert> :
                                filteredStaff?.length === minParticipantsRequired ?
                                    <Alert severity="success">To ensure there are 10 survey participants further filtering is not available.</Alert> :
                                    ''}
                        </Grid>
                        {/* {filteredStaff.length > 0 ? */}
                        <Grid item lg={3} md={12} xs={12} mb={0.5}>
                            <PulseTextField
                                placeholder='Search Participants by Name'
                                //label='Search Participants by Name'
                                value={searchText}
                                fullWidth
                                onChange={e => setSearchText(e.target.value)}
                            ></PulseTextField>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <ScrollContainer alwaysShowTracks={true} style={{ marginTop: '0px !important' }} >
                                <ParticipantsList>
                                    <TableStyle>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Stack direction="row" alignItems='center'>
                                                            First Name
                                                            <span style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => sortList('f')}>{sortLName === null ? (sortFName ? <ArrowUpward></ArrowUpward> : <ArrowDownward></ArrowDownward>) : <ImportExport></ImportExport>}</span>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Stack direction="row" alignItems='center'>
                                                            Last Name
                                                            <span style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => sortList('l')}>{sortFName === null ? (sortLName ? <ArrowUpward></ArrowUpward> : <ArrowDownward></ArrowDownward>) : <ImportExport></ImportExport>}</span>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>Employment Type</TableCell>
                                                    <TableCell>Job Title</TableCell>
                                                    <TableCell>Department</TableCell>
                                                    <TableCell>Year or Key Stage</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {putSelectedStaffFirst([...staff]).filter(x => {
                                                    if (searchText === '') return true
                                                    let name = x.firstName + ' ' + x.lastName
                                                    return name.toLowerCase().includes(searchText.toLowerCase())
                                                })?.map((row, id) => (
                                                    <TableRow key={row.SchoolStaffId}>
                                                        <TableCell>
                                                            <Checkbox
                                                                style={{ fontSize: 18, padding: '0', top: '-2px' }}
                                                                checked={filteredStaff.findIndex(x => x.schoolStaffId === row.schoolStaffId) > -1}
                                                                onChange={e => {
                                                                    let newlist = filteredStaff;
                                                                    let itemIndex = newlist.findIndex(x => x.schoolStaffId === row.schoolStaffId);
                                                                    if (itemIndex > -1) {
                                                                        newlist.splice(itemIndex, 1);
                                                                    }
                                                                    else {
                                                                        newlist.push(staff[id])
                                                                    }
                                                                    setFilteredStaff([...newlist]);

                                                                    onSurveyChange([...newlist])
                                                                    OnHasUpdate(true)
                                                                }}>
                                                            </Checkbox>
                                                        </TableCell>
                                                        <TableCell>{row.firstName}</TableCell>
                                                        <TableCell>{row.lastName}</TableCell>
                                                        <TableCell>{row.email}</TableCell>
                                                        <TableCell>{row.employmentType === 1 ? "Full Time" : "Part Time"}</TableCell>
                                                        <TableCell>{row.jobTitle}</TableCell>
                                                        <TableCell>{row.department}</TableCell>
                                                        <TableCell>{row.phaseYear}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableStyle>
                                </ParticipantsList>
                            </ScrollContainer>
                        </Grid>
                        {/* : ''} */}
                    </>
                    :
                    <Grid item lg={12} md={12} xs={12}>
                        <Skeleton width='100%' height={20} />
                        <Skeleton width='100%' height={20} />
                        <Skeleton width='100%' height={20} />
                    </Grid>
                }
            </Grid>
        </AppContainer >
    );
}

export default ParticipantsForm;