import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Sidebar} from 'react-pro-sidebar';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Outlet } from 'react-router-dom';
import { AppContainer, MainContainer, MenuContainer, BodyContainer } from '../../assets/styles/AppContainers';
import { SideMenu } from './SideMenu';
import { Header } from '../Header';


//const SchoolDashboardLayout = () =>
function SchoolDashboardLayout(props)
{
    const [toggled, setToggled] = React.useState(false);
    const [breakpoint, setBreakPoint] = React.useState(window.matchMedia('(max-width: 1024px)').matches);

    const contentHeader = (
        <div className='main-header-content'>
            <div>
                {breakpoint && (
                    <button className="sb-button" onClick={() => setToggled(!toggled)}>
                        <MenuRoundedIcon/>
                    </button>
                )}
            </div>

            <div className='mobile-logo'>
            <img alt='' src='https://welbee.international/wp-content/uploads/2021/04/welbee_logo_yellow.png'></img>
            </div>
            <Box
            sx={{
                display: {
                xs: 'block',
                sm: 'block',
                md: 'block',
                xl: 'none',
                lg: 'none',
                },
            }}
            >
            <div className='btn-request-assistance'>
                <MarkunreadIcon sx={{ fontSize: 20 }} />
            </div>
            </Box>
        </div>
    );

    return (
        <AppContainer>
            <MainContainer >
                <Sidebar className='leftsidebar' onBackdropClick={() => setToggled(false)} toggled={toggled} customBreakPoint="1024px" onBreakPoint={setBreakPoint}>
                    <MenuContainer>
                        <SideMenu />
                    </MenuContainer>                
                </Sidebar>
                <BodyContainer>
                    <div className='mobile-header'>{contentHeader}</div>                    
                    <div className='main-header'>
                        <Header />
                    </div>
                    <Outlet />
                </BodyContainer>
            </MainContainer>
        </AppContainer>
    );

}

export default SchoolDashboardLayout;
//export { SchoolDashboardLayout };