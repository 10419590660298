import React from 'react';
import { surveyService } from '../services';
import { PlayArrow } from '@mui/icons-material';
import { PulseStatus } from '../utils/Constants';

function PlayButton({ token, OnResume, isMainButton = false }) {
    return (
        <> {isMainButton ?
            <div className='survey-options' onClick={() => {
                surveyService.UpdatePulseStatusByToken(token, PulseStatus.Published)
                OnResume()
            }}>
                <div>
                    <PlayArrow className='schedule-icons' />
                </div>
                <div>Resume<br />Survey</div>
            </div> :
            <div className='resume-btn' onClick={() => {
                surveyService.UpdatePulseStatusByToken(token, PulseStatus.Published)
                OnResume()
            }}>
                <PlayArrow className='schedule-icons' />Resume
            </div>}
        </>

    );
}

export default PlayButton;