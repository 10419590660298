import React from 'react';
import { Grid, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { WhiteLayoutContent } from '../../../assets/styles/AppContainers';
// import { thankyou } from '../../../assets/images/index';

function SurveyCreated(props)
{
    const navigate = useNavigate()
    return (
        <WhiteLayoutContent>
            <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                    <div className='thankyou'>
                        {/* <img src={thankyou} alt='' /> */}
                        <h1>Your survey has been created</h1>
                        <Button className='view-all-btn' onClick={() => navigate('/')} >Go To Dashboard</Button>
                    </div>
                </Grid>
            </Grid>
        </WhiteLayoutContent>
    );
}

export default SurveyCreated;