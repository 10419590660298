import request from '../request';
import { accountApi } from '../Urls';

export default class accountService
{
    static async Login(data)
    {
        return request({
            url: accountApi.Login(),
            method: 'POST',
            data: data,
        })
    }
}