import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { questionBankService } from '../../services'


const initialState = {
    questionBank: [],
    loading: false
}


export const getQuestionBank = createAsyncThunk('loadQuestionBank.loadQuestionBank', async () =>
{
    return questionBankService.Get();
});

export const questionBankSlice = createSlice({
    name: 'questionBank',
    initialState,
    reducers: {},
    extraReducers: {
        [getQuestionBank.pending]: (state) =>
        {
            state.loading = true
        },
        [getQuestionBank.fulfilled]: (state, action) =>
        {
            state.questionBank = action.payload
            state.loading = false
        },
        [getQuestionBank.rejected]: (state) =>
        {
            state.loading = false
        },
    }
})

export default questionBankSlice.reducer 