import request from '../request';
import { questionPoolApi } from '../Urls';

export class questionPoolService
{
    static async GetQuestionPool(schoolId)
    {
        return request({
            url: questionPoolApi.GetQuestionPool(schoolId),
            method: 'GET'
        });
    }
}