import React, { useState, useEffect } from 'react';
import { QuestionsTextField, QuestionsSelect, SurveyLayoutContent } from '../../../assets/styles/AppContainers';
import { Button, Grid, Alert, IconButton, Skeleton, FormControl, InputLabel, MenuItem, Stack } from '@mui/material';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CancelIcon from '@mui/icons-material/Cancel';
import { GetDateFromString } from '../../../utils/Utils';
import CommentReplies from '../../../components/CommentReplies';
import { iconreply, iconreplied } from '../../../assets/images/index';
import useSelectedSurvey from '../../../hooks/useSelectedSurvey';
import { commentService } from '../../../services/api/commentsService';
import DownloadButton from '../../../components/DownloadButton';
import { useSelector } from 'react-redux';
import { InfoOutlined, NavigateNext } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import PulseTooltip from '../../../components/PulseTooltip';

const Roles = [
    'All Staff',
    'Teaching Staff',
    'Support Staff',
    'Leaders',
]
const SortOrder = [
    'Latest',
    'Oldest',
    'Not Replied',
    'Admin Replied',
    'My Replies',
    'Others Replied',
    'Staff Replied',
    'To Me',
    'To Others',

]
function Comments() {

    const { id } = useParams();

    const { organization } = useSelector(store => store);

    const { selectedSurvey, loading } = useSelectedSurvey();
    const [questions, setQuestions] = useState([]);
    const [comments, setComments] = useState([]);
    const [selectedReply, setSelectedReply] = useState(-1);
    const [leadersCount, setLeadersCount] = useState(0);
    const [teachingStaffCount, setTeachingStaffCount] = useState(0);
    const [supportStaffCount, setSupportStaffCount] = useState(0);
    const [selectedText, setSelectedText] = useState('');
    const [selectedJobRole, setSelectedJobRole] = useState(Roles[0]);
    const [selectedSort, setSelectedSort] = useState(SortOrder[0]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (selectedSurvey) {
            setQuestions(selectedSurvey?.surveyQuestions?.filter(x => x.questionLikertFormat === 1));
            setComments(selectedSurvey.comments ? selectedSurvey.comments : []);
            setLeadersCount(selectedSurvey.leadersStaffSubmissionCount);
            setTeachingStaffCount(selectedSurvey.teachingStaffSubmissionCount);
            setSupportStaffCount(selectedSurvey.supportStaffSubmissionCount);
            // onFilter();
        }
    }, [selectedSurvey]);
    useEffect(() => { onFilter() }, [selectedText, selectedJobRole, selectedSort]);

    const getJobRole = (jobRoleId) => {

        let teachingRoles = [4, 8]
        let supportRoles = [16, 32]
        let leadersRoles = [64, 128];

        let largestCountJobRole = teachingStaffCount > supportStaffCount && teachingStaffCount > leadersCount ? "Teaching Staff" :
            supportStaffCount > leadersCount ? "Support Staff" : "Leaders";

        if (teachingRoles.includes(jobRoleId)) {
            return teachingStaffCount > 4 ? "Teaching Staff" : largestCountJobRole;
        }

        if (supportRoles.includes(jobRoleId)) {
            return supportStaffCount > 4 ? "Support Staff" : largestCountJobRole;
        }
        if (leadersRoles.includes(jobRoleId)) {
            return leadersCount > 4 ? "Leaders" : largestCountJobRole;
        }

    }

    const onFilter = () => {
        if (selectedSurvey === undefined || selectedSurvey === null) return;
        let comment = [...selectedSurvey?.comments];
        if (comment) {
            if (selectedText !== '') {
                comment = comment.filter(x => x.comments.toLowerCase().includes(selectedText.toLowerCase()));
            }
            if (selectedJobRole !== Roles[0]) {
                comment = comment.filter(x => getJobRole(x.jobRole) === selectedJobRole);
            }
            if (selectedSort === SortOrder[0]) { // Latest        
                comment = [...comment?.sort((a, b) => {
                    if (a.commentReplies?.length > 0 && b.commentReplies?.length > 0) {
                        return new Date(b.commentReplies[0]?.replyDate) - new Date(a.commentReplies[0]?.replyDate)
                    }
                    else if (a.commentReplies?.length > 0 && b.commentReplies?.length === 0) {
                        return new Date(b.submitDate) - new Date(a.commentReplies[0]?.replyDate)
                    }
                    else if (b.commentReplies?.length > 0 && a.commentReplies?.length === 0) {
                        return new Date(b.commentReplies[0]?.replyDate) - new Date(a.submitDate)
                    }
                    return new Date(b.submitDate) - new Date(a.submitDate)
                })]
            }
            else if (selectedSort === SortOrder[1]) { // Oldest                
                comment.sort((a, b) => {
                    if (a.commentReplies?.length > 0 && b.commentReplies?.length > 0) {
                        return new Date(a.commentReplies[0].replyDate) - new Date(b.commentReplies[0].replyDate)
                    }
                    else if (a.commentReplies?.length > 0 && b.commentReplies?.length === 0) {
                        return new Date(a.commentReplies[0].replyDate) - new Date(b.submitDate)
                    }
                    else if (b.commentReplies?.length > 0 && a.commentReplies?.length === 0) {
                        return new Date(a.submitDate) - new Date(b.commentReplies[0].replyDate)
                    }
                    return new Date(b.submitDate) - new Date(a.submitDate)
                })
            }
            else if (selectedSort === SortOrder[2]) {
                comment = comment.filter(x => x.commentReplies?.length === 0);
            }
            else if (selectedSort === SortOrder[4]) {// My Replies
                comment = comment.filter(x => x.commentReplies.filter(y => y.replierId === organization.userInfo.Id).length > 0);
            }
            else if (selectedSort === SortOrder[5]) {// Other Replies
                comment = comment.filter(x => x.commentReplies.length > 0);
            }
            else if (selectedSort === SortOrder[7]) {// to me
                comment = comment.filter(x => {
                    if (x.commentReplies.length > 1) {
                        let idx = x.commentReplies.findIndex(x => x.isStaffReply === true)
                        if (idx > 0) {
                            return x.commentReplies[idx - 1].replierId === organization.userInfo.Id;
                        }
                    }
                    return false;
                });
            }
            else if (selectedSort === SortOrder[8]) {// replied to all
                comment = comment.filter(x => {
                    if (x.commentReplies.length > 1) {
                        let idx = x.commentReplies.findIndex(x => x.isStaffReply === true)
                        if (idx > 0) {
                            return true;
                        }
                    }
                    return false;
                });
            }
            setComments(comment);
        }
    }
    const downloadExcel = () => {
        commentService.DownloadCommentsInExcel(selectedSurvey?.token)
            .then(response => {
                const url = window.URL.createObjectURL(
                    new Blob([response], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    })
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${selectedSurvey.name} - comments.xlsx`
                );
                document.body.appendChild(link);
                link.click();
            })
    }

    return (

        <Grid container>
            <Grid item lg={6} md={6} xs={12}>
                <h2>Comments</h2>
            </Grid>
            <Grid item lg={6} md={6} xs={12} textAlign="right" mb={2}>
                {questions?.length > 0 && <DownloadButton download={downloadExcel} Title='Download Comments'></DownloadButton>}
            </Grid>

            {
                questions.length > 0 ?
                    <Grid item lg={12} md={12} xs={12} mb={2}>
                        <Grid container spacing={{sm: 1, xs: 2}} justifyContent={'flex-end'}>
                            <Grid item lg={5} md={5} xs={12}>
                                <QuestionsTextField fullWidth
                                    label='Keyword Search'
                                    size='small'
                                    onChange={e => setSelectedText(e.target.value)}>
                                </QuestionsTextField>
                            </Grid>

                            <Grid item lg={3} md={3} xs={12}>
                                <Stack direction='row' spacing={1} alignItems="center">
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id="job-role">Filter Job Role</InputLabel>
                                        <QuestionsSelect
                                            labelId="job-role"
                                            placeholder={'Filter Job Role'}
                                            label={'Filter Job Role'}
                                            value={selectedJobRole}
                                            onChange={e => {
                                                setSelectedJobRole(e.target.value)
                                            }}>
                                            {Roles.map(role => <MenuItem value={role}>{role}</MenuItem>)}
                                        </QuestionsSelect>
                                    </FormControl>
                                    <PulseTooltip arrow title='Filter by Job Role'>
                                        <InfoOutlined sx={{ fontSize: 'large' }}></InfoOutlined>
                                    </PulseTooltip>
                                </Stack>
                            </Grid>
                            <Grid item lg={3} md={3} xs={12}>
                                <Stack direction='row' spacing={1} alignItems="center">
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id="order-filter">Sort By</InputLabel>
                                        <QuestionsSelect
                                            labelId="order-filter"
                                            placeholder={'Sort By'}
                                            label={'Sort By'}
                                            value={selectedSort}
                                            onChange={e => {
                                                let sort = e.target.value;
                                                if (sort === SortOrder[3] || sort === SortOrder[6]) { return }
                                                setSelectedSort(e.target.value)
                                            }}>
                                            {
                                                SortOrder.map(sort => <MenuItem value={sort} disabled={sort === SortOrder[3] || sort === SortOrder[6]}>
                                                    <div style={{ paddingLeft: sort === SortOrder[4] || sort === SortOrder[5] || sort === SortOrder[7] || sort === SortOrder[8] ? 15 : 0 }}>{sort}</div>
                                                    {(sort === SortOrder[3]) ? <NavigateNext /> : null}

                                                    {(sort === SortOrder[6]) ? <NavigateNext /> : null}
                                                </MenuItem>)
                                            }
                                        </QuestionsSelect>
                                    </FormControl>
                                    <PulseTooltip arrow title='Sort List'>
                                        <InfoOutlined sx={{ fontSize: 'large' }}></InfoOutlined>
                                    </PulseTooltip>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid> : null
            }
            <Grid item lg={12} md={12} xs={12}>
                {loading ?
                    (<div>
                        <Skeleton width={'100%'} ></Skeleton >
                        <Skeleton width={'100%'} ></Skeleton >
                        <Skeleton width={'100%'} ></Skeleton >
                    </div>
                    ) : questions.length > 0 ? (
                        <SurveyLayoutContent>
                            <Carousel dynamicHeight={false} showArrows={true} showIndicators={false} showStatus={false} showThumbs={false} renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <button type="button" className='carousel-btn' onClick={onClickHandler} title={label} style={{
                                        position: 'absolute',
                                        zIndex: 2,
                                        top: '0',
                                        cursor: 'pointer',
                                        left: 0
                                    }}>
                                        {<ArrowBackIosIcon />}
                                    </button>
                                )
                            }
                                renderArrowNext={(onClickHandler, hasNext, label) =>
                                    hasNext && (
                                        <button type="button" className='carousel-btn' onClick={onClickHandler} title={label} style={{
                                            position: 'absolute',
                                            zIndex: 2,
                                            top: '0',
                                            cursor: 'pointer',
                                            right: 0
                                        }}>
                                            {<ArrowForwardIosIcon />}
                                        </button>
                                    )
                                } >
                                {
                                    questions.map(q => {
                                        return <>
                                            <div className='comment-question'>{q.text}</div>
                                            <Grid container>
                                                {comments?.length === 0 ? <Grid item lg={12} md={12} xs={12} mt={2}><Alert severity="error">No Comments found.</Alert></Grid> : comments?.filter(x => x.surveyQuestionId === q.id).map(comment => {
                                                    return <Grid item lg={12} md={12} xs={12}>
                                                        <div className='comment-content detail-comments'>
                                                            <div className='comments-meta'>
                                                                <div className='comment-date'>
                                                                    {GetDateFromString(comment.commentReplies && comment.commentReplies?.length > 0 ? comment.commentReplies[0].replyDate : comment.submitDate)}, {getJobRole(comment.jobRole)}
                                                                </div>
                                                                <div className='comments-text'>
                                                                    {comment.comments}
                                                                </div>
                                                            </div>

                                                            <div className='comments-reply'>
                                                                <Stack direction='row' alignItems="center">
                                                                    {selectedReply !== comment.commentId ? null : <CancelIcon onClick={e => setSelectedReply(-1)} sx={{ fontSize: '31px', color: '#53B5E0', cursor: 'pointer' }} />}
                                                                    <IconButton disableRipple sx={{ paddingLeft: '0' }} onClick={(e) => {
                                                                        if (selectedReply !== comment.commentId) {
                                                                            setSelectedReply(comment.commentId)
                                                                        }
                                                                        else {
                                                                            setSelectedReply(-1)
                                                                        }
                                                                    }}><img src={comment.commentReplies?.length > 0 ? iconreplied : iconreply}
                                                                        alt='' style={{ height: '26px' }} /></IconButton>


                                                                </Stack>
                                                            </div>
                                                        </div>
                                                        {selectedReply === comment.commentId ?
                                                            <div>
                                                                <CommentReplies
                                                                    StaffRole={getJobRole(comment.jobRole)}
                                                                    CommentReplies={comment.commentReplies}
                                                                    IsAdminReply={true}
                                                                    IsStaffReply={false}
                                                                    CommentId={comment.commentId}
                                                                    LoginUser={organization.userInfo}
                                                                    onSend={() => {
                                                                        let index = comments.findIndex(x => x.commentId === comment.commentId);
                                                                        comments[index].hasReplied = true;
                                                                        setComments([...comments]);
                                                                    }}
                                                                ></CommentReplies>
                                                                <Button className='prev-button' onClick={e => setSelectedReply(-1)} sx={{ position: 'absolute', marginTop: '-39px', right: '90px' }}>
                                                                    Close
                                                                </Button>
                                                            </div> : ''}
                                                    </Grid>
                                                }
                                                )}
                                            </Grid>
                                        </>
                                    }
                                    )
                                }
                            </Carousel>
                        </SurveyLayoutContent>) :
                        <Alert severity="error">
                            There are no open questions in this survey.
                        </Alert>
                }

            </Grid>
        </Grid >
    );
}

export default Comments;