import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { questionSetService } from '../../services'
import { questionBankService } from '../../services'

/// not being used !!!!

const initialState = {
    questionSets: [],
    loading: false
}

export const getQuestionSets = createAsyncThunk('questions/loadQuestionSets', async () =>
{

    return questionBankService.Get();
});

export const questionSetSlice = createSlice({
    name: 'questionSets',
    initialState,
    reducers: {},
    extraReducers: {
        [getQuestionSets.pending]: (state) =>
        {

            state.loading = true
        },
        [getQuestionSets.fulfilled]: (state, action) =>
        {

            state.questionSets = action.payload
            state.loading = false
        },
        [getQuestionSets.rejected]: (state) =>
        {

            state.loading = false
        },
    }
})

export default questionSetSlice.reducer 