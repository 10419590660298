import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Routes from './routes/Routes';
//import { getFolders } from './redux/features/folderSlice';
import { getQuestionSets } from './redux/features/questionSetSlice';
import { getQuestionBank } from './redux/features/questionBankSlice';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  typography: {
    fontSize: [14, "!important"],
    fontFamily: [
      'Avenir LT Pro',
    ].join(','),
  }
});

function App() {
  const dispatch = useDispatch()
  // const navigate = useNavigate()

  // const { school, loading } = useSelector((store) => store.school)

  useEffect(() => {
    dispatch(getQuestionSets())
    //   // if (school.schoolId)
    //   // {
    //   //   dispatch(getFolders(school.schoolId))
    //   // }
    dispatch(getQuestionBank())
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
