import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { questionPoolService } from '../../services/api/questionPoolService';


const initialState = {
    questions: [],
    loading: false
}


export const getQuestionPool = createAsyncThunk('questionPool/getQuestionPool', async () =>
{
    return questionPoolService.GetQuestionPool();
});

export const questionPoolSlice = createSlice({
    name: 'questionPool',
    initialState,
    reducers: {},
    extraReducers: {
        [getQuestionPool.pending]: (state) =>
        {
            state.loading = true
        },
        [getQuestionPool.fulfilled]: (state, action) =>
        {
            state.questions = action.payload
            state.loading = false
        },
        [getQuestionPool.rejected]: (state) =>
        {
            state.loading = false
        },
    }
})


export default questionPoolSlice.reducer 