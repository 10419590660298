import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { emailTemplateService } from '../../services/api/emailTemplateService';
import { Button, Divider, Grid, Modal, Box, Stack, Alert, List, Tooltip, MenuItem, FormControl, InputLabel } from '@mui/material';
import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import EmailTemplate from './EmailTemplate';
import { EmailInviteDefaultTemplate } from '../../utils/Constants';
import CloseIcon from '@mui/icons-material/Close';
import { QuestionsSelect } from '../../assets/styles/AppContainers';
import PulseTooltip from '../PulseTooltip';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    boxShadow: 5,
    p: 3,
};


function EmailTemplateList({ onClose, Survey, onSurveyChange, OnHasUpdate }) {

    const { organization } = useSelector(store => store)

    const [templates, setTemplates] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [newTemplate, setNewTemplate] = React.useState({ id: 0, orgId: organization.orgId, subject: null, content: null, footer: null });
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const [loading, setLoaded] = React.useState(true);

    useEffect(() => {
        //    if (templates.length > 0) return;
        emailTemplateService.GetGetListOfOrgEmailTemplates(organization.orgId)
            .then(res => {
                setTemplates(res)
                setLoaded(false);
            })
    }, []);

    const deleteTemplate = (id) => {
        if (id > 0) {
            emailTemplateService.DeleteEmailTemplate(id)
                .then(res => {
                    setTemplates(templates.filter(t => t.id !== id))
                    setSelectedTemplate(null);

                })
        }
    }
    const onUse = () => {
        let survey = Survey;
        survey.inviteEmailSubject = selectedTemplate.subject;
        survey.inviteEmailBody = EmailInviteDefaultTemplate.Salutation + selectedTemplate.content + EmailInviteDefaultTemplate.Link + selectedTemplate.footer;
        onSurveyChange(survey);
        OnHasUpdate(true);
        onClose();
    }

    return (
        <Grid container>
            {open && <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={style}>
                    <EmailTemplate templateToEdit={newTemplate} onClose={() => { setOpen(false); onClose() }} Survey={Survey} onSurveyChange={onSurveyChange} OnHasUpdate={OnHasUpdate} />
                </Box>
            </Modal>}

            <Grid item lg={10} md={10} xs={10}>
                <h2>Invitation Templates</h2>
            </Grid>
            <Grid item lg={2} md={2} xs={2}>
                <CloseIcon onClick={onClose}
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                        fontSize: '22px'
                    }} />
            </Grid>
            <Grid item lg={12} md={12} xs={12} mb={2}>
                <List className='email-template'>
                    {loading ? 'loading' : templates?.length > 0 ?
                        <FormControl fullWidth size="small">
                            <InputLabel id="selected-template-label">Select Invitation Template</InputLabel>
                            <QuestionsSelect
                                labelId="selected-template-label"
                                label="Select Invitation Template"
                                value={selectedTemplate} onChange={(e) => setSelectedTemplate(e.target.value)}>
                                {templates?.map(template => <MenuItem key={template.id} value={template} >{template.subject}</MenuItem>)}
                            </QuestionsSelect>
                        </FormControl>
                        : <Alert severity="error">No templates saved</Alert>}
                </List>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <Grid container >
                    <Grid item lg={12} md={12} xs={12}>
                        {templates?.length > 0 ? <h2>Preview</h2> : ''}
                    </Grid>

                    {selectedTemplate && <Grid container>
                        <Grid item lg={10} md={10} xs={10}>
                            Email Subject:  {selectedTemplate.subject}
                        </Grid>
                        <Grid item lg={2} md={2} xs={2} mb={1}>
                            <Stack direction="row" spacing={1} justifyContent="end">
                                <PulseTooltip title="Clone">
                                    <ContentCopy style={{ cursor: 'pointer', fontSize: '18px', color: 'green' }}
                                        onClick={() => {
                                            setNewTemplate({
                                                id: 0, orgId: organization.orgId, subject: selectedTemplate.subject, content: selectedTemplate.content, footer: selectedTemplate.footer
                                            });
                                            setOpen(true);
                                        }} />
                                </PulseTooltip>

                                <PulseTooltip title="Edit">
                                    <Edit
                                        style={{ cursor: 'pointer', fontSize: '18px', color: 'green' }}
                                        onClick={() => {
                                            setNewTemplate(selectedTemplate);
                                            setOpen(true);
                                        }} />
                                </PulseTooltip>
                                {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
                                <PulseTooltip title="Delete">
                                    <Delete
                                        style={{ cursor: 'pointer', fontSize: '18px', color: '#e84f6b' }}
                                        onClick={() => deleteTemplate(selectedTemplate.id)} />
                                </PulseTooltip>
                            </Stack>

                        </Grid>
                    </Grid>}
                    {!loading && <Grid item lg={12} md={12} xs={12} mb={5} >
                        {selectedTemplate ?
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: EmailInviteDefaultTemplate.Salutation?.replace('{StaffFirstName}', 'FirstName') }}></div>
                                <div dangerouslySetInnerHTML={{ __html: selectedTemplate.content }}></div>
                                <div dangerouslySetInnerHTML={{ __html: EmailInviteDefaultTemplate.Link }}></div>
                                <div dangerouslySetInnerHTML={{ __html: selectedTemplate.footer }}></div>
                            </div> : templates?.length > 0 ? 'Selecte a template to preview' : ''}
                    </Grid>}
                </Grid>

            </Grid>

            <Grid item lg={4} md={4} xs={12}>
                <Button className='prev-button' onClick={onClose} >Cancel</Button>
            </Grid>
            <Grid item lg={8} md={8} xs={8}>
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                    {selectedTemplate &&
                        <Button className='next-button' onClick={onUse}>Use</Button>
                    }
                    <Button className='next-button' onClick={() => { setNewTemplate(null); setOpen(true); }} >Add New</Button>
                </Stack>
            </Grid>
        </Grid >
    );
}

export default EmailTemplateList;