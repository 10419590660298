import React, { useEffect, } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { SurveyDashboardMenu } from '../../routes/MenuConfig';
import { FormControl, Grid, MenuItem, Stack, Box, IconButton, Typography, Menu } from '@mui/material';
import { PulseMATMenu } from '../../assets/styles/AppContainers';
import { useSelector, useDispatch } from 'react-redux';

import { surveyService } from '../../services';
import { getCurrentSurveyForDashboard } from '../../redux/features/surveySlice';

import { useNavigate } from "react-router-dom";
import '../../assets/styles/app.scss';
import { RepeatDuration } from '../../utils/Constants';
import MenuIcon from '@mui/icons-material/Menu';





function SurveyMenu({ selectedSurvey }) {

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        selectedSurvey ?
            <PulseMATMenu>                
                {/* <Stack direction={{ sm: 'row', xs: 'row' }} spacing={{ sm: 2, xs: 0 }}>
                    <div>
                        <NavLink className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""} to={`dashboard/token/${selectedSurvey?.token}`}>Overview</NavLink>
                    </div>
                    <div>
                        <NavLink to={`participants/token/${selectedSurvey?.token}`}>Participants</NavLink>
                    </div>
                    <div>
                        <NavLink to={`comments/token/${selectedSurvey?.token}`}>Comments</NavLink>
                    </div>
                    <div>
                        <NavLink to={`heatmap/token/${selectedSurvey?.token}`}>Heatmap</NavLink>
                    </div>
                    <div>
                        <NavLink to={`analytics/token/${selectedSurvey?.token}`}>Analytics</NavLink>
                    </div>
                </Stack> */}
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography  sx={{fontSize: '14px', fontWeight: 500, ml: 1.5, display: { xs: 'flex', md: 'none' }}}>Survey Overview</Typography>                    
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="inner menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            sx={{padding: 0}}
                        >
                            <MenuIcon sx={{fontSize: '22px'}}/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' }}}
                            className='responsive-menu'
                        >                            
                            <Stack direction='column' spacing={2} onClick={handleCloseNavMenu}>
                                <div className='responsive-menu-item'>
                                    <div>
                                        <NavLink className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""} to={`dashboard/token/${selectedSurvey?.token}`}>Overview</NavLink>
                                    </div>
                                    <div>
                                        <NavLink to={`participants/token/${selectedSurvey?.token}`}>Participants</NavLink>
                                    </div>
                                    <div>
                                        <NavLink to={`comments/token/${selectedSurvey?.token}`}>Comments</NavLink>
                                    </div>
                                    <div>
                                        <NavLink to={`heatmap/token/${selectedSurvey?.token}`}>Heatmap</NavLink>
                                    </div>
                                    <div>
                                        <NavLink to={`analytics/token/${selectedSurvey?.token}`}>Analytics</NavLink>
                                    </div>
                                </div>
                            </Stack>
                            
                        </Menu>
                    </Box>
                </Stack>
                


                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Stack direction='row' spacing={2}>
                        <div>
                            <NavLink className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""} to={`dashboard/token/${selectedSurvey?.token}`}>Overview</NavLink>
                        </div>
                        <div>
                            <NavLink to={`participants/token/${selectedSurvey?.token}`}>Participants</NavLink>
                        </div>
                        <div>
                            <NavLink to={`comments/token/${selectedSurvey?.token}`}>Comments</NavLink>
                        </div>
                        <div>
                            <NavLink to={`heatmap/token/${selectedSurvey?.token}`}>Heatmap</NavLink>
                        </div>
                        <div>
                            <NavLink to={`analytics/token/${selectedSurvey?.token}`}>Analytics</NavLink>
                        </div>
                    </Stack>
                </Box>                 
            
            </PulseMATMenu> : ''
    );
}

export { SurveyMenu };