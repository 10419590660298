import { Chip, Modal, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ParticipantList from './ParticipantList';
import CloseIcon from '@mui/icons-material/Close';
import { SurveyLayoutContent, WhiteLayoutContent } from '../../assets/styles/AppContainers';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    //border: '1px solid #f2f2f2',
    marginBottom: '6px',
    fontWeight: 'bold',    
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : '#f2f2f2',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(0),
      padding: theme.spacing(0),      
    },
    minHeight: '38px',
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    //borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: '#f9f9f9',
  }));

function ParticipantFilters({ participantFilters, participants }) {

    const { organization } = useSelector(store => store.organization)

    const [schools, setSchools] = useState([])
    const [counters, setCounters] = useState([])
    const [allStaff, setAllStaff] = useState(false)
    const [open, setOpen] = useState(false)
    const [selectedPeople, setSelectedPeople] = useState([])
    const [selectedLabel, setSelectedLabel] = useState('')

    useEffect(() => {

        if (participantFilters == null || participantFilters === '') return;

        let parsed = JSON.parse(participantFilters ?? {})
        if (parsed?.id === -1) {
            setAllStaff(true)
        }
        else {

            setSchools(parsed?.schools)
            setFilterCounters();
        }

    }, [participantFilters, participants])


    const setFilterCounters = () => {

        let parsed = JSON.parse(participantFilters ?? {})
        let selectedFilters = parsed?.selectedFilters;
        let selectedChildFilter = parsed?.selectedChildFilter;
        let people = [...participants]
        let filterCounters = []
        selectedFilters?.forEach((filter, idx) => {
            let subFilter = selectedChildFilter[idx]
            let counter = []
            let propName = '';
            let propValue = null;
            subFilter?.forEach(sub => {
                let count = 0;
                if (filter.label === 'Job Type') {
                    count = people.filter(person => {
                        return person.employmentType === sub.value
                    }).length
                    propName = 'employmentType'
                    propValue = sub.value
                }
                else if (filter.label === 'Time At School') {
                    count = people.filter(person => {
                        return person.timeWorking === sub.value
                    }).length
                    propName = 'timeWorking'
                    propValue = sub.value
                }
                else {

                    count = people.filter(person => {
                        return person[filter.label[0].toLowerCase() + filter.label.slice(1).replace(' ', '')].replace('\n', '').includes(sub.label.replace('\n', ''))
                    }).length
                    propName = filter.label[0].toLowerCase() + filter.label.slice(1).replace(' ', '')
                    propValue = sub.label
                }
                counter.push({ parentLabel: filter.label, label: sub.label, count: count, propName: propName, propValue: propValue })
            })
            filterCounters.push({ label: filter.label, counter: counter })
        })
        setCounters(filterCounters)

    }

    const getChip = ({ parentLabel, label, count, propName, propValue }) => <Chip clickable label={label + ': ' + count} onClick={e => handleChipClick(label, parentLabel, propName, propValue)} style={{marginRight: '5px', fontSize: '13px', fontWeight: '500'}}></Chip>

    const handleChipClick = (label, parentLabel, propName, propValue) => {
        let l = [...participants.filter(x => typeof (x[propName]) === 'number' ? x[propName] === propValue : x[propName].replace('\n', '').includes(propValue.replace('\n', '')))]
        setSelectedPeople(l)
        setSelectedLabel(parentLabel + ": " + label)
        setOpen(true)
    }

    return (
        allStaff ? <div>All Staff were added</div> :
            <div>
                <Modal className='warning-modal' open={open} onClose={e => setOpen(false)} >
                    <WhiteLayoutContent sx={{ padding: 2, width: '94%' }}>
                        <IconButton
                            aria-label="close"
                            onClick={e => setOpen(false)}
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                fontSize: '22px'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <h2>{selectedLabel}</h2>
                        <ParticipantList
                            staff={selectedPeople}>
                        </ParticipantList>
                    </WhiteLayoutContent>
                </Modal>

                {organization?.isMat && <Accordion>
                    <AccordionSummary>Schools</AccordionSummary>

                    {schools?.map(school => <AccordionDetails>{school.label}</AccordionDetails>)}
                </Accordion>}

                {counters?.map((filter, idx) =>
                    <Accordion>
                        <AccordionSummary
                            id={`panel1-header-${filter.label}`}>{filter.label}</AccordionSummary>
                        <AccordionDetails>{filter.counter?.map(subFilter => getChip({ ...subFilter }))}</AccordionDetails>
                    </Accordion>)}
            </div >
    );
}

export default ParticipantFilters;