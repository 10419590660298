import request from '../request';
import { folderApi } from '../Urls';

export default class folderService {

    static async GetFoldersBySchoolId(orgId, isMat)
    {
        return request({
            url: folderApi.GetSchoolFolders(orgId, isMat),
            method: 'GET'
        });
    }
    static async CreateFolderBySchoolId(orgId, isMat, name)
    {        
        return request({
            url: folderApi.CreateFolder(),
            method: 'POST',
            data: { id: 0, name: name, orgId: orgId, isMat: isMat }
        });
    }
    static async UpdateFolder(folder) {
        return request({
            url: folderApi.UpdateFolder(),
            method: 'PUT',
            data: folder
        });
    }
    static async DeleteFolder(id) {
        return request({
            url: folderApi.DeleteFolder(id),
            method: 'DELETE',
        });
    }
}