import React from 'react';
import { surveyService } from '../services';
import { Pause } from '@mui/icons-material';
import { PulseStatus } from '../utils/Constants';

function PauseButton({ token, onPause, isMainButton = false }) {
    return (
        <> {isMainButton ?
            <div className='survey-options' onClick={() => {
                surveyService.UpdatePulseStatusByToken(token, PulseStatus.Paused)
                onPause();
            }}>
                <div>
                    <Pause className='schedule-icons' style={{ cursor: 'pointer' }} />
                </div>
                <div>Pause<br />Survey</div>
            </div> :
            <div className='resume-btn' onClick={() => {
                surveyService.UpdatePulseStatusByToken(token, PulseStatus.Paused)
                onPause();
            }}>
                <Pause className='schedule-icons' style={{ cursor: 'pointer' }} />Pause
            </div>}
        </>

    );
}

export default PauseButton;