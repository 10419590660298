import React, { useEffect, useState } from 'react';
import useSelectedSurvey from '../../../hooks/useSelectedSurvey';
import { FilterSelect, PulseHeatmap, PulseTextField, QuestionsSelect, SurveyLayoutContent } from '../../../assets/styles/AppContainers';
import { Alert, Button, FormControl, Grid, InputLabel, ListItemText, Menu, MenuItem, Popover, Skeleton, Snackbar } from '@mui/material';
import { ScrollContainer } from 'react-nice-scroll';
import filtersService from '../../../services/api/filtersService';
import Popup from '../../../components/Popup';
import { Delete, ImportExport, North, South } from '@mui/icons-material';
import Select, { components } from "react-select";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { PulseStatus, SurveyFilterType } from "../../../utils/Constants";
import DownloadButton from '../../../components/DownloadButton';
import ModalPopup from '../../../components/ModalPopup';
import DownloadHeatmap from '../../../components/DownloadHeatmap';
import { surveyResultsService } from '../../../services/api/surveyResultsService';
import { Popper } from '@mui/base/Popper';
import LinkButton from '../../../components/LinkButton';


const QuestionTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="top" arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(0,0,0,0.9)",
        color: "#ffffff",
        maxWidth: 220,
        fontSize: 13,
        padding: "8px",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "rgba(0,0,0,0.9)",
    },
}));

const SortOrder = {
    none: 0,
    accesnding: 1,
    decesnding: 2,
};

const parentFiltersCont = [
    { id: 1, title: "Job Role" },
    { id: 2, title: "Employment Type" },
    { id: 3, title: "Time at School" },
];
const filtersCont = [
    { id: 1, parentFilterId: 1, title: "Teaching Staff (Classroom)" },
    { id: 2, parentFilterId: 1, title: "Teaching Staff (Non-Classroom)" },
    { id: 3, parentFilterId: 1, title: "Support Staff (Classroom)" },
    { id: 4, parentFilterId: 1, title: "Support Staff (Non-Classroom)" },
    { id: 5, parentFilterId: 1, title: "Middle Leaders" },
    { id: 6, parentFilterId: 1, title: "Senior Leaders" },
    { id: 1, parentFilterId: 2, title: "Full Time" },
    { id: 2, parentFilterId: 2, title: "Part Time" },
    { id: 1, parentFilterId: 3, title: "Less Than 1 Year" },
    { id: 2, parentFilterId: 3, title: "1 to 3 Years" },
    { id: 3, parentFilterId: 3, title: "4 to 5 Years" },
    { id: 4, parentFilterId: 3, title: "6 to 10 Years" },
    { id: 5, parentFilterId: 3, title: "11 to 15 Years" },
    { id: 6, parentFilterId: 3, title: "16 to 20 Years" },
    { id: 7, parentFilterId: 3, title: "More than 20 Years" },
];
const allStaff = { value: -1, title: 'All Staff' }

const Heatmap = () => {
    const { selectedSurvey, loading } = useSelectedSurvey()

    const [savedFilters, setSavedFilters] = useState([])
    const [selectedSavedFilter, setSelectedSavedFilter] = useState([])
    const [selectedParentFilter, setSelectedParentFilter] = useState(allStaff)
    const [selectedFilter, setSelectedFilter] = useState([])
    const [openModel, setOpenModel] = useState(false)
    const [filtersOptions, setFiltersOptions] = useState([])
    const [newFilterName, setNewFilterName] = useState('')
    const [sortOrder, setSortOrder] = useState(SortOrder.none)
    const [sortId, setSortId] = useState(null)
    const [allFilterData, setAllFilterData] = useState([])
    const [filters, setFilters] = useState([])
    const [questionData, setQuestionData] = useState([])
    const [parentFilters, setParentFilters] = useState([...parentFiltersCont])
    const [selectedDownloadFilters, setSelectedDownloadFilters] = useState([])
    const [errorDownload, setErrorDownload] = useState(false)

    const [showDownload, setShowDownload] = useState(false);
    const [anchor, setAnchor] = React.useState(null);
    const [downloadSelectedFilters, setDownloadSelectedFilters] = useState(false)

    const downloadCurrentHeatmap = async () => {
        var sf = [];
        // selectedDownloadFilters

        if (selectedParentFilter) {
            filters.filter(x => x.parentFilterId === selectedParentFilter.id).map((f) => {
                sf.push({ id: f.id, pId: selectedParentFilter.id, })
            })
        }
        if (selectedFilter?.length > 0) {
            selectedFilter.map((f) => {
                let spf = f.value.split("-")
                sf.push({ id: parseInt(spf[0]), pId: parseInt(spf[1]) })
            })
        }
        setSelectedDownloadFilters(p => {
            setDownloadSelectedFilters(!downloadSelectedFilters)
            return [...sf]
        })
    }
    // useEffect(() => {
    //     doDownloadHeatmap(selectedFilter?.length > 0 ? false : true);
    // }, [downloadSelectedFilters])

    const deleteSavedFitler = (id) => {
        filtersService
            .DeleteHeatmapSurveyFilter(id)
            .then((response) => {
                if (response) {
                    setSavedFilters([
                        ...savedFilters.filter((x) => x.id !== id),
                    ])
                }
            })
            .finally((e) => setOpenModel(false));
        return;
    }


    const handleSelectChange = (selectedOption) => {
        setSelectedFilter(selectedOption)
        setSelectedParentFilter(null)
        setSortOrder(SortOrder.none)
        setSortId(null)
    }

    const handleChecboxSelection = (groupValue) => {
        if (groupValue) {
            const selectedFilters = filtersOptions.map((mainObj) => {
                return {
                    ...mainObj,
                    options: mainObj.options.map((option) => ({
                        ...option,
                        checked: option.label === groupValue,
                    })),
                };
            });

            const flatOptions = selectedFilters.flatMap((mainObj) =>
                mainObj.options.filter((option) => option.checked)
            );

            setSelectedFilter(flatOptions)
            setSelectedParentFilter(null)
            //  setFiltersOptions(selectedFilters)
        } else {
            setSelectedFilter([])
            setSelectedParentFilter(null)
        }
    }

    const getSaveFilterForm = () => {
        return (
            <PulseTextField
                label={"Enter filter name"}
                style={{ width: "100%", margin: "15px 0 20px 0" }}
                onChange={(e) => setNewFilterName(e.target.value)}
            />
        );
    }

    const sortColumn = (id, order) => {
        let questionsScores = allFilterData
            .filter((x) => x.questionId === id)
            .sort((x, y) => {
                if (order === SortOrder.accesnding) return x.avg - y.avg;
                else return y.avg - x.avg;
            });
        let otherFilters = [];
        if (selectedFilter) {
            questionsScores.forEach((item) => {
                selectedFilter.map((eahFilter) => {

                    let sepEachFilter = eahFilter.value.split("-");
                    let filtered = filters.filter(
                        (x) =>
                            x.parentFilterId == sepEachFilter[1] && x.id == sepEachFilter[0]
                    );
                    if (
                        filtered.filter(
                            (x) =>
                                x.parentFilterId === item.parentFilterId &&
                                x.id === item.filterId
                        )[0]
                    )
                        otherFilters.push(eahFilter);
                });
            });
            setSelectedFilter([...otherFilters])
        }
        if (selectedParentFilter) {
            questionsScores = questionsScores.filter(
                (x) => x.parentFilterId === selectedParentFilter.id
            );
            let filtered = filters.filter(
                (x) => x.parentFilterId === selectedParentFilter.id
            );
            otherFilters = filters.filter(
                (x) => x.parentFilterId !== selectedParentFilter.id
            );
            questionsScores.forEach((item) => {
                if (
                    filtered.filter(
                        (x) =>
                            x.parentFilterId === item.parentFilterId && x.id === item.filterId
                    )[0]
                )
                    otherFilters.push(
                        filters.filter(
                            (x) =>
                                x.parentFilterId === item.parentFilterId &&
                                x.id === item.filterId
                        )[0]
                    );
            });
            setFilters([...otherFilters])
        }
        setSortOrder(order)
        setSortId(id)
    }

    const myCell = (sd, avg) => {
        let cell = "";
        if (avg > -1) {
            if ((sd > 7 || sd < 4) && sd !== "") {
                if (sd > 7) {
                    cell = (
                        <div className="redcell cell">
                            {avg.toFixed(2)}
                            {getDecile(sd)}
                        </div>
                    );
                } else if (sd >= 1 && sd < 4) {
                    cell = (
                        <div className="greencell cell">
                            {avg.toFixed(2)}
                            {getDecile(sd)}
                        </div>
                    );
                } else {
                    cell = (
                        <div className="cell">
                            {avg.toFixed(2)}
                            {getDecile(sd)}
                        </div>
                    );
                }
            } else {
                cell = (
                    <div className="cell">
                        {avg.toFixed(2)}
                        {getDecile(sd)}
                    </div>
                );
            }
        } else {
            cell = <div className="cell">-</div>;
        }
        return cell;
    }

    const createFilters = () => {
        let f = [];
        filtersCont.forEach((fil) => f.push(fil));
        setFilters(f)
    }

    useEffect(() => {
        if (!loading) {
            createFilters();
            let questionDataTemp = [];
            let allFitlerDataTemp = [];

            let newAdditionalParentFilter = [];
            var defaultFilters = [];
            let newAdditionalFilter = [];

            let averages = []
            if (selectedSurvey.isMatSurvey) {
                averages = [...selectedSurvey?.surveyQuestionAverages]
            }
            else {
                averages = selectedSurvey?.surveyQuestionAverages;
            }
            averages?.map((datum) => {
                questionDataTemp.push({
                    qid: datum.questionId,
                    avg: datum.questionAverage,
                    decile: datum.schoolDecilesAverage,
                });

                allFitlerDataTemp.push({
                    filterId: 1,
                    parentFilterId: 1,
                    questionId: datum.questionId,
                    avg: datum.teachingStaffClassAvg,
                    decile: datum.sdTeachingStaffClass,
                });
                allFitlerDataTemp.push({
                    filterId: 2,
                    parentFilterId: 1,
                    questionId: datum.questionId,
                    avg: datum.teachingStaffNonClassAvg,
                    decile: datum.sdTeachingStaffNonClass,
                });

                allFitlerDataTemp.push({
                    filterId: 3,
                    parentFilterId: 1,
                    questionId: datum.questionId,
                    avg: datum.supportStaffClassAvg,
                    decile: datum.sdSupportStaffClass,
                });
                allFitlerDataTemp.push({
                    filterId: 4,
                    parentFilterId: 1,
                    questionId: datum.questionId,
                    avg: datum.supportStaffNonClassAvg,
                    decile: datum.sdSupportStaffNonClass,
                });

                allFitlerDataTemp.push({
                    filterId: 5,
                    parentFilterId: 1,
                    questionId: datum.questionId,
                    avg: datum.middleLeaderAvg,
                    decile: datum.sdMiddleLeader,
                });
                allFitlerDataTemp.push({
                    filterId: 6,
                    parentFilterId: 1,
                    questionId: datum.questionId,
                    avg: datum.seniorLeaderAvg,
                    decile: datum.sdSeniorLeader,
                });

                allFitlerDataTemp.push({
                    filterId: 1,
                    parentFilterId: 2,
                    questionId: datum.questionId,
                    avg: datum.fullTimeAvg,
                    decile: datum.sdFullTime,
                });
                allFitlerDataTemp.push({
                    filterId: 2,
                    parentFilterId: 2,
                    questionId: datum.questionId,
                    avg: datum.partTimeAvg,
                    decile: datum.sdPartTime,
                });

                allFitlerDataTemp.push({
                    filterId: 1,
                    parentFilterId: 3,
                    questionId: datum.questionId,
                    avg: datum.timeAtSchool1YrAvg,
                    decile: datum.sdTimeAtSchool1Yr,
                });
                allFitlerDataTemp.push({
                    filterId: 2,
                    parentFilterId: 3,
                    questionId: datum.questionId,
                    avg: datum.timeAtSchool1To3YrsAvg,
                    decile: datum.sdTimeAtSchool1To3Yrs,
                });
                allFitlerDataTemp.push({
                    filterId: 3,
                    parentFilterId: 3,
                    questionId: datum.questionId,
                    avg: datum.timeAtSchool4To5YrsAvg,
                    decile: datum.sdTimeAtSchool4To5Yrs,
                });
                allFitlerDataTemp.push({
                    filterId: 4,
                    parentFilterId: 3,
                    questionId: datum.questionId,
                    avg: datum.timeAtSchool6To10YrsAvg,
                    decile: datum.sdTimeAtSchool6To10Yrs,
                });
                allFitlerDataTemp.push({
                    filterId: 5,
                    parentFilterId: 3,
                    questionId: datum.questionId,
                    avg: datum.timeAtSchool11To15YrsAvg,
                    decile: datum.sdTimeAtSchool11To15Yrs,
                });
                allFitlerDataTemp.push({
                    filterId: 6,
                    parentFilterId: 3,
                    questionId: datum.questionId,
                    avg: datum.timeAtSchool16To20YrsAvg,
                    decile: datum.sdTimeAtSchool16To20Yrs,
                });
                allFitlerDataTemp.push({
                    filterId: 7,
                    parentFilterId: 3,
                    questionId: datum.questionId,
                    avg: datum.timeAtSchool20AboveYrsAvg,
                    decile: datum.sdTimeAtSchool20AboveYrs,
                });

                filtersCont.forEach((f) => {
                    let scores = allFitlerDataTemp.filter(
                        (x) =>
                            x.filterId === f.id && x.parentFilterId === f.parentFilterId
                    );
                    if (scores.filter((x) => x.avg === -1).length !== scores.length) {
                        if (defaultFilters.filter(df => df.title === f.title).length === 0) {
                            defaultFilters.push(f)
                        }
                    }
                    // else {
                    //     setParentFilters([...parentFilters.filter(x => x.id !== f.parentFilterId)])
                    // }
                });
                if (datum.additionalFilters) {
                    let additionalFilters = JSON.parse(datum.additionalFilters);
                    Object.keys(additionalFilters).map((eachParentFilter, pIdx) => {
                        if (
                            newAdditionalParentFilter.filter(
                                (x) => x.title.replace(" ", "")
                                    === eachParentFilter.replace(" ", ""),
                            )?.length === 0
                        ) {
                            newAdditionalParentFilter.push({
                                id: 4 + pIdx,
                                title: eachParentFilter.replace("JobTitle", "Job Title"),
                            });
                        }
                        Object.keys(additionalFilters[eachParentFilter]).map(
                            (value, i) => {
                                if (
                                    defaultFilters.filter((x) => x.title === value)?.length ===
                                    0
                                ) {
                                    newAdditionalFilter.push({
                                        id: i + 1,
                                        parentFilterId: 4 + pIdx,
                                        title: value,
                                    });
                                }

                                allFitlerDataTemp.push({
                                    filterId: i + 1,
                                    parentFilterId: 4 + pIdx,
                                    questionId: datum.questionId,
                                    avg: additionalFilters[eachParentFilter][value][0],
                                    decile:
                                        additionalFilters[eachParentFilter][value].length > 1
                                            ? additionalFilters[eachParentFilter][value][1]
                                            : "",
                                });
                            }
                        );
                    });

                }

            });
            newAdditionalFilter.forEach((f) => {
                let scores = allFitlerDataTemp.filter(
                    (x) =>
                        x.filterId === f.id &&
                        x.parentFilterId === f.parentFilterId
                );
                if (scores.filter((x) => x.avg === -1).length !== scores.length) {
                    if (defaultFilters.filter(df => df.title === f.title).length === 0) {
                        defaultFilters.push(f)
                    }
                }
                // else {
                //     newAdditionalParentFilter = newAdditionalParentFilter.filter(x => x.id !== f.parentFilterId)
                // }
            });

            let parentFilteredList = [];
            parentFiltersCont.forEach((f) => {
                let scores = allFitlerDataTemp.filter(
                    (x) =>
                        //  x.filterId === f.id && 
                        x.parentFilterId === f.id
                );
                if (scores.length > 0 && scores.filter((x) => x.avg === -1).length !== scores.length) {
                    parentFilteredList.push(f)
                }
            });

            let addtionalParentFilteredList = [];
            newAdditionalParentFilter.forEach((f) => {
                let scores = allFitlerDataTemp.filter(
                    (x) =>
                        //  x.filterId === f.id && 
                        x.parentFilterId === f.id
                );
                if (scores.length > 0 && scores.filter((x) => x.avg === -1).length !== scores.length) {
                    addtionalParentFilteredList.push(f)
                }
            });


            let sortedParentFilters = [
                ...parentFilteredList,
                ...addtionalParentFilteredList,
            ].sort((x, y) => {
                if (x.title < y.title)
                    return -1
                if (x.title > y.title)
                    return 1
                return 0
            })
            setParentFilters([allStaff, ...sortedParentFilters])
            let newSortedDefaultFilters = defaultFilters.sort((x, y) => {
                if (x.parentFilterId === 3)
                    return 0;
                if (x.title < y.title)
                    return -1
                if (x.title > y.title)
                    return 1
                return 0
            })
            setFilters([...newSortedDefaultFilters])
            createGroupedOptions([...defaultFilters], sortedParentFilters);
            setQuestionData(questionDataTemp)
            setAllFilterData(allFitlerDataTemp)

            filtersService
                .GetHeatmapSurveyFilterBySurveyToken(selectedSurvey?.token)
                .then((data) => setSavedFilters(data))
                .catch();
        }
    }, [selectedSurvey, loading]);

    const toggleGroupSelection = (groupValue, groupSelected) => {

        setSelectedParentFilter(null)
        if (groupSelected) {
            if (filtersOptions)
                setSelectedFilter([...selectedFilter, ...filtersOptions.filter(x => x.options.filter(y => y.group === groupValue).length > 0)])
        } else {
            setSelectedFilter([])
        }
    }

    const createSingleOption = (idx, title, id) => {
        return <div className="filter-title">
            <label htmlFor={`checkbox${idx}`} onClick={(e) => toggleGroupSelection(idx, e.target.checked)}>
                <input
                    type="checkbox"
                    value={idx}
                    id={`checkbox${idx}`}
                    onChange={() => { }}
                />
                {title}
            </label>
        </div>
    }

    const createGroupedOptions = (dataSet, sortedParentFilters) => {

        const groups = [];
        sortedParentFilters.map((pf, idx) => groups.push(createSingleOption(idx, pf.title, pf.id)))
        const groupedOptions = [];
        sortedParentFilters.forEach((pf, idx) => {
            dataSet.filter(x => x.parentFilterId === pf.id).map(option => {
                const value = option.id + "-" + option.parentFilterId;
                const label = option.title;
                const group = idx// option.parentFilterId;
                const data = {
                    value,
                    label,
                    group: group,
                };
                const groupIndex = groupedOptions.findIndex(
                    (groupOption) => {
                        return groupOption.label === groups[group]
                    }
                );

                if (groupIndex === -1) {
                    groupedOptions.push({
                        label: groups[group],
                        options: [data],
                    });
                } else {
                    groupedOptions[groupIndex].options.push(data);
                }
            })
        });
        setFiltersOptions(groupedOptions)
        return groupedOptions;
    }

    const saveNewFitler = () => {
        let newFilter = {
            surveyToken: selectedSurvey.token,
            name: newFilterName,
            surveyFilterType: SurveyFilterType.Heatmap,
            filters: JSON.stringify(selectedFilter),
        };
        setSavedFilters([savedFilters, newFilter])
        filtersService
            .SaveNewFilter(newFilter)
            .then((data) => { })
            .finally((e) => setOpenModel(false));
    }

    const getDecile = (sd) => {
        if (sd > 0) {
            let val = "th";
            if (sd === 1) {
                val = "st";
            } else if (sd === 2) {
                val = "nd";
            } else if (sd === 3) {
                val = "rd";
            }
            return (
                <div className="decile">
                    ({sd}
                    <sup>{val}</sup>)
                </div>
            );
        }
    }

    const openMenu = Boolean(anchor);
    const id = openMenu ? 'simple-popper' : undefined;

    const doDownloadHeatmap = (downloadWithTitle) => {
        setErrorDownload(false)

        var selectedIds = selectedDownloadFilters.filter(x => x.pId !== null);
        if (selectedIds.length > 0) {
            surveyResultsService.DownloadHeatmap({
                token: selectedSurvey.token,
                ids: selectedIds,
                downloadWithTitle: downloadWithTitle
            }).then(response => {
                if (response) {
                    const url = window.URL.createObjectURL(
                        new Blob([response], {
                            type: 'application/pdf',
                        })
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${selectedSurvey.name} - heatmap.pdf`
                    );
                    document.body.appendChild(link);
                    link.click();
                    setSelectedDownloadFilters([])
                }
            }).finally(() => {
                setShowDownload(false)
                setSelectedDownloadFilters([])
            })
        }
        else {
            setErrorDownload(true)
        }
    }


    return (

        loading ?
            <>
                <Skeleton width={'100%'} ></Skeleton >
                <Skeleton width={'100%'} ></Skeleton >
                <Skeleton width={'100%'} ></Skeleton >
            </> :
            selectedSurvey.pulseStatus === PulseStatus.Live ?
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <h2>Heatmap</h2>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Alert severity="error">Heatmap is available once the survey is closed..</Alert>
                    </Grid>
                </Grid>
                :
                selectedSurvey?.surveyQuestionAverages?.length === 0 ?
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <h2>Heatmap</h2>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Alert severity="error"> There were insufficient filtered responses to display this information.</Alert>
                        </Grid>
                    </Grid>
                    :
                    <PulseHeatmap>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={errorDownload}
                            autoHideDuration={6000}
                            onClose={() => { setErrorDownload(false) }}
                        >
                            <Alert severity="error">Please select filters to download.</Alert>
                        </Snackbar>
                        <Grid container>
                            <Grid item lg={6} md={6} xs={12}>
                                <h2>Heatmap</h2>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12} textAlign="right" mb={2}>
                                <DownloadButton
                                    aria-describedby={id}
                                    download={(event) => {
                                        //setShowDownload(true) 
                                        setAnchor(anchor ? null : event.currentTarget);
                                    }} Title='Download Heatmap' ></DownloadButton>
                                <Popover
                                    id={id}
                                    open={openMenu}
                                    anchorEl={anchor}
                                    onClose={() => setAnchor(null)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}>
                                    <div style={{ marginTop: 1, width: 200, marginLeft: 15 }}>
                                        <LinkButton onClick={() => { setShowDownload(true); setAnchor(null) }}>Choose Filters to Download</LinkButton>
                                        <LinkButton onClick={() => { downloadCurrentHeatmap(); setAnchor(null) }}>Download Selected Filters</LinkButton>
                                    </div>
                                </Popover>
                                {showDownload && <ModalPopup
                                    onCancel={() => setShowDownload(false)}
                                    title={"Download Heatmap"}
                                    render={<DownloadHeatmap parentFilters={parentFilters} filters={filters} selectedFilters={selectedDownloadFilters} setSelectedFilters={setSelectedDownloadFilters} />}
                                    onAction={() => doDownloadHeatmap(true)}
                                >
                                </ModalPopup>
                                }
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className="flex-custom-heatmap">
                                    {savedFilters.length > 0 ? (
                                        <div className='save-filter-dd'>
                                            <FormControl size="small" fullWidth>
                                                <InputLabel id="saved-heatmap">
                                                    Saved Heatmaps
                                                </InputLabel>
                                                <QuestionsSelect
                                                    className='saved-heatmap'
                                                    id={"filters"}
                                                    labelId="saved-heatmap"
                                                    label="Saved Heatmaps"
                                                    value={selectedSavedFilter}
                                                    renderValue={(selected) => selected.name}
                                                    onChange={(e) => {
                                                        setSelectedSavedFilter(e.target.value)
                                                        setSelectedParentFilter(null)
                                                        setSelectedFilter(JSON.parse(e.target.value.filters))
                                                    }}>
                                                    {savedFilters.map((item) => (
                                                        <MenuItem value={item}>
                                                            <ListItemText primary={item.name} />
                                                            <Delete
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    deleteSavedFitler(item.id);
                                                                    return;
                                                                }}
                                                            ></Delete>
                                                        </MenuItem>
                                                    ))}
                                                </QuestionsSelect>
                                            </FormControl>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div className='search-filter-dd'>
                                        <Select
                                            className="custom-filter-heatmap-dropdown"
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            isMulti
                                            options={filtersOptions}
                                            onChange={(selected) => handleSelectChange(selected)}
                                            value={selectedFilter}
                                            placeholder="Search Filters"
                                            required={true}
                                            isClearable={true}
                                            columns={2}
                                            onClear={(e) => setSelectedFilter(null)}
                                            components={{
                                                Option: ({
                                                    getStyles,
                                                    Icon,
                                                    isDisabled,
                                                    isFocused,
                                                    isSelected,
                                                    innerProps,
                                                    label,
                                                    ...rest
                                                }) => {
                                                    const bg = isFocused
                                                        ? "#eee"
                                                        : isSelected
                                                            ? "#B2D4FF"
                                                            : "transparent";
                                                    const style = {
                                                        alignItems: "center",
                                                        backgroundColor: bg,
                                                        color: "inherit",
                                                        display: "flex",
                                                        width: "48%",
                                                    };
                                                    const props = {
                                                        ...innerProps,

                                                        style,
                                                    };

                                                    return (
                                                        <div className="heatmap-option">
                                                            <components.Option
                                                                {...rest}
                                                                onClick={() => {
                                                                    handleChecboxSelection(label);
                                                                }}
                                                                isDisabled={isDisabled}
                                                                isFocused={isFocused}
                                                                isSelected={isSelected}
                                                                getStyles={getStyles}
                                                                innerProps={props}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={() => { }}
                                                                    checked={isSelected}
                                                                />
                                                                {label}
                                                            </components.Option>
                                                        </div>
                                                    );
                                                },
                                            }}
                                        />
                                    </div>
                                    {selectedFilter && selectedParentFilter === null ? (
                                        <div>
                                            <Button
                                                variant="contained"
                                                className="next-button"
                                                onClick={(e) => setOpenModel(true)}>
                                                {" "}
                                                Save
                                            </Button>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {openModel ? (
                                        <Popup
                                            Title={"Save Heatmap"}
                                            Msg={<PulseTextField
                                                label={"Enter filter name"}
                                                style={{ width: "100%", margin: "15px 0 20px 0" }}
                                                onChange={(e) => {
                                                    setNewFilterName(e.target.value)
                                                }}
                                            />}
                                            onCancel={(e) => {
                                                setOpenModel(false)
                                                setNewFilterName('')
                                            }}
                                            onAction={saveNewFitler}
                                        ></Popup>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </Grid>
                        </Grid>

                        <div className="heatmap-grid">
                            <div className="filter-dropdown">
                                <ul>
                                    <li>
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="heatmap-filters">
                                                Heatmap Filters
                                            </InputLabel>
                                            <FilterSelect
                                                labelId="heatmap-filters"
                                                id={"filters"}
                                                value={selectedParentFilter}
                                                label="Heatmap Filters"
                                                onChange={(e) => {
                                                    setSelectedFilter(null)
                                                    setSelectedSavedFilter(null)
                                                    setSelectedParentFilter(e.target.value === -1 ? null : e.target.value)
                                                    setSortOrder(null)
                                                    setSortId(null)
                                                }}>
                                                {parentFilters?.map((item) => (
                                                    <MenuItem value={item}>{item.title}</MenuItem>
                                                ))}
                                            </FilterSelect>
                                        </FormControl>
                                    </li>
                                </ul>
                            </div>
                            <div className="heatmap-filters">
                                <ul>
                                    <li> Questions</li>
                                </ul>
                            </div>
                        </div>

                        <div className="heatmap-grid">
                            <div className="heatmap-questions">
                                <ul>
                                    <li>Staff Filters & Characteristics</li>
                                </ul>
                            </div>
                            <div className="heatmap-filters filtercell">
                                <ul>
                                    {selectedSurvey?.surveyQuestions?.filter(x => x.questionLikertFormat === 2)?.map((q, idx) => (
                                        <QuestionTooltip title={q.text}>
                                            <li>
                                                {" "}
                                                Q{idx + 1}
                                                {q.id === sortId &&
                                                    sortOrder === SortOrder.accesnding ? (
                                                    <North
                                                        className="south-sort-btn"
                                                        onClick={(e) =>
                                                            sortColumn(q.id, SortOrder.decesnding)
                                                        }
                                                    ></North>
                                                ) : q.id === sortId &&
                                                    sortOrder ===
                                                    SortOrder.decesnding ? (
                                                    <South
                                                        className="north-sort-btn"
                                                        onClick={(e) =>
                                                            sortColumn(q.id, SortOrder.accesnding)
                                                        }
                                                    ></South>
                                                ) : (
                                                    <ImportExport
                                                        className="sort-btn"
                                                        onClick={(e) =>
                                                            sortColumn(q.id, SortOrder.accesnding)
                                                        }
                                                    ></ImportExport>
                                                )}
                                            </li>
                                        </QuestionTooltip>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="heatmap-grid">
                            <div className="heatmap-questions">
                                <ul>
                                    <li>All Staff</li>
                                </ul>
                            </div>
                            <div className="heatmap-filters">
                                <ul>
                                    {questionData?.map((item) => (
                                        <li>
                                            {myCell(
                                                item?.decile
                                                    ?.replace("th", "")
                                                    .replace("rd", "")
                                                    .replace("nd", "")
                                                    .replace("st", ""),
                                                item.avg
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <ScrollContainer>
                            <div className="heatmap-fixed">
                                {selectedParentFilter?.title && selectedParentFilter.value !== -1 ? (
                                    <div className="heatmap-header">
                                        <ul>
                                            <li>{selectedParentFilter.title}</li>
                                        </ul>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {selectedParentFilter ? (
                                    <div className="heatmap-grid">
                                        <div className="heatmap-questions">
                                            <ul>
                                                {filters
                                                    ?.filter(
                                                        (x) =>
                                                            x.parentFilterId ===
                                                            selectedParentFilter?.id
                                                    )
                                                    ?.map((item) => (
                                                        <li>{item.title}</li>
                                                    ))}
                                            </ul>
                                        </div>
                                        <div className="heatmap-filters">
                                            {filters
                                                .filter(
                                                    (x) =>
                                                        x.parentFilterId ===
                                                        selectedParentFilter?.id
                                                )
                                                ?.map((dd) => (
                                                    <ul>
                                                        {selectedSurvey.surveyQuestions.filter(x => x.questionLikertFormat === 2).map((question) => {

                                                            let cellData =
                                                                allFilterData.filter(
                                                                    (x) =>
                                                                        x.parentFilterId ===
                                                                        dd.parentFilterId &&
                                                                        x.filterId === dd.id &&
                                                                        x.questionId === question.id
                                                                )[0];
                                                            return (
                                                                <li>
                                                                    {myCell(
                                                                        cellData?.decile,
                                                                        cellData?.avg
                                                                    )}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                ))}
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {selectedFilter
                                    ? selectedFilter?.map((dd) => (
                                        <div className="heatmap-grid">
                                            <div className="heatmap-questions">
                                                <ul>
                                                    <li>{dd.label}</li>
                                                </ul>
                                            </div>
                                            <div className="heatmap-filters">
                                                <ul>
                                                    {selectedSurvey.surveyQuestions.filter(x => x.questionLikertFormat === 2).map((question) => {
                                                        let cellData =
                                                            allFilterData.filter(
                                                                (x) =>
                                                                    x.parentFilterId ===
                                                                    parseInt(dd.value.split("-")[1]) &&
                                                                    x.filterId ===
                                                                    parseInt(dd.value.split("-")[0]) &&
                                                                    x.questionId === question.id
                                                            )[0];
                                                        return (
                                                            <li>
                                                                {myCell(
                                                                    cellData?.decile,
                                                                    cellData?.avg
                                                                )}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    ))
                                    : ""}
                            </div>
                        </ScrollContainer>
                    </PulseHeatmap>
    );
}

export default Heatmap;