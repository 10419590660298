import React from 'react';
import { PulseStatus, SurveyStatus } from '../../utils/Constants';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Stack } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ParticipantsList, SurveyLayoutContent, TableStyle } from '../../assets/styles/AppContainers';
import { ScrollContainer } from 'react-nice-scroll';
import PulseTooltip from '../PulseTooltip';

const QuestionsList = ({ survey }) => {

    const renderRows = () => {

        let rows = [...survey?.surveyQuestions];

        rows?.sort((x, y) => x.order - y.order);
        return rows?.map((question, index) =>
            <>
                <TableRow>
                    <TableCell>
                        <strong>Q {question.order}:</strong> {question.text}
                        {question.questionLikertFormat === 2 ? <>
                            <Stack direction="row" spacing={3} alignItems='center' mt={1}>
                                <h4>Options:</h4>
                                {question.surveyQuestionOptions.map((option, index) =>
                                    <div><strong>{index + 1}:</strong> {option.text}</div>
                                )}
                            </Stack>
                        </> : null}
                    </TableCell>
                </TableRow>
            </>
        )
    }
    return (
        survey?.pulseStatus === PulseStatus.Live ?
            <SurveyLayoutContent>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <h2>
                            <Stack direction="row" spacing={0.5} alignItems='center'>
                                <div>Questions</div>
                                <PulseTooltip title="When using the colour key for question answers, dark green indicates a higher score and red a lower score. These colours do not necessarily indicate good or poor scores overall.">
                                    <InfoOutlinedIcon style={{ cursor: 'pointer' }} />
                                </PulseTooltip>
                            </Stack>
                        </h2>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <ScrollContainer alwaysShowTracks={true}>
                            <ParticipantsList>
                                <TableStyle>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Question</TableCell>
                                                {/* <TableCell>Type</TableCell> */}
                                                {/* <TableCell></TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {renderRows()}
                                        </TableBody>
                                    </Table>
                                </TableStyle>
                            </ParticipantsList>
                        </ScrollContainer>
                    </Grid>
                </Grid>
            </SurveyLayoutContent>
            : null

    );
};

export default QuestionsList;