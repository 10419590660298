import React, { useEffect } from 'react';
import { AppContainer, QuestionsSelect } from '../../../assets/styles/AppContainers';
import { Box, Button, Grid, Modal, Stack, Alert } from '@mui/material';
import { EmailInviteDefaultTemplate } from '../../../utils/Constants';
import { useSelector } from 'react-redux';
import ModalPopup from '../../../components/ModalPopup';
import EmailTemplate from '../../../components/EmailTemplate/EmailTemplate';
import { emailTemplateService } from '../../../services/api/emailTemplateService';
import EmailTemplateList from '../../../components/EmailTemplate/EmailTemplateList';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    boxShadow: 5,
    p: 3,
};

const EmailInvite = ({ Survey, onSurveyChange, OnHasUpdate }) => {

    const { organization } = useSelector(store => store)

    //const [emailSubject, setEmailSubject] = React.useState(Survey.inviteEmailSubject);
    // const [emailBody, setEmailBody] = React.useState(Survey.inviteEmailBody);
    //const [newTemplate, setNewTemplate] = React.useState({ id: 0, orgId: organization.orgId, name: '', subject: '', body: '' });
    //  const [selectedTemplate, setSelectedTemplate] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        let survey = Survey;
        if (survey.inviteEmailSubject === null) {
            survey.inviteEmailSubject = EmailInviteDefaultTemplate.Subject;
            //      setEmailSubject(EmailInviteDefaultTemplate.Subject);
            OnHasUpdate(true);
        }
        if (survey.inviteEmailBody === null) {
            let body = EmailInviteDefaultTemplate.Salutation + EmailInviteDefaultTemplate.Content + EmailInviteDefaultTemplate.Link + EmailInviteDefaultTemplate.Footer;
            body = body?.replace('{schoolName}', organization.name);
            //   body = body.replace('{surveyLink}', `#/survey/token/${Survey.token}`);

            survey.inviteEmailBody = body;
            //  setEmailBody(body);
            OnHasUpdate(true);
        }
        onSurveyChange(survey);
        OnHasUpdate(true);
    }, [Survey]);


    return (
        <AppContainer>
            <Grid container padding={3}>
                <Grid item lg={10} md={10} xs={12}>
                    <h2>Customise Invitation</h2>
                    <Modal open={open} onClose={() => setOpen(false)}>
                        <Box sx={style}>
                            <EmailTemplateList onClose={() => setOpen(false)} Survey={Survey} onSurveyChange={onSurveyChange} OnHasUpdate={OnHasUpdate} />
                        </Box>
                    </Modal>
                    {/* {open && <ModalPopup
                        onCancel={() => setOpen(false)}
                        title={"Choose Template"}
                        //render={<EmailTemplate template={newTemplate} setTemplate={setNewTemplate} />}
                        render={<EmailTemplateList onClose={() => setOpen(false)} Survey={Survey} onSurveyChange={onSurveyChange} OnHasUpdate={OnHasUpdate} />}
                        onAction={d => setOpen(true)}
                    >
                    </ModalPopup>} */}
                </Grid>


                <Grid item lg={2} md={2} xs={12} textAlign={'right'}>
                    <Button variant="contained" className='next-button' onClick={() => setOpen(true)} >
                        Customise Invitation
                    </Button>
                </Grid>

                <Grid item lg={12} md={12} xs={12} pb={1} pt={2}>
                    <Alert severity="info">The email invitation below will be sent to participants unless you choose to customise it.</Alert>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                    <p>Email Subject: {Survey.inviteEmailSubject}</p>
                </Grid>

                {/* <Grid item lg={2} md={2} xs={2}>
                     <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="selected-template-label">Select Folder</InputLabel>
                        <QuestionsSelect
                            value={selectedTemplate}
                            labelId="selected-template-label"
                            label="Select from templates"
                            onChange={(e) => {
                                let t = e.target.value;
                                setSelectedTemplate(t);
                                setEmailSubject(t.subject);
                                setEmailBody(t.body);
                                let survey = Survey;
                                survey.inviteEmailSubject = t.subject;
                                survey.inviteEmailBody = t.body;
                                onSurveyChange(survey);
                                OnHasUpdate(true);
                            }}
                        >
                            {templates.map(t =>
                                <MenuItem value={t}><Grid container>
                                    <Grid item lg={8} md={8} xs={8}>{t.name}</Grid>
                                    <Grid item lg={2} md={2} xs={2}>
                                        {selectedTemplate?.id !== t?.id && <Edit
                                            style={{ cursor: 'pointer', fontSize: '18px', color: 'green' }}
                                            onClick={() => {
                                                setNewTemplate(t);
                                                setOpen(true);
                                            }}
                                        ></Edit>}
                                    </Grid>
                                    <Grid item lg={2} md={2} xs={2}>
                                        {selectedTemplate?.id !== t?.id && <Delete
                                            style={{ cursor: 'pointer', fontSize: '18px', color: '#e84f6b' }}
                                            onClick={() => deleteTemplate(t.id)}></Delete>}
                                    </Grid>
                                </Grid>
                                </MenuItem>)}
                        </QuestionsSelect>
                    </FormControl> 

                </Grid>*/}
                <Grid item lg={12} md={12} xs={12}>
                    <div>
                        <p><span dangerouslySetInnerHTML={{ __html: Survey.inviteEmailBody?.replace('{StaffFirstName}', 'FirstName') }}></span></p>
                    </div>
                </Grid>
            </Grid>
        </AppContainer >
    );
};

export default EmailInvite;