import { Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SurveyLayoutContent } from '../../../assets/styles/AppContainers';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { participantsService, surveyService } from '../../../services';
import ParticipantFilters from '../../../components/Participants/ParticipantFilters';
import ParticipantList from '../../../components/Participants/ParticipantList';

const Participants = () => {

    const location = useLocation();
    const organization = useSelector(store => store.organization)

    const [loading, setLoading] = useState(true)
    const [loadingParticipants, setLoadingParticipants] = useState(true)
    const [staff, setStaff] = useState([])
    const [participants, setParticipants] = useState([]);
    const [participantFitlers, setParticipantFilters] = useState('');


    useEffect(() => {
        setLoading(true)
        let token = location.pathname.split('token/')[1];
        participantsService.GetStaff(organization.orgId, organization.isMat)
            .then(resp => {
                if (resp.success) {
                    setStaff(resp.data.staffs.sort((x, y) => (x.firstName + x.lastName).localeCompare(y.firstName + y.lastName)))//.sort((x, y) => x.firstName.localeCompare(y.firstName))

                    setLoadingParticipants(true)
                    surveyService.GetSurveyByToken(token)
                        .then(resp1 => {
                            if (resp1) {
                                setParticipants([...resp1.surveyParticipants])
                                setParticipantFilters(resp1.participantFilters)
                            }
                        }).finally
                        (() => setLoadingParticipants(false))
                }
            })
            .finally(() => setLoading(false))
    }, [])

    return (loading & loadingParticipants ?
        <>
            <Skeleton width={'100%'} ></Skeleton >
            <Skeleton width={'100%'} ></Skeleton >
            <Skeleton width={'100%'} ></Skeleton >
        </> :
        <>
            <div className='question-selection'>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <h2>Participants</h2>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <ParticipantFilters participantFilters={participantFitlers} participants={[...staff.filter(x => participants.findIndex(y => y.schoolStaffId === x.schoolStaffId) > -1)]} />
                    </Grid>
                </Grid>
            </div>

            <Grid container mt={2}>
                <Grid item lg={12} md={12} xs={12}>
                    <SurveyLayoutContent>
                        <ParticipantList staff={staff.filter(x => participants.findIndex(y => y.schoolStaffId === x.schoolStaffId) > -1)} />
                    </SurveyLayoutContent>
                </Grid>
            </Grid>
        </>
    );
};

export default Participants;