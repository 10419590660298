import { Config } from '../utils/Config';

const { BASE_URL, WELBEE_URL } = Config;


export const folderApi = {
    GetSchoolFolders: (orgId, isMat) =>
        `${BASE_URL}/folders/GetFoldersBySchoolId?orgId=${orgId}&isMat=${isMat}`,
    CreateFolder: () =>
        `${BASE_URL}/folders`,
    UpdateFolder: () =>
        `${BASE_URL}/folders`,
    DeleteFolder: (id) =>
        `${BASE_URL}/folders?Id=${id}`,

};

export const questionPoolApi = {
    GetQuestionPool: () =>
        `${BASE_URL}/QuestionPool`,
};

export const staffManagementApi = {
    GetStaffBySchool: (schoolId) =>
        `${WELBEE_URL}/schoolstaff/GetSchoolStaffForPulse?schoolid=${schoolId}`,
    GetParticipantsBySurveyToken: () =>
        `${BASE_URL}/Participants/GetParticipantsBySurveyToken`,
    UpdateParticipantsAndSendInvites: () =>
        `${BASE_URL}/Participants/UpdateParticipantsAndSendInvites`,
    GetStaff: (id, isMat) =>
        `${BASE_URL}/Participants/GetStaff?orgId=${id}&isMat=${isMat}`,
    // GetParticipantsForSendInvites: () =>
    //     `${BASE_URL}/Participants/GetParticipantsForSendInvites`,
};

export const surveyApi = {
    CloseSurvey: () =>
        `${BASE_URL}/surveys/CloseSurvey`,
    ReopenSurvey: () =>
        `${BASE_URL}/surveys/Reopen`,
    GetSurveyForSurveyDashboard: () =>
        `${BASE_URL}/surveys/GetSurveyForSurveyDashboard`,
    GetSurveyByToken: () =>
        `${BASE_URL}/surveys/GetSurveyByToken`,
    GetSurveyQuestionsForSurvey: () =>
        `${BASE_URL}/surveys/GetSurveyQuestionsForSurvey`,
    GetSchoolDashboard: (orgId, isMat) =>
        `${BASE_URL}/surveys/GetSchoolDashboard?orgId=${orgId}&isMat=${isMat}`,
    SendInvites: () =>
        `${BASE_URL}/surveys/SendSurveyInvitations`,
    SubmitSurvey: () =>
        `${BASE_URL}/SurveySubmission`,
    ReRunSurvey: () =>
        `${BASE_URL}/Surveys/ReRun`,
    CreateSurvey: () =>
        `${BASE_URL}/surveys`,
    GetSurveysByOrgId: (orgId, isMAt) =>
        `${BASE_URL}/Surveys/GetSurveysByOrgId?orgId=${orgId}&isMat=${isMAt}`,
    GetSurveyByFolderId: (orgId, isMAt, folderId) =>
        `${BASE_URL}/Surveys/GetSurveysByOrgId?orgId=${orgId}&isMat=${isMAt}&folderId=${folderId}`,
    GetRerunSurveys: () =>
        `${BASE_URL}/Surveys/GetRerunSurveys`,
    UpdatePulseStatusByToken: (status) =>
        `${BASE_URL}/Surveys/UpdatePulseStatusByToken?pulseStatus=${status}`,
    GetClosedLiveSurveysBySchoolId: (schooId) =>
        `${BASE_URL}/Surveys/GetClosedLiveSurveysBySchoolId?schoolId=${schooId}`,
    DeleteSurvey: () =>
        `${BASE_URL}/Surveys/DeleteSurvey`,
    GetSurveyDashboardByToken: () =>
        `${BASE_URL}/Surveys/GetSurveyDashboardByToken`,
    GetAllPulseList: () =>
        `${BASE_URL}/surveys/GetAllPulseList`,
    GetLatestPulseToken: () =>
        `${BASE_URL}/surveys/GetLatestPulseToken`,
    PublishSurvey: () =>
        `${BASE_URL}/surveys/PublishSurvey`,
    CloneSurvey: () =>
        `${BASE_URL}/surveys/CloneSurveyByToken`,
    GetEditToken: () =>
        `${BASE_URL}/surveys/GetEditToken`,
    UpdateEndDate: () =>
        `${BASE_URL}/surveys/UpdateEndDate`,
};

export const commentsApi = {
    GetSurveyOpenQuestionAnswers: () =>
        `${BASE_URL}/SurveyComments/GetSurveyOpenQuestionAnswers`,
    GetCommentReplies: (id) =>
        `${BASE_URL}/SurveyComments/GetCommentReplies?id=${id}`,
    SaveCommentReply: () =>
        `${BASE_URL}/SurveyComments/SaveCommentReply`,
    GetCommentsForStaffReply: () =>
        `${BASE_URL}/SurveyComments/GetCommentsForStaffReply`,
    DownloadCommentsInExcel: () =>
        `${BASE_URL}/SurveyComments/DownloadCommentsInExcel`
};



export const surveyResultsApi = {
    GetQuestionAverages: () =>
        `${BASE_URL}/SurveyResults/GetQuestionAverages`,
    GetAnalyticsResults: () =>
        `${BASE_URL}/Analytics/GetAnalyticsResult`,
    DownloadHeatmap: () =>
        `${BASE_URL}/SurveyResults/DownloadHeatmap`,
};



export const questionBankApi = {
    Get: () =>
        `${BASE_URL}/QuestionBank`,
};


export const questionSetApi = {
    Get: () =>
        `${BASE_URL}/QuestionBank/GetQuestionSets`,
};

export const accountApi = {
    Login: () => `${BASE_URL}/Account/Login`,
};

export const questionApi = {
    GetEystnQuestions: () =>
        `${BASE_URL}/Questions/GetEystnQuestions`,
    GetOfstedQuestions: () =>
        `${BASE_URL}/Questions/GetOfstedQuestions`,
};

export const filterApi = {
    saveNewFilter: () =>
        `${BASE_URL}/SurveyFilters/SaveHeatmapSurveyFilter`,
    GetHeatmapSurveyFilterBySurveyToken: () =>
        `${BASE_URL}/SurveyFilters/GetHeatmapSurveyFilterBySurveyToken`,
    DeleteHeatmapSurveyFilter: (id) =>
        `${BASE_URL}/SurveyFilters/DeleteHeatmapSurveyFilter?id=${id}`,
};


export const emailTemplateApi = {
    GetGetListOfOrgEmailTemplates: (id) =>
        `${BASE_URL}/EmailTemplate/GetGetListOfOrgEmailTemplates?id=${id}`,
    AddEmailTemplate: () =>
        `${BASE_URL}/EmailTemplate/AddEmailTemplate`,
    UpdateEmailTemplate: (id) =>
        `${BASE_URL}/EmailTemplate/UpdateEmailTemplate?id=${id}`,
    DeleteEmailTemplate: (id) =>
        `${BASE_URL}/EmailTemplate/DeleteEmailTemplate?id=${id}`,
};