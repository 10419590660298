import request from '../request';
import { surveyApi } from '../Urls';

export default class surveyService {
    static async publishSurvey(token) {

        return request({
            url: surveyApi.PublishSurvey(),
            method: 'POST',
            data: token
        });

    }

    static async CreateSurvey(survey) {

        if (survey.token == null) {
            return request({
                url: surveyApi.CreateSurvey(),
                method: 'POST',
                data: { name: survey.name, schoolId: survey.schoolId, folderId: survey.folderId, folderName: survey.folder?.name, schoolName: survey.schoolName, isMatSurvey: survey.isMatSurvey, orgId: survey.orgId }
            });
        }
        else {
            return request({
                url: surveyApi.CreateSurvey(),
                method: 'PUT',
                data: survey
            });
        }
    }
    static async GetSurveysByOrgId(orgId, isMat, folderId) {

        if (folderId) {
            return request({
                url: surveyApi.GetSurveyByFolderId(orgId, isMat, folderId),
            });
        }
        else {
            return request({
                url: surveyApi.GetSurveysByOrgId(orgId, isMat),
            });
        }
    }

    static async GetClosedLiveSurveysBySchoolId(schooId) {

        return request({
            url: surveyApi.GetClosedLiveSurveysBySchoolId(schooId),
            method: 'GET',
        });
    }

    static async GetSchoolDashboard(orgId, isMat) {
        return request({
            url: surveyApi.GetSchoolDashboard(orgId, isMat),
        });
    }
    static async GetSurveyByToken(token) {
        return request({
            url: surveyApi.GetSurveyByToken(),
            method: 'POST',
            data: token
        });
    }
    static async GetSurveyByTokenForDashboard(token) // DELETE IT IF NOT NEEDED
    {
        return request({
            url: surveyApi.GetSurveyForSurveyDashboard(),
            method: 'POST',
            data: token
        });
    }
    static async SubmitSurveyResponse(surveySubmission) {
        return request({
            url: surveyApi.SubmitSurvey(),
            method: 'POST',
            data: JSON.stringify(surveySubmission)
        });
    }

    static async GetSurveyQuestionsForSurvey(token) {
        return request({
            url: surveyApi.GetSurveyQuestionsForSurvey(),
            method: 'POST',
            data: token
        });
    }
    static async CloseSurvey(token) {
        return request({
            url: surveyApi.CloseSurvey(),
            method: 'POST',
            data: token
        });
    }
    static async ReopenSurvey(token) {
        return request({
            url: surveyApi.ReopenSurvey(),
            method: 'POST',
            data: token
        });
    }
    static async DeleteSurvey(token) {
        return request({
            url: surveyApi.DeleteSurvey(),
            method: 'POST',
            data: token
        });
    }
    static async SendInvites(token) {
        return request({
            url: surveyApi.SendInvites(),
            method: 'POST',
            data: token
        });
    }
    static async ReRunSurvey(data) {
        return request({
            url: surveyApi.ReRunSurvey(),
            method: 'POST',
            data: data
        });
    }

    static async GetRerunSurveys(token) {
        return request({
            url: surveyApi.GetRerunSurveys(),
            method: 'POST',
            data: token
        });
    }
    static async UpdatePulseStatusByToken(token, status) {

        return request({
            url: surveyApi.UpdatePulseStatusByToken(status),
            method: 'POST',
            data: token,
        });
    }

    static async GetSurveyDashboardByToken(token) {
        return request({
            url: surveyApi.GetSurveyDashboardByToken(),
            method: 'POST',
            data: token
        });
    }
    static async GetAllPulseList(token) {
        return request({
            url: surveyApi.GetAllPulseList(),
            method: 'POST',
            data: token
        });
    }
    static async GetLatestPulseToken(token) {
        return request({
            url: surveyApi.GetLatestPulseToken(),
            method: 'POST',
            data: token
        });
    }

    static async CloneSurvey(token) {
        return request({
            url: surveyApi.CloneSurvey(),
            method: 'POST',
            data: token
        });
    }
    static async GetEditToken(token) {
        return request({
            url: surveyApi.GetEditToken(),
            method: 'POST',
            data: token
        });
    }


    static async UpdateEndDate(data) {
        return request({
            url: surveyApi.UpdateEndDate(),
            method: 'POST',
            data: data
        });
    }
}