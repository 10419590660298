import React, { useState } from 'react';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Modal } from '@mui/material';
import { HeaderContainer, QuestionsSelect, RequestAssistance, WhiteLayoutContent } from '../assets/styles/AppContainers';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PictureInPictureAlt, Settings } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { timezones } from '../utils/Constants';
import { updateSchoolTimezone } from '../redux/features/schoolTimezoneSlice';


function Header(props) {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { schoolTimezone } = useSelector((store) => store.schoolTimezone)
    const [selectedTimezone, setSelectedTimezone] = React.useState(timezones.find(x => x.id === schoolTimezone.id))

    const { organization } = useSelector(store => store)
    return (
        <HeaderContainer >
            <Grid container>
                <Grid item xs={7} lg={7}>
                    <h1 className='header-school-title'>{organization?.name}</h1>
                    {/* <span> {' '}</span> */}
                </Grid>
                <Grid item xs={5} lg={5}>
                    <Stack direction="row" spacing={1} justifyContent='end'>
                        {/* <RequestAssistance style={{ cursor: 'pointer' }} onClick={e => setOpen(!open)} > */}
                        {/* <MarkunreadIcon sx={{ fontSize: 20 }} /> */}
                        {/* <Settings sx={{ fontSize: 20 }} /> */}
                        {/* </RequestAssistance> */}
                        <FormControl fullWidth>
                            <InputLabel id="time-zone">Time Zone</InputLabel>
                            <QuestionsSelect style={{ height: 40 }}
                                placeholder={'Time Zone'}
                                label={'Time Zone'}
                                labelId="time-zone"
                                value={selectedTimezone}
                                onChange={(e) => {
                                    setSelectedTimezone(e.target.value)
                                    dispatch(updateSchoolTimezone(e.target.value))
                                }}
                            >
                                {timezones.map(tz =>
                                    <MenuItem value={tz}>{tz.name}</MenuItem>
                                )}
                            </QuestionsSelect>
                        </FormControl>
                        <Avatar alt="Zohaib Rafique" src="" sx={{ width: 40, height: 40 }} variant="rounded" />
                        <Stack direction="column" spacing={0} className='user-data'>
                            <div className='user-name'>{organization?.userInfo?.FirstName ? organization?.userInfo?.FirstName + ' ' + organization?.userInfo?.LastName : ' Welcome to Welbee Pulse'}</div>
                            {/* <div className='user-name'> Welcome to Welbee Pulse</div> */}
                            <div className='user-email'>{organization?.userInfo?.Email}</div>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </HeaderContainer >
    );
}

export { Header };