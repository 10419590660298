import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SchoolMenu } from '../../routes/MenuConfig';
import '../../assets/styles/app.scss';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { Alert } from '@mui/material';
import { Modal, Grid, Button, Snackbar } from '@mui/material';
import { PulseTextField, WhiteLayoutContent, Navigation } from '../../assets/styles/AppContainers';
import folderService from '../../services/api/folderService';
import { updateSchoolFolder, deleteSchoolFolder } from '../../redux/features/folderSlice';
import Popup from '../../components/Popup';

function SideMenu(props) {
    const dispatch = useDispatch();
    const { folders, loading } = useSelector((store) => store.folders)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState({ name: null, id: -1, schoolId: -1 });
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [msg] = useState('It will delete folder permanently. Are you sure to delete it.')
    const [openSnak, setOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateFolderName = () => {
        folderService.UpdateFolder(selectedFolder)
            .then(resp => {
                if (resp) {
                    setEditOpen(false);
                    dispatch(updateSchoolFolder(selectedFolder));
                    setSnackMsg('Folder is updated.');
                    setOpen(true);
                    setSnackSeverity('success');
                }
                else {
                    setSnackMsg('Error occured while updating folder.');
                    setOpen(true);
                    setSnackSeverity('error');
                }
            })
            .catch()
    }

    const deleteFolder = () => {
        setOpenDeleteConfirm(false);
        folderService.DeleteFolder(selectedFolder.id)
            .then(e => {
                if (e) {
                    setOpenDeleteConfirm(false);
                    dispatch(deleteSchoolFolder(selectedFolder));
                    setSnackMsg('Folder is deleted.');
                    setOpen(true);
                    setSnackSeverity('success');
                }
                else {
                    setSnackMsg('Error occured while deleting folder.');
                    setOpen(true);
                    setSnackSeverity('error');
                }
            })
            .catch();
    }

    return (
        <Navigation>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openSnak}
                onClose={() => { setOpen(false) }}
                autoHideDuration={2000}>
                <Alert onClose={() => { setOpen(false) }} severity={snackSeverity} sx={{ width: '100%' }}>
                    {snackMsg}
                </Alert>
            </Snackbar>


            <div className='logo'>
                <img alt='' src='https://welbee.international/wp-content/uploads/2021/04/welbee_logo_yellow.png'></img>
            </div>
            <div className='school-name'>
            </div>
            <div>
                <div className='menu'>
                    {SchoolMenu.map((item) => {
                        return (<div className='menu-item'>
                            <Link to={item.path}>{item.icon}
                                {item.title}
                            </Link>
                        </div>);
                    })}
                    <div className='menu-item'>
                        <Link
                            id="survey-dropdown"
                            aria-controls={open ? 'survey-dropdown' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            className='menu-dropdown'
                        >
                            <span>
                                <FolderOutlinedIcon sx={{ fontSize: '20px' }} /> Folders
                            </span>
                            <KeyboardArrowDownIcon
                                sx={{ fontSize: '20px' }}
                            />
                        </Link>
                        <Menu
                            id="survey-dropdown"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            className='dropdown-items'
                            MenuListProps={{
                                'aria-labelledby': 'survey-dropdown',
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={handleClose}>
                                <Link to={'/surveys-list'}>
                                    <span>All Surveys</span>
                                </Link>
                            </MenuItem>
                            {folders?.map(item => {
                                return <MenuItem onClick={handleClose}>
                                    <Link to={'/surveys/' + item.id}>
                                        <span>{item.name}</span>
                                    </Link>
                                    <Stack
                                        spacing={0.5}
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        width={50}
                                    >
                                        <div>
                                            <a href='#' onClick={e => { setSelectedFolder(item); setEditOpen(true) }} ><EditIcon /></a>
                                        </div>
                                        <div>
                                            <a href='#' onClick={e => { setSelectedFolder(item); setOpenDeleteConfirm(true); }} ><DeleteIcon /></a>
                                        </div>
                                    </Stack>

                                </MenuItem>
                            })}
                        </Menu>
                    </div>
                </div>

                <div className='bottom-menu'>

                    {/* <div className='menu-dashboard'>
                        <div className='menu-item'>
                            <Link to={'create-folder'}>
                                Create New Folder
                            </Link>
                        </div>
                    </div> */}

                    <div className='menu-logout'>
                        <div className='menu-item'>
                            <Link to={'/login'}>
                                Logout
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

            <Modal open={editOpen} onClose={() => setEditOpen(false)} className='warning-modal'>
                <WhiteLayoutContent>
                    <Grid container padding={3}>
                        <Grid item lg={12} md={12} xs={12}>
                            <h1>Edit Folder</h1>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} marginTop={2}>
                            <PulseTextField label={'Folder Name'} fullWidth value={selectedFolder.name} style={{ width: '100%', margin: '15px 20px 0 0' }} onChange={e => {
                                setSelectedFolder({ name: e.target.value, id: selectedFolder.id, schoolId: -1 });
                            }}></PulseTextField>
                        </Grid>
                    </Grid>
                    <Grid container paddingLeft={3} paddingRight={3} paddingBottom={3}>
                        <Grid item lg={6} md={6} xs={6}
                            display="flex"
                            justifyContent="flex-start">
                            <Button variant="contained" className='prev-button' onClick={() => setEditOpen(false)} >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item lg={6} md={6} xs={6}
                            display="flex"
                            justifyContent="flex-end">
                            <Button variant="contained" className='next-button' onClick={updateFolderName}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </WhiteLayoutContent >
            </Modal >
            {openDeleteConfirm ? <Popup Msg={msg} Title={'Delete Folder'} onCancel={e => setOpenDeleteConfirm(false)} onAction={deleteFolder}></Popup> : ''}
        </Navigation>
    );
}

export { SideMenu };