import { Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PulseTextField, PulseHeatmap, TableStyle } from '../../assets/styles/AppContainers';
import { ArrowDownward, ArrowUpward, ImportExport } from '@mui/icons-material';

function ParticipantList({ staff }) {

    const [staffs, setStaffs] = useState([])
    const [sortFName, setSortFName] = useState(true);
    const [sortLName, setSortLName] = useState(true);
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        setStaffs([...staff])
    }, [staff])

    const sortList = (column) => {

        let list = [...staff];
        if (column === 'f') {
            list.sort((x, y) => (sortFName ? x.firstName.localeCompare(y.firstName) : y.firstName.localeCompare(x.firstName)))
            setSortFName(!sortFName)
            setSortLName(null)
        }
        else {
            list.sort((x, y) => (sortLName ? x.lastName.localeCompare(y.lastName) : y.lastName.localeCompare(x.lastName)))
            setSortLName(!sortLName)
            setSortFName(null)
        }
        setStaffs([...list])
    }

    return (
        <Grid container>
            <Grid item lg={3} md={12} xs={12} mb={0.5}>
                <PulseTextField
                    placeholder='Search Participants by Name'
                    value={searchText}
                    fullWidth
                    onChange={e => setSearchText(e.target.value)}
                ></PulseTextField>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                {/* {participantFitlers} */}
                <PulseHeatmap>
                    <div className='heatmap-sticky'>
                        <TableStyle>
                            <Table sx={{marginTop: '0 !important'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Stack direction="row" alignItems='center'>
                                                First Name
                                                <span style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => sortList('f')}>{sortLName === null ? (sortFName ? <ArrowUpward></ArrowUpward> : <ArrowDownward></ArrowDownward>) : <ImportExport></ImportExport>}</span>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" alignItems='center'>
                                                Last Name
                                                <span style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => sortList('l')}>{sortFName === null ? (sortLName ? <ArrowUpward></ArrowUpward> : <ArrowDownward></ArrowDownward>) : <ImportExport></ImportExport>}</span>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Employment Type</TableCell>
                                        <TableCell>Job Title</TableCell>
                                        <TableCell>Department</TableCell>
                                        <TableCell>Year or Key Stage</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {staffs
                                        .filter(x => {
                                            if (searchText === '') return true
                                            let name = x.firstName + ' ' + x.lastName
                                            return name.toLowerCase().includes(searchText.toLowerCase())
                                        })?.map((row, id) => (
                                            <TableRow key={row.schoolStaffId}>
                                                <TableCell>{row.firstName}</TableCell>
                                                <TableCell>{row.lastName}</TableCell>
                                                <TableCell>{row.email}</TableCell>
                                                <TableCell>{row.employmentType === 1 ? "Full Time" : "Part Time"}</TableCell>
                                                <TableCell>{row.jobTitle}</TableCell>
                                                <TableCell>{row.department}</TableCell>
                                                <TableCell>{row.phaseYear}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableStyle>
                    </div>
                </PulseHeatmap>

            </Grid>
        </Grid>
    );
}
export default ParticipantList;