import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SurveySelect } from '../../assets/styles/AppContainers';
import { SurveyMenu } from './SurveyMenu';
import useSelectedSurvey from '../../hooks/useSelectedSurvey';
import { FormControl, Grid, MenuItem, Skeleton } from '@mui/material';
import { surveyService } from '../../services';
import { GetDateFromString } from '../../utils/Utils';

//const SurveyDashboardLayout = () =>
function SurveyDashboardLayout(props) {

    const navigate = useNavigate()

    const { selectedSurvey, loading } = useSelectedSurvey();
    const [pulses, setPulses] = useState([])
    const [selectedPulse, setSelectedPulse] = useState(null)
    const location = useLocation()


    useEffect(() => {
        if (selectedSurvey && selectedSurvey.token) {
            surveyService
                .GetAllPulseList(selectedSurvey?.token)
                .then(e => {
                    setPulses(e)
                    setSelectedPulse(e.filter(x => x.token === selectedSurvey.token)[0])
                })
        }
    }, [selectedSurvey])

    return (
        <>
            <Grid container alignItems="center" rowSpacing={{ xs: 1 }}>
                <Grid item lg={9} md={9} sm={6} xs={12}>
                    {loading ?
                        <div>
                            <Skeleton width='95%' height={20} />
                            <Skeleton width='95%' height={20} />
                        </div> :
                        <h1 >{selectedSurvey?.name}</h1>
                    }
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12} display='flex' justifyContent='end'>
                    <FormControl>
                        <SurveySelect onChange={e => {
                            //let url = new URL("..", window.origin + location.pathname)
                            // url.pathname = url.pathname.replace(url.pathname.substring(url.pathname.lastIndexOf('token/'), url.pathname.length - 1), `token/${e.target.value.token}`)
                            //navigate(url.pathname)
                            navigate(location.pathname.substring(0, location.pathname.lastIndexOf('token/')) + 'token/' + e.target.value.token);
                            window.location.reload(true)

                        }}
                            displayEmpty                            
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={selectedPulse}
                        >
                            {pulses.map((pulse, idx) => <MenuItem value={pulse}>{pulse.label ? pulse.label : `Pulse ${pulses.length - idx}`}{pulse.pulseStatus === 3 ? ` (Closed on: ${GetDateFromString(pulse.endDate)})` : ''} </MenuItem>)}
                        </SurveySelect>
                    </FormControl>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>{loading ?
                    <div style={{ margin: '10px 0' }}>
                        <Skeleton width='100%' height={20} />
                        <Skeleton width='100%' height={20} />
                    </div>
                    :
                    <SurveyMenu selectedSurvey={selectedSurvey} />}
                </Grid>

            </Grid >
            <Outlet />
        </>
    );

}

export default SurveyDashboardLayout;