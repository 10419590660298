export const QuestionSets = [
    {
        id: 3,
        name: 'Question Set 1',
    },
    {
        id: 4,
        name: 'Question Set 2',
    },
    {
        id: 5,
        name: 'Question Set 3',
    },
]

export const JobRole = [
    {
        id: 0,
        Name: 'All',
    },
    {
        id: 1,
        Name: 'Teaching',
    },
    {
        id: 2,
        Name: 'Support',
    },
    {
        id: 4,
        Name: 'Teaching Staff (Classroom)',
    },
    {
        id: 8,
        Name: 'Teaching Staff (Non-Classroom)',
    },
    {
        id: 16,
        Name: 'Support Staff (Classroom)',
    },
    {
        id: 32,
        Name: 'Support Staff (Non-Classroom)',
    },
    {
        id: 64,
        Name: 'Middle Leader',
    },
    {
        id: 128,
        Name: 'Senior Leader',
    },
]

export const SurveyStatus = {
    Draft: 1,
    Open: 2,
    Live: 3,
    Close: 4,
    Paused: 5,
    Stopped: 6,
}
export const PulseStatus = {
    Published: 1,
    Live: 2,
    Close: 3,
    Paused: 4,
    Skipped: 5,
}

export const RepeatDuration = {

    OneOff: 1,
    Weekly: 2,
    Fortnightly: 3,
    Monthly: 4,
    Custom: 5
}


export const QuestionsBankCategory = [
    { id: 0, name: 'Faith' },
    { id: 1, name: 'Equity, Diversity and Inclusion (Belonging)' },
    { id: 2, name: 'Organisational Mental Health Support' },
    //{ id: 3, name: 'Subjective Health Outcomes' },
    { id: 4, name: 'Health Outcomes and Behaviours' },
    { id: 5, name: 'Purpose & Engagement' },
    { id: 6, name: 'My Job and Autonomy' },
    { id: 7, name: 'Work Time Quality' },
    { id: 8, name: 'Environment' },
    { id: 9, name: 'Financial Wellbeing' },
    { id: 10, name: 'Open Questions' },
]

export const SurveyFilterType =
{
    Heatmap: 1,
    Analytics: 2,
}
export const timezones = [
    { id: 0, name: 'Pacific Time (US & Canada)' },
    { id: 1, name: 'Mountain Time (US & Canada)' },
    { id: 2, name: 'Central Time (US & Canada)' },
    { id: 3, name: 'Eastern Time (US & Canada)' },
    { id: 4, name: 'UK, Ireland, Lisbon Time' },
    { id: 5, name: 'Pakistan Standard Time' },
]

export const minParticipantsRequired = 10;

export const AlertType = {
    Success: 'success',
    Info: 'info',
    Warning: 'warning',
    Error: 'error',

}

export const EmailInviteDefaultTemplate = {
    Subject: 'Pulse survey: your feedback is important',
    Salutation: `  <p>Dear {StaffFirstName},</p>`,
    Link: `<p><br><a href='{surveyLink}' style='color: #53B5E0; font-weight: 900;'>Please click here to begin.</a></p>`,
    Content: `<p>Please complete the short survey. Your responses are anonymous and will not be attributed to you.</p><p>Your feedback is important to us and please make sure you have your say and don't rely on others, who may have different views.</p>`,
    Footer: ` <p><br><strong>Welbee (on behalf of {schoolName})</strong><br/><a href='mailto:support@welbee.co.uk' style='color: #EB5B41;'>support@welbee.co.uk</a></p>`
}