import React, { useEffect, useState } from 'react';
import { Grid, Button, Typography, Snackbar, Alert, Stack, TextField } from '@mui/material';
import { PulseTextField } from '../../assets/styles/AppContainers';
import { InfoOutlined } from '@mui/icons-material';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes } from '@lexical/html';
import CloseIcon from '@mui/icons-material/Close';
import { EmailInviteDefaultTemplate } from '../../utils/Constants';
import { emailTemplateService } from '../../services/api/emailTemplateService';
import { useSelector } from 'react-redux';
import { BLUR_COMMAND, COMMAND_PRIORITY_NORMAL } from 'lexical';

const EmailTemplate = ({ templateToEdit, onClose, Survey, onSurveyChange, OnHasUpdate }) => {

    const { organization } = useSelector(store => store)
    const [editorState, setEditorState] = useState();
    const [content, setContent] = useState(templateToEdit?.content ?? EmailInviteDefaultTemplate.Content);
    const [subject, setSubject] = useState(templateToEdit?.subject ?? EmailInviteDefaultTemplate.Subject);
    const [footer, setFooter] = useState(templateToEdit?.footer ?? EmailInviteDefaultTemplate.Footer.replace('{schoolName}', organization.name));
    const [open, setOpen] = React.useState(false);
    const [editContent, setEditContent] = useState(false);
    const [editFooter, setEditFooter] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);

    const initialConfig = {
        namespace: 'Pulse Email Template',
        theme: null,
        editable: true,
        onError: w => console.error('dsds'),
    };

    const onContentChange = (editorState, editor) => {

        setEditorState(editorState);

        editorState.read(() => {
            const html = $generateHtmlFromNodes(editor);
            if ("<p><br></p>" !== html)
                setContent(html);
        });

    }

    const onFooterChange = (editorState, editor) => {

        setEditorState(editorState);

        editorState.read(() => {
            const html = $generateHtmlFromNodes(editor);
            if ("<p><br></p>" !== html)
                setFooter(html);
        });

    }
    useEffect(() => {

    }, [])

    const onUse = () => {
        if (!subject || !content || !footer) {
            setOpen(true);
            return
        }
        let survey = Survey;
        survey.inviteEmailSubject = subject;
        survey.inviteEmailBody = EmailInviteDefaultTemplate.Salutation + content + EmailInviteDefaultTemplate.Link + footer;
        onSurveyChange(survey);
        OnHasUpdate(true);
        onClose();
    }

    const onSaveAndUse = () => {
        if (!subject || !content || !footer) {
            setOpen(true);
            return
        }
        setDisableButtons(true);

        if (templateToEdit === null || templateToEdit.id === 0) {
            emailTemplateService.AddEmailTemplate({ id: 0, orgId: organization.orgId, subject: subject, content: content, footer: footer })
                .then(res => {
                    onUse();
                })
        }
        else {
            emailTemplateService.UpdateEmailTemplate({ id: templateToEdit.id, orgId: organization.orgId, subject: subject, content: content, footer: footer })
                .then(res => {
                    onUse();
                })
        }
    }


    return (
        <Grid container>
            <Snackbar
                key={'snack-success'}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
            >
                <Alert severity="error" variant='filled'>
                    Please fill all fields
                </Alert></Snackbar>
            <Grid item lg={10} md={10} xs={10}>
                <h2>Email Template</h2>
            </Grid>
            <Grid item lg={2} md={2} xs={2}>
                <CloseIcon onClick={onClose}
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                        fontSize: '22px'
                    }} />
            </Grid>
            <Grid item lg={12} md={12} xs={12} onClick={() => { setEditContent(false); setEditFooter(false) }}>
                <PulseTextField label='Email Subject' fullWidth value={subject} onChange={(e) => setSubject(e.target.value)} />
            </Grid>
            <Grid item lg={12} md={12} xs={12} style={{ borderRadius: 5, borderWidth: 1, borderStyle: 'solid', borderColor: '#aaa' }} p={1} mb={1}>
                <Grid item lg={12} md={12} xs={12} onClick={() => { setEditContent(false); setEditFooter(false) }}>
                    <p style={{ color: '#444', fontWeight: 100 }} dangerouslySetInnerHTML={{ __html: EmailInviteDefaultTemplate.Salutation?.replace('{StaffFirstName}', '[FirstName]') }}></p>
                </Grid>

                <Grid item lg={12} md={12} xs={12} //onBlur={() => { setEditContent(false) }}
                >
                    {editContent && <LexicalComposer initialConfig={{ ...initialConfig }}>
                        <div className="editor-container" >
                            <ToolbarPlugin />
                            <div className="editor-inner">
                                <RichTextPlugin
                                    contentEditable={<ContentEditable className="editor-input" />}
                                    placeholder={<div className="editor-placeholder">Enter email content here</div>}
                                    ErrorBoundary={LexicalErrorBoundary}
                                />
                                <HistoryPlugin />
                                <AutoFocusPlugin />

                                <MyOnChangePlugin onChange={onContentChange} content={content} />
                            </div>
                        </div>
                    </LexicalComposer>
                    }
                    {!editContent && <p dangerouslySetInnerHTML={{ __html: content }} onClick={e => setEditContent(true)}></p>}
                </Grid>
                <Grid item lg={12} md={12} xs={12} onClick={() => { setEditContent(false); setEditFooter(false) }}>
                    <p style={{ color: 'gray' }} dangerouslySetInnerHTML={{ __html: '[' + EmailInviteDefaultTemplate.Link.replace('#53B5E0', '#888').replace('<br>', '').replace('<p>', '').replace('</p>', '').replace('href=\'{surveyLink}\'', '') + ']' }}></p>
                </Grid>

                <Grid item lg={12} md={12} xs={12} pb={4}>

                    {editFooter &&
                        <LexicalComposer initialConfig={{ ...initialConfig }}>
                            <div className="editor-container">
                                <ToolbarPlugin />
                                <div className="editor-inner">
                                    <RichTextPlugin
                                        contentEditable={<ContentEditable className="editor-input" />}
                                        placeholder={<div className="editor-placeholder">Enter email footer here</div>}
                                        ErrorBoundary={LexicalErrorBoundary}
                                    />
                                    <HistoryPlugin />
                                    <AutoFocusPlugin />

                                    <MyOnChangePlugin onChange={onFooterChange} content={footer} />
                                </div>
                            </div>
                        </LexicalComposer>}

                    {!editFooter && <p dangerouslySetInnerHTML={{ __html: footer === '<p><br></p>' ? '<p style=\'color: #333; font-weight: 100\' }}> Enter Footer here ...</p>' : footer }} onClick={e => setEditFooter(true)}></p>}
                    {/* <MUIRichTextEditor defaultValue={content} inlineToolbar={true} > </MUIRichTextEditor> */}

                </Grid>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
                <Button className='prev-button' onClick={onClose}>Cancel</Button>
            </Grid>
            <Grid item lg={6} md={6} xs={12} >
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <Button className={disableButtons ? 'grey-button' : 'next-button'} onClick={onSaveAndUse} disabled={disableButtons}>Save and Use</Button>
                    <Button className={disableButtons ? 'grey-button' : 'next-button'} onClick={onUse} disabled={disableButtons}>Use</Button>
                </Stack>
            </Grid>
        </Grid >
    );
};

const MyOnChangePlugin = ({ onChange,// onBlur,
    content }) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        editor.registerCommand(
            BLUR_COMMAND,
            () => {
                //    onBlur()
                return false
            },
            COMMAND_PRIORITY_NORMAL
        )
        return editor.registerUpdateListener(({ editorState }) => {
            onChange(editorState, editor);
        });

    }, [editor, onChange]);
    return null;
}




export default EmailTemplate;