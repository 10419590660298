import React from 'react';
import { Grid, Checkbox, Button, FormControlLabel } from '@mui/material';
import LinkButton from './LinkButton';

function DownloadHeatmap({ parentFilters, filters, selectedFilters, setSelectedFilters }) {


    const handleParentFilterChange = (val) => {

        let sfilters = selectedFilters;

        var parent = sfilters.findIndex(x => x.id === val && x.pId === null);
        if (parent > -1) {
            sfilters.splice(parent, 1);
            sfilters = [...sfilters.filter(x => x.pId !== val)];
        }
        else {
            sfilters = [...sfilters, { id: val, pId: null }, ...filters
                .filter(x => x.parentFilterId === val).map((option) => { return { id: option.id, pId: option.parentFilterId } })]
        }
        setSelectedFilters([...sfilters]);
        sfilters.length === filters.length + parentFilters.length ? setAll(true) : setAll(false);
    }

    const handleFilterChange = (id, pId) => {

        let prevFilters = selectedFilters;

        var idx = prevFilters.findIndex(e => e.id === id && e.pId === pId);
        if (idx > -1) {
            prevFilters.splice(idx, 1);
        }
        else {
            prevFilters.push({ id: id, pId: pId });
        }

        if (prevFilters.filter(x => x.pId === pId).length === filters.filter(x => x.parentFilterId === pId).length) {
            prevFilters.push({ id: pId, pId: null });
        }
        else {
            let oldParent = prevFilters.findIndex(x => x.id === pId && x.pId === null);
            if (oldParent > -1)
                prevFilters.splice(oldParent, 1);
        }
        setSelectedFilters([...prevFilters]);
        prevFilters.length === filters.length + parentFilters.length ? setAll(true) : setAll(false);
    }

    const isChecked = (id, parentId) => selectedFilters.findIndex(e => e.id === id && e.pId === parentId) > -1;

    const allChecked = (val) => {
        let newAll = [];

        if (val) {
            newAll = [...filters.map((option) => { return { id: option.id, pId: option.parentFilterId } })];
            newAll = [...newAll, ...parentFilters.map((option) => { return { id: option.id, pId: null } })];
        }
        setSelectedFilters([...newAll])
        setAll(val)
    }

    const [all, setAll] = React.useState(false);


    return (        
        <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControlLabel
                    label=<strong>{'Select All'}</strong>
                    control={
                        <Checkbox
                            checked={all}
                            onChange={e => allChecked(e.target.checked)}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                        />
                    }
                />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
                {selectedFilters.length > 0 ? <Button variant="outlined" className='prev-button clear-btn' onClick={() => { setSelectedFilters([]); setAll(false) }}>Clear Selected Filters</Button> : ''}
            </Grid>
            {parentFilters.map((item) => {
                if (item.value === -1) return null;
                return <Grid item lg={12} md={12} sm={12} xs={12}>

                    <FormControlLabel
                        label=<strong>{item.title}</strong>
                        control={
                            <Checkbox
                                value={isChecked(item.id, null)}
                                checked={isChecked(item.id, null)}
                                onChange={e => handleParentFilterChange(item.id)}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                            />
                        }
                    />
                    <Grid container style={{ marginBottom: 5 }}>
                        {filters.filter(x => x.parentFilterId === item.id).map((option, idx) => {
                            return <Grid item lg={6} md={6} sm={6} xs={12}>
                                <FormControlLabel
                                    label={option.title}
                                    control={
                                        <Checkbox
                                            value={isChecked(option.id, option.parentFilterId)}
                                            checked={isChecked(option.id, option.parentFilterId)}
                                            onChange={e => handleFilterChange(option.id, option.parentFilterId)}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                                        />
                                    }
                                />
                            </Grid>
                        })}
                    </Grid>
                </Grid >
            })
            }
        </Grid >
    );
}

export default DownloadHeatmap;