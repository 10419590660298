import React, { useEffect, useState } from 'react';
import { AppContainer, SurveyLayoutContent, ScoreLayoutContent, WhiteLayoutContent} from '../../../assets/styles/AppContainers';
import useSelectedSurvey from '../../../hooks/useSelectedSurvey';
import { Button, Grid, IconButton, Modal, Skeleton, Stack, Tooltip } from '@mui/material';
import Popup from '../../../components/Popup';
import { PulseStatus, SurveyStatus } from '../../../utils/Constants';
import { GetDateFromString } from '../../../utils/Utils';
import { ReRunSurveyButton } from '../../../components/ReRunSurveyButton';
import { ScrollContainer } from 'react-nice-scroll';
import PulseTooltip from '../../../components/PulseTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//import { useDispatch } from 'react-redux';
import { surveyService } from '../../../services';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import EditCloseDate from '../../../components/EditCloseDate';
import QuestionBar from '../../../components/QuestionBar/QuestionBar';
import QuestionsList from '../../../components/QuestionsList/QuestionsList';
import OptionLegends from '../../../components/QuestionBar/OptionLegends';
import SendInvitePopup from '../../../components/SendInvitePopup';
import ParticipationCount from '../../../components/Participants/ParticipationCount';
import CloseIcon from '@mui/icons-material/Close';

const OptType = {
    None: 0,
    Reopen: 1,
    Close: 2,
}
const Dashboard = () => {

    const { selectedSurvey, loading } = useSelectedSurvey();    
    const [openModal, setOpenModal] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [opt, setOpt] = React.useState(OptType.None);
    const [currPerc, setCurrPerc] = useState(0);
    const [sendInvitesPopup, setInvitePopup] = React.useState(false);
    const [openParticipation, setOpenParticipation] = React.useState(false);

    const GetStatus = () => {
        let pStatus = selectedSurvey?.pulseStatus
        let color = '#FFB205'
        let status = 'Draft'
        if (pStatus === PulseStatus.Close) {
            color = '#45338C';
            status = 'Closed'
        }
        if (pStatus === PulseStatus.Live) {
            color = '#007A7A';
            status = 'Live'
        }
        let div = <div className='status' style={{ backgroundColor: loading ? '#DEE8EC' : color }}>{loading ? '      ' : status}</div>;
        return div;
    }

    const CloseSurvey = () => {
        setOpt(OptType.Close)
        setMsg('This will close the survey. Are you sure to close the survey?')
        setOpenModal(true);
    }
    const ReopenSurvey = () => {
        setOpt(OptType.Reopen)
        setMsg('This will reopen the survey. Are you sure to reopen the survey?')
        setOpenModal(true);
    }
    const onConfirm = () => {
        setOpenModal(false);
        if (opt === OptType.Close) {

            surveyService.CloseSurvey(selectedSurvey.token)
                .then(async () => {
                    window.location.reload(false)
                    //  await dispatch(getCurrentSurveyForDashboard())
                    //navigate(`/surveys/dashboard/token/${cselectedSurvey.token}`)

                    //   setStatus(SurveyStatus.Close)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        if (opt === OptType.Reopen) {
            surveyService.ReopenSurvey(selectedSurvey.token)
                .then(async () => {
                    window.location.reload(false)
                    //    await dispatch(getCurrentSurveyForDashboard())
                    //navigate(`/surveys/dashboard/token/${currSurvey.survey.token}`)
                    //setStatus(SurveyStatus.Live)

                    // setStatus(SurveyStatus.Live)
                })
                .catch(err => {
                    console.log(err)
                });
        }
        setOpt(OptType.None)
    }
    useEffect(() => {
        animateWheel(currPerc)
    }, [selectedSurvey])

    const animateWheel = (val) => {
        let total = (selectedSurvey?.lastSubmittionCount / selectedSurvey?.surveyParticipants?.length) * 100;
        if (selectedSurvey) {
            if (val < total) {
                setCurrPerc(val + 1)
                setTimeout((val) => {
                    animateWheel(val + 1)
                }, 100)
                return
            }
            setCurrPerc(total)

        }
    }

    const onCancel = () => {
        setOpenModal(false);
        setOpt(OptType.None)
    }

    return (
        <AppContainer>
            <Grid container spacing={2} rowGap={2}>
                <Grid item lg={selectedSurvey?.comments?.length > 0 ? 8 : 12} md={selectedSurvey?.comments?.length > 0 ? 8 : 12} sm={12} xs={12}>
                    {openModal ? <Popup Msg={msg} onCancel={onCancel} onAction={onConfirm} Title={'Close'}></Popup> : ''}
                    <SurveyLayoutContent style={{ height: '91%' }}>
                        <div className='survey-content'>
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'center', sm: 'flex-start' }} rowGap={2} justifyContent='space-between'>
                                        <div className='survey-status' >
                                            <Stack direction='row'>
                                                <h2>Survey Status</h2>
                                                {GetStatus()}
                                            </Stack>
                                            <div>
                                                <EditCloseDate selectedSurvey={selectedSurvey}></EditCloseDate>
                                            </div>                                            
                                        </div>
                                        <div className='reopen-btn'>
                                            {loading ?
                                                <Skeleton width={60} height={40}></Skeleton> :

                                                (selectedSurvey?.pulseStatus === PulseStatus.Live ?
                                                    <div>
                                                        <Tooltip title='Click to re-send the survey invitation to individuals or choose All to send to everyone who has not taken the survey.' placement='bottom'>
                                                            <Button variant="contained" className='next-button' onClick={() => setInvitePopup(true)}>
                                                                <span>Resend Invitations</span>
                                                            </Button>
                                                        </Tooltip>
                                                        {sendInvitesPopup &&
                                                            <SendInvitePopup token={selectedSurvey.token} onClose={e => setInvitePopup(false)}
                                                                onAction={e => {
                                                                    setInvitePopup(false);
                                                                    //setMsg('Invitations sent');
                                                                }}></SendInvitePopup>}

                                                    </div> : '')
                                            }
                                            {selectedSurvey?.pulseStatus === PulseStatus.Close ?
                                                <ReRunSurveyButton token={selectedSurvey?.token}></ReRunSurveyButton> : ''}
                                        </div>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <div className='circular-progress'>

                                <CircularProgressbarWithChildren gressbar value={currPerc} styles={buildStyles({
                                    // Text size
                                    textSize: '18px',
                                    pathColor: '#53B5E0',
                                    textColor: '#000000',
                                    trailColor: '#E2E2E2',
                                    backgroundColor: '#E2E2E2',
                                })} >
                                    <>
                                        <div style={{ fontSize: 30, textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                                            <strong>{currPerc.toFixed(2)}%</strong><span style={{ fontSize: '18px' }}>Participation</span>                                            
                                        </div>                                        
                                    </>
                                </CircularProgressbarWithChildren>

                                {/* <Button className='btn-participation' variant='text' onClick={e => setOpenParticipation(true)}>See Participation List</Button> */}

                                <div className='btn-participation'>
                                    <Button variant="contained" className='next-button' onClick={e => setOpenParticipation(true)}>
                                        <span>Participation Breakdown</span>
                                    </Button>
                                </div>
                            </div>


                            <div className='reopen-btn'>
                                {loading ?
                                    <Skeleton width={60} height={40}></Skeleton> :
                                    (selectedSurvey?.pulseStatus === PulseStatus.Close ?
                                        (selectedSurvey?.lastSubmittionCount >= selectedSurvey?.surveyParticipants?.length ? <div><Button variant="contained" className='next-button' onClick={ReopenSurvey}>
                                            Reopen
                                        </Button></div> : '') :
                                        (selectedSurvey?.pulseStatus === PulseStatus.Live ? <div><Button variant="contained" className='next-button' onClick={CloseSurvey}>
                                            Close
                                        </Button></div> : '')
                                    )}
                            </div>                           

                            <div className='survey-data'>
                                <div>
                                    {selectedSurvey?.lastSubmittionCount}
                                    <span>Responses</span>
                                </div>

                                <div>
                                    {selectedSurvey?.surveyQuestions?.length}
                                    <span>Questions</span>
                                </div>

                                <div>
                                    {selectedSurvey?.surveyParticipants?.length}
                                    <span>Invites</span>
                                </div>
                            </div>
                        </div>
                    </SurveyLayoutContent>
                    {/* {highestQ?.length > 0 &&
                            !hqLoading ?
                            <SurveyLayoutContent>
                                <h2>Highest Scoring Questions</h2>
                                {highestQ.map((hq, i) =>
                                {                                    
                                    // <>  <div className='question'>
                                    //     Question {i + 1}
                                    //     <span>This is a sample question for demo</span>
                                    // </div>
                                    <TableStyle>
                                        <table>
                                            <tr>
                                                <td>Always</td>
                                                <td>{hq.alwaysPercentage}%</td>
                                            </tr>
                                            <tr>
                                                <td>Sometimes</td>
                                                <td>60%</td>
                                            </tr>
                                        </table>
                                    </TableStyle>
                                })}
                            </SurveyLayoutContent> : ''} */}
                    {/* </Stack> */}
                </Grid>
                {selectedSurvey?.comments?.length > 0 &&
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <SurveyLayoutContent style={{ height: '91%' }}>
                            <h2>Latest Comments</h2>
                            <ScrollContainer alwaysShowTracks>
                                <div className='comments-scroll'>
                                    {loading ? <Skeleton></Skeleton> : (selectedSurvey?.comments?.length === 0 ?
                                        <h5>No comments found</h5> :
                                        selectedSurvey?.comments?.map(comment =>
                                            <Grid item lg={12} md={12} xs={12} >
                                                <div className='comment-content'>
                                                    <div className='comment-date'>                                                    {GetDateFromString}
                                                        {GetDateFromString(comment.submitDate)}
                                                    </div>
                                                    <div className='comments-text'>
                                                        {comment.comments}
                                                    </div>
                                                </div>
                                            </Grid>
                                        )
                                    )}
                                </div>
                            </ScrollContainer>
                        </SurveyLayoutContent>
                    </Grid>
                }
            </Grid>

            <Grid container spacing={1} rowGap={2} mt={1}>
                {selectedSurvey?.surveyQuestionAverages?.length > 0 &&

                    <Grid item lg={12} md={12} sm={12} xs={12}>

                        <ScoreLayoutContent>
                            <h2>
                                <Stack direction="row" spacing={0.5} alignItems='center'>
                                    <div>Questions</div>
                                    <PulseTooltip title="When using the colour key for question answers, dark green indicates a higher score and red a lower score. These colours do not necessarily indicate good or poor scores overall.">
                                        <InfoOutlinedIcon style={{ cursor: 'pointer' }} />
                                    </PulseTooltip>
                                </Stack>
                            </h2>
                            {selectedSurvey.surveyQuestions.filter(x => x.questionLikertFormat === 2)?.map((qa, i) => {
                                let avg = selectedSurvey.surveyQuestionAverages[i];
                                return (
                                    <>
                                        <OptionLegends question={qa}></OptionLegends>
                                        <QuestionBar key={i} average={avg} question={qa} index={i} />
                                    </>
                                );
                            })}
                        </ScoreLayoutContent>
                    </Grid>
                }
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <QuestionsList survey={selectedSurvey}></QuestionsList>
                </Grid>
            </Grid >

            <Modal className='warning-modal' open={openParticipation} onClose={e => setOpenParticipation(false)}>
                <WhiteLayoutContent sx={{ padding: 2, width: '60%' }}>
                    <IconButton
                        aria-label="close"
                        onClick={e => setOpenParticipation(false)}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            fontSize: '22px'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>                    
                    <ParticipationCount teachingStaffCount={selectedSurvey?.teachingStaffSubmissionCount} supportStaffCount={selectedSurvey?.supportStaffSubmissionCount} leadStaffCount={selectedSurvey?.leadersStaffSubmissionCount} />
                </WhiteLayoutContent>
            </Modal>
        </AppContainer >
    );
}

export default Dashboard;