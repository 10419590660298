import React, { useState, useEffect, useRef } from "react";
import { surveyResultsService } from "../../../services/api/surveyResultsService";
import {
  Grid,
  Stack,
  MenuItem,
  Alert,
  FormControl,
  Skeleton,
} from "@mui/material";
import Select, { components } from "react-select";
import {
  
  PulseTextField,
  QuestionsSelect,
  WhiteLayoutContent,
} from "../../../assets/styles/AppContainers";

import PulseStackedBarChart from "../../../components/Analytics/PulseStackedBarChart";
import PulseBarChart from "../../../components/Analytics/PulseBarChart";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { formatUKDate } from "../../../utils/Utils";

const Analytics = () => {
  const [data, setData] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [chartOptions, setChartOptions] = useState("All Staff");
  const [loading, setLoading] = useState(true);
  const [selectedFilterLabel, setSelectedFilterLabel] = useState("All Staff");
  const [status, setStatus] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [Options, setOptions] = useState([]);
  const [filteredDataSet, setFilteredDataSet] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [barChartSwtich, setBarChartSwtich] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [allQuestionOptions, setAllQuestionOptions] = useState([]);
  const [insufficientRecord, setInsufficientRecord] = useState(false);
  const [chartType, setChartType] = useState(0);
  const [dateErrors, setDateErrors] = useState({ fromDate: "", toDate: "" });
  const [chartTitle, setChartTitle] = useState("");
  const [surveyCounts, setSurveyCount] = useState(0);
  const [surveys, setSurveys] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [firstSuvreyDate, setFirstSuvreyDate] = useState(null);
  const [lastSuvreyDate, setLastSuvreyDate] = useState(null);

  const handleChartOptionsChange = (e) => {
    setSelectedQuestion([]);
    setSelectedOptions([]);
    setBarChartSwtich(false);
    const selectedValue = e.target.value;
    setSelectedFilterLabel(selectedValue);
    setChartOptions(selectedValue);
    makeDataSet(data, selectedValue, questions, surveys);
  };

  const handleFetchChartData = (fromDates, toDates) => {
    let token = window.location.href.split("token/")[1];
    var analyticsRequest = {
      Token: token,
      FromDate: fromDates,
      ToDate: toDates,
    };
    setLoading(true);

    surveyResultsService
      .GetAnalyticsResults(analyticsRequest)
      .then((data) => {
        setStatus(true);

        if (data.questions && data.questions.length > 0) {
          const managementStandard = data.questions[0].managementStandard;

          if (managementStandard && managementStandard !== 0) {
            setChartType(managementStandard);
          } else {
            const questionPoolType = data.questions[0].questionPoolType;
            switch (questionPoolType) {
              case 1:
                setChartType(8);
                break;
              case 2:
                setChartType(9);
                break;
              default:
                setChartType(1);
            }
          }
        } else {
          setStatus(false);
        }
        var fromDate = new Date(data?.firstSurveyDate);
        var toDate = new Date(data?.lastSurveyDate);
        setFirstSuvreyDate(fromDate);
        setLastSuvreyDate(toDate);
        setData(data?.surveyQuestionAverages);
        setQuestions(data?.questions);
        setChartTitle(data?.chartTitle);  
        console.log(data.firstSurveyDate);   
        setSurveys(data?.surveys);
        if (data.questions?.length > 0) {
          makeDataSet(
            data.surveyQuestionAverages,
            chartOptions,
            data.questions,
            data.surveys
          );
          getFilterOptions(
            data.surveyQuestionAverages,
            data.questions,
            data.surveys
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    handleFetchChartData(null, null);
  }, []);

  useEffect(() => {
    if (fromDate !== null && toDate !== null) {
      handleFetchChartData(fromDate, toDate);
    }else{
      handleFetchChartData(null, null);
    }
  }, [fromDate, toDate]);

  const getDecile = (sd) => {
    let decile = Math.floor(sd);
    if (decile > 0) {
      let val = decile + "th";
      if (decile === 1) {
        val = decile + "st";
      } else if (decile === 2) {
        val = decile + "nd";
      } else if (decile === 3) {
        val = decile + "rd";
      }
      return `(${val})`;
    } else {
      return "";
    }
  };

  const checkboxRef = useRef(null);

  const createGroupedOptions = (allFilters, groupLabel) => {
    const groupName = (
      <div className="filter-title">
        <label htmlFor="groupCheckbox">
          <input
            type="checkbox"
            id="groupCheckbox"
            ref={checkboxRef}
            onChange={(e) => {
              toggleGroupSelection(allFilters, e.target.checked);
            }}
          />
          {`${groupLabel}`}
        </label>
      </div>
    );

    const formattedFilters = allFilters.map((filter) => ({
      label: filter,
      value: filter,
    }));

    return [
      {
        label: groupName,
        options: formattedFilters,
      },
    ];
  };

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = true;
    }
  }, []);

  const toggleGroupSelection = (allFilters, groupSelected) => {
    if (groupSelected) {
      setSelectedOptions(allFilters);
      setBarChartSwtich(true);
    } else {
      setBarChartSwtich(false);
      setSelectedOptions([]);
    }
  };

  const makeDataSet = (data, option, questionData, Surveys) => {
    const intermediateData = {};
    const questionIndices = {};
    const surveyIdSet = new Set();
    let surveyCount = 0;
    console.log(data);
    data?.forEach((response) => {
      const surveyId = response.surveyId;
      surveyIdSet.add(surveyId);
    });
    surveyCount = surveyIdSet.size;
    setSurveyCount(surveyCount);
    data?.forEach((response) => {
      const surveyId = response.surveyId;
      if (!questionIndices[surveyId]) {
        questionIndices[surveyId] = 1;
      }
      const qKey = `Q${questionIndices[surveyId]}`;
      questionIndices[surveyId] += 1;

      const addEntry = (key, value, decileValue, text, isMulti) => {
        const combinedKey = `${key}-${surveyId}`;
        if (!intermediateData[combinedKey]) {
          intermediateData[combinedKey] = {
            name: key,
            Decile: [],
            QuestionText: [],
            surveyId: surveyId,
          };
        }

        if (surveyCount > 1) {
          intermediateData[combinedKey][qKey] = value < 0 ? null : value;
        } else if (parseFloat(value) > 0) {
          intermediateData[combinedKey][qKey] = value;
        }

        if (
          decileValue === undefined ||
          decileValue === "" ||
          decileValue === null
        ) {
          intermediateData[combinedKey].Decile.push("");
        } else {
          intermediateData[combinedKey].Decile.push(decileValue);
        }

        intermediateData[combinedKey].QuestionText.push(text);
      };

      let qText = questionData?.find((x) => x.id === response.questionId)?.text;

      if (option === "All Staff") {
        const checkAndAddEntry = (name, value, decile, text) => {
          addEntry(name, value, getDecile(decile), text);
        };

        const survey = Surveys?.find(
          (survey) => survey.id === response.surveyId
        );

        if (survey) {        
          let fullName = `${survey.label !==null || survey.label !=='' ? `${survey.label}` : survey.name}`;
          checkAndAddEntry(
            fullName,
            response.questionAverage,
            response.sdQuestionAvg,
            qText
          );
        }
        
      }

      if (option === "Job Role") {
        const checkAndAddEntry = (name, value, decile, text) => {
          if (surveyCount > 1) {
            addEntry(name, value, getDecile(decile), text);
          } else if (parseFloat(value) > 0) {
            addEntry(name, value, getDecile(decile), text);
          }
        };

        checkAndAddEntry(
          "Teaching Staff (Classroom)",
          response.teachingStaffClassAvg,
          response.sdTeachingStaffClass,
          qText
        );
        checkAndAddEntry(
          "Teaching Staff (Non-Classroom)",
          response.teachingStaffNonClassAvg,
          response.sdTeachingStaffNonClass,
          qText
        );
        checkAndAddEntry(
          "Support Staff (Classroom)",
          response.supportStaffClassAvg,
          response.sdSupportStaffClass,
          qText
        );
        checkAndAddEntry(
          "Support Staff (Non-Classroom)",
          response.supportStaffNonClassAvg,
          response.sdSupportStaffNonClass,
          qText
        );
        checkAndAddEntry(
          "Middle Leader",
          response.middleLeaderAvg,
          response.sdMiddleLeader,
          qText
        );
        checkAndAddEntry(
          "Senior Leader",
          response.seniorLeaderAvg,
          response.sdSeniorLeader,
          qText
        );
      } else if (option === "Time At School") {
        const timeRanges = [
          {
            name: "Less than 1 year",
            value: response.timeAtSchool1YrAvg,
            decile: response.sdTimeAtSchool1Yr,
          },
          {
            name: "1 to 3 years",
            value: response.timeAtSchool1To3YrsAvg,
            decile: response.sdTimeAtSchool1To3Yrs,
          },
          {
            name: "4 to 5 years",
            value: response.timeAtSchool4To5YrsAvg,
            decile: response.sdTimeAtSchool4To5Yrs,
          },
          {
            name: "6 to 10 years",
            value: response.timeAtSchool6To10YrsAvg,
            decile: response.sdTimeAtSchool6To10Yrs,
          },
          {
            name: "11 to 15 years",
            value: response.timeAtSchool11To15YrsAvg,
            decile: response.sdTimeAtSchool11To15Yrs,
          },
          {
            name: "16 to 20 years",
            value: response.timeAtSchool16To20YrsAvg,
            decile: response.sdTimeAtSchool16To20Yrs,
          },
          {
            name: "Greater than 20 years",
            value: response.timeAtSchool20AboveYrsAvg,
            decile: response.sdTimeAtSchool20AboveYrs,
          },
        ];

        timeRanges.forEach((range) => {
          if (surveyCount > 1) {
            addEntry(range.name, range.value, getDecile(range.decile), qText);
          } else if (parseFloat(range.value) > 0) {
            addEntry(range.name, range.value, getDecile(range.decile), qText);
          }
        });
      } else if (option === "Employment Type") {
        const employmentTypes = [
          {
            name: "Part Time",
            value: response.partTimeAvg,
            decile: response.sdPartTime,
          },
          {
            name: "Full Time",
            value: response.fullTimeAvg,
            decile: response.sdFullTime,
          },
        ];

        employmentTypes.forEach((type) => {
          if (surveyCount > 1) {
            addEntry(type.name, type.value, getDecile(type.decile), qText);
          } else if (parseFloat(type.value) > 0) {
            addEntry(type.name, type.value, getDecile(type.decile), qText);
          }
        });
      } else if (
        option === "Department" ||
        option === "Job Title" ||
        option === "Key Stage or Year" ||
        option === "Schools"
      ) {
        var selectedOption =
          option === "Job Title"
            ? "JobTitle"
            : option === "Key Stage or Year"
            ? "Phase"
            : option;
        const filterData = JSON.parse(response.additionalFilters)?.[
          selectedOption
        ];
        if (filterData) {
          for (const [key, values] of Object.entries(filterData)) {
            const threshold = values[0];
            const decile = values[1];
            if (values?.length > 1 && threshold > 1) {
              addEntry(key, threshold, getDecile(decile), qText);
            } else if (threshold > 1) {
              addEntry(key, threshold, "", qText);
            }
          }
        }
      }
    });
    const finalDataSet = Object.values(intermediateData);

    if (finalDataSet && finalDataSet.length > 0) {
      setInsufficientRecord(false);
    } else {
      setInsufficientRecord(true);
    }

    const allQuestions =
      finalDataSet && finalDataSet?.length > 0
        ? Object.keys(finalDataSet[0]).filter(
            (key) =>
              key !== "Decile" &&
              key !== "QuestionText" &&
              key !== "surveyId" &&
              key !== "name"
          )
        : [];
    const allFilters = [...new Set(finalDataSet?.map((x) => x.name))];
    setAllQuestionOptions(allQuestions);
    const groupedOptions = createGroupedOptions(allFilters, option);
    setOptions(groupedOptions);
    setFilteredDataSet(finalDataSet);
    setDataSet(finalDataSet);
    return finalDataSet;
  };
  var filterOption = [];
  const checkFilterData = (data, option, questionData, Surveys) => {
    const intermediateData = {};
    const questionIndices = {};
    const surveyIdSet = new Set();
    let surveyCount = 0;
    data?.forEach((response) => {
      const surveyId = response.surveyId;
      surveyIdSet.add(surveyId);
    });
    surveyCount = surveyIdSet.size;
    setSurveyCount(surveyCount);
    data?.forEach((response) => {
      const surveyId = response.surveyId;
      if (!questionIndices[surveyId]) {
        questionIndices[surveyId] = 1;
      }
      const qKey = `Q${questionIndices[surveyId]}`;
      questionIndices[surveyId] += 1;

      const addEntry = (key, value, decileValue, text, isMulti) => {
        const combinedKey = `${key}-${surveyId}`;
        if (!intermediateData[combinedKey]) {
          intermediateData[combinedKey] = {
            name: key,
            Decile: [],
            QuestionText: [],
            surveyId: surveyId,
          };
        }

        if (surveyCount > 1) {
          intermediateData[combinedKey][qKey] = value < 0 ? null : value;
        } else if (parseFloat(value) > 0) {
          intermediateData[combinedKey][qKey] = value;
        }

        if (
          decileValue === undefined ||
          decileValue === "" ||
          decileValue === null
        ) {
          intermediateData[combinedKey].Decile.push("");
        } else {
          intermediateData[combinedKey].Decile.push(decileValue);
        }

        intermediateData[combinedKey].QuestionText.push(text);
      };

      let qText = questionData?.find((x) => x.id === response.questionId)?.text;

      if (option === "All Staff") {
        const checkAndAddEntry = (name, value, decile, text) => {
          addEntry(name, value, getDecile(decile), text);
        };

        const survey = Surveys?.find(
          (survey) => survey.id === response.surveyId
        );

        if (survey) {
          let fullName = `${survey.name} (${survey.label})`;
          checkAndAddEntry(
            fullName,
            response.questionAverage,
            response.sdQuestionAvg,
            qText
          );
        }
      }

      if (option === "Job Role") {
        const checkAndAddEntry = (name, value, decile, text) => {
          if (surveyCount > 1) {
            addEntry(name, value, getDecile(decile), text);
          } else if (parseFloat(value) > 0) {
            addEntry(name, value, getDecile(decile), text);
          }
        };

        checkAndAddEntry(
          "Teaching Staff (Classroom)",
          response.teachingStaffClassAvg,
          response.sdTeachingStaffClass,
          qText
        );
        checkAndAddEntry(
          "Teaching Staff (Non-Classroom)",
          response.teachingStaffNonClassAvg,
          response.sdTeachingStaffNonClass,
          qText
        );
        checkAndAddEntry(
          "Support Staff (Classroom)",
          response.supportStaffClassAvg,
          response.sdSupportStaffClass,
          qText
        );
        checkAndAddEntry(
          "Support Staff (Non-Classroom)",
          response.supportStaffNonClassAvg,
          response.sdSupportStaffNonClass,
          qText
        );
        checkAndAddEntry(
          "Middle Leader",
          response.middleLeaderAvg,
          response.sdMiddleLeader,
          qText
        );
        checkAndAddEntry(
          "Senior Leader",
          response.seniorLeaderAvg,
          response.sdSeniorLeader,
          qText
        );
      } else if (option === "Time At School") {
        const timeRanges = [
          {
            name: "Less than 1 year",
            value: response.timeAtSchool1YrAvg,
            decile: response.sdTimeAtSchool1Yr,
          },
          {
            name: "1 to 3 years",
            value: response.timeAtSchool1To3YrsAvg,
            decile: response.sdTimeAtSchool1To3Yrs,
          },
          {
            name: "4 to 5 years",
            value: response.timeAtSchool4To5YrsAvg,
            decile: response.sdTimeAtSchool4To5Yrs,
          },
          {
            name: "6 to 10 years",
            value: response.timeAtSchool6To10YrsAvg,
            decile: response.sdTimeAtSchool6To10Yrs,
          },
          {
            name: "11 to 15 years",
            value: response.timeAtSchool11To15YrsAvg,
            decile: response.sdTimeAtSchool11To15Yrs,
          },
          {
            name: "16 to 20 years",
            value: response.timeAtSchool16To20YrsAvg,
            decile: response.sdTimeAtSchool16To20Yrs,
          },
          {
            name: "Greater than 20 years",
            value: response.timeAtSchool20AboveYrsAvg,
            decile: response.sdTimeAtSchool20AboveYrs,
          },
        ];

        timeRanges.forEach((range) => {
          if (surveyCount > 1) {
            addEntry(range.name, range.value, getDecile(range.decile), qText);
          } else if (parseFloat(range.value) > 0) {
            addEntry(range.name, range.value, getDecile(range.decile), qText);
          }
        });
      } else if (option === "Employment Type") {
        const employmentTypes = [
          {
            name: "Part Time",
            value: response.partTimeAvg,
            decile: response.sdPartTime,
          },
          {
            name: "Full Time",
            value: response.fullTimeAvg,
            decile: response.sdFullTime,
          },
        ];

        employmentTypes.forEach((type) => {
          if (surveyCount > 1) {
            addEntry(type.name, type.value, getDecile(type.decile), qText);
          } else if (parseFloat(type.value) > 0) {
            addEntry(type.name, type.value, getDecile(type.decile), qText);
          }
        });
      } else if (
        option === "Department" ||
        option === "Job Title" ||
        option === "Key Stage or Year" ||
        option === "Schools"
      ) {
        var selectedOption =
          option === "Job Title"
            ? "JobTitle"
            : option === "Key Stage or Year"
            ? "Phase"
            : option === "Schools"? "Schools" : option;
        const filterData = JSON.parse(response.additionalFilters)?.[
          selectedOption
        ];
        console.log(filterData);
        if (filterData) {
          for (const [key, values] of Object.entries(filterData)) {
            const threshold = values[0];
            const decile = values[1];
            if (values?.length > 1 && threshold > 1) {
              addEntry(key, threshold, getDecile(decile), qText);
            } else if (threshold > 1) {
              addEntry(key, threshold, "", qText);
            }
          }
        }
      }
    });

    const finalDataSet = Object.values(intermediateData);
    if (finalDataSet && finalDataSet.length > 0) {
      filterOption.push(option);
    }
    return filterOption;
  };
  const handleQuestionCheckboxClick = (value) => {
    if (selectedQuestion.includes(value)) {
      setSelectedQuestion(
        selectedQuestion.filter((option) => option !== value)
      );
    } else {
      setSelectedQuestion([...selectedQuestion, value]);
    }
  };

  const handleCheckboxClick = (value) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const InputQuestionOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    innerProps,
    label,
    ...rest
  }) => {
    const bg = isFocused ? "#eee" : isSelected ? "#B2D4FF" : "transparent";
    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };
    const props = {
      ...innerProps,

      style,
    };

    return (
      <div className="heatmap-option">
        <components.Option
          {...rest}
          isDisabled={isDisabled}
          isFocused={isFocused}
          isSelected={isSelected}
          getStyles={getStyles}
          innerProps={props}
        >
          <input
            type="checkbox"
            onChange={() => handleQuestionCheckboxClick(label)}
            checked={isSelected}
          />
          {label}
        </components.Option>
      </div>
    );
  };

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    innerProps,
    label,
    ...rest
  }) => {
    const bg = isFocused ? "#eee" : isSelected ? "#B2D4FF" : "transparent";
    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex",
    };
    const props = {
      ...innerProps,

      style,
    };

    return (
      <div className="heatmap-option">
        <components.Option
          {...rest}
          isDisabled={isDisabled}
          isFocused={isFocused}
          isSelected={isSelected}
          getStyles={getStyles}
          innerProps={props}
        >
          <input
            type="checkbox"
            onChange={() => handleCheckboxClick(label)}
            checked={isSelected}
          />
          {label}
        </components.Option>
      </div>
    );
  };
  useEffect(() => {
    let filteredData = dataSet;
    if (selectedOptions && selectedOptions.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedOptions.includes(item.name)
      );
    } else {
      setBarChartSwtich(false);
    }

    if (selectedQuestion && selectedQuestion.length > 0) {
      filteredData = filteredData.map((response) => {
        const filteredResponse = {
          name: response.name,
          Decile: [],
          QuestionText: [],
          surveyId: response.surveyId,
        };

        selectedQuestion.forEach((question) => {
          if (response[question] !== undefined) {
            const index = question.substring(1) - 1;

            filteredResponse[question] = response[question];
            filteredResponse.Decile[index] = response.Decile[index];
            filteredResponse.QuestionText[index] = response.QuestionText[index];
          }
        });

        return filteredResponse;
      });
    }

    setFilteredDataSet(filteredData);
  }, [selectedOptions, selectedQuestion, dataSet]);

  const handleClear = () => {
    setFilteredDataSet(dataSet);
  };

  const handleSerachFilters = (selectValue, { action }) => {
    if (action === "clear") {
      if (selectValue?.length === 0) {
        setSelectedOptions([]);
        setBarChartSwtich(false);
      }
    } else {
      if (selectValue?.length > 0) {
        setSelectedOptions(selectValue.map((option) => option.value));
        setBarChartSwtich(true);
      } else {
        setSelectedOptions([]);
        setBarChartSwtich(false);
      }
    }
  };

  const handleSerachQuestions = (selectValue, { action }) => {
    if (action === "clear") {
      if (selectValue?.length === 0) {
        setSelectedQuestion([]);
      }
    } else {
      if (selectValue?.length) {
        setSelectedQuestion(selectValue.map((option) => option.value));
      } else {
        setSelectedQuestion([]);
      }
    }
  };

  const getFilterOptions = (data, questionData, Surveys) => {
    var allOptions = [
      "All Staff",
      "Job Role",
      "Time At School",
      "Employment Type",
      "Department",
      "Job Title",
      "Key Stage or Year",
      "Schools"
    ];
    allOptions.map((category) =>
      checkFilterData(data, category, questionData, Surveys)
    );
    const distinctOptions = [...new Set(filterOption)];
    setFilterOptions(distinctOptions);
  };

  return (
    <div>
      <Grid container>
        <Grid item md={12} xs={12}>
          <h2>Analytics</h2>
        </Grid>        
      </Grid>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "top",
            flexDirection: "column",
          }}
        >
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} />
          <Skeleton width="100%" height={20} />
        </div>
      ) : status && filteredDataSet?.length > 0 ? (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={1}                  
                  justifyContent={"flex-end"}
                >
                  <div>
                    <FormControl size="small" fullWidth>
                      <QuestionsSelect
                        value={chartOptions}
                        onChange={handleChartOptionsChange}
                        className="analytics-filter-dropdown"                    
                      >
                        {filterOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </QuestionsSelect>
                    </FormControl>
                  </div>
                  <div>
                    <Select
                      className="custom-filter-Questions-heatmap-dropdown"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      isMulti
                      options={allQuestionOptions.map((key) => ({
                        value: key,
                        label: key,
                      }))}
                      onChange={handleSerachQuestions}
                      isClearable={true}
                      isSearchable={true}
                      value={selectedQuestion.map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      placeholder="Select Questions"
                      required={true}
                      onClear={handleClear}
                      components={{
                        Option: InputQuestionOption,
                      }}
                    />
                  </div>
                  {chartOptions !== "All Staff" && (
                    <div>
                      <Select
                        className="custom-filter-heatmap-dropdown"
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti
                        options={Options}
                        onChange={handleSerachFilters}
                        value={selectedOptions.map((option) => ({
                          value: option,
                          label: option,
                        }))}
                        placeholder="Select Filters"
                        required={true}
                        isClearable={true}
                        isSearchable={true}
                        onClear={handleClear}
                        components={{
                          Option: InputOption,
                        }}
                      />
                    </div>
                  )}
                </Stack>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Stack
                  direction={{ xs: 'column-reverse', sm: 'row' }}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div>
                    <h2>{selectedFilterLabel}</h2>
                  </div>
                  <Stack
                    direction={{sm: 'row', xs: 'column'}}
                    spacing={{xs: 1, sm: 1}}
                    mb={3}
                    mt={{xs: 1, sm: 0}}
                    justifyContent={{sm: 'flex-end', xs: 'space-between'}}
                  >
                    <div>
                      <DatePicker
                        className="datetimepicker"
                        name="fromDate"
                        label="Start Date"
                        format="dd-MM-yyyy"
                        minDate={firstSuvreyDate}
                        maxDate={lastSuvreyDate}
                        value={fromDate}
                        slotProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                        }}
                        onChange={(date) => {
                          if (date) {
                            if (toDate && date > toDate) {
                              setDateErrors((prev) => ({
                                ...prev,
                                fromDate:
                                  "From Date cannot be greater than To Date",
                              }));
                              return;
                            }
                            setDateErrors((prev) => ({ ...prev, fromDate: "" }));
                            setFromDate(date);
                          } else {
                            setFromDate(null);
                          }
                        }}
                        renderInput={(params) => (
                          <PulseTextField
                          {...params}                      
                            fullWidth
                          />
                        )}
                      />
                      {dateErrors.fromDate && (
                        <span style={{ color: "red" }}>{dateErrors.fromDate}</span>
                      )}
                    </div>
                    <div>
                      <DatePicker
                        className="datetimepicker"
                        name="toDate"
                        label="End Date"
                        format="dd-MM-yyyy"
                        minDate={firstSuvreyDate}
                        maxDate={lastSuvreyDate}
                        value={toDate}
                        slotProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                        }}
                        onChange={(date) => {
                          if (date) {
                            if (fromDate && date < fromDate) {
                              setDateErrors((prev) => ({
                                ...prev,
                                toDate: "To Date cannot be less than From Date",
                              }));
                              return;
                            }
                            setDateErrors((prev) => ({ ...prev, toDate: "" }));
                            setToDate(date);
                          } else {
                            setToDate(null);
                          }
                        }}
                        renderInput={(params) => (
                          <PulseTextField
                            {...params}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      {dateErrors.toDate && (
                        <span style={{ color: "red" }}>{dateErrors.toDate}</span>
                      )}
                    </div>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </LocalizationProvider>
          {barChartSwtich ? (
            <PulseBarChart
              data={filteredDataSet}
              chartTitle={chartTitle}
              chartType={chartType}
              selectedQuestions={selectedQuestion}
              surveyCount={surveyCounts}
            />
          ) : (
            <WhiteLayoutContent className="analytics-graph">
              <PulseStackedBarChart
                data={filteredDataSet}
                chartType={chartType}
                chartTitle={chartTitle}
                chartCategory={chartOptions}
                surveyCount={chartOptions === "All Staff" ? 1 : surveyCounts}
              />
            </WhiteLayoutContent>
          )}
        </>
      ) : (
        <Alert severity="error">
          {insufficientRecord
            ? `There were insufficient filtered responses to display this information.`
            : `Analytics are available once the survey is closed.`}
        </Alert>
      )}
    </div>
  );
};

export default Analytics;
