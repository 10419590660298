import React, { useEffect, useState } from 'react';
import { Edit } from '@mui/icons-material';
import ModalPopup from './ModalPopup';
import { DatePicker, LocalizationProvider, TimePicker, } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import enGB from 'date-fns/locale/en-GB';
import Holidays from 'date-holidays';
import { GetDateFromString } from '../utils/Utils';
import { PulseStatus } from '../utils/Constants';
import { PulseTextField } from '../assets/styles/AppContainers';
import { surveyService } from '../services';
import { Stack } from '@mui/material';

const holidays = new Holidays('GB');
const hd = holidays.getHolidays();

function EditCloseDate({ selectedSurvey }) {

    const [open, setOpen] = useState(false)
    const [date, setDate] = useState(null)
    const [time, setTime] = useState(null)

    const updateDateAndTime = () => {
        surveyService.UpdateEndDate({ token: selectedSurvey?.token, date: date, hour: time.getHours(), minutes: time.getMinutes() })
        setOpen(false)
    }
    useEffect(() => {
        setDate(new Date(selectedSurvey?.endDate))
        setTime(new Date(selectedSurvey?.endDate).setHours(selectedSurvey?.endHour, selectedSurvey?.endMinute, 0, 0))
    }, [selectedSurvey])

    return (
        <div>
            {selectedSurvey && <div className='survey-datetime' >
                <Stack direction='column' rowGap={1}>
                    <div>
                        <strong>Start:&nbsp;</strong>{selectedSurvey?.startDate && GetDateFromString(selectedSurvey?.startDate)}{' '}
                        {selectedSurvey?.startHour?.toString()?.padStart(2, '0')}:{selectedSurvey?.startMinute?.toString()?.padStart(2, '0')}{' '}
                    </div>
                    <div>
                        <strong>{selectedSurvey.pulseStatus === PulseStatus.Live ? 'Closing' : 'Closed'}:</strong> {' '}
                        {selectedSurvey?.endDate && GetDateFromString(selectedSurvey?.endDate)}{' '}
                        {selectedSurvey?.endHour?.toString()?.padStart(2, '0')}:{selectedSurvey?.endMinute?.toString()?.padStart(2, '0')}{' '}
                        {selectedSurvey?.pulseStatus === PulseStatus.Live && <Edit sx={{ fontSize: 16, cursor: 'pointer', color: '#007A7A' }} onClick={() => setOpen(true)}></Edit>}
                    </div>
                </Stack>
                {open && <ModalPopup
                    onCancel={() => setOpen(false)}
                    title='Edit Schedule'
                    onAction={updateDateAndTime}
                    render={(<div className='schedule-modal'>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                            <Stack direction="column" rowGap={3} mt={3}>
                                <DatePicker className="datepicker" minDate={new Date(selectedSurvey?.endDate)}
                                    shouldDisableDate={(date) => {
                                        if (typeof (date) === 'string') {
                                            date = new Date(selectedSurvey?.endDate);
                                        }
                                        if (date.toDateString() === (new Date()).toDateString() && (new Date()).getHours() > 16) {
                                            return true;
                                        }
                                        if (date !== undefined || date !== null)
                                            return (date?.getDay() === 0 | date?.getDay() === 6 |

                                                hd.filter(x => x.start.getDate() === date?.getDate() && x.start.getMonth() === date?.getMonth()).length > 0) ? true : false
                                    }}
                                    label="Close Date"
                                    name="endDate"
                                    value={date}
                                    fullWidth renderInput={(params) => (<PulseTextField  {...params} />)}
                                    onChange={(e) => setDate(e)}
                                />
                                <TimePicker
                                    className="datepicker"
                                    label="Close time"
                                    name="EndTime"
                                    value={time}
                                    fullWidth renderInput={(params) => (<PulseTextField  {...params} />)}
                                    onChange={(e) => setTime(e)}
                                    minTime={new Date().setHours(8, 0, 0, 0)}
                                    maxTime={new Date().setHours(16, 0, 0, 0)}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </div>)}
                >
                </ModalPopup>}
            </div>
            }
        </div>
    );
}

export default EditCloseDate;