import { Stack, Grid } from '@mui/material';
import { id } from 'date-fns/locale';
import React from 'react';

const OptionLegends = ({ question }) => {

    const getLegentColor = (index) => {
        
        if (question.questionSignType === 2) {
            if (index === 0) {
                return 'never'
            }
            if (index === 1) {
                return 'seldom'
            }
            if (index === 2) {
                return 'sometimes'
            }
            if (index === 3) {
                return 'often'
            }
            if (index === 4) {
                return 'always'
            }

        }
        if (question.questionSignType == 1) {
            if (index === 0) {
                return 'always'
            }
            if (index === 1) {
                return 'often'
            }
            if (index === 2) {
                return 'sometimes'
            }
            if (index === 3) {
                return 'seldom'
            }
            if (index === 4) {
                return 'never'

            }
        }
    }

    return (
        question.surveyQuestionOptions &&
        <Grid container>
            <Grid item lg={10} md={10} sm={12} xs={12} ml={3}>
                <Stack direction='row' alignItems='center' spacing={{sm: 4, xs: 1 }} justifyContent='end'>
                    {
                        question.surveyQuestionOptions.map((option, index) => (
                            <div className='legends' key={index}>
                                <div className={`${getLegentColor(index)} legend-color`}></div>
                                <div>{option.text}</div>
                            </div>
                        ))
                    }
                </Stack>
            </Grid>
        </Grid>
    );
};

export default OptionLegends;