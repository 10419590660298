import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import BarChartIcon from '@mui/icons-material/BarChart';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';

const SchoolMenu = [
    {
        icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
        title: <span>Dashboard</span>,
        path: '/'
    },
    // {
    //     icon: <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" id="icon-surveys">
    //         <path d="M2.58333 0.75C1.56583 0.75 0.75 1.56583 0.75 2.58333V15.4167C0.75 16.4342 1.56583 17.25 2.58333 17.25H15.4167C16.4342 17.25 17.25 16.4342 17.25 15.4167V2.58333C17.25 1.56583 16.4342 0.75 15.4167 0.75H2.58333ZM2.58333 2.58333H15.4167V15.4167H2.58333V2.58333ZM4.41667 4.41667V6.25H13.5833V4.41667H4.41667ZM4.41667 8.08333V9.91667H13.5833V8.08333H4.41667ZM4.41667 11.75V13.5833H10.8333V11.75H4.41667Z" fill="#6D6D6D" />
    //     </svg>,
    //     title: <span>Surveys</span>,
    //     path: '/surveys-list'
    // },
];

const SurveyDashboardMenu = [
    {
        icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
        title: <span>Dashboard</span>,
        path: '/surveys/dashboard/token/'
    },
    {
        icon: <AppsIcon sx={{ fontSize: 20 }} />,
        title: <span>Heatmap</span>,
        path: '/surveys/Heatmap/token/'
    },
    {
        icon: <BarChartIcon sx={{ fontSize: 20 }} />,
        title: <span>Analytics</span>,
        path: '/surveys/Analytics/token/'
    },
    {
        icon: <ModeCommentOutlinedIcon sx={{ fontSize: 20 }} />,
        title: <span>Comments</span>,
        path: '/surveys/comments/token/'
    },
    // {
    //     icon: <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M2.58333 0.75C1.56583 0.75 0.75 1.56583 0.75 2.58333V15.4167C0.75 16.4342 1.56583 17.25 2.58333 17.25H15.4167C16.4342 17.25 17.25 16.4342 17.25 15.4167V2.58333C17.25 1.56583 16.4342 0.75 15.4167 0.75H2.58333ZM2.58333 2.58333H15.4167V15.4167H2.58333V2.58333ZM4.41667 4.41667V6.25H13.5833V4.41667H4.41667ZM4.41667 8.08333V9.91667H13.5833V8.08333H4.41667ZM4.41667 11.75V13.5833H10.8333V11.75H4.41667Z" fill="#6D6D6D" />
    //     </svg>,
    //     title: <span>Reports</span>,
    //     path: '/surveys/Reports/token/'
    // },

];

export { SurveyDashboardMenu, SchoolMenu }