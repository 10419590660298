import React, { useEffect, useState } from 'react';
import { Grid, Button, Skeleton, Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import HistoryIcon from '@mui/icons-material/History';
import { AppContainer, WhiteLayoutContent } from '../assets/styles/AppContainers';
import { useNavigate } from "react-router-dom";
import { surveyService } from '../services';
import SurveyBlock from '../components/SurveyBlock';
import { useSelector } from 'react-redux';
import { iconlive, iconcompleted, icondraft } from '../assets/images/index';
import '../assets/styles/app.scss';

function SchoolDashboard(props) {
    const [surveys, setSurveys] = useState([]);
    const [loading, setloading] = useState(true);
    const [liveCount, setLiveCount] = useState(0);
    const [completeCount, setCompleteCount] = useState(0);
    const [draftCount, setDraftCount] = useState(0);
    const [openCount, seOpenCount] = useState(0);

    const navigate = useNavigate()
    const { orgId, isMat } = useSelector((store) => store.organization)
    useEffect(() => {
        loadData();
    }, []);
    const loadData = () => {

        surveyService.GetSchoolDashboard(orgId, isMat)
            .then((data) => {
                setDraftCount(data.draftCount)
                setCompleteCount(data.completeCount)
                setLiveCount(data.liveCount)
                seOpenCount(data.openCount)
                const slicedArray = data.surveys.slice(0, 5);
                setSurveys(slicedArray);
                setloading(false)
            }).catch(err => {
                setloading(false)

            });
    }
    const resetStatus = (t, s) => {
        let surveyList = surveys
        let idx = surveyList.findIndex(x => x.token === t)
        if (idx > -1) {
            surveyList[idx].pulseStatus = s
            setSurveys(surveyList)
        }
    }
    return (
        <AppContainer>
            <Grid container columnSpacing={3}>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                    <WhiteLayoutContent>
                        <div className='surveys-box'>
                            <div className='icon-live survey-icons'>
                                <img src={iconlive} alt='' />
                            </div>
                            <div className='survey-counter' onClick={() => navigate('/live-surveys')}>
                                <h2>Live Surveys</h2>
                                <div className='counter'>{liveCount}</div>
                            </div>
                            <div className='survey-link' onClick={() => navigate('/live-surveys')}>View All</div>
                        </div>
                    </WhiteLayoutContent>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                    <WhiteLayoutContent>
                        <div className='surveys-box'>
                            <div className='icon-schedule survey-icons'>
                                {/* <img src={iconlive} alt='' /> */}
                                <HistoryIcon sx={{ fontSize: 48, color: '#ffffff' }} />
                            </div>
                            <div className='survey-counter' onClick={() => navigate('/schedule-surveys')}>
                                <h2>Scheduled Surveys</h2>
                                <div className='counter'>{openCount}</div>
                            </div>
                            <div className='survey-link' onClick={() => navigate('/schedule-surveys')}>View All</div>
                        </div>
                    </WhiteLayoutContent>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                    <WhiteLayoutContent>
                        <div className='surveys-box'>
                            <div className='icon-draft survey-icons'>
                                <img src={icondraft} alt='' />
                            </div>
                            <div className='survey-counter' onClick={() => navigate('/draft-surveys')}>
                                <h2>Draft Surveys</h2>
                                <div className='counter'>{draftCount}</div>
                            </div>
                            <div className='survey-link' onClick={() => navigate('/draft-surveys')}>View All</div>
                        </div>
                    </WhiteLayoutContent>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                    <WhiteLayoutContent>
                        <div className='surveys-box'>
                            <div className='icon-completed survey-icons'>
                                <img src={iconcompleted} alt='' />
                            </div>
                            <div className='survey-counter' onClick={() => navigate('/completed-surveys')}>
                                <h2>Completed Surveys</h2>
                                <div className='counter'>{completeCount}</div>
                            </div>
                            <div className='survey-link' onClick={() => navigate('/completed-surveys')}>View All</div>
                        </div>
                    </WhiteLayoutContent>
                </Grid>
            </Grid>
            <Grid container rowSpacing={{ xs: 2 }} justifyContent="center">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <h1>Live, Scheduled & Draft Surveys</h1>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} textAlign="right">
                    <Button variant="contained" className='next-button' onClick={() => navigate('/survey-panel')}>
                        Create New Survey
                    </Button>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    {
                        loading ? <>
                            <Grid item lg={12} md={12} xs={12} sx={{ marginTop: '10px' }}>
                                <Box>
                                    <Skeleton width='100%' height={20} />
                                    <Skeleton width='100%' height={20} />
                                    <Skeleton width='100%' height={20} />
                                </Box>
                            </Grid></> :
                            surveys.map((item, idx) => <SurveyBlock survey={item} key={idx} showReruns={true} reloadList={loadData} resetStatus={resetStatus}></SurveyBlock>)}

                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={3} textAlign='center'>
                    {!loading ? (surveys.length > 0 ? <Button className='view-all-btn' onClick={() => navigate('/surveys-list')} > View All Surveys</Button> : <Alert severity="error" icon={false}>No Live or Draft Surveys Created.</Alert>) : ''}
                </Grid>
            </Grid>
        </AppContainer >
    );
}

export default SchoolDashboard;