import React from 'react';
import { Grid, Stack } from '@mui/material';
import BarLabels from './BarLabels';
import Score from './Score';
import Gradient from './Gradient';

const keys = [
    'never', 'seldom', 'sometimes', 'often', 'always']

const QuestionBar = ({ average, question, index }) => {
    return (
        <div className='question-scores'>
            <Grid container>
                <Grid item lg={10} md={10} sm={12} xs={12} pt={2} pb={4} pl='1.25rem' pr={{sm: 0, xs: '1.25rem'}}>
                    <div className='question-text'><span>Q{index + 1}:</span> {question.text}</div>
                    <Stack direction={{sm: 'row', xs: 'column'}}>
                        <div style={{marginRight: '5px'}}>Participants</div>
                        <div style={{flex: 1}}>
                            <div className='scores'>
                                {keys.map((key, i) => {
                                    let width = average[key.replace('sometimes', 'sometime') + 'Percentage']
                                    let count = average[key]
                                    return width > 0 && <Score key={i} width={width} count={count}></Score>
                                })}
                            </div>
                            <Gradient average={average} question={question}></Gradient>
                            <BarLabels></BarLabels>
                        </div>
                    </Stack>
                </Grid>                
                
                <Grid item lg={2} md={2} sm={12} xs={12}>
                    <div className='overall'>                        
                        <div className='overall-score'>{average.questionAverage.toFixed(2)}</div>
                        <div className='overall-title'>Overall Score</div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default QuestionBar;