import request from '../request';
import { filterApi } from '../Urls';

export default class filtersService
{
    static async SaveNewFilter(data)
    {
        return request({
            url: filterApi.saveNewFilter(),
            method: 'POST',
            data: JSON.stringify(data),
        })
    }
    static async GetHeatmapSurveyFilterBySurveyToken(data)
    {
        return request({
            url: filterApi.GetHeatmapSurveyFilterBySurveyToken(),
            method: 'POST',
            data: data,
        })
    }
    static async DeleteHeatmapSurveyFilter(data)
    {
        return request({
            url: filterApi.DeleteHeatmapSurveyFilter(data),
            method: 'DELETE',
        })
    }
}