import React, { Component } from 'react';
import { Button, Grid } from '@mui/material';
import { SurveyLayoutContent } from '../../../assets/styles/AppContainers';
import CommentReplies from '../../../components/CommentReplies';
import { commentService } from '../../../services/api/commentsService';

class StaffReply extends Component
{
    constructor()
    {
        super();
        this.state = {
            loading: true,
            commentId: -1,
            commentText: '',
        }
    }

    componentDidMount()
    {
        let urlToken = window.location.href.split('token/')[1];
        commentService.GetCommentsForStaffReply(urlToken)
            .then(data =>
            {

                this.setState({
                    commentId: data.id,
                    commentText: data.comments,
                })
            })
            .catch(e => this.setState({ loading: false }));
    }
    render()
    {
        return (
            <Grid container rowGap={3}>
                <Grid item lg={12} md={12} xs={12}>
                    <SurveyLayoutContent>
                        <Grid container>
                            <Grid item lg={12} md={12} xs={12}>
                                <div className='comment-content'>
                                    <div className='comment-date'>
                                        {/* {GetDateFromString(comment.submitDate)} */}
                                    </div>
                                    <div className='comments-text'>
                                        <h3> {this.state.commentText}</h3>
                                    </div>

                                    {this.state.commentId > 0 ? <CommentReplies IsAdminReply={false} IsStaffReply={true} CommentId={this.state.commentId}></CommentReplies> : ''}
                                </div>
                            </Grid>
                        </Grid>
                    </SurveyLayoutContent>
                </Grid>
            </Grid >);
    }
}

export default StaffReply;