import React, { Component } from 'react';
import { QuestionsTextField, CommentsMeta } from '../assets/styles/AppContainers';
import '../assets/styles/app.scss';
import { ScrollContainer } from 'react-nice-scroll';
import { Avatar, Button, Grid } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { commentService } from '../services/api/commentsService';
import { GetDateFromString } from '../utils/Utils'

class CommentReplies extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Commnets: [],
            loading: true,
            reply: '',
            pause: false,
        };
        this.sendAdminReply = this.sendAdminReply.bind(this)
    }
    componentDidMount() {
        commentService.GetCommentReplies(this.props.CommentId).then(data => {
            this.setState({
                comments: data,
                loading: false,
            })
        }).catch(err =>
            this.setState({
                loading: false,
            }))
        this.setState({ loading: false })
    }

    getReplies() {
        return this.state.comments?.map((comment) =>
            <CommentsMeta>
                <Grid container>
                    <Grid item lg={1} md={1} sm={1} xs={3} style={{ justifyContent: 'flex-end', display: 'flex', paddingRight: 10 }} >{(comment.isAdminReply && this.props.IsStaffReply) ?
                        <div className='staff-avatar'>{comment.replyBy ? comment.replyBy : 'Admin'}</div> :
                        (comment.isStaffReply && this.props.IsAdminReply) ?
                            <div className='staff-avatar'>{this.props.StaffRole}</div> : ''}
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={8}>
                        <div className={(this.props.IsStaffReply && comment.isStaffReply) || (this.props.IsAdminReply && comment.isAdminReply) ? 'comment-reply-admin' : 'comment-reply-staff'} >
                            <div className='comment-date'>
                                {GetDateFromString(comment.replyDate)}
                            </div>
                            <div className='comments-replies'>
                                {comment.reply}
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={3}  >{(comment.isAdminReply && this.props.IsAdminReply) ?
                        <div className='admin-avatar'>{this.props.LoginUser.Id === comment.replierId ? 'You' : <PersonIcon sx={{ fontSize: 30 }} />}</div> :
                        (comment.isStaffReply && this.props.IsStaffReply) ?
                            <div className='admin-avatar'>You</div> : ''}
                    </Grid>
                </Grid>
            </CommentsMeta >
        )
    }
    sendAdminReply() {
        if (this.state.reply !== '') {
            if (!this.state.pause) {
                this.setState({
                    pause: true,
                })
                let reply = {
                    reply: this.state.reply,
                    commentId: this.props.CommentId,
                    isAdminReply: this.props.IsAdminReply,
                    isStaffReply: this.props.IsStaffReply,
                    replyDate: new Date(),
                    replyBy: this.props.IsAdminReply ? this.props.LoginUser.FirstName : null,
                    replierEmail: this.props.IsAdminReply ? this.props.LoginUser.Email : null,
                    replierId: this.props.IsAdminReply ? this.props.LoginUser.Id : null,
                }
                commentService.SaveCommentReply(reply).then(data => {
                    this.setState({
                        comments: [...this.state.comments, reply],
                        reply: '',
                        pause: false,
                    })
                    this.props.onSend()
                }).catch(err =>
                    this.setState({
                        loading: false,
                        pause: false,
                    }))
            }
        }
    }
    render() {
        return (
            <div className='commentreply'>
                <ScrollContainer alwaysShowTracks>
                    <div className='replies'>
                        {this.state.loading ? '' : this.getReplies()}
                    </div>
                </ScrollContainer>
                <Grid container style={{ marginTop: 20 }}>
                    <Grid item lg={12} md={12} xs={12} >
                        <QuestionsTextField fullWidth multiline value={this.state.reply} onChange={e => this.setState({ reply: e.target.value })} placeholder='Write your reply here..' rows={3} />
                    </Grid>
                    <Grid item lg={12} md={12} xs={12} sx={{ marginTop: '12px', textAlign: 'right' }}>
                        <Button variant="contained" className={this.state.pause ? 'grey-button' : 'next-button'} onClick={this.sendAdminReply}>Send</Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default CommentReplies;