import React from 'react';

const Gradient = ({ average, question }) => {
    return (
        <div className='gradient-bar' style={{                    
            
            // background: `linear-gradient(to right
            //     ${average.neverPercentage > 0 ? (question.questionSignType === 2 ? `,#ff0000 ${average.neverPercentage - 30}%` : `,#5F902A ${average.neverPercentage - 30}%`) : ``}        
            //     ${average.seldomPercentage > 0 ? (question.questionSignType === 2 ? `,#ff0000 ${average.seldomPercentage - 30}%` : `,#5F902A ${average.seldomPercentage - 30}%`) : ``} 
            //     ${average.sometimePercentage > 0 ? `,#FFCF05 ${average.sometimePercentage}%` : ``}
            //     ${average.oftenPercentage > 0 ? (question.questionSignType === 2 ? `,#5F902A  ${average.oftenPercentage + 80}% ` : `,#ff0000  ${average.oftenPercentage + 80}%`) : ``}
            //     ${average.alwaysPercentage > 0 ? (question.questionSignType === 2 ? `,#5F902A  ${average.alwaysPercentage + 80}% ` : `,#ff0000  ${average.alwaysPercentage + 80}%`) : ``} )`


           // background: 'linear-gradient(to right,  #5F902A 0%,#B2F170 20%,#FFCF05 40%,#FFE888 60%,#FF0000 80%,#FF9292 100%)',    

            // background: `linear-gradient(to right
            //      ${average.neverPercentage > 0 ? (question.questionSignType === 2 ? `,#ff0000 ${average.neverPercentage}%` : `,#5F902A ${average.neverPercentage}%`) : ``}        
            //      ${average.seldomPercentage > 0 ? (question.questionSignType === 2 ? `,#FF9292 ${average.seldomPercentage}%` : `,#B2F170 ${average.seldomPercentage}%`) : ``} 
            //     ${average.sometimePercentage > 0 ? `,#FFCF05 ${average.sometimePercentage}%` : ``}
            //      ${average.oftenPercentage > 0 ? (question.questionSignType === 2 ? `,#B2F170  ${average.oftenPercentage + 50}% ` : `,#FF9292  ${average.oftenPercentage + 50}%`) : ``}
            //      ${average.alwaysPercentage > 0 ? (question.questionSignType === 2 ? `,#5F902A  ${average.alwaysPercentage + 50}% ` : `,#ff0000  ${average.alwaysPercentage + 50}%`) : ``} )`
        }}>
            {/* {average.neverPercentage > 0 && <div style={{ width: `${average.neverPercentage}%`, borderRightColor: 'black', borderRightWidth: 1, borderRightStyle: 'solid' }}></div>}
            {average.seldomPercentage > 0 && <div style={{ width: `${average.seldomPercentage}%`, borderRightColor: 'black', borderRightWidth: 1, borderRightStyle: 'solid' }}></div>}
            {average.sometimePercentage > 0 && <div style={{ width: `${average.sometimePercentage}%`, borderRightColor: 'black', borderRightWidth: 1, borderRightStyle: 'solid' }}></div>}
            {average.oftenPercentage > 0 && <div style={{ width: `${average.oftenPercentage}%`, borderRightColor: 'black', borderRightWidth: 1, borderRightStyle: 'solid' }}></div>}
            {average.alwaysPercentage > 0 && <div style={{ width: `${average.alwaysPercentage}%`}}></div>} */}


            {average.neverPercentage > 0 ? (question.questionSignType === 2 ? <div style={{ width: `${average.neverPercentage}%`, background: '#ff0000' }}></div> : <div style={{ width: `${average.neverPercentage}%`, background: '#5F902A' }}></div>) : ``}        
            {average.seldomPercentage > 0 ? (question.questionSignType === 2 ? <div style={{ width: `${average.seldomPercentage}%`, background: '#FF7E35' }}></div> : <div style={{ width: `${average.seldomPercentage}%`, background: '#7DB93D' }}></div>) : ``} 
            {average.sometimePercentage > 0 ? <div style={{ width: `${average.sometimePercentage}%`, background: '#FFCF05' }}></div> : ``}
            {average.oftenPercentage > 0 ? (question.questionSignType === 2 ? <div style={{ width: `${average.oftenPercentage}%`, background: '#7DB93D' }}></div> : <div style={{ width: `${average.oftenPercentage}%`, background: '#FF7E35' }}></div>) : ``}
            {average.alwaysPercentage > 0 ? (question.questionSignType === 2 ? <div style={{ width: `${average.alwaysPercentage}%`, background: '#5F902A' }}></div> : <div style={{ width: `${average.alwaysPercentage}%`, background: '#ff0000' }}></div>) : ``}
            
        </div>
    );
};

export default Gradient;