import request from '../request';
import { staffManagementApi } from '../Urls';


export default class participantsService {
    static async GetStaffBySchool(schoolId) {
        return request({
            url: staffManagementApi.GetStaffBySchool(schoolId),
            method: 'GET'
        });
    }
    static async GetParticipants(token) {
        return request({
            url: staffManagementApi.GetParticipantsBySurveyToken(),
            method: 'POST',
            data: token
        });
    }
    // static async GetParticipantsForSendInvites(token) {
    //     return request({
    //         url: staffManagementApi.GetParticipantsForSendInvites(),
    //         method: 'POST',
    //         data: token
    //     });
    // }
    static async UpdateParticipantsAndSendInvites(data) {
        return request({
            url: staffManagementApi.UpdateParticipantsAndSendInvites(),
            method: 'POST',
            data: data
        });
    }
    static async GetStaff(id, isMat) {
        return request({
            url: staffManagementApi.GetStaff(id, isMat),
        });
    }
}
