import { useRoutes, Navigate } from "react-router-dom";
import SchoolDashboard from "../views/SchoolDashboard";
import { SchoolDashboardLayout, SurveyDashboardLayout } from "../layouts";
import { SurveyList, CreateSurveyScreen, SurveyCreated, Survey, SurveySubmitted, Dashboard, Comments, Heatmap, StaffReply, Login, ForgotPassword, Analytics } from "../views";
import FolderForm from "../components/FolderForm";
import { useSelector } from "react-redux";
import SuperAdmin from "../views/SuperAdmin";
import Participants from "../views/SurveyDashboard/Participants/Participants";


export default function Routes() {
    const routes = useRoutes([
        {
            path: '/',
            element: <SchoolDashboardLayout />,
            children: [
                { element: <Navigate to="/dashboard" />, index: true },
                {
                    path: 'dashboard', element: <ProtectedRoute><SchoolDashboard /> </ProtectedRoute>
                },
                { path: 'survey-panel/*', element: <ProtectedRoute><CreateSurveyScreen /></ProtectedRoute> },
                { path: 'surveys-list', element: <ProtectedRoute><SurveyList Status={0} /></ProtectedRoute> },
                { path: 'surveys/:fid', element: <ProtectedRoute> <SurveyList Status={0} /></ProtectedRoute> },
                { path: 'live-surveys', element: <ProtectedRoute><SurveyList Status={3} /></ProtectedRoute> },
                { path: 'schedule-surveys', element: <ProtectedRoute><SurveyList Status={2} /></ProtectedRoute> },
                { path: 'draft-surveys', element: <ProtectedRoute><SurveyList Status={1} /></ProtectedRoute> },
                { path: 'completed-surveys', element: <ProtectedRoute> <SurveyList Status={4} /></ProtectedRoute> },
                { path: 'survey-created', element: <ProtectedRoute><SurveyCreated /> </ProtectedRoute> },
                { path: 'edit', element: <ProtectedRoute><SurveyCreated /></ProtectedRoute> },
                { path: 'create-folder', element: <ProtectedRoute><FolderForm /></ProtectedRoute> },
                {
                    path: '/surveys',
                    element: <SurveyDashboardLayout />,
                    children: [
                        { element: <Navigate to="/dashboard" />, index: true },
                        { path: 'dashboard/token/*', element: <Dashboard /> },
                        { path: 'comments/token/*', element: <Comments /> },
                        { path: 'comments/:id/token/*', element: <Comments /> },
                        { path: 'reports/token/*', element: <FolderForm /> },
                        { path: 'heatmap/token/*', element: <Heatmap /> },
                        { path: 'Analytics/token/*', element: <Analytics /> },
                        { path: 'Participants/token/*', element: <Participants /> },
                    ]
                },
            ]
        },
        { path: 'survey/*', element: <Survey /> },
        { path: 'submitted', element: <SurveySubmitted /> },

        { path: 'staff-reply/*', element: <StaffReply /> },
        { path: 'login', element: <Login /> },

        { path: 'superadmin/*', element: <SuperAdmin /> },
        { path: 'forgot-password', element: <ForgotPassword /> },]);

    return routes;
}


const ProtectedRoute = ({ children }) => {
    const { organization } = useSelector((store) => store)
    if (RequireLogin(organization)) {
        return <Navigate to='/login' replace></Navigate>
    }

    return children;
}
const RequireLogin = (data) => {
    if (data?.access_token)
        return false;

    if (data?.expires_in) {
        var tokenExpDate = new Date(data?.expires_in * 1000);
        var dateNow = new Date();
        if (tokenExpDate >= dateNow) {
            return false;
        }
    }
    return true;

}