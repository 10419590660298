import request from '../request';
import { commentsApi } from '../Urls';

export class commentService {
    static async GetComments(token) {
        return request({
            url: commentsApi.GetSurveyOpenQuestionAnswers(),
            method: 'POST',
            data: token
        });
    }
    static async GetCommentReplies(id) {
        return request({
            url: commentsApi.GetCommentReplies(id),
        });
    }
    static async SaveCommentReply(data) {
        return request({
            url: commentsApi.SaveCommentReply(),
            method: 'POST',
            data: data
        });
    }
    static async GetCommentsForStaffReply(token) {
        return request({
            url: commentsApi.GetCommentsForStaffReply(),
            method: 'POST',
            data: token
        });
    }
    static async DownloadCommentsInExcel(token) {
        return request({
            url: commentsApi.DownloadCommentsInExcel(),
            method: 'POST',
            data: token,
            responseType: 'blob',
        });
    }
}