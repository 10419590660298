import React, { Component } from 'react';
import { Button, Grid, Modal } from '@mui/material';
import ScheduleForm from '../views/Surveys/SurveyForms/ScheduleForm';
import '../assets/styles/app.scss';
import { WhiteLayoutContent } from '../assets/styles/AppContainers';
import { surveyService } from '../../src/services'

export class ReRunSurveyButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSchedule: false,
            survey: {
                startDate: new Date(),
                endDate: new Date(),
                startHour: 0,
                startMin: 0,
                endHour: 0,
                endMin: 0,
                repeatDuration: 1,
                frequency: 2,
                noOfWorkingDays: 5,
                customRepeatCron: '',
                Preview: false
            },
            hasChanges: false,
            loading: false
        };
        this.setSurvey = this.setSurvey.bind(this);
        this.setHasUpdate = this.setHasUpdate.bind(this);
        this.openCloseModal = this.openCloseModal.bind(this);
        this.onSaveSchedule = this.onSaveSchedule.bind(this);
        this.onPreviewSchedule = this.onPreviewSchedule.bind(this);
    }
    componentDidMount() {
        if (this.props.onClose) {
            this.setState({ loading: !this.props.showButton })
            this.setState({ showSchedule: !this.props.showButton })
        }
    }
    setSurvey(val) {
        this.setState({ survey: val })
    }
    setHasUpdate(val) {
        this.setState({ hasChanges: val })
    }
    openCloseModal() {
        // if (this.state.loading) {
        //     return;
        // }
        this.setState({ showSchedule: !this.state.showSchedule, loading: false })
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    onPreviewSchedule() {
        this.setState({ preview: true }, this.onSaveSchedule())
    }

    onSaveSchedule() {
        let payload = {

            startHour: this.state.survey.startHour,
            startMin: this.state.survey.startMin,
            endHour: this.state.survey.endHour,
            endMin: this.state.survey.endMin,
            startDate: this.state.survey.startDate,
            endDate: this.state.survey.endDate,
            token: this.props.token
        }
        surveyService.ReRunSurvey(payload)
            .then((data) => {
                if (data) {
                    this.setState({ showSchedule: false })
                    if (data !== null && data !== '') {

                        window.location.replace(`#/survey-panel/${data}`)
                    }
                }
            })
            .catch(() => { this.setState({ showSchedule: false }) })
            .finally(() => {
                this.setState({ loading: false })
                if (this.props.onClose) {
                    this.props.onClose();
                }
            });
    }

    render() {
        return (
            <div>
                {this.props.showButton !== false && <Button className={this.state.loading ? 'grey-button' : 'prev-button'} onClick={this.openCloseModal} style={{ marginLeft: '7px' }}>Run&nbsp;Again</Button>}
                <Modal open={this.state.showSchedule} onClose={this.openCloseModal} className='warning-modal' sx={{ width: '50%' }}>
                    <WhiteLayoutContent>
                        <Grid container>
                            <Grid item lg={12} xs={12}>
                                <ScheduleForm Survey={this.state.survey} onSurveyChange={this.setSurvey} OnHasUpdate={this.setHasUpdate} onAutoSave={() => { return }}></ScheduleForm>
                            </Grid>
                            <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end', padding: 25, paddingTop: 0 }}>
                                <Grid item lg={6} xs={6} style={{ justifyContent: 'flex-start' }}>
                                    <Button variant="contained" className='prev-button' onClick={this.openCloseModal}>Cancel</Button>
                                </Grid>
                                <Grid item lg={6} xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                    <Button variant="contained" className='prev-button' onClick={this.onPreviewSchedule} style={{ marginRight: 10 }}>Preview</Button>
                                    <Button variant="contained" className='next-button' onClick={this.onSaveSchedule}>Publish</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </WhiteLayoutContent>
                </Modal>
            </div>
        );
    }
}