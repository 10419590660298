import React, { useState, useEffect } from 'react';
import { Grid, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { AppContainer, PulseTextField, QuestionsSelect } from '../../../assets/styles/AppContainers';
import FolderForm from '../../../components/FolderForm';
import { folderService } from '../../../services/api/folderService';
import { useSelector } from 'react-redux';
import { FormControl, MenuItem, InputLabel } from '@mui/material';
import { object, string } from 'yup';

const schema = object().shape({
    pulseInputField: string().matches(/^[^,]*$/, 'The Name cannot contain commas')
        .min(2, 'The Name must be at least 2 characters'),
});



function SurveyForm({ Survey, onSurveyChange, OnHasUpdate })
{
    const [name, setName] = useState(Survey.name);
    const [folderName, setFolderName] = useState(null);
    const [folderOption, setfolderOption] = useState(Survey.folderId === null && Survey.name !== '' ? '3' : (Survey.folderId !== null && Survey.folderId > 0 ? '2' : '0'));
    const [openCreateFolder, setOpenCreateFolder] = useState(false);
    const [openSelectFolder, setOpenSelectFolder] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(0)
    const { folders } = useSelector((store) => store.folders);
    const [surveyValidationError, setSurveyValidationError] = useState('');
    const [folderValidationError, setFolderValidationError] = useState('');
    useEffect(() =>
    {
        if (folderOption === '2')
        {
            setOpenSelectFolder(true)
            setSelectedFolder(Survey.folderId)
        }
    }, [])

    const validatePulseTextField = async () =>
    {
        try
        {
            await schema.validate({ pulseInputField: name }, { abortEarly: false });
            setSurveyValidationError('');
        } catch (errors)
        {
            setSurveyValidationError(errors.errors[0]);
        }
    };

    const validatePulseFolderField = async () =>
    {
        try
        {
            await schema.validate({ pulseInputField: folderName }, { abortEarly: false });
            setFolderValidationError('');
        } catch (errors)
        {
            setFolderValidationError(errors.errors[0]);
        }
    };

    const handleRadioChange = (e) =>
    {
        let val = e.target.value;
        setfolderOption(val);
        setOpenCreateFolder(false);
        setOpenSelectFolder(false);
        if (val === "1")
        {
            setOpenCreateFolder(true);
        }
        else if (val === "2")
        {
            setOpenSelectFolder(true);
        }
        else if (val === "3")
        {
            let s = Survey;
            s.folderId = null;
            onSurveyChange(s);

        }
        OnHasUpdate(true);
    }

    const getFoldersList = () =>
    {
        if (openSelectFolder)
            // return (
            //     <RadioGroup name="folderList" value={selectedFolder} onChange={(e) =>
            //     {
            //         let s = Survey;
            //         s.folderId = e.target.value;
            //         onSurveyChange(s);
            //         setSelectedFolder(e.target.value)
            //     }}>
            //         {folders.map(folder => <FormControlLabel value={folder.id} control={<Radio />} label={folder.name} />)}
            //</RadioGroup >);

            return (<FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="folder-label">Select Folder</InputLabel>
                <QuestionsSelect
                    value={selectedFolder}
                    labelId="folder-label"
                    label="Select Folder"
                    onChange={(e) =>
                    {
                        let s = Survey;
                        s.folderId = e.target.value;
                        onSurveyChange(s);
                        setSelectedFolder(e.target.value)
                        OnHasUpdate(true)
                    }}
                >
                    {folders.map(folder => <MenuItem value={folder.id}>{folder.name}</MenuItem>)}
                </QuestionsSelect>
            </FormControl>)


        return '';
    }
    const OnFolderCreate = (value) =>
    {
        setFolderName(value)
        let s = Survey;
        s.folderId = null;
        s.folder = { schoolId: s.schoolId, name: value };
        onSurveyChange(s);
        OnHasUpdate(true);
    }
    return (
        <AppContainer>
            <Grid container padding={3}>
                <Grid item lg={12} md={12} xs={12}>
                    <h2>Create Survey</h2>
                </Grid>
                <Grid item lg={12} md={12} xs={12} marginBottom={2} pt={1}>
                    <PulseTextField label="Survey Name" fullWidth onChange={e =>
                    {
                        let s = Survey;
                        s.name = e.target.value;
                        onSurveyChange(s);
                        OnHasUpdate(true);
                        setName(e.target.value)
                    }} value={name} onBlur={validatePulseTextField} />
                    {surveyValidationError && <div style={{ color: 'red' }}>{surveyValidationError}</div>}
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                    <h2>Save Survey</h2>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                    <RadioGroup
                        name="folderOptions"
                        value={folderOption}
                        onChange={(e) => handleRadioChange(e)}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Create New Folder" />
                        <FormControlLabel value="2" control={<Radio />} label="Select Existing Folder" />
                        <FormControlLabel value="3" control={<Radio />} label="No Folder" />
                    </RadioGroup>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    {openCreateFolder ? <PulseTextField sx={{ mt: 2 }} label="Folder Name" onBlur={validatePulseFolderField} fullWidth value={folderName} onChange={(e) => OnFolderCreate(e.target.value)} /> : ''}
                    {folderValidationError && <div style={{ color: 'red' }}>{folderValidationError}</div>}
                    {getFoldersList()}
                </Grid>
            </Grid>
        </AppContainer >
    );
}

export default SurveyForm;