import React, { useState } from 'react';
import '../assets/styles/app.scss';

import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';

function DownloadButton({ download, Title }) {

    const [downloading, setDownloading] = useState(false);


    const doDownload = (event) => {
        if (downloading) return;

        setDownloading(true);
        //  setTimeout(() => {
        download(event);
        setDownloading(false);
        //   }, 3000);
    }
    return (

        <LoadingButton
            loading={downloading}
            loadingPosition="start"
            startIcon={<DownloadIcon />}
            variant="contained"
            className={downloading ? 'grey-button' : 'next-button'}
            onClick={doDownload}
        >
            <span style={{ marginTop: '1px' }}>{Title} {downloading}</span>
        </LoadingButton>
    );
}

export default DownloadButton;