import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


function SuccessAlert({ Message })
{
    const [success, setSuccess] = useState(true)
    return (
        <Snackbar
            key={'snack-success'}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={success}
            autoHideDuration={6000}
            onClose={() => setSuccess(false)}
        >
            <Alert severity="success" variant='filled'>
                children
            </Alert></Snackbar>
    );
}

export default SuccessAlert;