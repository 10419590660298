import { Grid, Table, TableHead, TableRow, TableCell, Modal, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { ScrollContainer } from 'react-nice-scroll';
import { Preview, TableStyle, WhiteLayoutContent, ParticipantsList } from '../../../assets/styles/AppContainers';
import { GetDateFromString } from '../../../utils/Utils';
import { RepeatDuration } from '../../../utils/Constants';
import { useEffect } from 'react';
import { useState } from 'react';
//import CloseIcon from '@mui/icons-material/Close';

function SurveyDetailForm({ Survey, onCancel, onPublish }) {
    const [likerts, setLikert] = useState([]);
    const [opens, setOpens] = useState([]);
    const [cron, setCron] = useState(null);

    //todo: following need to put in utils folder 
    const GetRepeatDurationName = (id) => {
        if (id === RepeatDuration.OneOff)
            return 'oneOff'
        if (id === RepeatDuration.Weekly)
            return 'weekly'
        if (id === RepeatDuration.Monthly)
            return 'monthly'
        if (id === RepeatDuration.Fortnightly)
            return 'fortnightly'
        if (id === RepeatDuration.Custom)
            return 'custom'
    }
    const getTotalNoOfDays = (id, frequency) => {
        if (id === RepeatDuration.Weekly)
            return 7 * frequency
        if (id === RepeatDuration.Fortnightly)
            return 14 * frequency
        if (id === RepeatDuration.Monthly)
            return 30 * frequency
    }
    useEffect(() => {
        setLikert(Survey.surveyQuestions.filter(x => x.questionLikertFormat === 2))
        setOpens(Survey.surveyQuestions.filter(x => x.questionLikertFormat === 1))
        if (Survey.customRepeatCron) {
            setCron(JSON.parse(Survey.customRepeatCron));
        }
    }, [])
    const getFilterName = (filterJson) => {
        if (filterJson.length > 0) {
            let filters = JSON.parse(filterJson);
            if (filters?.selectedFilters) {
                return (
                    <>{filters.schools?.length > 0 ?
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Schools</TableCell>
                                </TableRow>
                            </TableHead>
                            {filters.schools?.map(cf =>
                                <TableRow>
                                    <TableCell>{cf.label}</TableCell>
                                </TableRow>)}
                        </Table> : ''}
                        {
                            filters.selectedFilters.filter(c => c.value !== -1).map((p, i) =>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{p.label}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {filters.selectedChildFilter[i]?.map(cf =>
                                        <TableRow>
                                            <TableCell>{cf.label}</TableCell>
                                        </TableRow>)}
                                </Table>)
                        }</>
                )
                // let str = '';
                // if (filters.length > 1)
                // {
                //     filters[filters.length - 2].name = filters[filters.length - 2].name + ' and ' + filters[filters.length - 1].name
                //     filters.splice(filters.length - 1, 1)
                //     filters.map(f =>
                //         str += f.name
                //     )
                //     return str;
                // }
                // else
                // {
                //     return filters[0].name;
                // }
            }
            else {
                return 'All staff'
            }
        }

    }
    const getText = (Survey) => {
        if (Survey.repeatDuration === RepeatDuration.OneOff) {
            return <>
                Your Survey <span className='preview-survey'>{Survey.name}</span>
                {' '} is scheduled to start on
                {' '}<span className='preview-survey'>{GetDateFromString(Survey.startDate)}</span>
                {' '}at
                {' '}<span className='preview-survey'>{Survey.startHour?.toString().padStart(2, '0')}:{Survey.startMin?.toString().padStart(2, '0')}</span>
                {' '} and close on
                {' '}<span className='preview-survey'>{GetDateFromString(Survey.endDate)}</span>
                {' '}at
                {' '}<span className='preview-survey'>{Survey.endHour?.toString().padStart(2, '0')}:{Survey.endMin?.toString().padStart(2, '0')}</span>.
            </>;
        }
        else if (Survey.repeatDuration === RepeatDuration.Custom) {
            return <>
                Your Survey <span className='preview-survey'>{Survey.name}</span>
                {' '} is scheduled to start on
                {' '}<span className='preview-survey'>{GetDateFromString(Survey.startDate)}</span>
                {' '}at
                {' '}<span className='preview-survey'>{Survey.startHour?.toString().padStart(2, '0')}:{Survey.startMin?.toString().padStart(2, '0')}</span>.
                {' '} The full schedule of surveys is shown below.
            </>;
        }
        else {
            return <>
                Your Survey <span className='preview-survey'>{Survey.name}</span>
                {' '} is scheduled to start on
                {' '}<span className='preview-survey'>{GetDateFromString(Survey.startDate)}</span>
                {' '}at
                {' '}<span className='preview-survey'>{Survey.startHour?.toString().padStart(2, '0')}:{Survey.startMin?.toString().padStart(2, '0')}</span>.
                {' '}It will run for <span className='preview-survey'>{Survey.noOfWorkingDays}</span> days and repeat {<span className='preview-survey'>{GetRepeatDurationName(Survey.repeatDuration)}</span>}, <span className='preview-survey'>{Survey.frequency}</span> times.
                {/* {' '}It will run for <span className='preview-survey'>{getTotalNoOfDays(Survey.repeatDuration, Survey.frequency)}</span> of days and repeat <span className='preview-survey'>{GetRepeatDurationName(Survey.repeatDuration)}</span> until
                {' '}<span className='preview-survey'>{GetDateFromString(Survey.surveyEndDate)}</span>. */}
            </>;
        }
    }
    return (
        <Modal open={true} className='preview-modal'>
            <WhiteLayoutContent>
                <IconButton
                    aria-label="close"
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        fontSize: '22px'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Grid container padding={3}>
                    <Grid item lg={12} md={12} xs={12}>
                        <h1>Review Your Survey</h1>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12} marginTop={1}>
                        {getText(Survey)}
                    </Grid>
                </Grid>
                <ScrollContainer alwaysShowTracks={true}>
                    <ParticipantsList>
                        {Survey.repeatDuration === RepeatDuration.Custom && cron?.length > 0 ?
                            <Grid container paddingLeft={3} paddingRight={3}>
                                <Grid item lg={12} md={12} xs={12}>
                                    <TableStyle>
                                        <Table>
                                            {cron?.length > 0 ? cron?.map(q => {
                                                return <TableRow>
                                                    <TableCell>{q?.label}: Starts {GetDateFromString(q.startDate)} at {q.startHour.toString().padStart(2, '0')}:{q.startMin.toString().padStart(2, '0')} and closes on {GetDateFromString(q.endDate)} at {q.endHour.toString().padStart(2, '0')}:{q.endMin.toString().padStart(2, '0')}</TableCell>
                                                </TableRow>
                                            }
                                            ) : null}
                                        </Table>
                                    </TableStyle>
                                </Grid>

                            </Grid> : null}
                        <Grid container paddingLeft={3} paddingRight={3}>
                            <Grid item lg={12} md={12} xs={12}>
                                {likerts.length > 0 ? <h3>The survey has the following Likert questions:</h3> : ''}

                                {likerts.length > 0 ?

                                    <TableStyle>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Likert </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {likerts?.map(q =>
                                                <TableRow>
                                                    <TableCell>{q.text}</TableCell>
                                                </TableRow>
                                            )}
                                        </Table>
                                    </TableStyle>
                                    : ""}

                                {opens.length > 0 ? <h3>The survey has the following open questions:</h3> : ''}
                                {opens.length > 0 ?


                                    <TableStyle>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Open </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {opens?.map(q =>
                                                <TableRow>
                                                    <TableCell>{q.text}</TableCell>
                                                </TableRow>
                                            )}
                                        </Table>
                                    </TableStyle>
                                    : ""}
                            </Grid>
                        </Grid>
                        <Grid container paddingLeft={3} paddingRight={3}>
                            <Grid item lg={12} md={12} xs={12}>
                                <h3>The following participants will be invited to take part:</h3>
                                <TableStyle>
                                    {Survey.participantFilters ? getFilterName(Survey.participantFilters) :
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>First Name</TableCell>
                                                    <TableCell>Last Name</TableCell>
                                                    <TableCell>Email</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {Survey.surveyParticipants?.map(p =>
                                                <TableRow>
                                                    <TableCell>{p.firstName}</TableCell>
                                                    <TableCell>{p.lastName}</TableCell>
                                                    <TableCell>{p.email}</TableCell>
                                                </TableRow>
                                            )}
                                        </Table>
                                    }
                                </TableStyle>

                            </Grid>
                        </Grid>
                    </ParticipantsList>
                </ScrollContainer>

                <Grid container padding={3}>
                    <Grid item lg={6} md={6} xs={6}
                        display="flex"
                        justifyContent="flex-start">
                        <Button variant="contained" className='prev-button' onClick={onCancel} >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item lg={6} md={6} xs={6}
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <Button variant="contained" className='prev-button' onClick={onCancel} sx={{ marginRight: '10px' }}>
                            Save Survey
                        </Button>
                        <Button variant="contained" className='next-button' onClick={onPublish} >
                            Create Survey
                        </Button>
                    </Grid>
                </Grid>
            </WhiteLayoutContent >
        </Modal >
    );
}

export default SurveyDetailForm;