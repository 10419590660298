export const Config = {
    BASE_URL: 'https://api.pulse.welbee.co.uk/api',

    //BASE_URL: 'https://api.pulse.welbee.co.uk/api',

    //BASE_URL: 'https://api.pulse.welbee.co.uk/api',

    WELBEE_URL: 'https://api.welbee.co.uk/api',
    //WELBEE_URL: 'https://api.welbee.co.uk/api',
    FRONTEND_URL: 'https://pulse.welbee.co.uk/',
};
