import React, { useEffect, useRef, useState } from "react";
import { ScrollContainer } from "react-nice-scroll";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { 
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Cell,
} from "recharts";
import { styled } from "@mui/material/styles";
import { ImportExport, North, South } from "@mui/icons-material";
import PulseChartTicksWarpper from "./PulseChartTicksWarpper";
import CircularProgress from '@mui/material/CircularProgress';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';


const customOrder = [
  "Teaching Staff (Classroom)",
  "Teaching Staff (Non-Classroom)",
  "Support Staff (Classroom)",
  "Support Staff (Non-Classroom)",
  "Middle Leader",
  "Senior Leader",
];

const QuestionTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="top" arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0,0,0,0.9)",
    color: "#ffffff",
    maxWidth: 320,
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(0,0,0,0.9)",
  },
}));

const sortOrder = {
  none: 0,
  accesnding: 1,
  decesnding: 2,
};

const StackedBarChart = ({
  data = [],
  chartType,
  chartTitle,
  chartCategory,
  surveyCount,
}) => {
  if (chartCategory === "Job Role") {
    data.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      const orderA = customOrder.indexOf(nameA);
      const orderB = customOrder.indexOf(nameB);

      if (orderA === -1 || orderB === -1) {
        return nameA.localeCompare(nameB);
      }
      return orderA - orderB;
    });
  } else {
    data.sort((a, b) => {
      if (chartCategory === "All Staff") {
        if (a.surveyId === b.surveyId) {
          return a.name.localeCompare(b.name); // Sort by name if surveyId is the same
        }
        return b.surveyId - a.surveyId; // Sort by surveyId in descending order
      } else {
        return a.name.localeCompare(b.name); // Default sorting by name
      }
    });
  }

  const [sortedDataSet, setSortedDataSet] = useState(data);
  const [headerWidth, setHeaderWidth] = useState(0);
  const [sortingOrder, setsortingOrder] = useState(0);
  const [sortQ, setSortQ] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const barHeight = 30;
  const gap = chartTitle === "All Staff" ? 1.8 : 1.2;
  const totalHeight = sortedDataSet.length * barHeight * gap;
  const headerRef = useRef(null);
  const firstItem = sortedDataSet[0] || {};
  const questionNumber = Object.keys(firstItem).filter(
    (key) =>
      key !== "name" &&
      key !== "Decile" &&
      key !== "surveyId" &&
      key !== "QuestionText"
  );

  const downloadChart = () => {
    setIsLoader(true);
    return new Promise((resolve, reject) => {
      try {
        const mgtChart = 'chart-download';
        const chartContainer = document.getElementById(mgtChart);
        if (!chartContainer) {
          console.error(`Element with id '${mgtChart}' not found in the DOM.`);
          reject(new Error(`Element with id '${mgtChart}' not found in the DOM.`));
          return;
        }
        const originalWidth = chartContainer.style.width;
        const originalPadding = chartContainer.style.padding;
        chartContainer.style.width = '2330px';
        chartContainer.style.padding = '20px';
        chartContainer.style.backgroundColor = 'white';
        domtoimage
          .toBlob(chartContainer)
          .then((blob) => {
            saveAs(blob, `${chartCategory}Graph.png`);
          })
          .catch((error) => {
            console.error('Error generating screenshot:', error);
          })
          .finally(() => {
            chartContainer.style.width = originalWidth;
            chartContainer.style.padding = originalPadding;
            setIsLoader(false);
            resolve();
          });
      } catch (error) {
        setIsLoader(false);
        console.log('chart-download', error);
        reject(error);
      }
    });
  };
  useEffect(() => {
    setSortedDataSet(data);
  }, [data]);

  var baseColor;

  switch (chartType) {
    case 1:
      baseColor = [
        "#45338C",
        "#5B4BA3",
        "#7264BB",
        "#887CD2",
        "#978DE2",
        "#ADA5F9",
        // "#7264BB",
        // "#796CC3",
        // "#8074CA",
        // "#887CD2",
        // "#8F84DA",
        // "#978DE2",
        // "#9E95E9",
        // "#A69DF1",
        // "#ADA5F9",
      ];
      break;
    case 2:
      baseColor = [
        "#E84F6B",
        "#ED6780",
        "#F27F95",
        "#F798AB",
        "#FCB0C0",
        "#FFC0CE",
        // "#F27F95",
        // "#F3889D",
        // "#F590A4",
        // "#F798AB",
        // "#F8A0B2",
        // "#FAA8B9",
        // "#FCB0C0",
        // "#FDB8C7",
        // "#FFC0CE",
      ];
      break;
    case 3:
      baseColor = [
        "#007A7A",
        "#0A9392",
        "#15ACAA",
        "#1FC5C1",
        "#29DED9",
        "#30EFE9",
        // "#15ACAA",
        // "#18B5B2",
        // "#1BBDB9",
        // "#1FC5C1",
        // "#22CEC9",
        // "#26D6D1",
        // "#29DED9",
        // "#2DE7E1",
        // "#30EFE9",
      ];
      break;
    case 4:
      baseColor = [
        "#53B5E0",
        "#5DBCE4",
        "#6DC6EB",
        "#7DD0F2",
        "#8CDBF8",
        "#92DEFB",
        // "#72CAED",
        // "#78CDF0",
        // "#7DD0F2",
        // "#82D4F4",
        // "#87D7F6",
        // "#8CDBF8",
        // "#92DEFB",
        // "#97E1FD",
        // "#9CE4FF",
      ];
      break;
    case 5:
      baseColor = [
        "#F980BC",
        "#FA8BC2",
        "#FB9ACB",
        "#FCAAD3",
        "#FEBADC",
        "#FFCAE5",
        // "#FCA0CE",
        // "#FCA5D1",
        // "#FCAAD3",
        // "#FDB0D6",
        // "#FDB5D9",
        // "#FEBADC",
        // "#FEBFDF",
        // "#FFC5E2",
        // "#FFCAE5",
      ];
      break;
    case 6:
      baseColor = [
        "#FFB205",
        "#FFB91C",
        "#FFC33E",
        "#FFCD60",
        "#FFD782",
        "#FFE1A4",
        // "#FFC649",
        // "#FFCA55",
        // "#FFCD60",
        // "#FFD06B",
        // "#FFD477",
        // "#FFD782",
        // "#FFDA8D",
        // "#FFDE99",
        // "#FFE1A4",
      ];
      break;
    case 7:
      baseColor = [
        "#FF5939",
        "#FF684B",
        "#FF7E66",
        "#FF9482",
        "#FFAA9D",
        "#FFC0B8",
        // "#FF856F",
        // "#FF8D79",
        // "#FF9482",
        // "#FF9B8B",
        // "#FFA394",
        // "#FFAA9D",
        // "#FFB1A6",
        // "#FFB9AF",
        // "#FFC0B8",
      ];
      break;
    case 8:
      baseColor = [
        "#53B5E0",
        "#5DBCE4",
        "#6DC6EB",
        "#7DD0F2",
        "#8CDBF8",
        "#92DEFB",
        // "#72CAED",
        // "#78CDF0",
        // "#7DD0F2",
        // "#82D4F4",
        // "#87D7F6",
        // "#8CDBF8",
        // "#92DEFB",
        // "#97E1FD",
        // "#9CE4FF",
      ];
      break;
    case 9:
      baseColor = [
        "#F980BC",
        "#FA8BC2",
        "#FB9ACB",
        "#FCAAD3",
        "#FEBADC",
        "#FFCAE5",
        // "#FCA0CE",
        // "#FCA5D1",
        // "#FCAAD3",
        // "#FDB0D6",
        // "#FDB5D9",
        // "#FEBADC",
        // "#FEBFDF",
        // "#FFC5E2",
        // "#FFCAE5",
      ];
      break;
    default:
      baseColor = [
        "#45338C",
        "#5B4BA3",
        "#7264BB",
        "#887CD2",
        "#978DE2",
        "#ADA5F9",
        // "#7264BB",
        // "#796CC3",
        // "#8074CA",
        // "#887CD2",
        // "#8F84DA",
        // "#978DE2",
        // "#9E95E9",
        // "#A69DF1",
        // "#ADA5F9",
      ];
  }
  const colorsPicker =
    chartCategory === "All Staff" ? baseColor : baseColor.slice(0, surveyCount);
  function groupBy(key, array) {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }

  function sortByQuestionKey(questionKey, direction) {
    const groupedData = groupBy("name", sortedDataSet);

    const sortedGroups = Object.entries(groupedData).sort(
      ([nameA, groupA], [nameB, groupB]) => {
        const minA = Math.min(...groupA.map((item) => item[questionKey]));
        const minB = Math.min(...groupB.map((item) => item[questionKey]));

        if (direction === sortOrder.accesnding) {
          if (minA < minB) return -1;
          if (minA > minB) return 1;

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;

          return 0;
        } else if (direction === sortOrder.decesnding) {
          if (minA > minB) return -1;
          if (minA < minB) return 1;

          if (nameA > nameB) return -1;
          if (nameA < nameB) return 1;

          return 0;
        }
      }
    );

    const sortedData = sortedGroups.flatMap(([, group]) => group);
    setSortedDataSet(sortedData);
    setsortingOrder(direction);
    setSortQ(questionKey);
  }

  const StickyHeaderOld = () => {
    return (
      <div
        ref={headerRef}
        style={{
          width: `${headerWidth}px`,
          position: "sticky",
          top: 0,
          display: "flex",
          background: "#fff",
          padding: "25px 0 10px 0",
          zIndex: 1001,
          justifyContent: "center",
          columnGap: "13px",
          marginLeft: "137px",
        }}
      >
        {questionNumber?.map((question, index) => {
          const questionText = sortedDataSet[0]?.QuestionText[index];
          const Qtxt = `Q${index + 1}`;
          return (
            <div key={question} className="top-questions">
              <span
                className="stackbar-questions"
                style={{
                  width: `${
                    questionNumber?.length === 1
                      ? 1300
                      : questionNumber?.length === 2
                      ? index === 0
                        ? 530
                        : 600
                      : 300
                  }px`,
                }}
              >
                <QuestionTooltip title={questionText} placement="top" arrow>
                  {question}
                </QuestionTooltip>
                {sortQ === Qtxt && sortingOrder === sortOrder.accesnding ? (
                  <North
                    className="north-sort-btn"
                    onClick={(e) =>
                      sortByQuestionKey(Qtxt, sortOrder.decesnding)
                    }
                  ></North>
                ) : sortQ === Qtxt && sortingOrder === sortOrder.decesnding ? (
                  <South
                    className="south-sort-btn"
                    onClick={(e) =>
                      sortByQuestionKey(Qtxt, sortOrder.accesnding)
                    }
                  ></South>
                ) : (
                  <ImportExport
                    className="sort-btn"
                    onClick={(e) =>
                      sortByQuestionKey(Qtxt, sortOrder.accesnding)
                    }
                  ></ImportExport>
                )}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const StickyHeader = ({ index, width, qNumber }) => {
    const questionText = sortedDataSet[0]?.QuestionText[index];
    const Qtxt = `Q${index + 1}`;
    console.log(width);
    console.log(index);
    return (
      <div
        style={{
          width: `${width}px`,
          position: "sticky",
          top: 0,
          display: "flex",
          background: "#fff",
          padding: "25px 0 10px 0",
          zIndex: 1001,
          justifyContent: "center",
          columnGap: "13px",
        }}
      >
        <div key={index} className="top-questions">
          <span
            className="stackbar-questions"
            style={{
              width: `${
                questionNumber?.length === 1
                  ? 1300
                  : questionNumber?.length === 2
                  ? index === 0
                    ? 530
                    : 600
                  : 300
              }px`,
            }}
          >
            <QuestionTooltip title={questionText} placement="top" arrow>
              {qNumber}
            </QuestionTooltip>
            {sortQ === qNumber && sortingOrder === sortOrder.accesnding ? (
              <North
                className="north-sort-btn"
                onClick={(e) =>
                  sortByQuestionKey(qNumber, sortOrder.decesnding)
                }
              ></North>
            ) : sortQ === qNumber && sortingOrder === sortOrder.decesnding ? (
              <South
                className="south-sort-btn"
                onClick={(e) =>
                  sortByQuestionKey(qNumber, sortOrder.accesnding)
                }
              ></South>
            ) : (
              <ImportExport
                className="sort-btn"
                onClick={(e) =>
                  sortByQuestionKey(qNumber, sortOrder.accesnding)
                }
              ></ImportExport>
            )}
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const calculateWidth = () => {
      const n = questionNumber?.length;
      return n === 1
        ? 1050
        : n === 2
        ? 700 + (n - 1) * 620
        : 430 + (n - 1) * 312;
    };
    setHeaderWidth(calculateWidth() > 1050 ? calculateWidth() : 1050);
  }, [questionNumber, sortedDataSet]);

  if (!questionNumber?.length && !sortedDataSet?.length) {
    return null;
  }

  const transformToGroupedData = (sortedData, questionNumbers) => {
    const groupedData = [];

    sortedData.forEach((entry, i) => {
      questionNumbers.forEach((question, index) => {
        const groupIndex = groupedData.findIndex(
          (group) => group.group === entry.name && group.category === question
        );

        if (groupIndex === -1) {
          const newGroup = {
            group: entry.name,
            category: question,
            [`survey${entry.surveyId}`]: entry[question],
            [`decileValue${entry.surveyId}`]: entry[question]===null?'': entry.Decile[index] ,
            label: entry.name,
          };

          groupedData.push(newGroup);
        } else {
          const existingGroup = groupedData[groupIndex];
          existingGroup[`survey${entry.surveyId}`] = entry[question];
          existingGroup[`decileValue${entry.surveyId}`] = entry[question]===null?'': entry.Decile[index];
        }
      });
    });

    return groupedData;
  };

  const groupByFilters = transformToGroupedData(sortedDataSet, questionNumber);

  const mapSurveyIds = (sortedData) => {
    const surveyIds = [];
    sortedData.forEach((entry) => {
      const surveyId = entry.surveyId;

      if (!surveyIds.includes(surveyId)) {
        surveyIds.push(surveyId);
      }
    });
    return surveyIds.sort((a, b) => a - b);
  };

  const surveyIds = mapSurveyIds(sortedDataSet);

  const dataKey = chartCategory === "All Staff" ? "name" : "group";

  const wrapperData =
    chartCategory === "All Staff" ? sortedDataSet : groupByFilters;

    console.log('data',groupByFilters);
  return (
    <>
      <div className='large-download-btn' style={{zIndex:10}}>
        <QuestionTooltip title='Download Chart' placement="top" arrow>
          {isLoader ? (
            <CircularProgress />
          ) : (
            <FileDownloadOutlinedIcon
              className='large-graph-btn'
              sx={{fontSize: '22px'}}
              onClick={(e)=>{e.preventDefault();downloadChart()}}
            />
          )}
       </QuestionTooltip>
      </div>          
      <PulseChartTicksWarpper
        data={wrapperData}
        dataKey={dataKey}
        chartType={chartType}
        chartTitle={chartTitle}
        chartCategory={chartCategory}
        questionNumber={questionNumber}
        sortByQuestionKey={sortByQuestionKey}
        headerWidth={headerWidth}
        headerRef={headerRef}
        sortQ={sortQ}
        sortingOrder={sortOrder}
        totalHeight={totalHeight}
      >
        <ScrollContainer
          alwaysShowTracks={true}    
        >
          <div className="horizontal-chart">
            <div className="stackbarchart">
              {questionNumber.map((question, index) => {
                const dataset =
                  chartCategory === "All Staff"
                    ? sortedDataSet
                    : groupByFilters.filter((q) => q.category === question);
                return (
                  <div key={question}>
                    <StickyHeader
                      qNumber={question}
                      index={index}
                      width={
                        questionNumber?.length === 1
                          ? 1050
                          : questionNumber?.length === 2
                          ? index === 0
                            ? 700
                            : 620
                          : index === 0
                          ? 300
                          : 312
                      }
                    />
                    <BarChart
                      width={
                        questionNumber?.length === 1
                          ? 1050
                          : questionNumber?.length === 2
                          ? index === 0
                            ? 700
                            : 620
                          : index === 0
                          ? 300
                          : 312
                      }                      
                      height={totalHeight}
                      layout="vertical"
                      data={dataset}
                      barSize={30}
                      margin={{ left: index === 0 ? 0 : 12 }}
                      fontWeight="bold"
                      fontFamily="Avenir LT Pro"
                      tick={{ fill: "#000", fontSize: 16 }}
                    >
                      <CartesianGrid horizontal={false} vertical={false} />
                      <XAxis type="number" hide={true} domain={[0, 5]} />
                      <YAxis
                        dataKey={dataKey}
                        type="category"
                        hide={true}
                        interval={"preserveEnd"}
                        width={110}
                        fontWeight="600"
                        fontFamily="Avenir LT Pro"
                        tick={{ fill: "#000", fontSize: 12 }}
                        tickLine={{ stroke: "none" }}
                      />

                      {chartCategory === "All Staff" ? (
                        <Bar
                          key={question}
                          dataKey={question}
                          stackId="1"
                          background={(props) => {
                            const { fill, dataKey, payload, ...rest } = props;
                            return payload.name === " " ? (
                              <rect {...rest} fill="white" />
                            ) : (
                              <rect {...rest} fill={fill} />
                            );
                          }}
                        >
                          {surveyIds.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colorsPicker[index % colorsPicker.length]}
                            />
                          ))}
                          <LabelList
                            dataKey={question}
                            position="insideMiddle"
                            fontFamily="Avenir LT Pro"
                            fill="#fff"
                            formatter={(value) => {
                              const currentDataIndex = dataset.findIndex(
                                (item) => item[question] === value
                              );
                              const decileValue =
                                dataset[currentDataIndex]?.Decile[index];
                              return `${value?.toFixed(2)} ${decileValue}`;
                            }}
                          />
                        </Bar>
                      ) : (
                        surveyIds.map((category, i) => (
                          <React.Fragment key={category}>
                            <Bar
                              dataKey={`survey${category}`}
                              stackId={`a${category}`}
                              fill={colorsPicker[i]}
                              fontFamily="Avenir LT Pro"
                              background={(props) => {
                                const { fill, dataKey, payload, ...rest } =
                                  props;
                                return payload.name === " " ? (
                                  <rect {...rest} fill="white" />
                                ) : (
                                  <rect {...rest} fill={fill} />
                                );
                              }}
                            >
                               <LabelList
                                dataKey={`survey${category}`}
                                position="center"
                                fill="#fff"
                                fontFamily="Avenir LT Pro"
                                formatter={(value, entry) => {
                                  return `${value?.toFixed(2)}`;
                                }}
                                fontSize={14}
                                margin={10}
                                dx={-17}
                              />
                              <LabelList
                                fontFamily="Avenir LT Pro"
                                dataKey={`decileValue${category}`}
                                position="insideCenter"
                                fill="#fff"
                                fontSize={12}
                                dx={17}
                                formatter={(value) => {
                                  if (value === null || value <= 0) {
                                    return null;
                                  }
                                  return value;
                                }}
                              /> 
                            </Bar>
                          </React.Fragment>
                        ))
                      )}
                    </BarChart>
                  </div>
                );
              })}
            </div>
          </div>
        </ScrollContainer>
      </PulseChartTicksWarpper> 
    </>
  );
};

const PulseStackedBarChart = ({
  data,
  chartType,
  chartTitle,
  chartCategory,
  surveyCount,
}) => {
  return (
    <div>
      <StackedBarChart
        data={data}
        chartType={chartType}
        chartTitle={chartTitle}
        chartCategory={chartCategory}
        surveyCount={surveyCount}
      />
    </div>
  );
};

export default PulseStackedBarChart;
