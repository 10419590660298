import React from 'react';

function ForgotPassword(props)
{
    return (
        <div>
            forgot
        </div>
    );
}

export default ForgotPassword;