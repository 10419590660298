import request from '../request';
import { questionBankApi } from '../Urls';

export default class questionBankService
{
    static async Get()
    {
        return request({
            url: questionBankApi.Get(),
        });
    }
}