import React, { useEffect, useState } from 'react';
import { ParticipantsList, PulseTextField, TableStyle, WhiteLayoutContent } from '../assets/styles/AppContainers';
import { Grid, Modal, Button, Table, TableHead, TableRow, TableCell, Checkbox, Snackbar, Alert, IconButton, FormGroup, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { participantsService, surveyService } from '../services';
import { ScrollContainer } from 'react-nice-scroll';
import { ArrowDownward, ArrowUpward, ImportExport } from '@mui/icons-material';

function SendInvitePopup({ token, onAction, onClose }) {
    const [pList, setPList] = useState([]);
    //  const [hasChanged, setChange] = useState(false);
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openSnak, setOpen] = useState(false);
    const [allParticipantsSelected, setAllParticipantsSelected] = useState(false);
    const [selectedText, setSelectedText] = useState('');
    const [pSavedList, setSavedPList] = useState([]);
    const [sortFName, setSortFName] = useState(true);
    const [sortLName, setSortLName] = useState(true);

    const SendInvites = () => {
        //if (hasChanged) {
        if (selected.length >= 1) {
            participantsService
                .UpdateParticipantsAndSendInvites({ token: token, schoolStaffIds: selected })
                .then(data => { onAction(); })
                .catch(e => { });
        }
        else {
            setOpen(true);
        }
        // }
        // else {
        //     if (pList.length >= 1) {
        //         surveyService.SendInvites(token)
        //         onAction();
        //     }
        //     else {
        //         setOpen(true);
        //     }
        // }
    }
    const checkUncheck = (e, participant) => {
        // setChange(true);
        if (e.target.checked) {
            setSelected([...selected, participant.schoolStaffId])
        }
        else {
            setSelected([...selected.filter(x => x !== participant.schoolStaffId)])
        }
    }
    useEffect(e => {
        participantsService.GetParticipants(token)
            .then(data => {
                if (data) {
                    let sortedList = [...data.sort((x, y) => (x.firstName + x.lastName).localeCompare(y.firstName + x.lastName))]
                    setPList(sortedList);
                    setSavedPList(sortedList);
                    setSelected([]);
                    setLoading(false);
                }
            })
            .catch(e => {
                setPList([]);
                setSavedPList([]);
                setSelected([]);
                setLoading(false);
            })
    }, [])

    useEffect(() => filterParticipants(), [selectedText])

    const filterParticipants = () => {
        if (selectedText !== '') {
            setPList([...pSavedList.filter(x => {
                return x.lastName.toLocaleLowerCase().indexOf(selectedText.toLocaleLowerCase()) > -1 || x.firstName.toLocaleLowerCase().indexOf(selectedText.toLocaleLowerCase()) > -1
            }
            )])
        }
        else {
            setPList([...pSavedList])
        }
    }


    const sortList = (column) => {

        let list = [...pSavedList];
        if (column === 'f') {
            list.sort((x, y) => (sortFName ? x.firstName.localeCompare(y.firstName) : y.firstName.localeCompare(x.firstName)))
            setSortFName(!sortFName)
            setSortLName(null)
        }
        else {
            list.sort((x, y) => (sortLName ? x.lastName.localeCompare(y.lastName) : y.lastName.localeCompare(x.lastName)))
            setSortLName(!sortLName)
            setSortFName(null)
        }
        setPList([...list])
    }


    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openSnak}
                onClose={() => { setOpen(false) }}
                autoHideDuration={2000}>
                <Alert onClose={() => { setOpen(false) }} severity="error" sx={{ width: '100%' }}>
                    Please select at least 1 participant to resend invite.
                </Alert>
            </Snackbar>
            <Modal open={true} className='warning-modal'>
                <WhiteLayoutContent>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            fontSize: '22px'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {loading ? 'Loading' :
                        <>
                            {pSavedList.length > 0 ? <Grid container padding={3}>
                                <Grid item lg={12} md={12} xs={12}>
                                    <h1>Invite Participants</h1>
                                    <p>Please select required participants.</p>
                                    <p>Note that reminders will only be sent to participants who have not yet taken the survey. </p>
                                </Grid>
                                <Grid item lg={12} md={12} xs={12}>
                                    <ScrollContainer>
                                        <ParticipantsList>
                                            <PulseTextField value={selectedText} label="Search" placeholder="Search" variant="outlined" onChange={(e) => setSelectedText(e.target.value)} />
                                            <TableStyle>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <FormGroup>
                                                                    <FormControlLabel control={
                                                                        <Checkbox
                                                                            checked={allParticipantsSelected}
                                                                            style={{ fontSize: 18, }}
                                                                            onChange={evt => {

                                                                                setAllParticipantsSelected(evt.target.checked)

                                                                                if (evt.target.checked) {
                                                                                    var staffIds = []
                                                                                    pList.map(p => staffIds.push(p.schoolStaffId))
                                                                                    if (staffIds.length > 0) {
                                                                                        setSelected(staffIds);
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    setSelected([]);
                                                                                }
                                                                            }}>
                                                                        </Checkbox>} label={'All'} >

                                                                    </FormControlLabel>
                                                                </FormGroup>
                                                            </TableCell>
                                                            <TableCell>First Name
                                                                <span style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => sortList('f')}>{sortFName === null ? (sortLName ? <ArrowUpward></ArrowUpward> : <ArrowDownward></ArrowDownward>) : <ImportExport></ImportExport>}</span>
                                                            </TableCell>
                                                            <TableCell>Last Name
                                                                <span style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => sortList('l')}>{sortLName === null ? (sortFName ? <ArrowUpward></ArrowUpward> : <ArrowDownward></ArrowDownward>) : <ImportExport></ImportExport>}</span>
                                                            </TableCell>
                                                            <TableCell>Email</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {
                                                        pList.map(e =>
                                                            <TableRow>
                                                                <TableCell> <Checkbox
                                                                    style={{ fontSize: 18, padding: 0, top: '-2px' }}
                                                                    checked={selected.findIndex(x => x === e.schoolStaffId) === -1 ? false : true}
                                                                    onChange={evt => checkUncheck(evt, e)}
                                                                ></Checkbox>  </TableCell>
                                                                <TableCell>{e.firstName}</TableCell>
                                                                <TableCell>{e.lastName}</TableCell>
                                                                <TableCell>{e.email}</TableCell>
                                                            </TableRow>)
                                                    }
                                                </Table>
                                            </TableStyle>
                                        </ParticipantsList>
                                    </ScrollContainer>
                                </Grid>
                            </Grid> :
                                <Grid container padding={3}>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <h3>All Participants have submitted their responses.</h3>
                                    </Grid>
                                </Grid>}
                            <Grid container paddingLeft={3} paddingRight={3} paddingBottom={3}>
                                <Grid item lg={6} md={6} xs={6}
                                    display="flex"
                                    justifyContent="flex-start">
                                    <Button variant="contained" className='prev-button' onClick={onClose} >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}
                                    display="flex"
                                    justifyContent="flex-end">
                                    {pList.length > 0 ? <Button variant="contained" className='next-button' onClick={SendInvites} >
                                        Send Invite
                                    </Button> : ''}
                                </Grid>
                            </Grid>
                        </>}
                </WhiteLayoutContent >
            </Modal>
        </>
    );
}

export default SendInvitePopup;