import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import { Radio } from '@mui/material';

const Colors = {
    mainbg: '#F0FBFF',
    text: '#000000',
    menu: '#6d6d6d',
    menuhoverbg: '#EDE7F6',
    pink: '#E84F6B',
    green: '#007A7A',
    purple: '#45338C',
    yellow: '#FFB205',
    skyblue: '#53B5E0',
    orange: '#FF5939',
    lightgrey: '#F2F2F2',
    white: '#ffffff',
    border: '#E2E2E2',
}

const AppContainer = styled('div')({
    width: '100%',
});

const MainContainer = styled('div')({
    height: '100%',
    display: 'flex',
});

const MenuContainer = styled('div')({
    width: '12.5rem',
    backgroundColor: '#ffffff',
    borderRadius: '0.5rem',
    boxShadow: '0 0 0.5rem 0 rgba(0,0,0,0.1)',
    marginRight: '0.938rem',
    padding: '2rem 1.25rem 1.25rem 1.25rem',
    position: 'fixed',
    height: '88.5vh',
    '@media only screen and (max-width: 1280px)': {
        borderRadius: '0',
        height: '100vh',
        padding: '0 1.25rem 0 1.25rem',
        '.logo': {
            paddingTop: '2rem'
        }

    }
});

const Navigation = styled('div')({
    '.logo': {
        display: 'flex',
        justifyContent: 'center',
        'img': {
            width: '50%',
        }
    },
    '.menu': {
        marginTop: '2rem',

        '.menu-item': {
            width: '100%',
            margin: '0.313rem 0',
            fontWeight: '500',
            fontSize: '0.938rem',
            'a': {
                color: '#6d6d6d',
                display: 'flex',
                padding: '0.688rem 0 0.688rem 1rem',
                borderRadius: '6.25rem',

                'svg': {
                    marginRight: '0.75rem',
                    fill: '#6d6d6d !important',
                    transition: 'all 0.3s ease-in-out',
                }
            },
            '.menu-dropdown': {
                justifyContent: 'space-between',
            },
            'span': {
                display: 'inline-flex',
                alignItems: 'center',
                color: '#000000 !important',
            },
            'a:hover, a:active': {
                color: '#000000',
                backgroundColor: '#EDE7F6',
                'svg': {
                    fill: '#000000 !important',
                },
            }
        }
    }
});

const BodyContainer = styled('div')({
    width: 'calc(100% - 12.5rem)',
    marginLeft: '16rem',
    '@media only screen and (max-width: 1024px)': {
        width: '100%',
        marginLeft: '0',
    }
});

const HeaderContainer = styled('div')({
    borderBottom: '#BBEDFF 1px solid',
    paddingBottom: '1.25rem',
    marginBottom: '1.5rem',
    h1: {
        marginTop: '0',
        fontSize: '1.375rem',
        fontWeight: '800'
    },
    span: {
        fontSize: '0.938rem',
        marginBottom: '1.25rem',
        display: 'inline-block',
    },
    '.user-data': {
        '.user-name': {
            fontWeight: '800',
        },
        '.user-email': {
            fontSize: '0.875rem',
            marginTop: '0.313rem',
        }
    },
    '.header-school-title': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0',
        height: '100%'
    }
});

const LoginContainer = styled('div')({
    justifyContent: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '.login-logo': {
        marginBottom: '1.875rem',
        'img': {
            width: 'auto',
            height: '3.75rem',
        }
    },
    '.login': {
        padding: '2.5rem',
        'p': {
            marginTop: '0.313rem !important',
            marginBottom: '0.938rem !important',
            color: '#6d6d6d',
        }
    }
});

const RequestAssistance = styled('div')({
    backgroundColor: '#ffffff',
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '0.25rem',
    boxShadow: '0 0 0.5rem 0 rgba(0,0,0,0.1)',
    textAlign: 'center',
    lineHeight: '3.2rem'
});

const LayoutContent = styled('div')({
    height: '100%',
});

const Surveys = styled('div')({
    backgroundColor: '#ffffff',
    marginTop: '0.938rem',
    boxShadow: '0 0 0.5rem 0 rgba(0,0,0,0.1)',
    borderRadius: '0.25rem',
    '.survey-status': {
        display: 'inline-block',
        color: '#ffffff',
        padding: '0.313rem 0.625rem',
        fontSize: '0.813rem',
        borderRadius: '0 0 0.25rem 0.25rem',
        marginLeft: '0.938rem',
    },
    '.survey': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '0.938rem',
        paddingRight: '0.938rem',
        flexWrap: 'wrap',
        '.survey-name': {
            flexGrow: '2',
            'h3': {
                marginTop: '0.75rem',
                marginBottom: '0',
                fontSize: '1.125rem',
            },
            'p': {
                margin: '0',
                fontSize: '0.813rem',
                paddingTop: '0.25rem',
            },
        },
        '.survey-extra': {
            display: 'flex',
            flexDirection: 'row',
            gap: '1.875rem',
            textAlign: 'center',
            fontSize: '0.75rem',
            fontWeight: '600',
            '.survey-link, .survey-options': {
                flexDirection: 'column',
                cursor: 'pointer',
            },
            '@media only screen and (max-width: 1023px)': {
                margin: '1rem 0 0 0',
            }
        },
        '@media only screen and (max-width: 1023px)': {
            justifyContent: 'center'
        }
    },
    '.participants': {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #dcdcdc',
        padding: '0.625rem 0.938rem',
        fontSize: '0.813rem',
        color: '#6d6d6d',
        borderBottomLeftRadius: '0.25rem',
        borderBottomRightRadius: '0.25rem',
    },    
});

const WhiteLayoutContent = styled('div')({
    backgroundColor: '#ffffff',
    borderRadius: '0.5rem',
    boxShadow: '0 0 0.5rem 0 rgba(0,0,0,0.1)',
    position: 'relative',
    '.recharts-legend-item': {
        display: 'inline-flex !important',
        alignItems: 'center'
    }
});

const SurveyLayoutContent = styled('div')({
    backgroundColor: '#ffffff',
    borderRadius: '0.5rem',
    padding: '1.25rem',    
    boxShadow: '0 0 0.5rem 0 rgba(0,0,0,0.1)',         
    '.question': {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '0.85rem',
        'span': {
            fontWeight: '500',
            marginTop: '0.188rem',
            marginBottom: '0.313rem',
        }
    },
    '.circular-progress': {
        width: '15rem',
        //height: '15rem',
        margin: '2rem auto 0 auto',
        textAlign: 'center',
        '.btn-participation':{
            marginTop: '1rem',
        }
    },
    '.survey-status': {
        display: 'flex',        
        flexDirection: 'column',
        rowGap: '0.8rem',        
        'h2': {
            marginRight: '0.5rem',
            paddingBottom: '0',
        },
        '.status': {
            fontSize: '0.875rem',
            padding: '0.313rem 0.938rem',
            color: '#ffffff',
            borderRadius: '3.125rem',
        },        
    },
    '.survey-data': {
        background: '#E0F7FF',
        padding: '1.25rem',
        marginTop: '1.25rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        'div': {
            display: 'inline-flex',
            flexDirection: 'column',
            textAlign: 'center',
            fontWeight: '900',
            fontSize: '1.625rem',
            'span': {
                fontWeight: '500',
                fontSize: '0.875rem',
            }
        }
    },
    '.reopen-btn': {
        display: 'flex',
        justifyContent: 'end',
    },
    '.comments-scroll': {
        height: '52vh',        
    },
    '.comments-fixed': {
        maxHeight: '400px',
    },
    '.survey-content': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
    },
    '.survey-datetime':{
        fontSize: '0.875rem',  
        background: '#EDE7F6',
        padding: '0.938rem',
        borderRadius: '0.25rem',         
        '@media only screen and (min-width: 1023px)': {
            position: 'absolute', 
        }     
    }, 
    '@media only screen and (max-width: 1023px)': {
        height: 'auto !important',
    },  
});

const ScoreLayoutContent = styled('div')({        
    '.question-scores':{        
        border: '1px solid #E2E2E2',
        backgroundColor: '#ffffff',
        marginBottom: '15px',        
        borderRadius: '0.5rem',
        //boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.1)',
        '.question-text':{
            fontSize:'22px',
            fontWeight: '500',
            marginBottom: '20px',
            marginLeft: '80px',
            'span':{
                color: '#45338C',
                fontWeight: '800', 
                marginBottom: '5px',               
                fontSize:'22px',
            },
            '@media only screen and (max-width: 1023px)': {
                marginLeft: '0',                
            },
        },
        ':last-child':{
            marginBottom: '0',
        },
    },
    '.scores':{
        display: 'flex', 
        flexDirection: 'row',
        marginBottom: '5px',
        width: '100%',
        fontSize: '12px',
    },
    '.overall':{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderLeft: '1px solid #e2e2e2',
        marginLeft: '30px',
        height: '100%',
        backgroundColor: '#F2F2F2',
        borderTopRightRadius: '0.5rem',
        borderBottomRightRadius: '0.5rem',        
        '.overall-title':{
            fontSize: '14px',
            marginTop: '5px',
        },
        '.overall-score':{
            fontSize: '30px',
            fontWeight: '700'
        },
        '@media only screen and (max-width: 1023px)': {
            marginLeft: '0',
            height: '100px',
        }
    },
    '.gradient-bar':{
        height: 48,
        display: 'flex',
        // border: '1px solid #000000',
        // borderTop: 'none',
        // borderRight: 'none',            
        width: '100%'  
    },
    '.barlabels':{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginTop: '10px',
        borderTop: '1px solid #000000',            
        '.label-percent':{
            height: 2,
            paddingTop: 10,
            float: 'left',
            textAlign: 'end',
            borderRightColor: 'black',
            borderRightStyle: 'solid',
            borderRightWidth: 1,
            fontSize: '12px',            
        }
    },
    '.legends':{
        display: 'flex',
        flexDirection: 'row',
        gap: '6px',
        marginBottom: '10px',
        marginTop: '10px',
        alignItems: 'center',
        '.legend-color':{
            width: 14,
            height: 14,
            borderRadius: '50%',
        },
        '.never':{            
            backgroundColor: '#ff0000',            
        },
        '.seldom':{            
            backgroundColor: '#FF7E35',
        },
        '.sometimes':{
            backgroundColor: '#FFCF05',
        },
        '.often':{
            backgroundColor: '#7DB93D',
        },
        '.always':{
            backgroundColor: '#5F902A',
        },
    },
});

const CommentsMeta = styled('div')({
    paddingTop: '0.313rem',
    marginLeft: '2.5rem',
    '.commentmeta': {
        display: 'flex',
        justifyContent: 'space-between',
    },
    '.staff-replied': {
        marginLeft: '0.5rem',
        fontSize: '0.75rem',
        fontWeight: '600'
    },
    '.comments-replies': {
        lineHeight: '1.125rem',
        marginTop: '0.313rem'
    },
    '.comment-reply-staff': {
        margin: '0.125rem auto',
        backgroundColor: '#F2F2F2',
        padding: '0.938rem',
        position: 'relative',
        width: '97%',
        borderRadius: '0.25rem',
        borderTopLeftRadius: '0',
        textAlign: 'left',
    },
    '.comment-reply-admin': {
        margin: '0.25rem auto',
        backgroundColor: '#d4f8ff',
        padding: '0.938rem',
        position: 'relative',
        width: '97%',
        borderRadius: '0.25rem',
        borderTopRightRadius: '0',
        textAlign: 'right',
    },
    '.comment-reply-staff:after': {
        content: '""',
        position: 'absolute',
        left: '-1rem',
        top: '0',
        width: '0',
        height: '0',
        borderTop: '0 solid transparent',
        borderBottom: '1.063rem solid transparent',
        borderRight: '1.063rem solid #F2F2F2',
    },
    '.comment-reply-admin:before': {
        content: '""',
        width: '0',
        height: '0',
        position: 'absolute',
        right: '-1rem',
        top: '0',
        borderTop: '0 solid transparent',
        borderBottom: '1.063rem solid transparent',
        borderLeft: '1.063rem solid #d4f8ff',
    },
    '.admin-avatar': {                
        color: '#000000',
        fontSize: '0.813rem',
        marginLeft: '0.5rem',
        textAlign: 'left',        
    },
    '.staff-avatar': {                
        color: '#000000',
        fontSize: '0.813rem',        
    },
});

const TableStyle = styled('div')(
    ({ theme }) => `
	table {	
        font-size: 0.835rem !important;
        border-collapse: collapse;
        width: 100%;        
        margin-bottom: 0 !important;
        margin-top: 0.625rem;
        border: #E2E2E2 1px solid;
	}
    th {	    
        text-align: left;                
        background-color: #53B5E0;
        color: #ffffff;
        padding: 0.5rem !important; 
	}      
	td{
        padding: 0.375rem 0.5rem !important; 	    
        text-align: left;
        font-weight: 500;
	}    
	tr:nth-of-type(odd){
		background-color: ${theme.palette.action.hover};
        border-top: 1px solid #e2e2e2;        
        border-bottom: 1px solid #e2e2e2;        
	}
	`,
);

const PulseTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        'fieldset': {
            borderColor: 'rgba(0,0,0,0.23)',
            height: '3rem',
        },
        '&:hover fieldset': {
            borderColor: '#53B5E0',
        },
        '&:focus fieldset': {
            borderColor: '#53B5E0',
        },
    },
    '& input': {
        fontSize: '0.875rem',
        paddingTop: '0.825rem'
    },
    '& label': {
        color: '#888888',
        fontSize: '0.875rem',
        lineHeight: '0.813rem',
    },
    '& label.Mui-focused': {
        color: '#53B5E0',
    }
});

const SurveyBlueRadio = styled(Radio)({
    appearance: 'none',
    WebkitAppearance: 'none',
    padding: '0',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: '2px solid transparent',
    marginBottom: '1.563rem',
    marginTop: '1.563rem',
    '& .MuiFormControlLabel-label': {
        fontSize: '1.5rem !important',
        fontWeight: '500 !important',
    },
    '&::after': {
        content: '""',
        display: 'block',
        borderRadius: '50%',
    },
    '& svg': {
        width: '8.125rem',
        height: '8.125rem',
        '& path': {
            fill: 'transparent',
        },
        '@media only screen and (max-width: 1023px)': {
            width: '6.25rem',
            height: '6.25rem',
        },
    },
    '&.Mui-checked': {
        'svg': {
            backgroundColor: '#53B5E0',
            borderRadius: '50%',
            'path': {
                fill: '#53B5E0 !important',
                border: '2px solid #53B5E0',
            },
        },
    },
    ':hover': {
        border: '2px solid #53B5E0'
    },
});
const SurveyRedRadio = styled(Radio)({
    appearance: 'none',
    WebkitAppearance: 'none',
    padding: '0',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: '2px solid transparent',
    marginBottom: '1.563rem',
    marginTop: '1.563rem',
    '& .MuiFormControlLabel-label': {
        fontSize: '1.5rem !important',
        fontWeight: '500 !important',
    },
    '&::after': {
        content: '""',
        display: 'block',
        borderRadius: '50%',
    },
    '& svg': {
        width: '8.125rem',
        height: '8.125rem',
        '& path': {
            fill: 'transparent',
        },
        '@media only screen and (max-width: 1023px)': {
            width: '6.25rem',
            height: '6.25rem',
        },
    },
    '&.Mui-checked': {
        'svg': {
            backgroundColor: '#FF5939',
            borderRadius: '50%',
            'path': {
                fill: '#FF5939 !important',
                border: '2px solid #FF5939',
            },
        },
    },
    ':hover': {
        border: '2px solid #FF5939'
    },
});
const SurveyGreenRadio = styled(Radio)({
    appearance: 'none',
    WebkitAppearance: 'none',
    padding: '0',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: '2px solid transparent',
    marginBottom: '1.563rem',
    marginTop: '1.563rem',
    '& .MuiFormControlLabel-label': {
        fontSize: '1.5rem !important',
        fontWeight: '500 !important',
    },
    '&::after': {
        content: '""',
        display: 'block',
        borderRadius: '50%',
    },
    '& svg': {
        width: '8.125rem',
        height: '8.125rem',
        '& path': {
            fill: 'transparent',
        },
        '@media only screen and (max-width: 1023px)': {
            width: '6.25rem',
            height: '6.25rem',
        },
    },
    '&.Mui-checked': {
        'svg': {
            backgroundColor: '#007A7A',
            borderRadius: '50%',
            'path': {
                fill: '#007A7A !important',
                border: '2px solid #007A7A',
            },
        },
    },
    ':hover': {
        border: '2px solid #007A7A'
    },
});

const SurveyYellowRadio = styled(Radio)({
    appearance: 'none',
    WebkitAppearance: 'none',
    padding: '0',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: '2px solid transparent',
    marginBottom: '1.563rem',
    marginTop: '1.563rem',
    '& .MuiFormControlLabel-label': {
        fontSize: '1.5rem !important',
        fontWeight: '500 !important',
    },
    '&::after': {
        content: '""',
        display: 'block',
        borderRadius: '50%',
    },
    '& svg': {
        width: '8.125rem',
        height: '8.125rem',
        '& path': {
            fill: 'transparent',
        },
        '@media only screen and (max-width: 1023px)': {
            width: '6.25rem',
            height: '6.25rem',
        },
    },
    '&.Mui-checked': {
        'svg': {
            backgroundColor: '#ffcf05',
            borderRadius: '50%',
            'path': {
                fill: '#ffcf05 !important',
                border: '2px solid #ffcf05',
            },
        },
    },
    ':hover': {
        border: '2px solid #ffcf05'
    },
});

const SurveyPinkRadio = styled(Radio)({
    appearance: 'none',
    WebkitAppearance: 'none',
    padding: '0',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: '2px solid transparent',
    marginBottom: '1.563rem',
    marginTop: '1.563rem',
    '& .MuiFormControlLabel-label': {
        fontSize: '1.5rem !important',
        fontWeight: '500 !important',
    },
    '&::after': {
        content: '""',
        display: 'block',
        borderRadius: '50%',
    },
    '& svg': {
        width: '8.125rem',
        height: '8.125rem',
        '& path': {
            fill: 'transparent',
        },
        '@media only screen and (max-width: 1023px)': {
            width: '6.25rem',
            height: '6.25rem',
        },
    },
    '&.Mui-checked': {
        'svg': {
            backgroundColor: '#f5abcc',
            borderRadius: '50%',
            'path': {
                fill: '#f5abcc !important',
                border: '2px solid #f5abcc',
            },
        },
    },
    ':hover': {
        border: '2px solid #f5abcc'
    },
});

const QuestionsTextField = styled(TextField)({
    fontSize: '0.813rem',
    fontWeight: '500',
    background: '#ffffff',
    '& .MuiOutlinedInput-root': {
        'fieldset': {
            borderColor: 'rgba(0,0,0,0.23)',
        },
        '&:hover fieldset': {
            borderColor: '#53B5E0',
        },
        '&:focus fieldset': {
            borderColor: '#53B5E0',
        },
    },
    '& input': {
        //fontWeight: '500',        
    },
    '& label': {
        color: '#888888',
    },
    '& label.Mui-focused': {
        color: '#53B5E0',
    }
});

const QuestionsSelect = styled(Select)({
    fontSize: '0.813rem',
    fontWeight: '500',
    background: '#ffffff',
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0,0,0,0.23)',
        zIndex: '0',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#53B5E0',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#53B5E0',
    },
    '& label.Mui-focused': {
        color: '#888888',
        fontWeight: '800',
    },
});

const FilterSelect = styled(Select)({
    fontSize: '0.813rem',
    fontWeight: '500',
    '& .MuiInputBase-input': {
        position: 'relative',
        fontSize: '0.813rem',
        padding: '0.688rem 1.625rem 0.688rem 0.75rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#53B5E0 !important',
    },
    '& svg': {
        fill: '#53B5E0',
        zIndex: '999',
        width: '1.5em',
        height: '1.5em',
        top: 'calc(50% - 0.75em)'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#53B5E0',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#53B5E0',
    },
});

const SurveySelect = styled(Select)({
    fontSize: '0.875rem',
    fontWeight: '500',
    width: '265px',    
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#007A7A',
        backgroundColor: '#007A7A',
        fontSize: '0.875rem !important',
        fontWeight: '500',
        color: '#ffffff',
        zIndex: '0',
    },
    '& .MuiInputBase-input': {
        color: '#ffffff',
        zIndex: '999',
        padding: '0.75rem 0.875rem !important'
    },
    '& svg': {
        fill: '#ffffff',
        zIndex: '999',
        width: '1.5em',
        height: '1.5em',
        top: 'calc(50% - 0.75em)'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#007A7A',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#007A7A',
    },
});


const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(0, 0, 0, 1)',

        '&:hover': {
            color: '#000000',
            backgroundColor: '#d1eaff',
        },
        '&.Mui-selected': {
            color: '#000000',
            fontWeight: '600',
            backgroundColor: '#d1eaff',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

const ParticipantsList = styled('div')({
    maxHeight: '460px',
    'table': {
        marginTop: '0 !important'
    }
});

const Preview = styled('div')({
    //height: '120px',
    'table': {
        marginTop: '0 !important'
    }
});

const PulseHeatmap = styled('div')({
    float: 'left',
    width: '100%',
    '.heatmap-grid': {
        width: '100%',
        float: 'left',
    },
    '.heatmap-questions': {
        width: '20%',
        float: 'left',
        'ul': {
            listStyle: 'none',
            padding: '0',
            margin: '0',
            width: '100%',
            'li': {
                height: '2.5rem',
                flex: '0 0 auto',
                alignItems: 'center',
                background: '#ffffff',
                margin: '0 0 0.25rem',
                paddingLeft: '0.75rem',
                paddingRight: '0.75rem',
                fontWeight: '600',
                border: '1px solid rgb(226, 226, 226)',
                display: 'flex',
                fontSize: '0.75rem',
                textAlign: 'left',
                overflow: 'hidden',
            },
        },

    },

    '.filter-dropdown': {
        width: '20%',
        float: 'left',
        'ul': {
            listStyle: 'none',
            padding: '0',
            margin: '0',
            width: '100%',
            'li': {
                flex: '0 0 auto',
                alignItems: 'center',
                margin: '0 0 0.25rem',
                display: 'flex',
                fontSize: '0.813rem',
                textAlign: 'left',
                'label': {
                    paddingTop: '0.188rem',
                    color: '#53B5E0',
                    fontWeight: '500'
                },
            },
        },
    },
    '.heatmap-filters': {
        width: '80%',
        float: 'left',
        'ul': {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            listStyle: 'none',
            padding: '0',
            margin: '0',
            'li': {
                flex: '1 1 0',
                fontSize: '0.75rem',
                height: '2.5rem',
                fontWeight: 'bold',
                background: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                border: '1px solid rgb(226, 226, 226)',
                margin: '0 0 0.25rem 0.25rem',
                '@media only screen and (max-width: 768px)': {
                    display: 'inline-grid',
                }
            }
        },
        '.cell': {
            height: '2.5rem',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: '0.813rem',
        },
        '.redcell': {
            backgroundColor: '#f7b2a5',
        },
        '.greencell': {
            backgroundColor: '#84C1C3',
        },
    },
    '.filtercell ul li': {
        backgroundColor: 'rgba(83, 181, 224, 0.25)',
        border: '1px solid rgba(83, 181, 224, 0.32)',
        '.sort-btn, .south-sort-btn, .north-sort-btn': {
            backgroundColor: '#53b5e0',
            width: '0.938rem',
            height: '0.938rem',
            borderRadius: '50%',
            marginLeft: '0.625rem',
            padding: '0.125rem',
            color: '#ffffff',
            fontSize: '1rem',
            cursor: 'pointer',
            '@media only screen and (max-width: 768px)': {
                width: '0.75rem',
                height: '0.75rem',
                fontSize: '0.875rem',
                marginLeft: '0',
            }
        },
        '.south-sort-btn, .north-sort-btn, .sort-btn:hover': {
            backgroundColor: '#E84F6B',
        }

    },
    '.decile': {
        fontSize: '0.688rem',
        top: '-0.125rem',
        left: '0.125rem',
        position: 'relative'
    },
    '.heatmap-header': {
        width: '100%',
        float: 'left',
        'ul': {
            listStyle: 'none',
            padding: '0',
            margin: '0 0 0.25rem 0',
        },
        'ul li': {
            backgroundColor: 'rgba(83, 181, 224, 0.25)',
            border: '1px solid rgba(83, 181, 224, 0.32)',
            height: '2.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            fontSize: '0.813rem',
            fontWeight: '600',
            paddingLeft: '0.75rem',
            textTransform: 'uppercase'
        }
    },    
    'div.heatmap-sticky': {
        maxHeight: ' 400px',
        //border: '0.1px solid transparent',
         overflow: 'overlay',
        // scrollbarWidth: 'thin',
        // scrollbarColor: 'blue orange',        
        float: 'left',   
        width: '100%',        
    },

    'div.heatmap-sticky::-webkit-scrollbar': {
        background: 'rgba(222, 222, 222, .75)',
        width: '8px',        
    },
    // 'div.heatmap-sticky::-webkit-scrollbar-track': {        
    //     borderRadius: '0px',
    // },
    'div.heatmap-sticky::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        background: 'rgba(0, 0, 0, .5)',        
    },    
    // 'div.heatmap-sticky:hover::-webkit-scrollbar': {
    //     background: 'lightgrey',
    // },

    'div.heatmap-sticky::-moz-scrollbar': {
        background: 'rgba(222, 222, 222, .75)',
        width: '8px',  
    },
    // 'div.heatmap-sticky::-moz-scrollbar-track': {
    //     boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    //     borderRadius: '0.625rem',
    // },
    'div.heatmap-sticky::-moz-scrollbar-thumb': {
        borderRadius: '8px',
        background: 'rgba(0, 0, 0, .5)',
        position: 'absolute',
    },
    // 'div.heatmap-sticky:hover::-moz-scrollbar': {
    //     background: 'lightgrey',
    // },


    '.flex-custom-heatmap': {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        columnGap: '0.625rem',
        width: '79.75%',
        float: 'right',
        'form': {
            display: 'flex',
            flexDirection: 'row',
            columnGap: '0.625rem',
        },
        '.custom-filter-heatmap-dropdown': {
            width: '100% !important',
        },
        '.MuiFormLabel-root': {
            fontWeight: '500',
            fontSize: '0.813rem',
            top: '0.063rem',
            color: '#808080'
        },
        '@media only screen and (max-width: 1023px)': {
            width: '100%',
            flexDirection: 'column',
            marginBottom: '0.625rem',
        }
    },

    '.custom-heatmap-dropdown': {
        width: '23.6vw',
        float: 'left',
        fontSize: '0.875rem',
        marginBottom: '1.25rem',
        fontWeight: '500',
        textAlign: 'left',
        background: '#fff !important',
        minHeight: '38px !important',
    },
    '.heatmap-fixed': {
        width: '100%',
        maxHeight: '330px',
        '@media only screen and (min-width: 1920px)': {
            maxHeight: '510px',
        },
    },
    '.ns-container': {
        maxHeight: '330px',
        margin: '0 !important',
        overflow: 'hidden !important',
        '@media only screen and (min-width: 1920px)': {
            maxHeight: '510px',
        },
    },
    '.saved-heatmap': {
        background: '#ffffff',
        minHeight: '38px',
        fontWeight: '600',
        '@media only screen and (max-width: 1023px)': {
            width: '100% !important',
        }
    },
    '.search-filter-dd':{
        width: '75%',
        '@media only screen and (max-width: 1023px)': {
            width: '100%',
            marginBottom: '0.625rem',
        }
    },
    '.save-filter-dd':{
        width: '35%',
        '@media only screen and (max-width: 1023px)': {
            width: '100%',
            marginBottom: '0.625rem',
        }
    }

});

const PulseMATMenu = styled('div')({
    background: 'rgba(83, 181, 224, 0.3)',
    border: '1px solid rgba(83, 181, 224, 0.44)',
    margin: '0.938rem 0',
    padding: '0.625rem 0.625rem 0.625rem 0',
    borderRadius: '0.5rem',
    'div > a': {
        color: '#000000',
        fontWeight: 500,
        padding: '0.625rem',
        width: '100%',
        ':hover': {
            background: '#53B5E0',
            color: '#ffffff',
            borderRadius: '0.375rem',
        },
    },
    'div > .active': {
        color: '#ffffff',
        background: '#53B5E0',
        borderRadius: '0.375rem',
    },       
});

export {
    Colors,
    AppContainer,
    MainContainer,
    MenuContainer,
    Navigation,
    BodyContainer,
    HeaderContainer,
    LayoutContent,
    WhiteLayoutContent,
    RequestAssistance,
    TableStyle,
    PulseTextField,
    StyledTab,
    Surveys,
    QuestionsTextField,
    QuestionsSelect,
    ParticipantsList,
    SurveyLayoutContent,
    SurveySelect,
    PulseHeatmap,
    Preview,
    CommentsMeta,
    LoginContainer,
    SurveyBlueRadio,
    SurveyRedRadio,
    SurveyGreenRadio,
    SurveyYellowRadio,
    SurveyPinkRadio,
    FilterSelect,
    PulseMATMenu,
    ScoreLayoutContent
};