import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { folderService } from '../../services';


const initialState = {
  folders: [],
  loading: false
}


export const getFolders = createAsyncThunk('folders.getFolders', async ({ orgId, isMat }) => {

  return await folderService.GetFoldersBySchoolId(orgId, isMat);
});

export const createtFolder = createAsyncThunk('folders.createtFolder', async ({ orgId, isMat, name }) => {

  return folderService.CreateFolderBySchoolId(orgId, isMat, name);
});
export const folderSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    updateSchoolFolder: (state, action) => {
      let idx = state.folders.findIndex(x => x.id === action.payload.id);
      if (idx > -1) {
        state.folders[idx].name = action.payload.name;
      }
    },
    deleteSchoolFolder: (state, action) => {

      let idx = state.folders.findIndex(x => x.id === action.payload.id);
      if (idx >= 0) {
        state.folders.splice(idx, 1);
      }
    },
  },
  extraReducers:
  {
    [createtFolder.pending]: (state) => {
      state = { loading: true, ...state }
    },
    [createtFolder.fulfilled]: (state, action) => {

      return {
        folders: state.folders ? [...state.folders, action.payload] : [action.payload],
        loading: false
      }
    },
    [createtFolder.rejected]: (state) => {

      state = { loading: false, ...state }
    },
    [getFolders.pending]: (state) => {
      state = { loading: true, ...state }
    },
    [getFolders.fulfilled]: (state, action) => {

      return {
        folders: [...action.payload],
        loading: false
      }
    },
    [getFolders.rejected]: (state) => {

      state = { loading: false, ...state }
    },
  }

})

export const { updateSchoolFolder, deleteSchoolFolder } = folderSlice.actions;
export default folderSlice.reducer 