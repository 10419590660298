import request from '../request';
import { emailTemplateApi } from '../Urls';

export class emailTemplateService {
    static async GetGetListOfOrgEmailTemplates(id) {
        return request({
            url: emailTemplateApi.GetGetListOfOrgEmailTemplates(id),
        });
    }
    static async AddEmailTemplate(data) {
        return request({
            url: emailTemplateApi.AddEmailTemplate(),
            method: 'POST',
            data: data
        });
    }
    static async UpdateEmailTemplate(data) {
        return request({
            url: emailTemplateApi.UpdateEmailTemplate(data.id),
            method: 'PUT',
            data: data
        });
    }
    static async DeleteEmailTemplate(id) {
        return request({
            url: emailTemplateApi.DeleteEmailTemplate(id),
            method: 'DELETE',
        });
    }
}