import React, { useEffect, useState } from "react";
import { Grid, Radio, FormControlLabel, RadioGroup, Button, TextField } from "@mui/material";
import { AppContainer, PulseTextField, QuestionsTextField } from "../../../assets/styles/AppContainers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider, TimePicker, } from "@mui/x-date-pickers";
import Holidays from 'date-holidays';
import { DeleteOutline } from "@mui/icons-material";
import { getDate } from "date-fns";
import enGB from 'date-fns/locale/en-GB';

function ScheduleForm({ Survey, onSurveyChange, OnHasUpdate, onAutoSave }) {
  const [selectedDuration, setSelectedDuration] = useState(1);
  const [counter, setCounter] = useState(1);
  const [customDuration, setCustomDuration] = useState([{ label: 'Pulse 1', startDate: null, startHour: null, startMin: null, endDate: null, endHour: null, endMin: null }]);
  const [frequency, setFrequency] = useState(2)
  const [noOfWorkingDays, setNoOfWorkingDays] = useState(5)

  const holidays = new Holidays('GB');
  const hd = holidays.getHolidays();

  useEffect(() => {
    setSelectedDuration(Survey.repeatDuration)
    let crons = []
    crons = Survey?.customRepeatCron ? JSON.parse(Survey?.customRepeatCron) : null
    if (crons == null) {
      crons = [{ label: 'Pulse 1', startDate: null, startHour: null, startMin: null, endDate: null, endHour: null, endMin: null }]
    }
    setCustomDuration(crons);
    setCounter(crons.length)
    if (Survey.noOfWorkingDays) {
      setNoOfWorkingDays(Survey.noOfWorkingDays)
    }
    if (Survey.frequency) {
      setFrequency(Survey.frequency)
    }
  }, []);

  const onDurationSelectionChange = (opt) => {
    let surv = Survey;
    surv.repeatDuration = parseInt(opt);

    setSelectedDuration(opt);
    if (surv.repeatDuration === 2) {
      setNoOfWorkingDays(5)
      surv.noOfWorkingDays = 5;
    }
    if (surv.repeatDuration === 3) {
      setNoOfWorkingDays(5)
      surv.noOfWorkingDays = 5;
    }
    if (surv.repeatDuration === 4) {
      setNoOfWorkingDays(5)
      surv.noOfWorkingDays = 5;
    }
    onSurveyChange(surv)
    OnHasUpdate(true)
    onAutoSave()
  };

  const addNewRow = () => {
    setCounter(counter + 1);
    setCustomDuration([...customDuration, { label: 'Pulse ' + (counter + 1), startDate: null, startHour: null, startMin: null, endDate: null, endHour: null, endMin: null }]);
  }
  const deleteRow = (id) => {
    let surveyVal = Survey;
    if (counter > 0) {
      setCounter(counter - 1);
      let cd = customDuration;
      cd.splice(id, 1)
      setCustomDuration([...cd]);
      surveyVal.customRepeatCron = JSON.stringify([...cd]);
      surveyVal.startDate = cd.length > 0 ? cd[0].startDate : null
      surveyVal.startHour = cd.length > 0 ? cd[0].startHour : null
      surveyVal.startMin = cd.length > 0 ? cd[0].startMin : null

      surveyVal.endDate = cd.length > 0 ? cd[cd.length - 1].endDate : null
      surveyVal.endHour = cd.length > 0 ? cd[cd.length - 1].endHour : null
      surveyVal.endMin = cd.length > 0 ? cd[cd.length - 1].endMin : null
    }
    onSurveyChange({ ...surveyVal })
    OnHasUpdate(true)
    onAutoSave()
  }

  const setCustomDateandTime = (i, sdate, stime, edate, etime) => {
    let surveyVal = Survey;
    let customDateTime = customDuration;
    if (sdate !== null) {
      customDateTime[i].startDate = sdate;
      if (i === 0) {
        surveyVal.startDate = sdate;
        //edate = sdate;
      }
    }
    if (stime !== null) {
      let shour = stime.getHours();
      let smin = stime.getMinutes();

      customDateTime[i].startHour = shour;
      customDateTime[i].startMin = smin;
      if (i === 0) {
        surveyVal.startHour = shour;
        surveyVal.startMin = smin;
      }
    }
    if (etime !== null) {
      let ehour = etime.getHours();
      let emin = etime.getMinutes();

      customDateTime[i].endHour = ehour;
      customDateTime[i].endMin = emin;

      if (i === counter - 1) {
        surveyVal.endHour = ehour;
        surveyVal.endMin = emin;
      }
    }


    if (edate != null) {
      customDateTime[i].endDate = edate;
      if (i === counter - 1) {
        surveyVal.endDate = edate;
      }
    }

    setCustomDuration([...customDateTime])

    surveyVal.customRepeatCron = JSON.stringify(customDateTime);
    onSurveyChange({ ...surveyVal })
    OnHasUpdate(true)
    onAutoSave()
  }

  const getTimeValue = (d, h, m) => {
    if (h !== null && m !== null) {
      let dateVal = d !== null ? new Date(d) : new Date();
      dateVal.setHours(h)
      dateVal.setMinutes(m);
      return dateVal
    }

  }
  const getDateValue = (d) => {
    if (d !== null)
      return new Date(d);
  }
  const diasablePastTime = (date) => {
    if (date === null || date === undefined) {
      return true;
    }
    if (typeof (date) === 'string') { date = new Date(date) }

    if (date.toDateString() === (new Date()).toDateString() && (new Date()).getHours() > 16) {
      return true;
    }
    const currDate = new Date()
    if ((new Date(date)).getDate() === currDate.getDate() && (new Date(date)).getMonth() === currDate.getMonth() && (new Date(date)).getFullYear() === currDate.getFullYear()) {
      return true;
    }
    return false;
  }
  return (
    <AppContainer>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <Grid container padding={3}>
          <Grid item lg={12} md={12} xs={12}>
            <h2>Survey Schedule</h2>
          </Grid>

          <Grid item lg={12} md={12} xs={12}>
            <RadioGroup row
              name="qOptions"
              value={selectedDuration}
              onChange={(e) => onDurationSelectionChange(e.target.value)}
            >
              <FormControlLabel value={'1'} control={<Radio />} label="One-Off" />
              <FormControlLabel value={'2'} control={<Radio />} label="Weekly" />
              <FormControlLabel value={'3'} control={<Radio />} label="Fortnightly" />
              <FormControlLabel value={'4'} control={<Radio />} label="Monthly" />
              <FormControlLabel value={'5'} control={<Radio />} label="Custom" />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container paddingLeft={3} paddingRight={3} marginBottom={5}>
          {Array.from(Array(selectedDuration === '5' || selectedDuration === 5 ? counter : 1)).map((c, index) => {
            return <Grid container spacing={2} sx={{ mb: 2 }}>

              <Grid item lg={2} md={2} sm={12} xs={12}>
                {selectedDuration === '5' || selectedDuration === 5 ? <QuestionsTextField fullWidth label="Pulse" value={customDuration[index].label} onChange={e => {
                  let newCronLabel = customDuration;
                  newCronLabel[index].label = e.target.value
                  setCustomDuration([...newCronLabel])
                }} /> : ''}
              </Grid>

              <Grid item lg={2} md={2} sm={2} xs={6}>
                <DatePicker className="datepicker" minDate={new Date()}
                  shouldDisableDate={(date) => {
                    if (typeof (date) === 'string') {
                      date = new Date(date);
                    }
                    if (date.toDateString() === (new Date()).toDateString() && (new Date()).getHours() > 16) {
                      return true;
                    }
                    if (date !== undefined || date !== null)
                      return (date?.getDay() === 0 | date?.getDay() === 6 |

                        hd.filter(x => x.start.getDate() === date?.getDate() && x.start.getMonth() === date?.getMonth()).length > 0) ? true : false
                  }}
                  label="Start Date"
                  name="StartDate"
                  value={getDateValue(selectedDuration < 5 ? Survey.startDate : customDuration[index].startDate)}
                  fullWidth renderInput={(params) => (<PulseTextField  {...params} />)}
                  onChange={(e) => { setCustomDateandTime(index, e, null, null, null) }}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={6}>
                <TimePicker
                  disablePast={diasablePastTime(selectedDuration < 5 ? Survey.startDate : customDuration[index].startDate)}
                  className="datepicker"
                  label="Start time"
                  name="StartTime"
                  value={getTimeValue(getDateValue(selectedDuration < 5 ? Survey.startDate : customDuration[index].startDate), selectedDuration < 5 ? Survey.startHour : customDuration[index].startHour, selectedDuration < 5 ? Survey.startMin : customDuration[index].startMin)}
                  fullWidth renderInput={(params) => (<PulseTextField  {...params} />)}
                  onChange={(e) =>
                    setCustomDateandTime(index, null, e, null, null)
                  }
                  minTime={new Date().setHours(8, 0, 0, 0)}
                  maxTime={new Date().setHours(16, 0, 0, 0)}
                />
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={6}>
                {selectedDuration > 1 && selectedDuration < 5 ?
                  <TextField fullWidth label="No of Working Days" value={noOfWorkingDays} onChange={e => {
                    let seletedDurationl = parseInt(selectedDuration)
                    let noOfWorkingDaysChnages = parseInt(e.target.value)
                    if (noOfWorkingDaysChnages > 0 && (
                      (seletedDurationl === 2 && noOfWorkingDaysChnages < 6)
                      || (seletedDurationl === 3 && noOfWorkingDaysChnages < 11)
                      || (seletedDurationl === 4 && noOfWorkingDaysChnages < 21))
                    ) {
                      setNoOfWorkingDays(noOfWorkingDaysChnages)
                      let surveyVal = Survey
                      surveyVal.noOfWorkingDays = noOfWorkingDaysChnages
                      onSurveyChange(surveyVal)
                      OnHasUpdate(true)
                    }
                  }}
                    placeholder="No of Working Days" type="number"></TextField> :

                  <DatePicker className="datepicker"
                    disablePast={true}
                    label="End Date" name="EndDate"
                    minDate={selectedDuration < 5 ? Survey.startDate : customDuration[index].startDate}
                    value={getDateValue(selectedDuration === 1 ? Survey.endDate : customDuration[index].endDate)}
                    onChange={(e) => setCustomDateandTime(index, null, null, e, null)}
                    fullWidth renderInput={(params) => (<PulseTextField  {...params} />)}
                    shouldDisableDate={(date) => {
                      if (typeof (date) === 'string') {
                        date = new Date(date);
                      }
                      let startDate = selectedDuration === 1 ? Survey.startDate : customDuration[index].startDate

                      if (date !== undefined || date !== null)
                        return (
                          date < new Date(startDate) | date?.getDay() === 0 | date?.getDay() === 6 |
                          hd.filter(x => x.start.getDate() === date?.getDate() && x.start.getMonth() === date?.getMonth()).length > 0)
                          ? true : false
                    }}
                  />}
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={6}>
                {selectedDuration > 1 && selectedDuration < 5 ?
                  <TextField fullWidth label="Frequency" placeholder="Frequency" onChange={e => {
                    setFrequency(parseInt(e.target.value))
                    let surveyVal = Survey
                    surveyVal.frequency = parseInt(e.target.value)
                    onSurveyChange({ ...surveyVal })
                    OnHasUpdate(true)
                  }}
                    value={frequency} type="number"></TextField> :
                  <TimePicker
                    disablePast={diasablePastTime(selectedDuration > 5 ? Survey.endDate : customDuration[index].endDate)}
                    className="datepicker"
                    label="End time"
                    name="EndTime"
                    value={getTimeValue(getDateValue(selectedDuration === 1 ? Survey.endDate : customDuration[index].endDate), selectedDuration === 1 ? Survey.endHour : customDuration[index].endHour, selectedDuration === 1 ? Survey.endMin : customDuration[index].endMin)}
                    onChange={(e) => setCustomDateandTime(index, null, null, null, e)}
                    fullWidth renderInput={(params) => (<PulseTextField  {...params} />)}
                    minTime={new Date().setHours(8, 0, 0, 0)}
                    maxTime={new Date().setHours(16, 0, 0, 0)}
                  />}
              </Grid>

              <Grid item lg={2} md={2} xs={12}>
                {index > 0 ? <DeleteOutline color="action" style={{ fontSize: 20, marginTop: '14px' }} onClick={e => deleteRow(index)}></DeleteOutline> : ''}
              </Grid>


            </Grid>
          })}
          <Grid item lg={3} md={3} xs={6}>
            {selectedDuration === '5' || selectedDuration === 5 ? <Button className="next-button" onClick={addNewRow}>Add more </Button> : ''}
          </Grid>
        </Grid>
      </LocalizationProvider>
    </AppContainer >
  );
}

export default ScheduleForm;