import { Grid } from '@mui/material';
import React from 'react';

const BarLabels = () => {
    return (
        <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} >
                <div className='barlabels'>
                    <div className='label-percent' style={{ width: '0%' }}>
                        <span>0%</span>
                    </div>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((element, i) =>
                        <div className='label-percent' style={{width: `${element === 10 ? 9 : 10}% `}}>
                            <span>
                                {element * 10}%
                            </span>
                        </div>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default BarLabels;