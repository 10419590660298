import React, { Component } from 'react';
import { surveyService } from '../../services'
import { styled } from '@mui/material/styles';
import { Stepper, Step, StepLabel, Button, Grid, InputLabel, RadioGroup, FormControlLabel, MenuItem, FormControl, TextField, Snackbar, Alert } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { AppContainer, SurveyBlueRadio, SurveyYellowRadio, SurveyPinkRadio, QuestionsSelect, PulseTextField, SurveyRedRadio, SurveyGreenRadio } from '../../assets/styles/AppContainers';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 14,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 0,
        backgroundColor: theme.palette.mode === 'dark' ? '#007A7A' : '#007A7A',
    },
}));

class Survey extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            orgName: '',
            openSnak: false,
            progress: 2,
            questions: [],
            startSurvey: false,
            responses: [],
            loaded: false,
            currQuestion: {},
            currIdx: 0,
            closed: false,
            token: window.location.href.split('survey/token/')[1],
            currResponse: {
                surveyQuestionId: 0,
                comments: '',
                value: 0
            },
            timeWorking: null,
            employmentType: null,
            jobRole: null,
            durationAvailable: true,
            empTypeAvailable: true,
            jobRoleAvailable: true,
        };
        this.handleNext = this.handleNext.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.renderWelcomeScreen = this.renderWelcomeScreen.bind(this)
        this.renderOption = this.renderOption.bind(this)
    }

    componentWillMount() {
    }

    componentDidMount() {
        surveyService.GetSurveyQuestionsForSurvey(window.location.href.split('survey/token/')[1])
            .then((data) => {
                if (data) {
                    if (data.isParticipated) {
                        window.location.replace("#/submitted");
                    }
                    try {
                        this.setState(
                            {
                                orgName: data.orgName,
                                questions: data.surveyQuestions.sort((x, y) => x.order - y.order),
                                loaded: true,
                                activeStep: 0,
                                currQuestion: data.surveyQuestions[0],
                                closed: data.isClosed,
                                timeWorking: data.timeWorking,
                                employmentType: data.employmentType,
                                jobRole: data.jobRole,
                                durationAvailable: data.timeWorking ? true : false,
                                empTypeAvailable: data.employmentType ? true : false,
                                jobRoleAvailable: data.jobRole ? true : false,
                                currResponse: {
                                    surveyQuestionId: data.surveyQuestions[0].id,
                                    comments: '',
                                    value: 0
                                }
                                ///////////////
                            })

                    } catch {

                        this.setState(
                            {
                                loaded: true,
                                closed: data.isClosed,
                            });
                    }
                }
                else {
                    this.setState(
                        {
                            questions: [],
                            loaded: true,
                            closed: true
                        })
                }
            })
            .catch((err) => {
                this.setState(
                    {
                        questions: [],
                        loaded: true,
                    })
            });
    }
    updateResponses(currVal, nextVal) {
        if (this.state.currResponse.value === 0 && this.state.currQuestion.questionLikertFormat === 2) {
            this.setState({ openSnak: true })
            return;
        }
        this.setState({ process: (nextVal / this.state.questions.length) * 100 });
        if (nextVal <= this.state.questions.length) {
            let newresp = this.state.responses;
            if (currVal < nextVal) {
                let idx = this.state.responses.findIndex(x => x.surveyQuestionId === this.state.currResponse.surveyQuestionId)
                if (idx === -1) {
                    newresp.push(this.state.currResponse);
                }
                else {
                    newresp[idx] = this.state.currResponse;
                }
            }
            let nextCurrResp = -1;

            if (nextVal <= this.state.questions.length - 1) {
                nextCurrResp = this.state.responses.findIndex(x => x.surveyQuestionId === this.state.questions[nextVal].id);

                this.setState({
                    currIdx: nextVal,
                    currQuestion: this.state.questions[nextVal],
                    responses: [...newresp],
                    currResponse: nextCurrResp > -1 ? newresp[nextCurrResp] : {
                        surveyQuestionId: this.state.questions[nextVal].id,
                        comments: '',
                        value: 0
                    }
                })
                // skip or show next question
                if (currVal < nextVal) {
                    if (this.state.questions[currVal].skipNextOnValue) {
                        let idx = this.state.questions[currVal].skipNextOnValue.split(',').findIndex(x => parseInt(x) === this.state.currResponse.value);
                        if (idx > -1) {
                            this.updateResponses(nextVal, nextVal + 1);
                        }
                    }
                }
                else if ((nextVal - 1) > -1) {
                    if (this.state.questions[nextVal - 1].skipNextOnValue) {
                        let idx = this.state.questions[nextVal - 1].skipNextOnValue.split(',').findIndex(x => parseInt(x) === this.state.responses[nextVal - 1].value);
                        if (idx > -1) {
                            this.updateResponses(nextVal, nextVal - 1);
                        }
                    }
                }
            }
            else {
                this.setState({
                    currIdx: nextVal,
                })
            }
        }
        // else if (nextVal === this.state.questions.length + 1) 
        // {
        //     this.setState({
        //         currIdx: nextVal,
        //     })
        // }
        else {

            // let emptyResponse = this.state.responses.filter(x => (x.value === 0 || x.value === null) && x.comments === null)
            // if (emptyResponse.length > 0) {
            //     this.setState({ openSnak: true })
            // }
            // else {
            //     if (this.state.responses.filter(x => x.value === 0 //&& x.comments === ''
            //     ).length > 0) {
            //         this.setState({ openSnak: true })
            //         return;
            //     }
            surveyService.SubmitSurveyResponse(
                {
                    surveyToken: this.state.token,
                    responses: { participantId: 0, surveyAnswers: this.state.responses },
                    timeWorking: this.state.timeWorking,
                    employmentType: this.state.employmentType,
                    jobRole: this.state.jobRole
                });
            window.location.replace("#/submitted")
            //  }
        }
    }
    handleNext() {
        //  this.setState({ process: this.state.currIdx === 0 ? 0 : (((this.state.currIdx + 1) / this.state.questions?.length) * 100) });
        this.updateResponses(this.state.currIdx, this.state.currIdx + 1)
    }
    handleBack() {
        //    this.setState({ process: this.state.currIdx === 0 ? 0 : (((this.state.currIdx - 1) / this.state.questions?.length) * 100) });
        this.updateResponses(this.state.currIdx, this.state.currIdx - 1)
    }
    renderQuestions(question) {
        let div =

            <Stepper activeStep={this.state.currIdx} style={{ width: '70%' }}>

                {this.state.questions.map((question, index) => (
                    <Step key={`q-${index + 1}`} style={{ width: '70%' }}>
                        <StepLabel autoFocus={question.id === this.state.currQuestion.id ? true : false}>
                            {`Q-${index + 1}`}
                        </StepLabel>
                    </Step>
                ))}
                {(!this.state.durationAvailable || !this.state.empTypeAvailable) ?
                    <Step key={'finish-slide'} style={{ width: '70%' }}>
                        <StepLabel>Employment Status
                        </StepLabel>
                    </Step> : ''
                }
                <Step key={'finish-slide'} style={{ width: '70%' }}>
                    <StepLabel                         >
                        Submit
                    </StepLabel>
                </Step>
            </Stepper >
        return div;
    }
    getRadioLabel(opt, level) {
        return opt[level]?.text;
    }

    renderOption() {
        let curoption =
            this.state.currIdx < this.state.questions.length ?
                <Grid container>
                    <Grid item lg={12} md={12} xs={12}>
                        <div className='survey-questions'>
                            <div className='surveys-questioneir'>
                                <h1>{this.state.currQuestion.text}</h1>
                                {this.state.currQuestion.explanation ? <h3>{this.state.currQuestion.explanation}</h3> : ''}
                                {this.state.currQuestion.questionLikertFormat === 1 ? <TextField
                                    multiline
                                    fullWidth
                                    rows={2}
                                    placeholder="Type comments here" value={this.state.currResponse.comments}
                                    style={{ width: '75%', marginTop: 10 }}
                                    onChange={(e) => this.setState({ currResponse: { surveyQuestionId: this.state.currQuestion.id, comments: e.target.value, value: 0 } })}></TextField> :
                                    <>
                                        <Grid item lg={12} md={12} xs={12}>
                                            <div className='survey-radio'>
                                                <RadioGroup
                                                    className='survey-radiogroup'
                                                    row onChange={(e) => {
                                                        let currResp = this.state.currResponse;
                                                        currResp.surveyQuestionId = this.state.currQuestion.id;
                                                        currResp.value = parseInt(e.target.value);

                                                        this.setState({ currResponse: { ...currResp } }, () => {
                                                            if (this.state.currQuestion.additionalInfoRequired === true) {
                                                                return;
                                                            }
                                                            setTimeout(() => {
                                                                this.handleNext()
                                                            }, 1000)
                                                        })
                                                    }}
                                                    value={this.state.currResponse.value}
                                                >
                                                    {
                                                        this.state.currQuestion.surveyQuestionOptions.map((opt, idx) => <FormControlLabel className='radio-label' value={opt.value} control={
                                                            opt.value === 1 ? <SurveyRedRadio disableRipple /> : opt.value === 2 ? <SurveyBlueRadio disableRipple /> : opt.value === 3 ? <SurveyYellowRadio disableRipple /> : opt.value === 4 ? <SurveyGreenRadio disableRipple /> : opt.value === 5 ? <SurveyPinkRadio disableRipple /> :
                                                                <SurveyBlueRadio disableRipple />} label={opt.text} labelPlacement="bottom" />)
                                                    }
                                                    {/*<FormControlLabel value="1" control={<SurveyRadio />} label={this.getRadioLabel(this.state.currQuestion.surveyQuestionOptions, 0)} labelPlacement="bottom" />
                                                        <FormControlLabel value="2" control={<SurveyRadio />} label={this.getRadioLabel(this.state.currQuestion.surveyQuestionOptions, 1)} labelPlacement="bottom" />
                                                        <FormControlLabel value="3" control={<SurveyRadio />} label={this.getRadioLabel(this.state.currQuestion.surveyQuestionOptions, 2)} labelPlacement="bottom" />
                                                        <FormControlLabel value="4" control={<SurveyRadio />} label={this.getRadioLabel(this.state.currQuestion.surveyQuestionOptions, 3)} labelPlacement="bottom" />
                                                    <FormControlLabel value="5" control={<SurveyRadio />} label={this.getRadioLabel(this.state.currQuestion.surveyQuestionOptions, 4)} labelPlacement="bottom" />*/}
                                                </RadioGroup>
                                            </div>
                                        </Grid>
                                        {
                                            this.state.currQuestion.additionalInfoRequired === true ?
                                                <Grid item lg={9} md={9} xs={12} style={{ margin: '40px auto 0 auto' }}>
                                                    <h3 style={{ lineHeight: '1.4em' }}>{this.state.currQuestion.additionalInfoText}</h3>
                                                    <TextField
                                                        multiline
                                                        fullWidth
                                                        rows={2}
                                                        placeholder="Type comments here" value={this.state.currResponse.comments}
                                                        style={{ marginTop: 10 }} onChange={(e) => {
                                                            let currResponse = this.state.currResponse;
                                                            currResponse.surveyQuestionId = this.state.currQuestion.id;
                                                            currResponse.comments = e.target.value

                                                            this.setState({ currResponse: { ...currResponse } });
                                                        }}></TextField>
                                                </Grid>
                                                : ''
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid > : <>
                    {this.state.currIdx === this.state.questions.length && (!this.state.employmentType | !this.state.timeWorking | !this.state.jobRole) ? (
                        <div className='thankyou'>
                            <Grid container columnSpacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12} textAlign='center'>
                                    <h1>Employment Status</h1>
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}></Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} textAlign='left'>
                                    {this.state.empTypeAvailable === false ?
                                        <FormControl sx={{ mt: 2 }} size="large" label="Job type" fullWidth>
                                            <InputLabel id="job-select-label">Job Type</InputLabel>
                                            <QuestionsSelect
                                                label='Job Type'
                                                labelId="job-select-label"
                                                value={this.state.employmentType}
                                                onChange={(e) => this.setState({ employmentType: e.target.value })}
                                                displayEmpty
                                            //inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value={1}>Full Time</MenuItem>
                                                <MenuItem value={2}>Part Time</MenuItem>
                                            </QuestionsSelect>
                                        </FormControl> : ''}
                                    {/* </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} textAlign='left'> */}
                                    {this.state.durationAvailable === false ?
                                        <FormControl sx={{ mt: 2 }} size="large" fullWidth>
                                            <InputLabel id="time-select-label">Time At School</InputLabel>
                                            <QuestionsSelect
                                                label='Time At School'
                                                labelId="Time-select-label"
                                                value={this.state.timeWorking}
                                                onChange={(e) => this.setState({ timeWorking: e.target.value })}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value={1}>Less than 1 Year</MenuItem>
                                                <MenuItem value={2}>1 to 3 Years</MenuItem>
                                                <MenuItem value={3}>4 to 5 Years</MenuItem>
                                                <MenuItem value={4}>6 to 10 Years</MenuItem>
                                                <MenuItem value={5}>11 to 15 Years</MenuItem>
                                                <MenuItem value={6}>16 to 20 Years</MenuItem>
                                                <MenuItem value={7}>Greater than 20 Years</MenuItem>
                                            </QuestionsSelect>
                                        </FormControl> : ''}
                                    {/* </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12} textAlign='left'> */}
                                    {this.state.jobRoleAvailable === false ?
                                        <FormControl sx={{ mt: 2 }} size="large" label="Job Role" fullWidth>
                                            <InputLabel id="role-select-label">Job Role</InputLabel>
                                            <QuestionsSelect
                                                label='Job Role'
                                                labelId="role-select-label"
                                                value={this.state.jobRole}
                                                onChange={(e) => this.setState({ jobRole: e.target.value })}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value={4}>Teaching Staff (Classroom)</MenuItem>
                                                <MenuItem value={8}>Teaching Staff (Non-Classroom)</MenuItem>
                                                <MenuItem value={16}>Support Staff (Classroom)</MenuItem>
                                                <MenuItem value={32}>Support Staff (Non-Classroom)</MenuItem>
                                                <MenuItem value={64}>Middle Leader</MenuItem>
                                                <MenuItem value={128}>Senior Leader</MenuItem>
                                            </QuestionsSelect>
                                        </FormControl> : ''}
                                </Grid>
                                <Grid item lg={4} md={4} sm={12} xs={12}></Grid>
                            </Grid ></div>) : (
                        <div className='thankyou'>
                            <Snackbar anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }} open={this.state.openSnak}
                                onClose={() => this.setState({ openSnak: false })}
                                autoHideDuration={2000}>
                                <Alert onClose={() => () => this.setState({ openSnak: false })} severity="error" sx={{ width: '100%' }}>
                                    Few Questions were not answered. Please go back
                                </Alert>
                            </Snackbar>
                            <h2>Thank you for completing our survey. Please click below to submit your response.</h2>
                            <Button
                                variant="contained"
                                onClick={this.handleNext}
                                className='next-button'
                                sx={{ mr: 2 }}
                            >Submit
                            </Button></div>)}</>
        return curoption;
    }

    renderQuestionair() {
        const questionSlider =

            <Grid container>
                <Grid item lg={12} md={12} xs={12} >
                    <Snackbar anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }} open={this.state.openSnak}
                        onClose={() => this.setState({ openSnak: false })}
                        autoHideDuration={2000}>
                        <Alert onClose={() => () => this.setState({ openSnak: false })} severity="error" sx={{ width: '100%' }}>
                            Please provide a response before moving to the next question
                        </Alert>
                    </Snackbar>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    {this.renderOption()}
                    <div className='survey-buttons'>
                        <Button
                            disabled={this.state.currIdx === 0}
                            onClick={this.handleBack}
                            className='next-button'
                            sx={{ ml: 2 }}
                        >
                            Back
                        </Button>
                        {this.state.currIdx < this.state.questions.length ? <Button
                            variant="contained"
                            onClick={this.handleNext}
                            className='next-button'
                            sx={{ mr: 2 }}
                        >
                            Next
                        </Button> : ''}
                    </div>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                    <div className='progress'>
                        <BorderLinearProgress value={this.state.process} height={4} variant="determinate"></BorderLinearProgress>
                    </div>
                </Grid>
            </Grid>

        return questionSlider;
    }
    renderWelcomeScreen() {
        const welcomeScreen = this.state.loaded ?
            <Grid container justifyContent="center">
                <Grid item lg={6} md={8} sm={12} xs={12}>
                    <div className='thankyou'>
                        <img alt='' src='https://welbee.international/wp-content/uploads/2021/04/welbee_logo_yellow.png' className='image-responive'></img>
                        <div>
                            <h1>Welcome</h1>
                            <h3>
                                {/* Please help make{' '}
                                <span className='school-name'>
                                    {this.state.orgName ? this.state.orgName : ' you work place '}
                                </span>
                                {' '}an even better place to work! */}


                                Please take the survey and provide feedback to   {this.state.orgName ? this.state.orgName : ' you work place '}. All the answers you provide are anonymous and cannot be attributed to you. Thank you for participating.
                            </h3>
                        </div>
                        {/* <h3 style={{ margin: '40px 0' }}>
                            <p>
                                We are committed to supporting all staff with their
                                wellbeing and this survey will help us to do that. The
                                survey should take around ten minutes to complete and when
                                answering each question please think about your work over
                                the last six months. All the answers you give will be
                                anonymous. Thank you for participating.
                            </p>  </h3> */}
                        <Button variant="contained" className='next-button' onClick={() => this.setState({ startSurvey: true, process: 2 })}>
                            Start Your Survey
                        </Button>
                    </div>
                </Grid>
            </Grid>
            : '';
        return welcomeScreen;
    }
    renderClosedSurvey() {
        const welcomeScreen = this.state.loaded ?
            <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                    <div className='thankyou'>

                        <h1>Survey is closed.</h1>
                    </div>
                </Grid>
            </Grid>
            : '';
        return welcomeScreen;
    }
    render() {
        return (
            <AppContainer>{
                this.state.closed ? this.renderClosedSurvey() : (
                    this.state.startSurvey ?
                        this.renderQuestionair() :
                        this.renderWelcomeScreen())}
            </AppContainer>
        )
    }
}

export default Survey;