import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { green } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import '../../assets/styles/app.scss';
import { WhiteLayoutContent, QuestionsTextField, LoginContainer } from '../../assets/styles/AppContainers';
import { iconlogin } from '../../assets/images/index';
import Stack from '@mui/material/Stack';
import accountService from '../../services/api/accountService';
import { useDispatch } from 'react-redux';
import { updateOrgInfo } from '../../redux/features/orgSlice'
import { getFolders } from '../../redux/features/folderSlice';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';


function Login(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPwd] = useState('');
    const [lockScreen, setLockScreen] = useState(false);
    const [err, setErr] = useState('');
    const [pErr, setPErr] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        dispatch(updateOrgInfo({}));
    }, [])

    const onLogin = () => {
        if (!lockScreen) {
            if (email === '' || email === undefined || email === null) {
                setErr('Email or username is required.')
                return;
            }
            if (password === '' || password === undefined || password === null) {
                setPErr('Password is required.')
                return;
            }
            setErr('')
            setPErr('')
            setLockScreen(true);            
            accountService
                .Login({ email: email, password: password, rememberMe: false })
                .then(resp => {
                    let respObj = JSON.parse(resp)
                    setLockScreen(false);
                    if (respObj.success) {                        
                        dispatch(updateOrgInfo(respObj.data));
                        //dispatch(getFolders(respObj.data.schoolId))
                        dispatch(getFolders({ orgId: respObj.data.MatGroupId !== null ? respObj.data.MatGroupId : respObj.data.schoolId, isMat: respObj.data.MatGroupId !== null }))
                        navigate(`/dashboard`)
                    }
                    else {
                        setErr(respObj.message)
                    }
                })
                .catch(err => {                    
                    setLockScreen(false);
                    setErr(err.message);
                })

        }
    }
    return (
        <Grid container spacing={6}>
            <Grid item xs={12} lg={5} md={5} display={{xs:'none', lg:'block'}}>
                <div className='login-main'>
                    <h1>Welcome to <span>Welbee Pulse</span></h1>
                    <p>Systematically measure and improve staff wellbeing<br />across your School or Group</p>
                    <div className='login-symbol'>
                        <img src={iconlogin} alt='' />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
                <LoginContainer>
                    <div className='login-logo'>
                        <img alt='' src='https://welbee.international/wp-content/uploads/2021/04/welbee_logo_yellow.png'></img>
                    </div>
                    <WhiteLayoutContent>
                        <Stack spacing={2} className='login'>
                            <h1>Welcome Back</h1>
                            <p>Please enter your login details</p>

                            <QuestionsTextField fullWidth
                                placeholder='Email'
                                label='Email'
                                value={email}
                                onChange={e => { setErr(''); setEmail(e.target.value) }}>
                            </QuestionsTextField>
                            {err && <div style={{ color: 'red' }}>{err}</div>}

                            <QuestionsTextField fullWidth
                                label='Password'
                                value={password}
                                type={showPassword ? "text" : "password"}
                                InputProps={
                                    {
                                        endAdornment: (
                                            <InputAdornment sx={{ fontSize: 24 }} position="end" onClick={() => setShowPassword(!showPassword)}> {
                                                showPassword ? (
                                                    <Visibility className="cursor_pointer" />
                                                ) : (
                                                    <VisibilityOff />
                                                )
                                            }
                                            </InputAdornment>
                                        ),
                                    }
                                }
                                onChange={e => { setErr(''); setPwd(e.target.value) }}>
                            </QuestionsTextField>
                            {pErr && <div style={{ color: 'red' }}>{pErr}</div>}

                            <div className='pink-link'>Forgot your password?</div>
                            <Button className={lockScreen ? 'grey-button' : 'login-button'} onClick={onLogin} disabled={lockScreen}>Login</Button>
                            {lockScreen && (
                                <CircularProgress
                                    size={24} className='login-button-lock'
                                />
                            )}
                        </Stack>
                    </WhiteLayoutContent>
                </LoginContainer>
            </Grid>
        </Grid>
    );
}

export default Login;