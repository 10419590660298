import { surveyService } from '../services';
import { JobRole } from './Constants'

export const ToJobRoleString = (val) => {

    return JobRole.find(x => x.id === val)?.Name
}

// export function GetDateFromString(dateStr)
// {
//     let date = new Date(dateStr);
//     const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

//     let dateFormat = date.getDate() + "/" + monthNames[date.getMonth()] + "/" + date.getFullYear()
//     return dateFormat;
// }

export function GetDateFromString(dateStr) {
    let date = new Date(dateStr);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    let dateFormat = date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0') + "/" + date.getFullYear()
    return dateFormat;
}
export function LoadSurveyDashboard(token) {
    surveyService.GetLatestPulseToken(token)
        .then(data => {
            if (data) {
                window.location = `#/surveys/dashboard/token/${data}`
            }
        })

}

export const formatUKDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB').format(date);
};