import request from '../request';
import { questionSetApi } from '../Urls';

export class questionSetService
{
    static async Get()
    {
        return request({
            url: questionSetApi.Get(),
        });
    }
}