import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isMat: false,
    isSchool: false,
    isSuperAdmin: false,
    orgId: 0,
    name: '',
    userInfo: {},
    access_token: '',
    expires_in: null,
    name: '',
}


export const orgSlice = createSlice({
    name: 'Organization',
    initialState,
    reducers: {
        updateOrgInfo: (state, action) => {
            state.userInfo = action.payload.userInfo
            state.isMat = action.payload.MatGroupId && action.payload.schoolId === 0 ? true : false
            state.isSchool = action.payload.schoolId === 0 ? false : true
            state.isSuperAdmin = action.payload.schoolId === 0 && action.payload.MatGroupId === null
            state.orgId = action.payload.MatGroupId && action.payload.schoolId === 0 ? action.payload.MatGroupId : action.payload.schoolId
            state.access_token = action.payload.access_token
            state.expires_in = action.payload.expires_in
            state.name = action.payload.schoolName
            localStorage.setItem('token', action.payload.access_token)
        },
    },
})

export const { updateOrgInfo } = orgSlice.actions;
export default orgSlice.reducer 