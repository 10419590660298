import React, { useEffect } from 'react';
import { getFolders } from '../../redux/features/folderSlice';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateOrgInfo } from '../../redux/features/orgSlice';

const SuperAdmin = () => {
    //const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const urlParams = window.location.hash.substring(1).split('?')[1].split('&');

    let isMatOrg = false;
    let orgId = 0;

    isMatOrg = urlParams[3].split('=')[1] === 'true';

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const name = atob(urlParams[0].split('=')[1]);
    const email = atob(urlParams[1].split('=')[1]);
    const ismat = isMatOrg;
    const access_token = urlParams[4].split('=')[1];
    const id = parseInt(urlParams[2].split('=')[1]);

    useEffect(() => {

        const orgInfo = {
            userInfo: { Name: name, Email: email },
            orgId: id,
            MatGroupId: ismat ? id : null,
            schoolId: !ismat ? id : 0,
            isSuperAdmin: true,
            access_token: access_token,
            expires_in: 8888888,
            schoolName: name

        }
        dispatch(updateOrgInfo(orgInfo));
        dispatch(getFolders({ orgId: orgInfo.orgId, isMat: ismat }))

        navigate(`/dashboard`)

    }, []);



    return (
        <div>
            <LoadingButton></LoadingButton>
        </div>
    );
};

export default SuperAdmin;
