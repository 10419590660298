import { combineReducers, configureStore } from '@reduxjs/toolkit'
import folderReducer from './features/folderSlice';
import questionPoolReducer from './features/questionPoolSlice';
import currSurveyReducer from './features/surveySlice'
import questionBankReducer from './features/questionBankSlice';
import schoolTimezoneReducer from './features/schoolTimezoneSlice'
import orgReducer from './features/orgSlice';


import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    folders: folderReducer,
    organization: orgReducer,
    questionSets: questionPoolReducer,
    currSurvey: currSurveyReducer,
    questionBank: questionBankReducer,
    schoolTimezone: schoolTimezoneReducer
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer
})