import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, FormControl, InputLabel, Skeleton } from '@mui/material';
import { surveyService } from '../../services';
import SurveyBlock from '../../components/SurveyBlock';
import { QuestionsSelect, QuestionsTextField, Surveys } from '../../assets/styles/AppContainers';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
function SurveyList({ Status }) {
    const { fid } = useParams();
    const [surveys, setSurveys] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(Status)
    const [selectedName, setName] = useState('')
    const [folderName, setFolderName] = useState(null);
    const [loading, setLoading] = useState(true)

    const { organization } = useSelector((store) => store)
    const { folders } = useSelector((store) => store.folders)

    useEffect(() => {
        GetList()
    }, [fid]);

    const GetList = () => {
        surveyService.GetSurveysByOrgId(organization.orgId, organization.isMat, fid !== undefined ? fid : null)
            .then((data) => {
                setSurveys(data.surveys);
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
            });
        if (fid !== undefined && fid !== null) {
            let folder = folders.find(x => x.id === fid);
            if (folder) {
                setFolderName(folder.name);
                setLoading(false)
            }
            else {
                setFolderName(null);
                setLoading(false)
            }
        }
    }
    const reloadList = (id) => {
        setLoading(true)
        GetList()
    }
    const GetFilteredList = () => {
        //let sList = surveys.filter(x => x.surveyStatus === (selectedStatus === 0 ? x.surveyStatus : selectedStatus));
        let sList = [];//surveys.filter(x => x.surveyStatus === (selectedStatus === 0 ? x.surveyStatus : selectedStatus));
        if (selectedStatus === 1) {
            sList = surveys?.filter(x => x.surveyStatus === 1).sort((x, y) => {
                if (x.id > y.id) { return -1 } else { return 1 }
            });// open and draft
        }
        else {
            sList = surveys?.filter(x => x.surveyStatus === (selectedStatus === 0 ? x.surveyStatus : selectedStatus)).sort((x, y) => { if (x.id > y.id && x.surveyStatus == y.surveyStatus) { return -1 } else { return 1 } });
        }
        if (selectedName !== undefined || selectedName !== null || selectedName !== '') { sList = sList?.filter(x => x.name.toLowerCase().indexOf(selectedName.toLowerCase()) > -1) }
        let div = <div className='no-block'>No {selectedStatus === 0 ? '' : selectedStatus === 1 ? 'draft' : selectedStatus === 2 ? 'draft' : selectedStatus === 3 ? 'live' : 'completed'} surveys found</div>;

        if (sList?.length > 0) {
            div = sList.map(item => <SurveyBlock survey={item} showReruns={true} reloadList={reloadList}></SurveyBlock>)
        }
        return div;
    }
    return (
        <>
            <Grid container spacing={1}>
                <Grid item lg={7} md={7} xs={12} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <h1>
                        {folderName ? folderName : 'All Surveys'}
                    </h1>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <FormControl fullWidth sx={{ my: 1 }}>
                        <QuestionsTextField size='small' label='Search Survey Name' onChange={e => setName(e.target.value)}></QuestionsTextField>
                    </FormControl>
                </Grid>
                <Grid item lg={2} md={2} sm={6} xs={12} >
                    <FormControl fullWidth sx={{ my: 1 }}>
                        <InputLabel id="select-label">Status Filters</InputLabel>
                        <QuestionsSelect
                            labelId="select-label"
                            value={selectedStatus}
                            placeholder={'Select Status'}
                            label={'Select Status'}
                            size='small'
                            onChange={(e) => setSelectedStatus(e.target.value)}>
                            <MenuItem value={0}>All</MenuItem>
                            <MenuItem value={1} >Draft</MenuItem>
                            <MenuItem value={2}>Scheduled</MenuItem>
                            <MenuItem value={3}>Live</MenuItem>
                            <MenuItem value={4}>Completed</MenuItem>
                        </QuestionsSelect>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item lg={12} md={12} xs={12}>
                    {loading ?
                        <div>
                            <Skeleton width='100%' height={20} />
                            <Skeleton width='100%' height={20} />
                            <Skeleton width='100%' height={20} />
                        </div>
                        : GetFilteredList()}

                </Grid>
            </Grid >
        </>
    );
}

export default SurveyList;